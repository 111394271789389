import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../registration/registration.css";
const Footer = () => (
  <div className="main-footer text-center">
    <Container>
      <Row className="row-sm">
        <Col md={6} className="col-md-6 text-start">
          <span>
            Copyright © {new Date().getFullYear()}{" "}
            <a
              href="https://pythiangames.org/"
              target="_blank"
              rel="noreferrer"
            >
              Modern Pythian Games
            </a>
            . All rights reserved.
          </span>
        </Col>
        <Col md={6} className="col-md-6 text-end">
          <span>
            Designed by{" "}
            <a
              target="_blank"
              href="https://sportingindia.tech/"
              rel="noreferrer"
            >
              Sportingindia
            </a>
          </span>
        </Col>
      </Row>
    </Container>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
