import moment from "moment/moment";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import imageUrl from "../../../ImageUrl";
import {
  evententryview,
  eventwiseentryview,
  userdataview,
} from "../../../services/admin";
import "./list.css";
const Eventwiseview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [eid, setEventid] = useState("");
  const [name, setEventName] = useState("");
  const [venue, setEventVenue] = useState("");
  const [categname, setCategoryName] = useState("");

  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [member, setMember] = useState("");
  const [gender, setGender] = useState("");

  const [files, setFiles] = useState([]);
  let arr = [];
  const [uname, setUser] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDOB] = useState("");
  const [username, setUsername] = useState("");
  const [utype, setUsertupe] = useState("");

  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [ishow, setImage] = useState(false);
  const [file, setFile] = useState(false);
  var efile = "";

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }
  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/");
    const viewlist = last[last.length - 2];
    setUsertupe(viewlist);

    eventwiseentryview(id).then((response) => {
      if (response.status === true) {
        setEventid(response.data.details.event_uid);
        setEventName(response.data.details.event_name);
        setEventVenue(response.data.details.event_venue);
        setSDate(
          moment(response.data.details.event_start).format("DD-MM-YYYY")
        );
        setEDate(moment(response.data.details.event_end).format("DD-MM-YYYY"));
        setCDate(
          moment(response.data.details.event_entry_close).format("DD-MM-YYYY")
        );
        if (response.data.details.membership_type !== null) {
          setMember(response.data.details.membership_type);
        }

        setGender(response.data.details.gender);
        if (response.data.details.address_1 !== "address") {
          setAddress(response.data.details.address_1);
        }
        if (response.data.details.country_name !== null) {
          setCountry(response.data.details.country_name);
        }
        if (response.data.details.state_name !== null) {
          setState(response.data.details.state_name);
        }
        if (response.data.details.city_name !== null) {
          setCity(response.data.details.city_name);
        }
        if (response.data.details.pincode !== null) {
          setPincode(response.data.details.pincode);
        }

        setUser(
          response.data.details.first_name +
            " " +
            response.data.details.last_name
        );
        // setUsername(response.data.event_entry_files.username);

        setMobile(response.data.details.mobile);
        setEmail(response.data.details.email);
        setDOB(moment(response.data.details.dob).format("DD-MM-YYYY"));
        arr = [];
        setFiles([]);
        if (response.data.entry_files.length !== 0) {
          for (let i = 0; i < response.data.entry_files.length; i++) {
            if (efile !== response.data.entry_files[i].category) {
              efile = response.data.entry_files[i].category;
            } else {
              efile = "";
            }
            arr.push({
              category: efile,
              name: response.data.entry_files[i].file_name,
              type: response.data.entry_files[i].file_type,
            });
            // setEventid(response.dataevent_entry_files.entry_files[2].event_uid);
          }
          const cats = [
            ...new Set(response.data.entry_files.map((q) => q.category)),
          ];
          if (cats.length !== 0) {
            var cname = "";
            for (let i = 0; i < cats.length; i++) {
              if (cname === "") {
                cname = cats[i];
              } else if (cname === "") {
                cname = cname + " , " + cats[i];
              }
            }
          }
          setCategoryName(cname);
        }
        setFiles(arr);
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            User Registration View
          </h2>
        </div>
      </div>

      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="row">
                  <h4>Event Details</h4>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Name</b>
                    </div>
                    <div className="col-md-8">
                      <span>{name}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Venue</b>
                    </div>
                    <div className="col-md-8">
                      <span>{venue}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Start Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{sdate}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event End Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{edate}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Result Declare Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{cdate}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Name</b>
                      </div>
                      <div className="col-md-8">
                        <span>{uname}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Date of Birth</b>
                      </div>
                      <div className="col-md-8">
                        <span>{dob}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Mobile</b>
                      </div>
                      <div className="col-md-8">
                        <span>{mobile}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Email</b>
                      </div>
                      <div className="col-md-8">
                        <span>{email}</span>
                      </div>
                    </div>
                  </div>
                  {member !== "" && (
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Membership Type</b>
                        </div>
                        <div className="col-md-8">
                          <span>{member}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Gender</b>
                      </div>
                      <div className="col-md-8">
                        <span>{gender}</span>
                      </div>
                    </div>
                  </div>

                  {utype === "eventuserview" && (
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Username</b>
                        </div>
                        <div className="col-md-8">
                          <span>{username}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Category</b>
                      </div>
                      <div className="col-md-8">
                        <span style={{color:"green",fontWeight:"700"}}>{categname}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <h4>Primary Information</h4>
                <div className="row">
                  {address !== "" && (
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Address</b>
                        </div>
                        <div className="col-md-8">
                          <span>{address}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {country !== "" && (
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Country</b>
                        </div>
                        <div className="col-md-8">
                          <span>{country}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {state !== "" && (
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span>{state}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {city !== "" && (
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">City</b>
                        </div>
                        <div className="col-md-8">
                          <span>{city}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {pincode !== "" && (
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span>{pincode}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr />
                <h4>File Details</h4>
                <div className="row">
                  {files.map((data, i) => {
                    return (
                      <div className="col-md-6" key={i}>
                        {data.type === 1 && (
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Video</b>
                                <div>
                                  <video width="100%" height="100%" controls>
                                    <source
                                      src={
                                        imageUrl +
                                        "evententry/video/" +
                                        data.name
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                  <div>
                                    <a
                                      href={
                                        imageUrl +
                                        "evententry/video/" +
                                        data.name
                                      }
                                      className="btn-small"
                                      target="_blank"
                                      download
                                    >
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.type === 2 && (
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Audio</b>
                                <div>
                                  <video width="100%" height="100%" controls>
                                    <source
                                      src={
                                        imageUrl +
                                        "evententry/audio/" +
                                        data.name
                                      }
                                      type="Audio/mp3"
                                    />
                                  </video>
                                  <div>
                                    <a
                                      href={
                                        imageUrl +
                                        "evententry/audio/" +
                                        data.name
                                      }
                                      className="btn-small"
                                      target="_blank"
                                      download
                                    >
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.type === 3 && (
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Image</b>
                                <a>
                                  <img
                                    className="profile_img"
                                    src={
                                      imageUrl + "evententry/image/" + data.name
                                    }
                                    height="160"
                                    alt="photo"
                                  />
                                </a>
                                <div>
                                  <a
                                    onClick={(e) =>
                                      ImageShow(
                                        imageUrl +
                                          "evententry/image/" +
                                          data.name
                                      )
                                    }
                                    className="btn-small btn-s"
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                  <a
                                    href={
                                      imageUrl + "evententry/image/" + data.name
                                    }
                                    className="btn-small"
                                    target="_blank"
                                    download
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.type === 4 && (
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Document</b>
                                <a
                                  href={
                                    imageUrl + "evententry/docs" + data.name
                                  }
                                >
                                  <img
                                    className="profile_img"
                                    src={require("../../../assets/images/word.png")}
                                    height="160"
                                    alt="photo"
                                  />
                                </a>
                                <div>
                                  <a
                                    href={
                                      imageUrl + "evententry/docs" + data.name
                                    }
                                    className="btn-small"
                                    target="_blank"
                                    download
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {i % 2 === 1 && <br />}
                      </div>
                    );
                  })}
                </div>
                <br />
                <br />

                <hr />

                <a
                  className="btn btn-next btn-secondary"
                  style={{ float: "right" }}
                  onClick={() => {
                    navigate(`/eventwiselist/${eid}`);
                  }}
                >
                  Back
                </a>
              </div>
              <Modal show={ishow} onHide={ImageClose}>
                <Modal.Body className="hhh">
                  <img
                    className="profile_img prof-img"
                    src={file}
                    height="160"
                    alt="photo"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={ImageClose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Eventwiseview;
