import React, { useState } from "react";
// import "antd/dist/antd.css";
import axios from "axios";
import { Upload, Progress } from "antd";

export const Fileupload = () => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [progress, setProgress] = useState(0);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, action, onProgress } = options;
    const url = action;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("image", file);
    try {
      const res = await axios.post(url, fmData, config);

    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const handleOnChange = ({ file, fileList, event }) => {
    // console.log(file, fileList, event);
    //Using Hooks to update the state to the current filelist
    if(fileList[0].percent === 100){
        setDefaultFileList(fileList);
    }
   
    //filelist - [{uid: "-1",url:'Some url to image'}]
  };

  return (
    <div class="container">
      <Upload
        accept="image/*"
        customRequest={uploadImage}
        onChange={handleOnChange}
        listType="picture-card"
        className="image-upload-grid"
      >
        {defaultFileList.length >= 1 ? null : <div>Upload Button</div>}
      </Upload>
      <Progress percent={progress} />
      {/* {progress > 0 ? <Progress percent={progress} /> : null} */}
    </div>
  );
};
