import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

// Sentry.init({
//     dsn: 'https://50774c50c7004584bc8c5ca7ce9c89dc@o616715.ingest.sentry.io/6776681',
//     integrations: [new BrowserTracing()],
  
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <App />
    
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
