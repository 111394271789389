import { Component } from "react";
import Swal from "sweetalert2";

class Categorystatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
    if (this.props.data.is_active === 1) {
      this.state.checked = true;
    }
    // this.handleSwitch = this.handleSwitch.bind(this);
  }

  // handleSwitch(e) {
  //   if (this.state.checked === true) {
  //     Swal.fire({
  //       text: "Are you sure want to Deactivate?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#ac0404",
  //       cancelButtonColor: "#6259ca",
  //       confirmButtonText: "Deactivate",
  //       allowOutsideClick: false,
  //     }).then((result) => {
  //       if (result.isConfirmed) {

  //       }
  //     });
  //   } else {
  //     Swal.fire({
  //       // title: "Are you sure?",
  //       text: "Are you sure want to Activate?",
  //       icon: "success",
  //       showCancelButton: true,
  //       confirmButtonColor: "#008000",
  //       cancelButtonColor: "#6259ca",
  //       confirmButtonText: "Activate",
  //       allowOutsideClick: false,
  //     }).then((result) => {
  //       if (result.isConfirmed) {

  //       }
  //     });
  //   }
  // }

  render() {
    return (
      <div className="text-left">
        <label className="custom-switch mb-0">
          <input
            type="checkbox"
            name="custom-switch-checkbox"
            className="custom-switch-input"
            checked={this.state.checked}
            onChange={this.handleSwitch}
          />

          <span className="custom-switch-indicator"></span>
        </label>
      </div>
    );
  }
}

export default Categorystatus;
