import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./guest.css";
export const QuizTest3 = () => {
  const navigate = useNavigate();

  return (
    <div id="registrationpage">
      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            {" "}
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
        </div>
        <div className="registrationright">
          <form action="#" className="form">
            <h2 className="text-right">Quiz Test</h2>
            <div className="form-step form-step-active">
              <div className="form-inner">
                <h4>Questions</h4>
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                      <div className="quiz-app">
                        <div className="quiz-info">
                          <div className="category">
                            Category
                            <span>
                              <b> : General</b>
                            </span>
                          </div>
                          <div className="count">
                            Questions Count
                            <span>
                              <b> : 10</b>
                            </span>
                          </div>
                        </div>
                        <div className="quiz-area">
                          <h5>3 . What is the largest mammal?</h5>
                          <p>Hint: It lives in the ocean</p>
                        </div>
                        <div className="options-area">
                          <div className="option">
                            <input
                              name="options"
                              type="radio"
                              id="option_0"
                              //   value={radiovalue3}
                              //   onChange={(e) => setRadioValue3(e.target.value)}
                            ></input>
                            <label htmlFor="option_0">Elephant</label>
                          </div>
                          <div className="option">
                            <input
                              name="options"
                              type="radio"
                              id="option_0"
                              //   value={radiovalue3}
                              //   onChange={(e) => setRadioValue3(e.target.value)}
                            ></input>
                            <label htmlFor="option_0">Giraffe</label>
                          </div>
                          <div className="option">
                            <input
                              name="options"
                              type="radio"
                              id="option_0"
                              //   value={radiovalue3}
                              //   onChange={(e) => setRadioValue3(e.target.value)}
                            ></input>
                            <label htmlFor="option_0">Blue Whale</label>
                          </div>
                          <div className="option">
                            <input
                              name="options"
                              type="radio"
                              id="option_0"
                              //   value={radiovalue3}
                              //   onChange={(e) => setRadioValue3(e.target.value)}
                            ></input>
                            <label htmlFor="option_0">Hippopotamus</label>
                          </div>
                        </div>
                        <button
                          className="submit-button"
                          onClick={navigate("/quizqn4")}
                        >
                          Next
                        </button>
                        <div className="bullets">
                          <div className="spans">
                            <span className="on"></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                          <div
                            className="countdown"
                            style={{ fontWeight: "bold" }}
                          >
                            02:21
                          </div>
                        </div>
                        <div className="results"></div>
                      </div>
                  </div>
                </div>
              </div>

              <div className="">
                  <Link
                    className="btn btn-secondary"
                    onClick={navigate("/quizqn2")}
                  >
                    Back
                  </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
