import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  citylist,
  countrylist,
  genderlist,
  getemail,
  getmobile,
  interestcategory,
  statelist,
} from "../../services/register";
import "./guest.css";
import { viewevent, sendguestmail } from "../../services/admin";
import {
  guestregistration,
  getguesteventpay,
  guestpaymentresponse,
} from "../../services/register";
import { Modal } from "react-bootstrap";

import moment from "moment";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import imageUrl from "../../ImageUrl";
import Maillink from "../../guestmail";
import Wordlink from "../../wordpresslink";

const Eventregistration = () => {
  const navigate = useNavigate();
  const [step1submit, setStep1] = useState(false);
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");

  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [email, setEmail] = useState("");
  const [mobilecode, setMobcode] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [glist, setGenderlist] = useState([]);
  const [eventcat, setEventcat] = useState("");

  const [memstatus, setStatusmember] = useState("");

  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [pincode, setPincode] = useState("");

  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);

  const { id } = useParams();
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const [mobonly, setMobileonly] = useState("");
  const handle = (value, data, event, formattedValue) => {
    setMobcode(data.dialCode);
    setMobile(value);
    setMobileonly(value.slice(data.dialCode.length));
  };

  // function handle(e){
  //   setMobile(e)
  // }

  function datelist() {
    var day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }
    setDaylist(day);

    var year = [];

    for (let i = 1930; i <= new Date().getFullYear() - 5; i++) {
      year.push(i);
    }
    setYearlist(year);
  }

  function masterlist() {
    genderlist().then((response) => {
      setGenderlist(response.genders);
    });
  }

  const [valmob, setValMob] = useState("");
  function checkmobile(e) {
    e.preventDefault();
    setValMob("");
    setMobError("");

    // if (e.target.value !== "") {
    //   getmobile(e.target.value).then((response) => {
    //     if (response.status === false) {
    //       setValMob(response.message);
    //       Swal.fire({
    //         title:
    //           response.message +
    //           " - " +
    //           response.data.username +
    //           " has been already have this Mobile Number",
    //         allowOutsideClick: false,
    //       });
    //     }
    //   });
    // }
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    // if (e.target.value !== "") {
    //   getemail(e.target.value).then((response) => {
    //     if (response.status === false) {
    //       setValEmail(response.message);
    //       Swal.fire({
    //         title:
    //           response.message +
    //           " - " +
    //           response.data.username +
    //           " has been already have this Email",
    //         allowOutsideClick: false,
    //       });
    //     }
    //   });
    // }
  }

  const [check, setCheck] = useState(false);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  const [formiderror, setIDErrors] = useState("");

  function idfile(e) {
    e.preventDefault();
    setIDErrors("");
    setIDUpload("");
    setIDFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(e.target.files[0]));
        setIDFile(e.target.files[0]);
      } else {
        setIDErrors("Identity Proof should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024;
      if (size > 900) {
        setIDErrors("Identity Proof size should be less than 900KB");
      }
    }
  }

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setPhotoUpload("");
    setPhotoFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));
        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024;
      if (size > 300) {
        setPhotoErrors("Photograph size should be less than 300KB");
      }
    }
  }
  //popup function
  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  const handleClose = () => setShow(false);
  function guestreg(e) {
    e.preventDefault();
    setStep1(true);
    setErrorMessage("");
    setEmailError("");
    let evalid = "";
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      evalid = "Please Enter a valid Email Address";
      setEmailError("Please Enter a valid Email Address");
    }
    if (mobile.length < 10) {
      setMobError("Mobile Number must be 10 digits");
    } else {
      setMobError("");
    }

    if (formphoto === "") {
      setPhotoErrors("Photograph is required");
    }
    if (formid === "") {
      setIDErrors("Identity Proof is required");
    }
    if (check === false) {
      setErrorMessage("Please Agree our terms and conditions");
    }
    if (daygroup !== "" && monthgroup !== "" && yeargroup !== "") {
      setDOB(yeargroup + "-" + monthgroup + "-" + daygroup);
    }
    if (
      fname !== "" &&
      lname !== "" &&
      email !== "" &&
      evalid === "" &&
      mobile !== "" &&
      mobile.length >= 10 &&
      gender !== "" &&
      daygroup !== "" &&
      monthgroup !== "" &&
      yeargroup !== "" &&
      memstatus !== "" &&
      eventcat !== "" &&
      add1 !== "" &&
      pincode !== "" &&
      country !== "" &&
      state !== "" &&
      city !== "" &&
      formid !== "" &&
      formiderror === "" &&
      formphoto !== "" &&
      formphotoerror === "" &&
      check === true
    ) {
      setLoading(true);

      let cid = "";
      if (eventcat.length !== 0) {
        for (let j = 0; j < eventcat.length; j++) {
          if (cid === "") {
            cid = eventcat[j].value;
          } else {
            cid = cid + "," + eventcat[j].value;
          }
        }
      }

      let intid = "";
      if (interestcat.length !== 0) {
        for (let j = 0; j < interestcat.length; j++) {
          if (intid === "") {
            intid = interestcat[j].value;
          } else {
            intid = intid + "," + interestcat[j].value;
          }
        }
      }

      const tempData = new FormData();
      tempData.append("event_uid", id);
      tempData.append("guest_firstname", fname);
      tempData.append("guest_lastname", lname);
      tempData.append("guest_email", email);
      tempData.append("guest_mobile", mobonly);
      tempData.append("guest_gender", gender);
      tempData.append(
        "guest_dob",
        yeargroup + "-" + monthgroup + "-" + daygroup
      );
      tempData.append("mobile_country_code", mobilecode);

      tempData.append("membership_type", memstatus);
      tempData.append("category_uid", cid);
      tempData.append("address_1", add1);
      tempData.append("address_2", add2);
      tempData.append("pincode", pincode);

      tempData.append("country", country);
      tempData.append("state", state);
      tempData.append("city", city);
      tempData.append("interested_category_uid", "0");
      tempData.append("guest_photo", formphoto);
      tempData.append("identity_proof", formid);
      guestregistration(tempData).then((responsedata) => {
        if (responsedata.status === true) {
          // localStorage.setItem("userid", responsedata.data.id);

          if (status === 1) {
            getguesteventpay(responsedata.data.guest_id, id).then(
              (response) => {
                paynow(
                  responsedata.data.guest_id,
                  response.order_details.amount,
                  response.payment_type.payment_currency,
                  response.order_details.id,
                  response.payment_type.payment_amount
                );
              }
            );
          } else {
            const temp = new FormData();
            temp.append("guest_uid", responsedata.data.guest_id);
            temp.append(
              "url",
              Maillink + `/${responsedata.data.guest_id_hash}`
            );
            sendguestmail(temp).then((response) => {
              if (response.status === true) {
                setLoading(false);
                Swal.fire({
                  title: "Mail has been sent successfully",
                  showCancelButton: false,
                  confirmButtonColor: "#6259ca",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                    window.open("https://pythiangames.org/", "_blank");
                  }
                });
                // window.open("http://35.154.131.100/pythiangames/", "_blank");
                // toast.success("Mail has been send successfully");
              }
            });
          }
        } else {
          setErrorMessage(responsedata.data);
        }
      });
    }
  }
  //payment
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  let userId = localStorage.getItem("userid");
  async function paynow(userid, amt, currency, orderid, payamt) {
    const options = {
      // key: "rzp_test_XFnDXpEfL0bcLT",
      key: "rzp_live_EtyIA627A0V6mO",
      payment_amount: amt,
      payment_currency: currency,
      order_uid: orderid,
      name: "Pythian Games",
      description: "Event Registeration",
      image: require("../../assets/images/logo/logonew.png"),
      handler: function (response) {
        const paydet = {
          user_uid: 0,
          role_uid: 2,
          payment_type_id: 2,
          order_uid: response.razorpay_order_id,
          payment_uid: response.razorpay_payment_id,
          payment_sign: response.razorpay_signature,
          payment_amount: payamt,
          payment_currency: currency,
          guest_uid: userid,
          event_uid: id,
        };
        guestpaymentresponse(paydet).then((responsepay) => {
          if (responsepay.status === true) {
            // window.location.reload();
            const temp = new FormData();
            temp.append("guest_uid", responsepay.data.guest_uid);
            temp.append("url", Maillink + `/${responsepay.data.guest_id_hash}`);
            sendguestmail(temp).then((response) => {
              if (response.status === true) {
                window.location.reload();
                toast.success("Mail has been send successfully");
              }
            });
            handleShow();
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  const [ename, setEName] = useState("");
  const [vname, setVName] = useState("");
  const [cname, setCName] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [amount, setAmt] = useState("");
  const [status, setStatus] = useState(0);
  const [catlist, setCatlist] = useState([]);
  const [intcatlist, setIntCatlist] = useState([]);

  let arr = [];
  let arr1 = [];
  const [interestcat, setEventinterest] = useState("");
  const [filecir, setFilecir] = useState("");

  let arr2 = "";

  useEffect(() => {
    datelist();
    masterlist();
    countrylist().then((response) => {
      setCountrylist(response.country);
    });

    interestcategory().then((response) => {
      if (response.data.length !== 0) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
          });
        }
        setIntCatlist(arr1);
      }
    });

    viewevent(id).then((response) => {
      if (response.status === true) {
        if (response.data.SelectedEventCategories.length !== 0) {
          for (
            let i = 0;
            i < response.data.SelectedEventCategories.length;
            i++
          ) {
            arr.push({
              value: response.data.SelectedEventCategories[i].category_uid,
              label: response.data.SelectedEventCategories[i].category,
            });

            if (arr2 === "") {
              arr2 = response.data.SelectedEventCategories[i].category;
            } else {
              arr2 =
                arr2 +
                " , " +
                response.data.SelectedEventCategories[i].category;
            }
          }
          setCatlist(arr);
        }
        setEName("Event Name:- " + response.data.event_name);
        setVName("Event Venue:- " + response.data.event_venue);
        setSDate(
          "Start Date:- " +
            moment(response.data.event_start).format("DD-MM-YYYY")
        );
        setEDate(
          "End Date:- " + moment(response.data.event_end).format("DD-MM-YYYY")
        );
        setCDate(
          "Result Declare:- " +
            moment(response.data.event_entry_close).format("DD-MM-YYYY")
        );

        setCName("Category:- " + arr2);
        setAmt("Entry Fee:- ₹ " + response.data.payment_amount + " INR");
        setFilecir(imageUrl + "evententry/circular/" + response.data.circular);
        if (response.data.payment_status !== null) {
          setStatus(response.data.payment_status);
        } else {
          setStatus(0);
        }
      }
    });
  }, []);

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }

  const handleChange = (e) => {
    setEventcat(e);
  };

  const hangleInterest = (e) => {
    setEventinterest(e);
  };

  const [loading, setLoading] = useState(false);

  return (
    <div id="registrationpage">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}

      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            {" "}
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
        </div>
        <div className="registrationright">
          <form action="#" className="form">
            <h2 className="text-right">Event Registration</h2>
            <div className="guestreg row">
              <div className="col-md-12">
                <h6>{ename}</h6>
              </div>
              {/* <div className="col-md-12">
                <h6>{vname}</h6>
              </div> */}
              <div className="col-md-12">
                <h6>{cname}</h6>
              </div>
              <div className="col-md-4">
                <h6>{sdate}</h6>
              </div>
              <div className="col-md-4">
                <h6>{edate}</h6>
              </div>
              <div className="col-md-4">
                <h6>{cdate}</h6>
              </div>
            </div>

            <div className="form-step form-step-active">
              <div className="form-inner">
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        {" "}
                        First Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your First Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        value={fname}
                        onChange={(e) => {
                          setFName(e.target.value);
                        }}
                      />
                      {step1submit && !fname && (
                        <span className="errortxt text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Last Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Last / Family Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        value={lname}
                        onChange={(e) => {
                          setLName(e.target.value);
                        }}
                      />

                      {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Email Address :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        onBlur={checkemail}
                        placeholder="Enter Your Email Address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {step1submit && !email && (
                        <span className="errortxt text-danger">
                          Email is required
                        </span>
                      )}
                      {emailerror !== "" && email && (
                        <span className="errortxt text-danger">
                          {emailerror}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Mobile Number :<b className="text-danger">*</b>{" "}
                      </label>
                      <PhoneInput
                        specialLabel={""}
                        country={"in"}
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        countryCodeEditable={false}
                        onChange={handle}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      {step1submit && !mobile && (
                        <span className="errortxt text-danger">
                          Mobile Number is required
                        </span>
                      )}
                      {moberror !== "" && mobile && (
                        <span className="errortxt text-danger">{moberror}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Gender :<b className="text-danger">*</b>
                      </label>
                      <select
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your Gender
                        </option>
                        {glist.map((data, i) => {
                          return (
                            <option value={data.gender_uid} key={i}>
                              {data.gender}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !gender && (
                        <span className="errortxt text-danger">
                          Gender is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Date of Birth :<b className="text-danger">*</b>
                      </label>

                      <div className="row">
                        <div className="col-md-4 col-sm-4 xpr-0">
                          <select
                            value={daygroup}
                            onChange={(e) => {
                              setDateDay(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Day
                            </option>
                            {dateday.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpx-0">
                          <select
                            value={monthgroup}
                            onChange={(e) => {
                              setDateMonth(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Month
                            </option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpl-0">
                          <select
                            value={yeargroup}
                            onChange={(e) => {
                              setDateYear(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Year
                            </option>
                            {dateyear.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {step1submit && !dob && (
                        <span className="errortxt text-danger">
                          Date of Birth is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Event Category :<b className="text-danger">*</b>
                      </label>
                      <Select
                        placeholder="Select Your Event Category"
                        value={eventcat}
                        options={catlist}
                        onChange={handleChange}
                        isMulti
                        isClearable
                      />
                      {step1submit && !eventcat && (
                        <span className="errortxt text-danger">
                          Event Category is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        {" "}
                        Address 1 :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Address 1"
                        value={add1}
                        onChange={(e) => {
                          setAdd1(e.target.value);
                        }}
                      />
                      {step1submit && !add1 && (
                        <span className="errortxt text-danger">
                          Address 1 is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Address 2 :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Address 2"
                        value={add2}
                        onChange={(e) => {
                          setAdd2(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Country :<b className="text-danger">*</b>
                      </label>
                      <select value={country} onChange={Selectcountry}>
                        <option value="" disabled>
                          Select Your Country
                        </option>
                        {colist.map((data, i) => {
                          return (
                            <option value={data.country_uid} key={i}>
                              {data.country_name}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !country && (
                        <span className="errortxt text-danger">
                          Country is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        State :<b className="text-danger">*</b>
                      </label>
                      <select value={state} onChange={Selectstate}>
                        <option value="" disabled>
                          Select Your State
                        </option>
                        {slist.map((data, i) => {
                          return (
                            <option value={data.state_uid} key={i}>
                              {data.state_name}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !state && (
                        <span className="errortxt text-danger">
                          State is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        City :<b className="text-danger">*</b>
                      </label>
                      <select
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your City
                        </option>
                        {clist.map((data, i) => {
                          return (
                            <option value={data.city_uid} key={i}>
                              {data.city_name}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !city && (
                        <span className="errortxt text-danger">
                          City is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Pincode :<b className="text-danger">*</b>{" "}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Pincode"
                        value={pincode}
                        onBlur={checkmobile}
                        onChange={(e) => {
                          setPincode(e.target.value);
                        }}
                        maxLength={6}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {step1submit && !pincode && (
                        <span className="errortxt text-danger">
                          Pincode is required
                        </span>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Interesting Category :<b className="text-danger">*</b>
                      </label>
                      <Select
                        placeholder="Select Your Event Category"
                        value={interestcat}
                        options={intcatlist}
                        onChange={hangleInterest}
                        isMulti
                        isClearable
                      />
                      {step1submit && !interestcat && (
                        <span className="errortxt text-danger">
                          Interesting Category is required
                        </span>
                      )}
                    </div>
                  </div> */}
                </div>

                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="dob">
                        1. Identity Proof : <b className="text-danger">*</b>{" "}
                        <span>(Maximum size 900KB)</span>
                      </label>

                      <div className="mt-4">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={idfile}
                        />
                      </div>
                    </div>

                    {formidupload !== "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img src={formidupload} width="100%" height="auto" />
                        </div>
                      </div>
                    )}

                    {formidupload === "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={require("../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formiderror !== "" && (
                    <span className="errortxt text-danger">{formiderror}</span>
                  )}
                </div>

                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="dob">
                        2. Add Photograph : <b className="text-danger">*</b>{" "}
                        <span>(Maximum size 300KB)</span>
                      </label>

                      <div className="mt-4">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={photofile}
                        />
                      </div>
                    </div>

                    {formphotoupload !== "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={formphotoupload}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}

                    {formphotoupload === "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={require("../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formphotoerror !== "" && (
                    <span className="errortxt text-danger">
                      {formphotoerror}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-9">
                  <div className="checkbox checkbox-1">
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      onChange={checkterm}
                      checked={check}
                    />
                    <label htmlFor="checkbox-1">
                      Agree to our{" "}
                      <a
                        target="_blank"
                        href="https://www.pythiangames.org/privacy"
                      >
                        Privacy Policy
                      </a>{" "}
                      ,{" "}
                      <a
                        target="_blank"
                        href="https://www.pythiangames.org/terms"
                      >
                        Terms & Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        // href={require("../../assets/images/Poetry_and_Painting_art_Compition.pdf")}
                        href={filecir}
                      >
                        Submission & Guidelines
                      </a>
                      <b className="text-danger">*</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  {status === 1 && (
                    <div className="member">
                      <label>{amount}</label>
                    </div>
                  )}
                  {status === 0 && (
                    <div className="member">
                      <label>Entry Fee: Free</label>
                    </div>
                  )}
                </div>
              </div>

              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}

              <div className="">
                <Link to={"/login"} className="btn btn-secondary">
                  Back
                </Link>
                <a
                  className="btn btn-primary btn-next"
                  style={{ float: "right" }}
                  onClick={guestreg}
                >
                  Submit
                </a>
              </div>
            </div>

            <Modal
              show={show}
              onHide={handleClose}
              size="large"
              backdrop="static"
            >
              <Modal.Header>
                <h4>Payment Successful!</h4>
                <i
                  className="fa fa-window-close fa-lg"
                  aria-hidden="true"
                  onClick={handleClose}
                ></i>
              </Modal.Header>
              <Modal.Body>
                <p>Registration completed</p>
              </Modal.Body>
              <Modal.Footer>
                <a
                  className="btn btn-success mr-3"
                  style={{ float: "right" }}
                  onClick={handleClose}
                >
                  ok
                </a>
              </Modal.Footer>
            </Modal>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Eventregistration;
