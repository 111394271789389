import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  countrylist,
  currencylist,
  donarordercreation,
  donarpaymentdone,
  donarregistration,
  genderlist,
  guestmailsent,
} from "../../services/register";
import "./guest.css";
import { guestpaymentresponse } from "../../services/register";
import { Modal } from "react-bootstrap";

import moment from "moment";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import imageUrl from "../../ImageUrl";

export const DonationPage = () => {
  const navigate = useNavigate();
  const [step1submit, setStep1] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");

  const [email, setEmail] = useState("");
  const [mobilecode, setMobcode] = useState("");
  const [mobile, setMobile] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");

  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);

  //   const { id } = useParams();
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const [mobonly, setMobileonly] = useState("");
  const handle = (value, data, event, formattedValue) => {
    setMobcode(data.dialCode);
    setMobile(value);
    setMobileonly(value.slice(data.dialCode.length));
  };

  const [valmob, setValMob] = useState("");
  function checkmobile(e) {
    e.preventDefault();
    setValMob("");
    //setMobError("");

    // if (e.target.value !== "") {
    //   getmobile(e.target.value).then((response) => {
    //     if (response.status === false) {
    //       setValMob(response.message);
    //       Swal.fire({
    //         title:
    //           response.message +
    //           " - " +
    //           response.data.username +
    //           " has been already have this Mobile Number",
    //         allowOutsideClick: false,
    //       });
    //     }
    //   });
    // }
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    // if (e.target.value !== "") {
    //   getemail(e.target.value).then((response) => {
    //     if (response.status === false) {
    //       setValEmail(response.message);
    //       Swal.fire({
    //         title:
    //           response.message +
    //           " - " +
    //           response.data.username +
    //           " has been already have this Email",
    //         allowOutsideClick: false,
    //       });
    //     }
    //   });
    // }
  }

  function Selectcountry(e) {
    setCountry(e.target.value);
    currencylist(e.target.value).then((res) => {
      setCurrency(res.payment_currency.payment_currency);
    });
  }

  //popup function

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [submitted, setSubmitted] = useState(false);

  function guestreg(e) {
    e.preventDefault();
    setSubmitted(true);
    setStep1(true);
    setErrorMessage("");
    setEmailError("");
    setMobError("");
    let evalid = "";
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      evalid = "Please Enter a valid Email Address";
      setEmailError("Please Enter a valid Email Address");
    }

    if (
      fname !== "" &&
      lname !== "" &&
      email !== "" &&
      evalid === "" &&
      country !== ""
    ) {
      setLoading(true);

      const tempData = new FormData();
      tempData.append("doner_first_name", fname);
      tempData.append("doner_last_name", lname);
      tempData.append("doner_email", email);
      tempData.append("doner_mobile", mobonly);
      tempData.append("doner_country", country);
      tempData.append("paid_amount", amount);
      tempData.append("paid_curreny", currency);
      tempData.append("doner_bio", remark);
      // tempData.append("mobile_country_code", mobilecode);

      donarregistration(tempData).then((res) => {
        if (res.status === true) {
          setSubmitted(false);
          console.log(res.data.id);
          donarordercreation(res.data.id, currency, amount).then((response) => {
            paynow(
              response.order_details.amount,
              response.order_details.currency,
              response.order_details.id,
              res.data.id
            );
            setLoading(false);
          });
        } else {
          toast.error("Process Failed");
        }
      });
    }
  }

  //payment

  async function paynow(amt, currency, orderid, uid) {
    const options = {
      // key: "rzp_test_XFnDXpEfL0bcLT",
      key: "rzp_live_EtyIA627A0V6mO",
      //   payment_amount: amt,
      payment_currency: currency,
      order_id: orderid,
      name: "Pythian Games",
      description: "Event Registeration",
      image: require("../../assets/images/logo/logonew.png"),
      handler: function (response) {
        // const paydet = {
        //   doner_uid: uid,
        //   order_uid: response.razorpay_order_id,
        //   payment_uid: response.razorpay_payment_id,
        //   payment_sign: response.razorpay_signature,
        //   payment_amount: amt,
        //   payment_currency: currency,
        // };
        const temp1 = new FormData();
        temp1.append("doner_uid", uid);
        temp1.append("order_uid", response.razorpay_order_id);
        temp1.append("payment_uid", response.razorpay_payment_id);
        temp1.append("payment_sign", response.razorpay_signature);
        temp1.append("payment_amount", amt);
        temp1.append("payment_currency", currency);

        donarpaymentdone(temp1).then((responsepay) => {
          if (responsepay.status === true) {
            Swal.fire({
              title: "Successfully Donated",
              showCancelButton: false,
              confirmButtonColor: "#6259ca",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                window.open("https://pythiangames.org/", "_blank");
              }
            });
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  useEffect(() => {
    countrylist().then((response) => {
      setCountrylist(response.country);
    });
  }, []);

  return (
    <div id="registrationpage">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}

      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            {" "}
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
        </div>

        <div className="registrationright">
          <form action="#" className="form">
            <h2 className="text-center">Donate Now</h2>
            <h3 className="text-center">"Empower Change with Your Donation"</h3>
            <div className="guestreg row">
              <div className="col-md-12">
                <h6></h6>
              </div>
              <div className="col-md-12">
                <h6></h6>
              </div>
              <div className="col-md-4">
                <h6></h6>
              </div>
              <div className="col-md-4">
                <h6></h6>
              </div>
            </div>

            <div className="form-step form-step-active">
              <div className="form-inner">
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        {" "}
                        First Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your First Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={fname}
                        onChange={(e) => {
                          setFName(e.target.value);
                        }}
                      />
                      {step1submit && !fname && (
                        <span className="errortxt text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Last Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Last / Family Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={lname}
                        onChange={(e) => {
                          setLName(e.target.value);
                        }}
                      />

                      {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Email Address :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        onBlur={checkemail}
                        placeholder="Enter Your Email Address"
                        maxLength={50}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {step1submit && !email && (
                        <span className="errortxt text-danger">
                          Email is required
                        </span>
                      )}
                      {emailerror !== "" && email && (
                        <span className="errortxt text-danger">
                          {emailerror}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Mobile Number :{/* <b className="text-danger">*</b> */}{" "}
                      </label>
                      <PhoneInput
                        specialLabel={""}
                        country={"in"}
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        countryCodeEditable={false}
                        onChange={handle}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {mobile.length !== 12 && mobile && (
                        <span className="errortxt text-danger">{moberror}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Country :<b className="text-danger">*</b>
                      </label>
                      <select value={country} onChange={Selectcountry}>
                        <option value="" disabled>
                          Select Your Country
                        </option>
                        {colist.map((data, i) => {
                          return (
                            <option value={data.country_uid} key={i}>
                              {data.country_name}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !country && (
                        <span className="errortxt text-danger">
                          Country is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            {" "}
                            Currency :<b className="text-danger">*</b>
                          </label>
                          <input type="text" value={currency} disabled />
                          {step1submit && !currency && (
                            <span className="errortxt text-danger">
                              Currency is required
                            </span>
                          )}
                        </div>
                        <div className="col-md-8">
                          <div className="input-group">
                            <label htmlFor="username">
                              {" "}
                              Amount :
                              <b className="text-danger">*</b>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Your Donation Amount"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={15}
                              min={1}
                              value={amount}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                            />
                            {step1submit && !amount && (
                              <span className="errortxt text-danger">
                                Amount is required
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="input-group">
                      <label htmlFor="username">Bio :</label>
                      <textarea
                        rows={3}
                        placeholder="Describe Yourself"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                      {/* {step1submit && !remark && (
                        <span className="errortxt text-danger">
                          Bio is required
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>

              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}

              <div className="">
                <a
                  className="btn btn-primary btn-next"
                  style={{ float: "right" }}
                  onClick={guestreg}
                >
                  Donate Now
                </a>
              </div>
            </div>

            <Modal
              show={show}
              onHide={handleClose}
              size="large"
              backdrop="static"
            >
              <Modal.Header>
                <h4>Payment Successful!</h4>
                <i
                  className="fa fa-window-close fa-lg"
                  aria-hidden="true"
                  onClick={handleClose}
                ></i>
              </Modal.Header>
              <Modal.Body>
                <p>Registration completed</p>
              </Modal.Body>
              <Modal.Footer>
                <a
                  className="btn btn-success mr-3"
                  style={{ float: "right" }}
                  onClick={handleClose}
                >
                  ok
                </a>
              </Modal.Footer>
            </Modal>
          </form>
        </div>
      </div>
    </div>
  );
};
