import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import baseUrl from "../Api";

export async function userview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/user/details/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function userbillingdata(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/user/bill-details/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function changepassword(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/user/change-password`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function userupdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/user/update`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function userproofupdate(data) {
  try {
    return await axios
      .post(`${baseUrl}/user/proof-update`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
function Catcherror(error) {
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/Pythian/#/login");
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    console.log("Error", error.message);
  }
}
