import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./guest.css";
import { Container } from "react-bootstrap";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <div id="registrationpage">
      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            {" "}
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
        </div>
        <div className="registrationright">
          <form action="#" className="form">
            <div className="text-right">
              <a
                className="btn btn-primary mr-2"
                onClick={() => navigate(-1)}
              >
                Back
              </a>
            </div>
            <Container fluid className="p-3 text-center mt-5">
              <div className="text-center text-white background-text ">
                <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                  Registration successful !
                </h1>
                <p className="tx-20 mb-3 text-success">
                Please check your email and upload your files with the <br></br>given link to complete the participation process.
                  {/* Link can be sent it to your Email. <br></br>Kindly click the
                  link and submit the files to complete the event registration
                  process. */}
                </p>
              </div>
            </Container>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
