import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row, Tab, Nav } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import {
  resultwithoutmark,
  resultwithmark,
  listeventcategory,
  listeventcategorymark,
  viewevent,
  ExportResultData,
} from "../../../services/admin";
import { useCallback } from "react";
import { toast } from "react-toastify";

const Eventmark = () => {
  const gridRef = useRef();
  const { id } = useParams();

  const [eventname, setEventname] = useState("");

  const [markfilterProduct, setMarkfilterProduct] = useState([]);
  const [nomarkfilterProduct, setNomarkfilterProduct] = useState([]);

  const [filterProductone, setFilterProductone] = useState([]);
  const [filterProducttwo, setFilterProducttwo] = useState([]);
  const [filterProductthree, setFilterProductthree] = useState([]);
  const [filterProductfour, setFilterProductfour] = useState([]);
  const [filterProductfive, setFilterProductfive] = useState([]);
  const [filterProductsix, setFilterProductsix] = useState([]);
  const [filterProductseven, setFilterProductseven] = useState([]);

  const [firstcatid, setFirstcatatid] = useState("");
  const [secondcatid, setSecondcatatid] = useState("");
  const [thirdcatid, setThirdcatatid] = useState("");
  const [fourthcatid, setFourthcatatid] = useState("");
  const [fifthcatid, setFifthcatatid] = useState("");
  const [sixthcatid, setSixthcatatid] = useState("");
  const [seventhcatid, setSeventhcatatid] = useState("");

  const [catadd, setCatadd] = useState("");
  const [catlist, setCatlist] = useState([]);

  const columnDefs = [
    {
      headerName: "Name",
      width: 250,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Category",
      width: 250,
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Jury Name",
      width: 250,
      field: "jury_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Score",
      width: 250,
      field: "score",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const catcolumnDefsmark = [
    {
      headerName: "Name",
      width: 200,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 150,
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Jury Name",
      width: 150,
      field: "jury_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Score",
      width: 150,
      field: "score",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Grade",
      width: 150,
      field: "grade",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Average",
      width: 150,
      field: "average",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  let nomarkarr = [];
  let markarr = [];
  let arr1 = [];
  let arr2 = [];
  let arr3 = [];
  let arr4 = [];
  let arr5 = [];
  let arr6 = [];
  let arr7 = [];

  useEffect(() => {
    userreload();
    tabViewOne();
    tabViewTwo();
    tabViewThree();
    tabViewFour();
    tabViewfive();

    viewevent(id).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          setEventname(res.data.event_name);
        }
      }
    });

    listeventcategory(id).then((response) => {
      if (response.status === true) {
        setCatlist(response.data);

        let foundData1 = response.data
          .filter((item) => item.category_uid === 1)
          .map((item) => item.category_uid);
        setFirstcatatid(foundData1[0]);

        let foundData2 = response.data
          .filter((item) => item.category_uid === 2)
          .map((item) => item.category_uid);
        setSecondcatatid(foundData2[0]);

        let foundData3 = response.data
          .filter((item) => item.category_uid === 3)
          .map((item) => item.category_uid);
        setThirdcatatid(foundData3[0]);

        let foundData4 = response.data
          .filter((item) => item.category_uid === 4)
          .map((item) => item.category_uid);
        setFourthcatatid(foundData4[0]);

        let foundData5 = response.data
          .filter((item) => item.category_uid === 5)
          .map((item) => item.category_uid);
        setFifthcatatid(foundData5[0]);

        let foundData6 = response.data.find((item) => item.category_uid === 6);
        setSixthcatatid(foundData6);

        let foundData7 = response.data.find((item) => item.category_uid === 7);
        setSeventhcatatid(foundData7);
      }
    });
  }, []);

  function userreload() {
    setMarkfilterProduct([]);
    setNomarkfilterProduct([]);
    resultwithmark(id).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            var juryname = response.data[i].jury_first_name;
            if (response.data[i].jury_last_name !== null) {
              juryname = juryname + " " + response.data[i].jury_last_name;
            }
            markarr.push({
              first_name: name,
              category: response.data[i].category,
              jury_name: juryname,
              score: response.data[i].score,
              grade: response.data[i].grade,
            });
          }
          setMarkfilterProduct(markarr);
        }
      }
    });
    resultwithoutmark(id).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            var juryname = response.data[i].jury_first_name;
            if (response.data[i].jury_last_name !== null) {
              juryname = juryname + " " + response.data[i].jury_last_name;
            }
            nomarkarr.push({
              first_name: name,
              category: response.data[i].category,
              jury_name: juryname,
              score: response.data[i].score,
              grade: response.data[i].grade,
            });
          }

          setNomarkfilterProduct(nomarkarr);
        }
      }
    });
  }

  function tabViewOne() {
    setFilterProductone([]);
    listeventcategorymark(id, firstcatid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            arr1.push({
              first_name: name,
              category: response.data[i].category,
              jury_name: response.data[i].jury_user_uid,
              score: response.data[i].total_score,
              grade: response.data[i].category_code,
              average: response.data[i].average,
            });
          }
          setFilterProductone(arr1);
        }
      }
    });
  }

  function tabViewTwo() {
    setFilterProducttwo([]);
    listeventcategorymark(id, secondcatid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            arr2.push({
              first_name: name,
              category: response.data[i].category,
              jury_name: response.data[i].jury_user_uid,
              score: response.data[i].total_score,
              grade: response.data[i].category_code,
              average: response.data[i].average,
            });
          }
          setFilterProducttwo(arr2);
        }
      }
    });
  }

  function tabViewThree() {
    setFilterProductthree([]);
    listeventcategorymark(id, thirdcatid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            arr3.push({
              first_name: name,
              category: response.data[i].category,
              jury_name: response.data[i].jury_user_uid,
              score: response.data[i].total_score,
              grade: response.data[i].category_code,
              average: response.data[i].average,
            });
          }
          setFilterProductthree(arr3);
        }
      }
    });
  }

  function tabViewFour() {
    setFilterProductfour([]);
    listeventcategorymark(id, fourthcatid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            arr4.push({
              first_name: name,
              category: response.data[i].category,
              jury_name: response.data[i].jury_user_uid,
              score: response.data[i].total_score,
              grade: response.data[i].category_code,
              average: response.data[i].average,
            });
          }
          setFilterProductfour(arr4);
        }
      }
    });
  }

  function tabViewfive() {
    setFilterProductfive([]);
    listeventcategorymark(id, fifthcatid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            arr5.push({
              first_name: name,
              category: response.data[i].category,
              jury_name: response.data[i].jury_user_uid,
              score: response.data[i].total_score,
              grade: response.data[i].category_code,
              average: response.data[i].average,
            });
          }
          setFilterProductfive(arr5);
        }
      }
    });
  }

  function tabViewsix() {
    setFilterProductsix([]);
    listeventcategorymark(id, sixthcatid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr6.push({
              total_score: response.data[i].total_score,
              first_name:
                response.data[i].first_name + " " + response.data[i].last_name,
              category: response.data[i].category,
              category_code: response.data[i].category_code,
            });
          }
          setFilterProductsix(arr6);
        }
      }
    });
  }

  function tabViewseven() {
    setFilterProductseven([]);
    listeventcategorymark(id, seventhcatid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr7.push({
              total_score: response.data[i].total_score,
              first_name:
                response.data[i].first_name + " " + response.data[i].last_name,
              category: response.data[i].category,
              category_code: response.data[i].category_code,
            });
          }
          setFilterProductseven(arr7);
        }
      }
    });
  }

  const onBtnUpdate = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  // function onBtnUpdate(e) {
  //   e.preventDefault();
  //   const temp5 = new FormData();
  //   temp5.append("category", catadd);
  //   temp5.append("event_uid", id);

  //   ExportResultData(temp5).then((res) => {
  //     if (res.status === true) {
  //     console.log(res);
  //       toast.success("File Is Exported");
  //     } else {
  //       toast.error(res.message);
  //     }
  //   });
  // }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            PARTICIPANTS MARK LIST - {eventname.toUpperCase()}
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="row">
                <div className="input-group">
                  <div className="col-md-2">
                    <label htmlFor="username">
                      Catagory <b className="text-danger">*</b>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <select
                      value={catadd}
                      onChange={(e) => setCatadd(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Category
                      </option>
                      <option value="x">All Mark</option>
                      <option value="y">Without Mark</option>
                      <option value="z">With Mark</option>
                      {catlist.map((data, i) => {
                        return (
                          <option value={data.category_uid} key={i}>
                            {data.category}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <a className="btn btn-info" onClick={onBtnUpdate}>
                    Export
                  </a>
                  <Link to={"/eventresult"} className="btn btn-secondary">
                    Back
                  </Link>
                </div>
              </div>

              <div className="panel panel-primary tabs-style-2">
                <div className=" tab-menu-heading">
                  <div className="tabs-menu1">
                    <Tab.Container defaultActiveKey="notallot">
                      <Nav
                        variant="pills"
                        className="nav panel-tabs main-nav-line"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="notallot" className="mt-1">
                            Without Mark
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="allot" className="mt-1">
                            With Mark
                          </Nav.Link>
                        </Nav.Item>
                        {firstcatid === 1 && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="paint"
                              onClick={tabViewOne}
                              className="mt-1"
                            >
                              Painting
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        {secondcatid === 2 && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="poem"
                              onClick={tabViewTwo}
                              className="mt-1"
                            >
                              Poem
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        {thirdcatid === 3 && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="music"
                              onClick={tabViewThree}
                              className="mt-1"
                            >
                              Music
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        {fourthcatid === 4 && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="dance"
                              onClick={tabViewFour}
                              className="mt-1"
                            >
                              Dance
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        {fifthcatid === 5 && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="theatrearts"
                              onClick={tabViewfive}
                              className="mt-1"
                            >
                              Theatre Arts
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="notallot" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  ref={gridRef}
                                  columnDefs={columnDefs}
                                  rowData={nomarkfilterProduct}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="allot" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  ref={gridRef}
                                  columnDefs={columnDefs}
                                  rowData={markfilterProduct}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="paint" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  ref={gridRef}
                                  columnDefs={catcolumnDefsmark}
                                  rowData={filterProductone}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="poem" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  ref={gridRef}
                                  columnDefs={catcolumnDefsmark}
                                  rowData={filterProducttwo}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="music" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  ref={gridRef}
                                  columnDefs={catcolumnDefsmark}
                                  rowData={filterProductthree}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="dance" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  ref={gridRef}
                                  columnDefs={catcolumnDefsmark}
                                  rowData={filterProductfour}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content>
                        <Tab.Pane eventKey="theatrearts" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  ref={gridRef}
                                  columnDefs={catcolumnDefsmark}
                                  rowData={filterProductfive}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Eventmark;


