import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./dashboard.scss";
import { jurydashboardeventlist } from "../../services/jury";
function Jurydashboard() {
  const [eventcount, setEventcount] = useState(null);
  useEffect(() => {
    jurydashboardeventlist(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setEventcount(response.data.length);
      }
    });
  }, []);
  return (
    <Fragment>
      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            WELOCOME TO JURY DASHBOARD
          </h2>
        </div>
      </div>
      {/* <!-- End Page Header --> */}
      <div className="row row-sm">
        <div className="col-md-4">
          <Card className="custom-card me-4 ">
            <Card.Body>
              <div className="d-flex no-block align-items-center currency-item">
                <div>
                  <span className="text-muted tx-13 mb-3">JURY NAME</span>
                  <h3 className="m-0 mt-2">{localStorage.getItem("Name")}</h3>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-4 ">
          <Card className="custom-card me-4 ">
            <Card.Body>
              <div className="d-flex no-block align-items-center currency-item">
                <div>
                  <span className="text-muted tx-13 mb-3">
                    TOTAL EVENTS ASSIGNED
                  </span>
                  <h3 className="m-0 mt-2">{eventcount}</h3>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-4">
          <Card className="custom-card me-4 ">
            <Card.Body>
              <div className="d-flex no-block align-items-center currency-item">
                <div>
                  <span className="text-muted tx-13 mb-3">PYTHIAN AWARDS</span>
                  <h3 className="m-0 mt-2">-</h3>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

Jurydashboard.propTypes = {};

Jurydashboard.defaultProps = {};

export default Jurydashboard;
