import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./dashboard/sidebar/sidebar";
import Header from "./dashboard/header";
import Footer from "./dashboard/footer";
import Switcher from "./dashboard/switcher/switcher";
import PrivateRoute from "./dashboard/privateroute";
import UserSideBar from "./userdashboard/sidebar/usersidebar";
import GuestSideBar from "./guestdashboard/sidebar/guestsidebar";
import AdminSideBar from "./pythiandashboard/sidebar/adminsidebar";
import JurySideBar from "./jurydashboard/sidebar/jurysidebar";
// import '../index.scss';

const Dashboardapp = () => {
  document.querySelector("body").classList.remove("error-1");
  document
    .querySelector("body")
    .classList.remove("app", "sidebar-mini", "landing-page", "horizontalmenu");
  document.querySelector("body").classList.add("main-body", "leftmenu");

  const remove = () => {
    document.querySelector(".sidebar-right").classList.remove("sidebar-open");
    document.querySelector("body").classList.remove("main-sidebar-show");
    document.querySelector(".demo_changer").classList.remove("active");
    document.querySelector(".demo_changer").style.right = "-270px";
  };

  return (
    <Fragment>
      <div className="horizontalMenucontainer">
        <Switcher />
        <div className="page">
          <Header />
          {localStorage.getItem("Roleid") === '1' && <SideBar />}
          {localStorage.getItem("Roleid") === '2' && <UserSideBar />}
          {localStorage.getItem("Roleid") === '3' && <AdminSideBar />}
          {localStorage.getItem("Roleid") === '4' && <JurySideBar />}

          <div className="main-content side-content">
            <div
              className="main-container container-fluid"
              onClick={remove}
            >
              <div className="inner-body">
                <PrivateRoute />
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};
export default Dashboardapp;
