import { AgGridReact } from "ag-grid-react";
import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { addcategory, categorylist } from "../../../services/admin";
import Categorystatus from "./categorystatus";

const ChildMessageRenderer = (props) => {
  // const invokeParentMethod = () => {
  //   alert(`${props.value}`);
  // };

  return (
    <Link to={"/event/editcategory/" + props.value} className="btn-view">
      Edit
    </Link>
    // <span className="text-info" onClick={invokeParentMethod}>
    //   <i className="ti ti-eye"></i>
    //   {props.value}
    // </span>
  );
};

const Category = () => {
  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 150,
      field: "category_uid",
      filter: false,
      cellRenderer: ChildMessageRenderer,
    },
    {
      headerName: "Category",
      width: 400,
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category Code",
      width: 270,
      field: "category_code",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "is_active",
      filter: false,
      cellRenderer: Categorystatus,
      cellStyle: { textAlign: "left" },
    },
  ];

  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }

  const [eshow, setEShow] = useState(false);
  function handleEShow() {
    setEShow(true);
  }
  function handleEClose() {
    setEShow(false);
  }

  const [catname, setName] = useState("");
  const [catcode, setCode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  function addcate() {
    const temp = {
      user_uid: localStorage.getItem("Userid"),
      category_name: catname,
      category_code: catcode,
    };
    if (catname !== "") {
      if (catcode !== "") {
        addcategory(temp).then((response) => {
          if (response.status === true) {
            setShow(false);
            window.location.reload();
          }
        });
      } else {
        setErrorMessage("Please Enter Your Category Code");
      }
    } else {
      setErrorMessage("Please Enter Your Category Name");
    }
  }
  useEffect(() => {
    categorylist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">CATEGORY LIST</h2>
        </div>
      </div>

      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <div align="right">
              <a
                className="btn btn-success"
                style={{ marginRight: "10px", marginTop: "10px" }}
                onClick={handleShow}
              >
                <i className="fa fa-plus"></i> Add Category
              </a>
            </div>
            <Card.Body>
              <div className="responsive">
                <div className="App" style={{ overflow: "scroll" }}>
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600 }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header>
                <h4>Add Category</h4>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Category Name <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Your Category Name"
                        value={catname}
                        maxLength={15}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Category Code <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Your Category Code"
                        value={catcode}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {errorMessage !== "" && (
                  <span className="errortxt text-danger">{errorMessage}</span>
                )}
              </Modal.Body>
              <Modal.Footer>
                <a
                  className="btn btn-prev btn-primary"
                  style={{ float: "right" }}
                  onClick={addcate}
                >
                  Add
                </a>
                <a
                  className="btn btn-next btn-secondary"
                  style={{ float: "right" }}
                  onClick={handleClose}
                >
                  Cancel
                </a>
              </Modal.Footer>
            </Modal>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Category;
