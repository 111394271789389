import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { paymentlist } from "../../../services/admin";
import Usericon from "./usericon";
import { userdatalist } from "../../../services/admin";
import { Link } from "react-router-dom";
import "./users.css";

const Paymentlist = () => {
  const gridRef = useRef();

  const [filterProduct, setFilterProduct] = useState([]);
  let arr = [];
  const columnDefs = [
    {
      headerName: "Actions",
      width: 140,
      field: "user_uid",
      filter: false,
      //cellRenderer: Usericon,
      cellRenderer: function (params) {
        return (
          <div className="row">
            <div className="col-md-5 col-rgh">
              <Link
                to={"/users/paymentview/" + params.value}
                className="btn-view"
              >
                View
              </Link>
            </div>
            {/* <div className="col-md-5">
              <a
                // onClick={(e) => deletejury(e, params.value)}
                className="btn-delete"
              >
                Delete
              </a>
            </div> */}
          </div>
        );
      },
    },
    {
      headerName: "Username",
      width: 170,
      field: "username",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Id",
      width: 200,
      field: "payment_uid",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Order Id",
      width: 200,
      field: "order_uid",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Payment Amount",
      width: 150,
      field: "payment_amount",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "User Type",
      width: 150,
      field: "user_type",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Payment Date",
      width: 150,
      field: "payment_date",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
  ];

  useEffect(() => {
    paymentlist().then((response) => {
      if (response.status === true) {
        if (response.data.user_paid_list !== null) {
          if (response.data.user_paid_list.length !== 0) {
            for (let i = 0; i < response.data.user_paid_list.length; i++) {
              arr.push({
                username: response.data.user_paid_list[i].username,
                payment_uid: response.data.user_paid_list[i].payment_uid,
                order_uid: response.data.user_paid_list[i].order_uid,
                payment_amount: response.data.user_paid_list[i].payment_amount,
                user_type: "User",
                payment_date: response.data.user_paid_list[i].payment_date,
                user_uid: response.data.user_paid_list[i].user_uid,
              });
            }
          }
        }
        setFilterProduct(arr);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">PAYMENT LIST</h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="App">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600, width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Paymentlist;
