import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import baseUrl from "../Api";

export async function registerjury(data) {
  try {
    return await axios
      .post(`${baseUrl}/jury-registration`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function newregisterjury(data) {
  try {
    return await axios
      .post(`${baseUrl}/jury-new-registration`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function jurylistdetail(role, id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/user-list/${role}/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//assigned jury userwise list
export async function assignedjurylist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/assign-jury-list/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function notassignedjurylist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/not_assign-jury-event-entry/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//jury list
export async function jurylist(status) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/jurylist/${status}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//selected userlist
export async function selecteduserlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/event-userlist`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//selected guestlist
export async function selectedguestlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/event-guestlist`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//add selected user
export async function addselecteduser(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/addjurytouser`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//add selected guest
export async function addselectedguest(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/addjurytoguest`, data, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//add jury
// export async function addselectedjury(data) {
//   try {
//     const header = {
//       Authorization: "Bearer " + localStorage.getItem("Token"),
//       "Content-Type": "application/json",
//     };
//     return await axios
//       .post(`${baseUrl}/superadmin/addjury`, data, { headers: header })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         Catcherror(error);
//       });
//   } catch (error) {
//     Catcherror(error);
//   }
// }

export async function addjurytoevent(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/assign-jury-to-entries`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

// Delete jury for entries
export async function deletejuryevent(event_uid, category_uid, jury_uid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(
        `${baseUrl}/superadmin/jury-remove-event/${event_uid}/${category_uid}/${jury_uid}`,
        { headers: header }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Delete entries in Non-assigned table
export async function deleteEntries(eid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-entry-delete/${eid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function juryviewdetail(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    // (/jury-details/)
    return await axios
      .get(`${baseUrl}/jury-details/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function juryaddlist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-jury-list/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function juryaddlistdetail(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-added-jury-list/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function eventjuryadd(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/add-jury-to-event`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function eventjurydelete(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/remove-jury-to-event`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function jurydashboardeventlist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/jury/jury-event-list/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function juryallocated(jury_uid, eid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/jury/jury-event-files-withmark/${jury_uid}/${eid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function jurynotallocated(jury_uid, eid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/jury/jury-event-files-withoutmark/${jury_uid}/${eid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function jurychangepassword(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/jury/change-password`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//jury detail update
export async function juryupdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "multipart/form-data",
    };
    return await axios
      .post(`${baseUrl}/update-jury`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function jurygradeoptions() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/jury/score-grade-list`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function evententryview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/jury/event-entry-files/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function eventuserentryview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/jury/event-entry-files-user/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//user entry view
export async function userentryview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/jury/user-event-details/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

// export async function addjurymarks(data) {
//   try {
//     const header = {
//       Authorization: "Bearer " + localStorage.getItem("Token"),
//       "Content-Type": "application/json",
//     };
//     return await axios
//       .post(`${baseUrl}/jury/add-score-grade`, data, {
//         headers: header,
//       })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         Catcherror(error);
//       });
//   } catch (error) {
//     Catcherror(error);
//   }
// }
export async function addjuryscore(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/jury/add-score-grade`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

function Catcherror(error) {
  // console.log(error.response);
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/Pythian/#/login");
    localStorage.clear();
    // window.location.reload();
    // <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
    console.log("Error", error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    console.log("Error", error.message);
  }
}
