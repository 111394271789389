import moment from "moment/moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { delguest, guestmembereventview } from "../../../services/admin";
import imageUrl from "../../../ImageUrl";
import {
  approveuser,
  reasonlist,
  guestuserdataview,
} from "../../../services/admin";
import "../users/users.css";
import { deleteEntries } from "../../../services/jury";

const Guestmembereventview = () => {
  const navigate = useNavigate();

  const [fname, setFirst] = useState("");
  const [lname, setLast] = useState("");
  const [member, setMember] = useState("");
  const [email, setEmail] = useState("");
  const [pmobile, setPrimary] = useState("");
  const [smobile, setSecondary] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [photo, setPhoto] = useState("");
  const [identity, setIdentity] = useState("");

  const [event, setEvent] = useState("");
  const [venue, setVenue] = useState("");
  const [sdate, setStart] = useState("");
  const [edate, setEnd] = useState("");
  const [cdate, setClose] = useState("");
  const [regdate, setRegDate] = useState("");
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [ishow, setImage] = useState(false);
  const [file, setFile] = useState(false);

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }
  const { id } = useParams();

  let arr = [];
  
  function delGuest(e) {
    e.preventDefault();
    Swal.fire({
      title: `Are you sure want to Delete this user?`,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        delguest(id).then((response) => {
          if (response.status === true) {
            navigate(-1);
          }
        });
      }
    });
  }

  function deleteEntry(e) {
    // console.log(cid);
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEntries(id).then((response) => {
          if (response.status === true) {
            navigate(-1);
            // window.location.reload();
            toast.success(response.message);
          }
        });
      }
    });
  }
  useEffect(() => {
    guestmembereventview(id).then((response) => {
      if (response.status === true) {
        setFirst(response.data.details.first_name);
        setLast(response.data.details.last_name);
        setMember(response.data.details.membership_type);
        setEmail(response.data.details.email);
        if (response.data.details.mobile_country_code !== null) {
          setPrimary(
            "(+" +
              response.data.details.mobile_country_code +
              ") " +
              response.data.details.mobile
          );
        } else {
          setPrimary(response.data.details.mobile);
        }
        setGender(response.data.details.gender);
        setDOB(moment(response.data.details.guest_dob).format("DD-MM-YYYY"));

        if (response.data.details.address_1 !== "") { 
          if (response.data.details.address_1 === null) {
            setStreet("");
          } else if (response.data.details.address_1 !== "address") {
            setStreet(response.data.details.address_1);
          }
        }
        setCountry(response.data.details.country_name);
        setState(response.data.details.state_name);
        setCity(response.data.details.city_name);
        setPincode(response.data.details.pincode);
        if (response.data.details.guest_photo !== null) {
          setPhoto(imageUrl + "user/photo/" + response.data.details.guest_photo);
        }
        if (response.data.details.identity_proof !== null) {
          setIdentity(
            imageUrl + "user/id_proof/" + response.data.details.identity_proof
          );
        }

        setEvent(response.data.details.event_name);
        setVenue(response.data.details.event_venue);
        // setCategory(response.data.event_venue);

        setStart(moment(response.data.details.event_start).format("DD-MM-YYYY"));
        setEnd(moment(response.data.details.event_end).format("DD-MM-YYYY"));
        // setClose(moment(response.data.event_entry_close).format("DD-MM-YYYY"));

        // setRegDate(moment(response.data.reg_date).format("DD-MM-YYYY"));
        if (response.data.entry_files.length !== 0) {
          for (let i = 0; i < response.data.entry_files.length; i++) {
            var str = response.data.entry_files[i].file_name;
            arr.push({
              format: str,
              name: response.data.entry_files[i].file_name,
              type: response.data.entry_files[i].file_type,
            });
          }
        }
        setFiles(arr);
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Guest Users Information{" "}
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <h5>Personal Information</h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">First Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{fname}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{lname}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {member !== null && (
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Membership Type</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{member}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email Address</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Primary Mobile</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pmobile}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Secondary Mobile</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{smobile}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Date of Birth</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dob}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Username</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{uname}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Status</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{status}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <hr />
                <h5>Primary Information</h5>
                <div className="row">
                  {/*  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Education</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{education}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/*  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Occupcation</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{occu}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {street !== "" && (
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Street / House No</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{street}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Country</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{country}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {state !== "" && (
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">State</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{state}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {city !== "" && (
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">City</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{city}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {pincode !== null && (
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Pincode</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{pincode}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr />
                <h5>Event Details</h5>
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-2">
                          <b className="text-inverse">Event Name</b>
                        </div>
                        <div className="col-md-10">
                          <span className="text-inverse">{event}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Event Venue</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{venue}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Entry Start Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{sdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Event End Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{edate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Event Close Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{cdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Registered Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{regdate}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <hr></hr>
                <div className="row">
                  {photo !== "" && (
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="link-btn">
                            <b className="text-inverse">Photograph</b>
                            <a>
                              <img
                                className="profile_img"
                                src={photo}
                                height="160"
                                alt="photo"
                              />
                            </a>
                            <div>
                              <a
                                onClick={(e) => ImageShow(photo)}
                                className="btn-small btn-s"
                                target="_blank"
                              >
                                View
                              </a>
                              <a
                                href={photo}
                                className="btn-small"
                                target="_blank"
                                download
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {identity !== "" && (
                    <div className="col-md-6 bot-gap">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="link-btn">
                            <b className="text-inverse">Identity Proof</b>
                            <a>
                              <img
                                className="profile_img"
                                src={identity}
                                height="160"
                                alt="photo"
                              />
                            </a>
                            <div>
                              <a
                                onClick={(e) => ImageShow(identity)}
                                className="btn-small btn-s"
                                target="_blank"
                              >
                                View
                              </a>
                              <a
                                href={identity}
                                className="btn-small"
                                target="_blank"
                                download
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}
                {photo !== "" && <hr />}{" "}
                {files.length !== 0 && <h5>File Details</h5>}
                <div className="row">
                  {files.map((data, i) => {
                    return (
                      <div className="col-md-6" key={i}>
                        {data.type === 1 && (
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Video</b>
                                <div>
                                  <video width="100%" height="100%" controls>
                                    <source
                                      src={
                                        imageUrl +
                                        "evententry/video/" +
                                        data.name
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.type === 2 && (
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Audio</b>
                                <div>
                                  <video width="100%" height="100%" controls>
                                    <source
                                      src={
                                        imageUrl +
                                        "evententry/audio/" +
                                        data.name
                                      }
                                      type="Audio/mp3"
                                    />
                                  </video>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.type === 3 && (
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Identity Proof</b>
                                <a>
                                  <img
                                    className="profile_img"
                                    src={
                                      imageUrl + "evententry/image/" + data.name
                                    }
                                    height="160"
                                    alt="photo"
                                  />
                                </a>
                                <div>
                                  <a
                                    onClick={(e) =>
                                      ImageShow(
                                        imageUrl +
                                          "evententry/image/" +
                                          data.name
                                      )
                                    }
                                    className="btn-small btn-s"
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                  <a
                                    href={
                                      imageUrl + "evententry/image/" + data.name
                                    }
                                    className="btn-small"
                                    target="_blank"
                                    download
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.type === 4 && (
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Document</b>
                                <a
                                  href={
                                    imageUrl + "evententry/docs" + data.name
                                  }
                                >
                                  <img
                                    className="profile_img"
                                    src={require("../../../assets/images/word.png")}
                                    height="160"
                                    alt="photo"
                                  />
                                </a>
                                <div>
                                  <a
                                    href={
                                      imageUrl + "evententry/docs" + data.name
                                    }
                                    className="btn-small"
                                    target="_blank"
                                    download
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {i % 2 === 1 && <br />}
                      </div>
                    );
                  })}
                </div>
                <br />
                <br />
                <div className="text-center">
                  <a
                    className="btn btn-prev btn-secondary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </a>
                  {/* <a
                    className="btn btn-prev btn-info"
                    onClick={() => {
                      navigate("/users/guestedit/" + id);
                    }}
                  >
                    Edit
                  </a> */}
                  <a className="btn btn-prev btn-danger" onClick={deleteEntry}>
                    Delete
                  </a>
                </div>
              </div>
              <Modal show={ishow} onHide={ImageClose}>
                <Modal.Body className="hhh">
                  <img
                    className="profile_img prof-img"
                    src={file}
                    height="160"
                    alt="photo"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={ImageClose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Guestmembereventview;
