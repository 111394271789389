import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { Link } from "react-router-dom";
import { jurylistdetail, jurylist } from "../../../services/jury";
import { deljury } from "../../../services/admin";
import Swal from "sweetalert2";
import imageUrl from "../../../ImageUrl";
const ApprovedJurylist = () => {
  const gridRef = useRef();
  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 120,
      field: "user_uid",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="row">
            <div className="text-center">
              <Link to={"/viewjury/" + params.value} className="btn-view">
                View
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Photo",
      width: 150,
      field: "photo",
      filter: false,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            src={imageUrl + 'jury/photo/' + params.data.photo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },
    {
      headerName: "Username",
      width: 150,
      field: "unique_uid",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Name",
      width: 150,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 300,
      field: "email",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Mobile",
      width: 150,
      field: "primary_mobile",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Registered Date",
      width: 150,
      field: "reg_date",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
  ];

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  function delJury(e, uid) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure want to Delete?",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deljury(uid).then((response) => {
          if (response.status === true) {
            juryreload();
          }
        });
      }
    });
  }
  let arr = [];
  useEffect(() => {
    juryreload();
  }, []);
  function juryreload() {
    setFilterProduct([]);
    jurylist(1).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">APPROVED JURY LIST</h2>
        </div>
      </div>

      <Row className="row-sm mx-0 mt-3">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
             
              <div className="responsive">
                <div
                  className="ag-theme-alpine ag-style"
                  style={{
                    height: 600,
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowData={filterProduct}
                    pagination="true"
                    paginationPageSize="10"
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ApprovedJurylist;
