import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import {
  citylist,
  countrylist,
  genderlist,
  juryslanguage,
  statelist,
} from "../../../services/register";
import { getemail } from "../../../services/register";
import { Card, Col, Row } from "react-bootstrap";
import { registerjury } from "../../../services/jury";
import Swal from "sweetalert2";
import "./jury.css";
import { eventcategorylist } from "../../../services/admin";
const Juryregister = () => {
  const navigate = useNavigate();
  const [step1submit, setStep1] = useState(false);
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [emailerr, setEmailErr] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");

  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [email, setEmail] = useState("");
  const [mobilecode, setMobcode] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [glist, setGenderlist] = useState([]);

  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [pincode, setPincode] = useState("");

  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);

  const [category, setCategory] = useState("");
  const [catlist, setCatList] = useState([]);

  const [interestlang, setLanguage] = useState("");
  const [langlist, setLanglist] = useState([]);

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }
  //email validator
  // const [emailErr, setEmailErr] = useState("");

  // if (validator.isEmail(email)) {
  //   setEmailErr("");
  // } else {
  //   setEmailErr("Enter valid Email!");
  // }

  const [mobonly, setMobileonly] = useState("");
  const handle = (value, data, event, formattedValue) => {
    setMobcode(data.dialCode);
    setMobile(value);
    setMobileonly(value.slice(data.dialCode.length));
  };

  function datelist() {
    var day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }
    setDaylist(day);

    var year = [];

    for (let i = 1930; i <= new Date().getFullYear() - 5; i++) {
      year.push(i);
    }
    setYearlist(year);
  }

  function masterlist() {
    genderlist().then((response) => {
      setGenderlist(response.genders);
    });
  }

  const [check, setCheck] = useState(false);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  const [formiderror, setIDErrors] = useState("");

  function idfile(e) {
    e.preventDefault();
    setIDErrors("");
    setIDUpload("");
    setIDFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(e.target.files[0]));
        setIDFile(e.target.files[0]);
      } else {
        setIDErrors("Identity Proof should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024;
      if (size > 900) {
        setIDErrors("Identity Proof size should be less than 900KB");
      }
    }
  }

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setPhotoUpload("");
    setPhotoFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));
        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024;
      if (size > 900) {
        setPhotoErrors("Photograph size should be less than 900KB");
      }
    }
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailErr("");
    if (e.target.value !== "") {
      getemail(e.target.value).then((response) => {
        if (response.status === false) {
          setValEmail(response.message);
          // Swal.fire({
          //   title:
          //     response.message +
          //     " - " +
          //     response.data.username +
          //     " has been already have this Email",
          //   allowOutsideClick: false,
          // });
          setEmailErr("another user already have this email");
        }
      });
    }
  }
  function juryreg(e) {
    e.preventDefault();
    setStep1(true);
    setErrorMessage("");
    setEmailError("");
    let evalid = "";
    if (email !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        //evalid = "Please Enter a valid Email Address";
        setEmailError("Please Enter a valid Email Address");
      }
    }
    if (mobile !== "") {
      if (mobile.length < 10) {
        setMobError("Mobile Number must be 10 digits");
      } else {
        setMobError("");
      }
    }

    // if (formphoto === "") {
    //   setPhotoErrors("Photograph is required");
    // }
    // if (formid === "") {
    //   setIDErrors("Identity Proof is required");
    // }

    if (daygroup !== "" && monthgroup !== "" && yeargroup !== "") {
      setDOB(yeargroup + "-" + monthgroup + "-" + daygroup);
    }
    if (
      fname !== "" &&
      // lname !== "" &&
      // email !== "" &&
      // evalid === "" &&
      // mobile !== "" &&
      // mobile.length >= 10 &&
      gender !== ""
      // daygroup !== "" &&
      // monthgroup !== "" &&
      // yeargroup !== "" &&
      // add1 !== "" &&
      // pincode !== "" &&
      // country !== "" &&
      // state !== "" &&
      // city !== "" &&
      // formid !== "" &&
      // emailerror === "" &&
      // emailerr === "" &&
      // formiderror === "" &&
      // formphoto !== "" &&
      // formphotoerror === "" &&
    ) {
      setLoading(true);
      const tempData = new FormData();
      tempData.append("first_name", fname);
      tempData.append("last_name", lname);
      tempData.append("gender", gender);
      tempData.append(
        "date_of_birth",
        yeargroup + "-" + monthgroup + "-" + daygroup
      );
      tempData.append("primary_mobile", mobonly);
      tempData.append("primary_mob_country_code", mobilecode);
      tempData.append("email_id", email);

      tempData.append("street_no", add1);
      tempData.append("address_2", add2);

      tempData.append("pincode", pincode);
      tempData.append("country", country);
      tempData.append("state", state);
      tempData.append("city", city);
      tempData.append("photo", formphoto);
      tempData.append("identity", formid);
      tempData.append("register_by", localStorage.getItem("Userid"));
      tempData.append("qualification_1", artadd1);
      tempData.append("qualification_2", artadd2);
      tempData.append("qualification_3", artadd3);
      tempData.append("qualification_4", artadd4);
      tempData.append("qualification_5", artadd5);
      registerjury(tempData).then((response) => {
        if (response.status === true) {
          navigate("/jurylists");
          setStep1(false);
          setLoading(false);
        } else {
          setErrorMessage(response.data);
        }
      });
    }
  }

  //multidoc
  const [artadd, setArtadd] = useState(1);

  const [artadd1, setArtadd1] = useState("");
  const [artadd2, setArtadd2] = useState("");
  const [artadd3, setArtadd3] = useState("");
  const [artadd4, setArtadd4] = useState("");
  const [artadd5, setArtadd5] = useState("");

  function addmulti() {
    if (artadd < 5) {
      setArtadd(artadd + 1);
    }
  }

  function addremove() {
    if (artadd == 1) {
      setArtadd2("");
      setArtadd3("");
    } else if (artadd == 2) {
      setArtadd2("");
    } else if (artadd == 3) {
      setArtadd3("");
    } else if (artadd == 4) {
      setArtadd4("");
    } else if (artadd == 5) {
      setArtadd5("");
    }
    setArtadd(artadd - 1);
  }
  useEffect(() => {
    datelist();
    masterlist();
    countrylist().then((response) => {
      setCountrylist(response.country);
    });
    let arr1 = [];
    eventcategorylist().then((response) => {
      if (response.data.length !== 0) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
          });
        }
        setCatList(arr1);
      }
    });
    let arr2 = [];
    juryslanguage().then((response) => {
      if (response.language.length !== 0) {
        for (let i = 0; i < response.language.length; i++) {
          arr2.push({
            value: response.language[i].language_uid,
            label: response.language[i].language,
          });
        }
        setLanglist(arr2);
      }
    });
  }, []);

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }

  const hangleInterest = (e) => {
    setCategory(e);
  };

  const hangleLanguage = (e) => {
    setLanguage(e);
  };

  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">JURY REGISTRATION</h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="form-inner">
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        {" "}
                        First Name : <b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your First Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        value={fname}
                        maxLength={50}
                        onChange={(e) => {
                          setFName(e.target.value);
                        }}
                      />
                      {step1submit && !fname && (
                        <span className="errortxt text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Last Name :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Last / Family Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={50}
                        value={lname}
                        onChange={(e) => {
                          setLName(e.target.value);
                        }}
                      />

                      {/* {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )} */}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Gender : <b className="text-danger">*</b>
                      </label>
                      <select
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your Gender
                        </option>
                        {glist.map((data, i) => {
                          return (
                            <option value={data.gender_uid} key={i}>
                              {data.gender}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !gender && (
                        <span className="errortxt text-danger">
                          Gender is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Date of Birth :</label>

                      <div className="row rw">
                        <div className="col-md-4 col-sm-4 xpr-0 xprs">
                          <select
                            value={daygroup}
                            onChange={(e) => {
                              setDateDay(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Day
                            </option>
                            {dateday.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpx-0 xprs">
                          <select
                            value={monthgroup}
                            onChange={(e) => {
                              setDateMonth(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Month
                            </option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpl-0 xprs">
                          <select
                            value={yeargroup}
                            onChange={(e) => {
                              setDateYear(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Year
                            </option>
                            {dateyear.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {/* {step1submit &&
                        (!daygroup || !monthgroup || !yeargroup) && (
                          <span className="errortxt text-danger">
                            Date of Birth is required
                          </span>
                        )} */}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Email Address :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Email Address"
                        value={email}
                        onBlur={checkemail}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {/* {step1submit && !email && (
                        <span className="errortxt text-danger">
                          Email is required
                        </span>
                      )} */}
                      {emailerror !== "" && email && (
                        <span className="errortxt text-danger">
                          {emailerror}
                        </span>
                      )}
                      {emailerr !== "" && email && (
                        <span className="errortxt text-danger">{emailerr}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Mobile Number : </label>
                      <PhoneInput
                        specialLabel={""}
                        country={"in"}
                        placeholder="Enter Mobile Number"
                        countryCodeEditable={false}
                        value={mobile}
                        onChange={handle}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      {/* {step1submit && !mobile && (
                        <span className="errortxt text-danger">
                          Mobile Number is required
                        </span>
                      )} */}
                      {/* {step1submit && mobile.length < 10 && (
                        <span className="errortxt text-danger">
                          Mobile Number is required
                        </span>
                      )} */}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username"> Address 1 :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Address 1"
                        value={add1}
                        onChange={(e) => {
                          setAdd1(e.target.value);
                        }}
                      />
                      {/* {step1submit && !add1 && (
                        <span className="errortxt text-danger">
                          Address 1 is required
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Address 2 :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Address 2"
                        value={add2}
                        onChange={(e) => {
                          setAdd2(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Country :</label>
                      <select value={country} onChange={Selectcountry}>
                        <option value="" disabled>
                          Select Your Country
                        </option>
                        {colist.map((data, i) => {
                          return (
                            <option value={data.country_uid} key={i}>
                              {data.country_name}
                            </option>
                          );
                        })}
                      </select>
                      {/* {step1submit && !country && (
                        <span className="errortxt text-danger">
                          Country is required
                        </span>
                      )} */}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">State :</label>
                      <select value={state} onChange={Selectstate}>
                        <option value="" disabled>
                          Select Your State
                        </option>
                        {slist.map((data, i) => {
                          return (
                            <option value={data.state_uid} key={i}>
                              {data.state_name}
                            </option>
                          );
                        })}
                      </select>
                      {/* {step1submit && !state && (
                        <span className="errortxt text-danger">
                          State is required
                        </span>
                      )} */}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">City :</label>
                      <select
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your City
                        </option>
                        {clist.map((data, i) => {
                          return (
                            <option value={data.city_uid} key={i}>
                              {data.city_name}
                            </option>
                          );
                        })}
                      </select>
                      {/* {step1submit && !city && (
                        <span className="errortxt text-danger">
                          City is required
                        </span>
                      )} */}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Pincode : </label>
                      <input
                        type="text"
                        placeholder="Enter Your Pincode"
                        value={pincode}
                        onChange={(e) => {
                          setPincode(e.target.value);
                        }}
                        maxLength={6}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {/* {step1submit && !pincode && (
                        <span className="errortxt text-danger">
                          Pincode is required
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>

                <hr />

                <h5>Professional Information</h5>

                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Category : <b className="text-danger">*</b>
                      </label>
                      <Select
                        placeholder="Select Your Category"
                        value={category}
                        options={catlist}
                        onChange={hangleInterest}
                        isMulti
                        isClearable
                      />
                      {/* {submit && !category && (
                        <span className="errortxt text-danger">
                          Category is required
                        </span>
                      )} */}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Language :<b className="text-danger">*</b>{" "}
                      </label>
                      <Select
                        placeholder="Select Your Language"
                        value={interestlang}
                        options={langlist}
                        onChange={hangleLanguage}
                        isMulti
                        isClearable
                      />
                      {/* {submit && !interestlang && (
                        <span className="errortxt text-danger">
                          Language is required
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>

                <hr />

                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="dob">
                        Photograph : <span>(Maximum size 900KB)</span>
                      </label>

                      <div className="mt-4">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={photofile}
                        />
                      </div>
                    </div>

                    {formphotoupload !== "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={formphotoupload}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}

                    {formphotoupload === "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formphotoerror !== "" && (
                    <span className="errortxt text-danger">
                      {formphotoerror}
                    </span>
                  )}
                </div>

                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="dob">
                        Identity Proof : <span>(Maximum size 900KB)</span>
                      </label>

                      <div className="mt-4">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={idfile}
                        />
                      </div>
                    </div>

                    {formidupload !== "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img src={formidupload} width="100%" height="auto" />
                        </div>
                      </div>
                    )}

                    {formidupload === "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formiderror !== "" && (
                    <span className="errortxt text-danger">{formiderror}</span>
                  )}
                </div>
              </div>

              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}
              {/* <div>
                <span className="errortxt text-danger">
                  Note: Qualification Document should be doc or docx format.
                </span>
              </div> */}
              <div className="">
                <a
                  className="btn btn-primary btn-next"
                  style={{ float: "right" }}
                  onClick={juryreg}
                >
                  Submit
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Juryregister;
