export const MENUITEMS = [
  {
    menutitle: "Navigation",
    Items: [
      {
        path: "/dashboard",
        icon: "ti-home",
        type: "link",
        active: false,
        selected: false,
        title: "Dashboard",
      },
      {
        title: "Members",
        icon: "ti-user",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/users/adduser",
            type: "link",
            active: false,
            selected: false,
            title: "Add Members",
          },
          {
            path: "/users/pendingusers",
            type: "link",
            active: false,
            selected: false,
            title: "Pending Members",
          },
          {
            path: "/users/approvedusers",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Members",
          },

          {
            path: "/users/removedusers",
            type: "link",
            active: false,
            selected: false,
            title: "Removed Members",
          },
          {
            path: "/users/blockedusers",
            type: "link",
            active: false,
            selected: false,
            title: "Blocked Members",
          },

          {
            path: "/guestlist",
            type: "link",
            active: false,
            selected: false,
            title: "Guest Members List",
          },
          {
            path: "/users/paymentlist",
            type: "link",
            active: false,
            selected: false,
            title: "Payment List",
          },
        ],
      },
      {
        title: "Master",
        icon: "ti-crown",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/master/role",
            type: "link",
            active: false,
            selected: false,
            title: "Role",
          },
          {
            path: "/master/pgadmin",
            type: "link",
            active: false,
            selected: false,
            title: "PG Admin",
          },
        ],
      },
      {
        title: "Tournament / Event",
        icon: "ti-announcement",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/event/categorylist",
            type: "link",
            active: false,
            selected: false,
            title: "Category List",
          },
          {
            path: "/addevent",
            type: "link",
            active: false,
            selected: false,
            title: "Add Event",
          },
          {
            path: "/quizregister",
            type: "link",
            active: false,
            selected: false,
            title: "Add Quiz",
          },
          {
            path: "/eventlist",
            type: "link",
            active: false,
            selected: false,
            title: "List Events",
          },
        ],
      },



      {
        title: "Festival",
        icon: "ti-star",
        type: "sub",
        active: false,
        selected: false,
        children: [
          // {
          //   path: "/",
          //   type: "link",
          //   active: false,
          //   selected: false,
          //   title: "Add Jury",
          // },
          {
            path: "/festival/festivaleventlist",
            type: "link",
            active: false,
            selected: false,
            title: "Festival Event 2023",
          },
          // {
          //   path: "/jury/approvedjury",
          //   type: "link",
          //   active: false,
          //   selected: false,
          //   title: "Approved Jury",
          // },

          // {
          //   path: "/jury/removedjury",
          //   type: "link",
          //   active: false,
          //   selected: false,
          //   title: "Removed Jury",
          // },
          // {
          //   path: "/jury/blockedjury",
          //   type: "link",
          //   active: false,
          //   selected: false,
          //   title: "Blocked Jury",
          // },

        
        ],
      },

      // {
      //   title: "Results",
      //   icon: "ti-medall",
      //   type: "sub",
      //   active: false,
      //   selected: false,
      //   children: [
      //     {
      //       path: "/eventresult",
      //       type: "link",
      //       active: false,
      //       selected: false,
      //       title: "Event Result",
      //     },
      //   ],
      // },
      {
        title: "Jury",
        icon: "ti-wand",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/juryregister",
            type: "link",
            active: false,
            selected: false,
            title: "Add Jury",
          },
          {
            path: "/jury/pendingjury",
            type: "link",
            active: false,
            selected: false,
            title: "Pending Jury",
          },
          {
            path: "/jury/approvedjury",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Jury",
          },

          {
            path: "/jury/removedjury",
            type: "link",
            active: false,
            selected: false,
            title: "Removed Jury",
          },
          {
            path: "/jury/blockedjury",
            type: "link",
            active: false,
            selected: false,
            title: "Blocked Jury",
          },

          // {
          //   path: "/jurylists",
          //   type: "link",
          //   active: false,
          //   selected: false,
          //   title: "Lists Jury",
          // },
        ],
      },
      {
        path: "/donarlist",
        icon: "ti-gift",
        type: "link",
        active: false,
        selected: false,
        title: "Donar List",
      },
      {
        path: "/paymentway",
        icon: "ti-money",
        type: "link",
        active: false,
        selected: false,
        title: "Payment",
      },

      //new Dashboard sidemenu
      // {
      //   path: "/entries",
      //   icon: "ti-package",
      //   type: "link",
      //   active: false,
      //   selected: false,
      //   title: "Entries",
      // },


      // {
      //   title: "Category Co-Ordinator",
      //   icon: "ti-bookmark-alt",
      //   type: "sub",
      //   active: false,
      //   selected: false,
      //   // children: [
      //   //     {
      //   //         path: '',
      //   //         type: 'link',
      //   //         active: false,
      //   //         selected: false,
      //   //         title: 'Pending Co-Ordinator'
      //   //     },
      //   //     {
      //   //         path: '',
      //   //         type: 'link',
      //   //         active: false,
      //   //         selected: false,
      //   //         title: 'Approved Co-Ordinator'
      //   //     },
      //   //     {
      //   //         path: '',
      //   //         type: 'link',
      //   //         active: false,
      //   //         selected: false,
      //   //         title: 'Add Co-Ordinator'
      //   //     },
      //   //     {
      //   //         path: '',
      //   //         type: 'link',
      //   //         active: false,
      //   //         selected: false,
      //   //         title: 'Removed Co-Ordinator'
      //   //     }
      //   // ]
      // },
      // {
      //   title: "Dispute Redressal",
      //   icon: "ti-ruler-pencil",
      //   type: "sub",
      //   active: false,
      //   selected: false,
      //   // children: [
      //   //     {
      //   //         path: '',
      //   //         type: 'link',
      //   //         active: false,
      //   //         selected: false,
      //   //         title: 'Ticket Status List'
      //   //     }
      //   // ]
      // },
    ],
  },
];
