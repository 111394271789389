import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  citylist,
  countrylist,
  genderlist,
  getemail,
  getmobile,
  guestmailsent,
  guestnewregistration,
  interestcategory,
  statelist,
} from "../../services/register";
import "./guest.css";
import { viewevent, sendguestmail } from "../../services/admin";
import {
  guestregistration,
  getguesteventpay,
  guestpaymentresponse,
} from "../../services/register";
import { Modal } from "react-bootstrap";

import moment from "moment";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import imageUrl from "../../ImageUrl";
import Maillink from "../../guestmail";
import Wordlink from "../../wordpresslink";

const SingleGuestregister = () => {
  const navigate = useNavigate();
  const [step1submit, setStep1] = useState(false);
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");

  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [email, setEmail] = useState("");
  const [mobilecode, setMobcode] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [glist, setGenderlist] = useState([]);
  const [eventcat, setEventcat] = useState("");

  const [memstatus, setStatusmember] = useState("");

  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [pincode, setPincode] = useState("");

  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);
  const [multifile, setMulti] = useState([]);

  const { id } = useParams();
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const [mobonly, setMobileonly] = useState("");
  const handle = (value, data, event, formattedValue) => {
    setMobcode(data.dialCode);
    setMobile(value);
    setMobileonly(value.slice(data.dialCode.length));
  };

  // function handle(e){
  //   setMobile(e)
  // }

  function datelist() {
    var day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }
    setDaylist(day);

    var year = [];

    for (let i = 1930; i <= new Date().getFullYear() - 5; i++) {
      year.push(i);
    }
    setYearlist(year);
  }

  function masterlist() {
    genderlist().then((response) => {
      setGenderlist(response.genders);
    });
  }

  const [valmob, setValMob] = useState("");
  function checkmobile(e) {
    e.preventDefault();
    setValMob("");
    //setMobError("");

    // if (e.target.value !== "") {
    //   getmobile(e.target.value).then((response) => {
    //     if (response.status === false) {
    //       setValMob(response.message);
    //       Swal.fire({
    //         title:
    //           response.message +
    //           " - " +
    //           response.data.username +
    //           " has been already have this Mobile Number",
    //         allowOutsideClick: false,
    //       });
    //     }
    //   });
    // }
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    // if (e.target.value !== "") {
    //   getemail(e.target.value).then((response) => {
    //     if (response.status === false) {
    //       setValEmail(response.message);
    //       Swal.fire({
    //         title:
    //           response.message +
    //           " - " +
    //           response.data.username +
    //           " has been already have this Email",
    //         allowOutsideClick: false,
    //       });
    //     }
    //   });
    // }
  }

  const [check, setCheck] = useState(false);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  const [formiderror, setIDErrors] = useState("");

  function idfile(e) {
    e.preventDefault();
    setIDErrors("");
    setIDUpload("");
    setIDFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(e.target.files[0]));
        setIDFile(e.target.files[0]);
      } else {
        setIDErrors("Identity Proof should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024;
      if (size > 900) {
        setIDErrors("Identity Proof size should be less than 900KB");
      }
    }
  }

  //popup function
  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  const handleClose = () => setShow(false);

  const [submitted, setSubmitted] = useState(false);

  function guestreg(e) {
    e.preventDefault();
    setSubmitted(true);
    setStep1(true);
    setErrorMessage("");
    setEmailError("");
    setMobError("");
    let evalid = "";
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      evalid = "Please Enter a valid Email Address";
      setEmailError("Please Enter a valid Email Address");
    }

    if (daygroup !== "" && monthgroup !== "" && yeargroup !== "") {
      setDOB(yeargroup + "-" + monthgroup + "-" + daygroup);
    }

    if (check === false) {
      setErrorMessage("Please Agree our terms and conditions");
    }

    let filecheck = true;

    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (multifile[i].chkerr !== "") {
          if (multifile[i].fileload !== "") {
            if (multifile[i].fileerror !== "") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - " + multifile[i].fileerror
              );
              break;
            }
          } else {
            if (multifile[i].filetype === "1") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - Video file is required"
              );
            } else if (multifile[i].filetype === "2") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - Audio file is required"
              );
            } else if (multifile[i].filetype === "3") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - Image file is required"
              );
            } else if (multifile[i].filetype === "4") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - Document file is required"
              );
            }
            break;
          }
        }
      }
    }

    // if (mobile.length !== 12) {
    //   setMobError("Mobile Number must be minimum 10 digits");
    // }
    if (
      fname !== "" &&
      lname !== "" &&
      email !== "" &&
      evalid === "" &&
      gender !== "" &&
      daygroup !== "" &&
      monthgroup !== "" &&
      yeargroup !== "" &&
      eventcat !== "" &&
      country !== "" &&
      check === true &&
      filecheck === true
    ) {
      // setLoading(true);

      let cid = "";
      if (eventcat.length !== 0) {
        for (let j = 0; j < eventcat.length; j++) {
          if (cid === "") {
            cid = eventcat[j].value + "";
          } else {
            cid = cid + "," + eventcat[j].value + "";
          }
        }
      }

      let intid = "";
      if (interestcat.length !== 0) {
        for (let j = 0; j < interestcat.length; j++) {
          if (intid === "") {
            intid = interestcat[j].value;
          } else {
            intid = intid + "," + interestcat[j].value;
          }
        }
      }

      const tempData = new FormData();
      tempData.append("event_uid", id);
      tempData.append("guest_firstname", fname);
      tempData.append("guest_lastname", lname);
      tempData.append("guest_email", email);
      tempData.append("guest_mobile", mobonly);
      tempData.append("guest_gender", gender);
      tempData.append(
        "guest_dob",
        yeargroup + "-" + monthgroup + "-" + daygroup
      );
      tempData.append("mobile_country_code", mobilecode);

      tempData.append("address_1", "address");
      tempData.append("country", country);
      tempData.append("interested_category_uid", "0");
      var gct = "";
      gct = cid.split(",");
      if (gct.length > 1) {
        tempData.append("category_uid", cid);
      }

      if (multifile.length !== 0) {
        for (let i = 0; i < multifile.length; i++) {
          if (multifile[i].fileload !== "") {
            if (gct.length === 1) {
              tempData.append("category_uid", multifile[i].category_uid);
            }
            if (multifile[i].file_type !== "") {
              tempData.append(
                "file_type_" + multifile[i].category_uid,
                multifile[i].file_type
              );
            }

            tempData.append(
              "file_name_" +
                multifile[i].category_uid +
                "_" +
                multifile[i].filetype,
              multifile[i].fileload
            );
          }
        }
      }

      guestnewregistration(tempData).then((responsedata) => {
        if (responsedata.status === true) {
          if (status === 1) {
            setSubmitted(false);
            getguesteventpay(responsedata.data.guest_id, id).then(
              (response) => {
                paynow(
                  responsedata.data.guest_id,
                  response.order_details.amount,
                  response.payment_type.payment_currency,
                  response.order_details.id,
                  response.payment_type.payment_amount
                );
              }
            );
          } else {
            if (responsedata.data.guest_id !== 0) {
              guestmailsent(responsedata.data.guest_id).then((res) => {
                if (res.status === true) {
                  setLoading(false);
                  Swal.fire({
                    title: "Registration is Success.",
                    showCancelButton: false,
                    confirmButtonColor: "#6259ca",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                      window.open("https://pythiangames.org/", "_blank");
                    }
                  });
                }
              });
            }
          }
        } else {
          setErrorMessage(responsedata.data);
        }
      });
    }
  }
  //payment
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  let userId = localStorage.getItem("userid");
  async function paynow(userid, amt, currency, orderid, payamt) {
    const options = {
      // key: "rzp_test_XFnDXpEfL0bcLT",
      key: "rzp_live_EtyIA627A0V6mO",
      payment_amount: amt,
      payment_currency: currency,
      order_uid: orderid,
      name: "Pythian Games",
      description: "Event Registeration",
      image: require("../../assets/images/logo/logonew.png"),
      handler: function (response) {
        const paydet = {
          user_uid: 0,
          role_uid: 2,
          payment_type_id: 2,
          order_uid: response.razorpay_order_id,
          payment_uid: response.razorpay_payment_id,
          payment_sign: response.razorpay_signature,
          payment_amount: payamt,
          payment_currency: currency,
          guest_uid: userid,
          event_uid: id,
        };
        guestpaymentresponse(paydet).then((responsepay) => {
          if (responsepay.status === true) {
            guestmailsent(userid).then((res) => {
              if (res.status === true) {
                Swal.fire({
                  title: "Registration is Success.",
                  showCancelButton: false,
                  confirmButtonColor: "#6259ca",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                    window.open("https://pythiangames.org/", "_blank");
                  }
                });
              }
            });
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  //image file
  const [formphotoerror, setPhotoErrors] = useState("");

  function photofile(e, cat, file, index) {
    // e.preventDefault();
    // setPhotoErrors("");
    // setErrorMessage("");
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            if (e.target.files.length !== 0) {
              multifile[i].fileerror = "";
              if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                multifile[i].fileload = e.target.files[0];
                setMulti([...multifile]);

                var image = document.getElementById("image");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    image.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
              } else {
                multifile[i].fileload = e.target.files[0];

                multifile[i].fileerror =
                  "Image should be jpg or png or jpeg format";
                setMulti([...multifile]);

                setPhotoErrors("Image should be jpg or png or jpeg format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 2) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror = "Image size should be less than 2MB";
                } else {
                  multifile[i].fileerror =
                    "Image should be jpg or png or jpeg format and file size should be less than 2MB";
                }
                setMulti([...multifile]);
                setPhotoErrors("Image size should be less than 2MB");
              }
            }
          }
        }
      }
    }
  }
  //audio file
  const [formaudio, setAudioFile] = useState("");
  const [formaudioupload, setAudioUpload] = useState("");
  const [formaudioerror, setAudioErrors] = useState("");

  function audiofile(e, cat, file, index) {
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";

            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(mp3|wav)$/i)) {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                setMulti([...multifile]);

                var audio = document.getElementById("audio");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    audio.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
              } else {
                multifile[i].fileload = e.target.files[0];

                multifile[i].fileerror = "Audio should be mp3 or wav format";
                setMulti([...multifile]);

                setAudioErrors("Audio should be mp3 or wav format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 50) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Audio size should be less than 50MB";
                } else {
                  multifile[i].fileerror =
                    "Audio should be mp3 or wav format and file size should be less than 50MB";
                }
                setMulti([...multifile]);

                setAudioErrors("Audio size should be less than 50MB");
              }
            }
          }
        }
      }
    }

    // e.preventDefault();
    // setPhotoErrors("");
    // setErrorMessage("");
    // if (e.target.files.length !== 0) {
    //   if (e.target.files[0].name.match(/\.(mp3|wav)$/i)) {
    //     setAudioUpload(URL.createObjectURL(e.target.files[0]));
    //     setAudioFile(e.target.files[0]);
    //   } else {
    //     setAudioErrors("audio should be mp3 or wav format");
    //   }
    //   var size = e.target.files[0].size / 1024 / 1024;
    //   if (size > 3) {
    //     setAudioErrors("Audio size should be less than 3MB");
    //   }
    // }
  }

  const [formworderror, setWordErrors] = useState("");

  function wordfile(e, cat, file, index) {
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";
            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(doc|docx)$/i)) {
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                multifile[i].fileload = e.target.files[0];
                setMulti([...multifile]);

                var worddoc = document.getElementById("word");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    worddoc.src =
                      "https://view.officeapps.live.com/op/embed.aspx?src=" +
                      URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
                // setVideoFile(e.target.files[0]);
              } else {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileerror =
                  "Document should be .doc or .docx format";
                setMulti([...multifile]);

                setWordErrors("Document should be .docx format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 2) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Document size should be less than 2MB";
                } else {
                  multifile[i].fileerror =
                    "Document should be .doc or .docx format and file size should be less than 2MB";
                }
                setMulti([...multifile]);
                setWordErrors("Document size should be less than 2MB");
              }
            }
          }
        }
      }
    }
  }

  //video file
  const [formvideo, setVideoFile] = useState("");
  const [formvideoerror, setVideoErrors] = useState("");

  function videofile(e, cat, file, index) {
    // e.preventDefault();
    // setVideoErrors("");
    // setErrorMessage("");
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";
            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(mp4)$/i)) {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                setMulti([...multifile]);
                var video = document.getElementById("video");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    video.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
              } else {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileerror = "Video should be mp4 format";
                setMulti([...multifile]);

                setVideoErrors("Video should be mp4 format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 50) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Video size should be less than 50MB";
                } else {
                  multifile[i].fileerror =
                    "Video should be mp4 format and file size should be less than 50MB";
                }
                setMulti([...multifile]);
                setVideoErrors("Video size should be less than 50MB");
              }
            }
          }
        }
      }
    }
  }

  const [ename, setEName] = useState("");
  const [vname, setVName] = useState("");
  const [cname, setCName] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [amount, setAmt] = useState("");
  const [status, setStatus] = useState(0);
  const [catlist, setCatlist] = useState([]);
  const [intcatlist, setIntCatlist] = useState([]);

  let arr = [];
  let arr1 = [];
  let arr3 = [];

  const [interestcat, setEventinterest] = useState("");
  const [filecir, setFilecir] = useState("");

  let arr2 = "";

  useEffect(() => {
    datelist();
    masterlist();
    countrylist().then((response) => {
      setCountrylist(response.country);
    });

    interestcategory().then((response) => {
      if (response.data.length !== 0) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
          });
        }
        setIntCatlist(arr1);
      }
    });

    viewevent(id).then((response) => {
      if (response.status === true) {
        if (response.data.SelectedEventCategories.length !== 0) {
          for (
            let i = 0;
            i < response.data.SelectedEventCategories.length;
            i++
          ) {
            var f1 = 0;
            if (response.data.SelectedEventCategories[i].file_type !== "") {
              f1 =
                response.data.SelectedEventCategories[i].file_type.split(",");
            }

            for (let j = 0; j < f1.length; j++) {
              var head =
                "Attach Files for " +
                response.data.SelectedEventCategories[i].category;
              if (j !== 0) {
                head = "";
              }
              arr3.push({
                heading: head,
                file_type: response.data.SelectedEventCategories[i].file_type,
                category_uid:
                  response.data.SelectedEventCategories[i].category_uid,
                category: response.data.SelectedEventCategories[i].category,
                filetype: f1[j],
                fileload: "",
                fileurl: "",
                fileerror: "",
                present: "",
                chkerr: "",
              });
            }

            arr.push({
              value: response.data.SelectedEventCategories[i].category_uid,
              label: response.data.SelectedEventCategories[i].category,
            });

            if (arr2 === "") {
              arr2 = response.data.SelectedEventCategories[i].category;
            } else {
              arr2 =
                arr2 +
                " , " +
                response.data.SelectedEventCategories[i].category;
            }
          }
          setMulti(arr3.sort((a, b) => a.category_uid - b.category_uid));
          setCatlist(arr);
        }
        setEName("Event Name:- " + response.data.event_name);
        setVName("Event Venue:- " + response.data.event_venue);
        setSDate(
          "Start Date:- " +
            moment(response.data.event_start).format("DD-MM-YYYY")
        );
        setEDate(
          "End Date:- " + moment(response.data.event_end).format("DD-MM-YYYY")
        );
        setCDate(
          "Result Declare:- " +
            moment(response.data.event_entry_close).format("DD-MM-YYYY")
        );

        setCName("Category:- " + arr2);
        setAmt("Entry Fee:- ₹ " + response.data.payment_amount + " INR");
        setFilecir(imageUrl + "evententry/circular/" + response.data.circular);
        if (response.data.payment_status !== null) {
          setStatus(response.data.payment_status);
        } else {
          setStatus(0);
        }
      }
    });
  }, []);

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }

  const handleChange = (e) => {
    setEventcat(e);
    for (let i = 0; i < multifile.length; i++) {
      multifile[i].present = "";
      multifile[i].chkerr = "";
      setMulti([...multifile]);
    }
    if (e.length !== 0) {
      for (let j = 0; j < e.length; j++) {
        for (let i = 0; i < multifile.length; i++) {
          if (e[j].value === multifile[i].category_uid) {
            if (multifile[i].heading !== "") {
              multifile[i].present = e[j].value;
            }
            multifile[i].chkerr = e[j].value;
          }

          setMulti([...multifile]);
        }
      }
    }
  };

  const hangleInterest = (e) => {
    setEventinterest(e);
  };

  const [loading, setLoading] = useState(false);
  return (
    <div id="registrationpage">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}

      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            {" "}
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
        </div>
        <div className="registrationright">
          <form action="#" className="form">
            <h2 className="text-right">Guest Registration</h2>
            <div className="guestreg row">
              <div className="col-md-12">
                <h6>{ename}</h6>
              </div>
              {/* <div className="col-md-12">
                <h6>{vname}</h6>
              </div> */}
              <div className="col-md-12">
                <h6>{cname}</h6>
              </div>
              <div className="col-md-4">
                <h6>{sdate}</h6>
              </div>
              <div className="col-md-4">
                <h6>{edate}</h6>
              </div>
              <div className="col-md-4">
                <h6>{cdate}</h6>
              </div>
            </div>

            <div className="form-step form-step-active">
              <div className="form-inner">
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        {" "}
                        First Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your First Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={fname}
                        onChange={(e) => {
                          setFName(e.target.value);
                        }}
                      />
                      {step1submit && !fname && (
                        <span className="errortxt text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Last Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Last / Family Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={lname}
                        onChange={(e) => {
                          setLName(e.target.value);
                        }}
                      />

                      {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Email Address :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        onBlur={checkemail}
                        placeholder="Enter Your Email Address"
                        maxLength={50}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {step1submit && !email && (
                        <span className="errortxt text-danger">
                          Email is required
                        </span>
                      )}
                      {emailerror !== "" && email && (
                        <span className="errortxt text-danger">
                          {emailerror}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Mobile Number :{/* <b className="text-danger">*</b> */}{" "}
                      </label>
                      <PhoneInput
                        specialLabel={""}
                        country={"in"}
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        countryCodeEditable={false}
                        onChange={handle}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      {/* {step1submit && !mobile && (
                        <span className="errortxt text-danger">
                          Mobile Number is required
                        </span>
                      )} */}
                      {mobile.length !== 12 && mobile && (
                        <span className="errortxt text-danger">{moberror}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Gender :<b className="text-danger">*</b>
                      </label>
                      <select
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your Gender
                        </option>
                        {glist.map((data, i) => {
                          return (
                            <option value={data.gender_uid} key={i}>
                              {data.gender}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !gender && (
                        <span className="errortxt text-danger">
                          Gender is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Age :<b className="text-danger">*</b>
                      </label>

                      <div className="row">
                        <div className="col-md-4 col-sm-4 xpr-0">
                          <select
                            value={daygroup}
                            onChange={(e) => {
                              setDateDay(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Day
                            </option>
                            {dateday.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpx-0">
                          <select
                            value={monthgroup}
                            onChange={(e) => {
                              setDateMonth(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Month
                            </option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpl-0">
                          <select
                            value={yeargroup}
                            onChange={(e) => {
                              setDateYear(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Year
                            </option>
                            {dateyear.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {step1submit &&
                        (!daygroup || !monthgroup || !yeargroup) && (
                          <span className="errortxt text-danger">
                            Age is required
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Event Category :<b className="text-danger">*</b>
                      </label>
                      <Select
                        placeholder="Select Your Event Category"
                        value={eventcat}
                        options={catlist}
                        onChange={handleChange}
                        isMulti
                        isClearable
                      />
                      {step1submit && !eventcat && (
                        <span className="errortxt text-danger">
                          Event Category is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Country :<b className="text-danger">*</b>
                      </label>
                      <select value={country} onChange={Selectcountry}>
                        <option value="" disabled>
                          Select Your Country
                        </option>
                        {colist.map((data, i) => {
                          return (
                            <option value={data.country_uid} key={i}>
                              {data.country_name}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !country && (
                        <span className="errortxt text-danger">
                          Country is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-step form-step-active">
                  {multifile.map((data, i) => {
                    return (
                      <div key={i}>
                        {data.present !== "" && (
                          <div className="form-inner form-innertwo">
                            <h4>{data.heading}</h4>
                            {data.file_type.includes("1") && (
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-8">
                                    <label htmlFor="dob">
                                      Add Video File :{" "}
                                      <b className="text-danger">*</b>{" "}
                                      <span>
                                        (Accepted Files are .mp4, .wav & Max
                                        Upload Size 50MB )
                                      </span>
                                    </label>

                                    <div className="mt-4">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept=".mp4"
                                        onChange={(e) =>
                                          videofile(
                                            e,
                                            data.category_uid,
                                            "1",
                                            i
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="imagecontainer">
                                      <video
                                        id="video"
                                        width="100%"
                                        height="110px"
                                        controls
                                      />
                                    </div>
                                  </div>
                                </div>
                                {data.fileload === "" && submitted && (
                                  <span className="errortxt text-danger">
                                    Video file is required
                                  </span>
                                )}
                              </div>
                            )}
                            {data.file_type.includes("2") && (
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-8">
                                    <label htmlFor="dob">
                                      Add Audio File :{" "}
                                      <b className="text-danger">*</b>{" "}
                                      <span>
                                        (Accepted Files are .mp3 & Max Upload
                                        Size 50MB )
                                      </span>
                                    </label>

                                    <div className="mt-4">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept=".mp3,.wav"
                                        onChange={(e) =>
                                          audiofile(
                                            e,
                                            data.category_uid,
                                            "2",
                                            i
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="imagecontainer">
                                      <audio
                                        id="audio"
                                        controls
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {data.fileload === "" && submitted && (
                                  <span className="errortxt text-danger">
                                    Audio file is required
                                  </span>
                                )}
                              </div>
                            )}
                            {data.file_type.includes("3") && (
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-8">
                                    <label htmlFor="dob">
                                      Add Image File :{" "}
                                      <b className="text-danger">*</b>{" "}
                                      <span>
                                        (Accepted Files are .jpg,.jpeg,.png &
                                        Max Upload Size 2MB )
                                      </span>
                                    </label>

                                    <div className="mt-4">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept=".png,.jpg,.jpeg"
                                        onChange={(e) =>
                                          photofile(
                                            e,
                                            data.category_uid,
                                            "3",
                                            i
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="imagecontainer">
                                      <img
                                        id="image"
                                        width="100%"
                                        height="auto"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {data.fileload === "" && submitted && (
                                  <span className="errortxt text-danger">
                                    Image file is required
                                  </span>
                                )}
                              </div>
                            )}

                            {data.file_type.includes("4") && (
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-8">
                                    <label htmlFor="dob">
                                      Add Word File :{" "}
                                      <b className="text-danger">*</b>{" "}
                                      <span>
                                        (Accepted Files are .doc,.docx & Max
                                        Upload Size 2MB )
                                      </span>
                                    </label>

                                    <div className="mt-4">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept=".doc,.docx"
                                        onChange={(e) =>
                                          wordfile(e, data.category_uid, "4", i)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="imagecontainer">
                                      {/* <iframe
                                          id="word"
                                          width="100%"
                                          height="110px"
                                        /> */}
                                      <img
                                        src={require("../../assets/images/word.png")}
                                        width="100%"
                                        height="auto"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {data.fileload === "" && submitted && (
                                  <span className="errortxt text-danger">
                                    Word file is required
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="row">
                <div className="col-md-9">
                  <div className="checkbox checkbox-1">
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      onChange={checkterm}
                      checked={check}
                    />
                    <label htmlFor="checkbox-1">
                      Agree to our{" "}
                      <a
                        target="_blank"
                        href="https://www.pythiangames.org/privacy"
                      >
                        Privacy Policy
                      </a>{" "}
                      ,{" "}
                      <a
                        target="_blank"
                        href="https://www.pythiangames.org/terms"
                      >
                        Terms & Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        // href={require("../../assets/images/Poetry_and_Painting_art_Compition.pdf")}
                        href={filecir}
                      >
                        Submission & Guidelines
                      </a>
                      <b className="text-danger">*</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  {status === 1 && (
                    <div className="member">
                      <label>{amount}</label>
                    </div>
                  )}
                  {status === 0 && (
                    <div className="member">
                      <label>Entry Fee: Free</label>
                    </div>
                  )}
                </div>
              </div>

              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}

              <div className="">
                <Link to={"/login"} className="btn btn-secondary">
                  Back
                </Link>
                <a
                  className="btn btn-primary btn-next"
                  style={{ float: "right" }}
                  onClick={guestreg}
                >
                  Submit
                </a>
              </div>
            </div>

            <Modal
              show={show}
              onHide={handleClose}
              size="large"
              backdrop="static"
            >
              <Modal.Header>
                <h4>Payment Successful!</h4>
                <i
                  className="fa fa-window-close fa-lg"
                  aria-hidden="true"
                  onClick={handleClose}
                ></i>
              </Modal.Header>
              <Modal.Body>
                <p>Registration completed</p>
              </Modal.Body>
              <Modal.Footer>
                <a
                  className="btn btn-success mr-3"
                  style={{ float: "right" }}
                  onClick={handleClose}
                >
                  ok
                </a>
              </Modal.Footer>
            </Modal>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SingleGuestregister;
