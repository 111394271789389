import React, { useState } from "react";
import "../login/login.css";
import { Link, useNavigate } from "react-router-dom";
import { guestloginotpemail } from "../../services/login";
import { useEffect } from "react";
import { admineventlist } from "../../services/admin";

function Guestlogin() {
  const [eventid, setEvent] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  function Submitforgot(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage("");
    localStorage.clear();
    const tempData = {
      eventuid: eventid,
      emailaddress: email,
    };
    if (eventid !== "") {
      if (email !== "") {
        let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regEmail.test(email)) {
          setErrorMessage("Please Enter a valid Email Address");
        } else {
          guestloginotpemail(tempData).then((response) => {
            if (response.status === true) {
              localStorage.setItem("Guestid", response.data.guest_uid);
              // localStorage.setItem("eventid", response.data.event_uid);
              localStorage.setItem("User", response.data.guest_email);
              localStorage.setItem("Otp", response.data.otp);
              localStorage.setItem("Roleid", "Guest");

              if (response.data.last_name !== null) {
                localStorage.setItem(
                  "Name",
                  response.data.guest_firstname +
                    " " +
                    response.data.guest_lastname
                );
              } else {
                localStorage.setItem("Name", response.data.guest_firstname);
              }
              navigate("/guestotplogin");
            } else {
              setErrorMessage(response.message);
            }
          });
        }
      }
    }
  }

  const [elist, setEventlist] = useState([]);

  useEffect(() => {
    admineventlist().then((response) => {
      if (response.data.length !== 0) {
        setEventlist(response.data);
      }
    });
  }, []);
  return (
    <div>
      <main id="loginpage">
        <div className="container">
          <div className="loginpage_section">
            <div className="loginpage_secionone">
              <div className="loginpage_sectionone_left">
                <div className="loginpage_logo">
                  <a href="https://pythiangames.org/" target="_blank">
                    {" "}
                    <img
                      src={require("../../assets/images/logo/logonew.png")}
                      height="150"
                    />
                  </a>
                </div>

                <img
                  src={require("../../assets/images/imageleft.png")}
                  className="loginleftimg"
                />
              </div>
            </div>

            <div className="loginpage_seciontwo">
              <div className="loginpage_sectiontwo_form">
                <form className="form">
                  <h5 className="text-center"> Welcome to</h5>
                  <h5 className="text-center"> Modern Pythian Games</h5>
                  <h2 className="text-center">Guest Login</h2>
                  <div className="input-group">
                    {/* <fieldset className="eventuid"> */}
                    <label>Event Name</label>
                    <select
                      value={eventid}
                      onChange={(e) => setEvent(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Your Event
                      </option>
                      {elist.map((data, i) => {
                        return (
                          <option value={data.event_uid} key={i}>
                            {data.event_name}
                          </option>
                        );
                      })}
                    </select>
                    {submitted && !eventid && (
                      <span className="errortext">
                        Please Select Your Event
                      </span>
                    )}
                    {/* </fieldset> */}
                  </div>

                  {/* <fieldset className="emailaddress"> */}
                  <label htmlFor="username">Email Address</label>
                  <input
                    type="text"
                    placeholder="Enter Your Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {submitted && !email && (
                    <span className="errortext">
                      Please Enter Your Email Address
                    </span>
                  )}
                  {/* </fieldset> */}

                  {errorMessage && (
                    <span className="errortext"> {errorMessage} </span>
                  )}

                  <button type="submit" className="btn" onClick={Submitforgot}>
                    {" "}
                    Send OTP
                  </button>
                  {/* <div className="text-center mt-4">
                                        <a className="text-primary"><Link to={'/login'}>Login</Link></a>
                                    </div> */}
                </form>
              </div>
            </div>
            <div className="loginpage_footer">
              <div className="copyrights">
                <span>
                  Â© {new Date().getFullYear()} Pythian Games | All Rights
                  Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Guestlogin;
