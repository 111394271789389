import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Card, Col, Form, Nav, Row, Tab, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import imageUrl from "../../ImageUrl";
import {
  citylist,
  countrylist,
  genderlist,
  interestcategory,
  juryslanguage,
  statelist,
} from "../../services/register";
import { changepassword, userbillingdata, userview } from "../../services/user";
import PhoneInput from "react-phone-input-2";
import {
  jurychangepassword,
  juryviewdetail,
  juryupdate,
} from "../../services/jury";
import Select from "react-select";
import "./jurydashboard.css";
import { eventcategorylist } from "../../services/admin";

const JuryProfile = () => {
  const [submit, setSubmit] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [dobview, setDOBView] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [gendername, setGenderName] = useState("");
  const [glist, setGenderlist] = useState([]);
  const [addr1, setAddress1] = useState("");
  const [addr2, setAddress2] = useState("");

  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);
  const [coname, setCoName] = useState("");

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);
  const [sname, setSName] = useState("");

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);
  const [ciname, setCiName] = useState("");

  const [primob, setPrimobile] = useState("");
  const [primobcode, setPriCode] = useState("");
  const [primobonly, setPriOnly] = useState("");

  const [qual1, setQual1] = useState("");
  const [qual2, setQual2] = useState("");
  const [qual3, setQual3] = useState("");
  const [qual4, setQual4] = useState("");
  const [qual5, setQual5] = useState("");

  const handleprimary = (value, data, event, formattedValue) => {
    setPriCode(data.dialCode);
    setPrimobile(value);
    setPriOnly(value.slice(data.dialCode.length));
  };

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }
  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }
  const [ishow, setImage] = useState(false);
  const [file, setFile] = useState(false);

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }
  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }

  let arr1 = [];
  let arr2 = [];
  function masterlist() {
    genderlist().then((response) => {
      // if (response.status === true) {
      setGenderlist(response.genders);
      // }
    });

    countrylist().then((response) => {
      setCountrylist(response.country);
    });

    eventcategorylist().then((response) => {
      if (response.data.length !== 0) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
          });
        }
        setCatList(arr1);
      }
    });

    juryslanguage().then((response) => {
      if (response.language.length !== 0) {
        for (let i = 0; i < response.language.length; i++) {
          arr2.push({
            value: response.language[i].language_uid,
            label: response.language[i].language,
          });
        }
        setLanglist(arr2);
      }
    });
  }

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  const [formiderror, setIdErrors] = useState("");
  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setErrorMessage("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));
        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Photograph size should be less than 3MB");
      }
    }
  }

  function idfile(event) {
    setIdErrors("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(event.target.files[0]));
        setIDFile(event.target.files[0]);
      } else {
        setIdErrors("Identity Profile should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setIdErrors("Identity Profile size should be less than 3MB");
      }
    }
  }

  const [curpass, setCurPass] = useState("");
  const [newpass, setNewPass] = useState("");
  const [confirmpass, setConfirmPass] = useState("");
  const [conerrorMessage, setConErrorMessage] = useState("");

  function changepass(e) {
    e.preventDefault();
    setConErrorMessage("");
    const pass = {
      user_uid: localStorage.getItem("Userid"),
      old_password: curpass,
      new_password: newpass,
    };
    if (curpass !== "") {
      if (newpass !== "") {
        if (newpass.length >= 8) {
          if (newpass.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)) {
            if (confirmpass !== "") {
              if (newpass === confirmpass) {
                jurychangepassword(pass).then((response) => {
                  if (response.status === true) {
                    navigate("/login");
                  } else {
                    setConErrorMessage(response.message);
                  }
                });
              } else {
                setConErrorMessage(
                  "Confirm Password should be same as New Password"
                );
              }
            } else {
              setConErrorMessage("Please Enter Your Confirm Password");
            }
          } else {
            setConErrorMessage(
              "Your password should be minimum 8 character with atleast 1 number, 1 lowercase character (a-z) and 1 uppercase character (A-Z)"
            );
          }
        } else {
          setConErrorMessage("New Password should be minimum 8 characters");
        }
      } else {
        setConErrorMessage("Please Enter Your New Password");
      }
    } else {
      setConErrorMessage("Please Enter Your Current Password");
    }
  }

  function addsubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    setSubmit(true);
    // if (formphotoupload === "" && formidupload === "") {
    //   setPhotoErrors("Photograph is required");
    //   setIdErrors("DOB Proof is required");
    // }
    // if (formphotoupload === "") {
    //   setPhotoErrors("Photograph is required");
    // } else if (formidupload === "") {
    //   setIdErrors("DOB Proof is required");
    // }
    // if (formphotoupload !== "" && formidupload !== "") {
    //   setSubmit(true);
    // }

    setEmailError("");
    setMobError("");
    var vaild = "";
    if (email !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        vaild = "require";
        setEmailError("Please Enter a valid Email Address");
      }
    }

    if (primob !== null) {
      if (primob.length < 10) {
        vaild = "require";
        setMobError("Mobile Number must be 10 characters");
      }
    }

    if (
      fname !== "" &&
      vaild === "" &&
      // lname !== "" &&
      // dob !== "" &&
      // addr1 !== "" &&
      // addr2 !== "" &&
      email !== "" &&
      // pincode !== "" &&
      // country !== "" &&
      // state !== "" &&
      // city !== "" &&
      emailerror === "" &&
      moberror === "" &&
      primob !== "" &&
      gender !== "" &&
      interestlang !== "" &&
      category !== "" &&
      formphotoerror === "" &&
      formiderror === ""
    ) {
      let cid = "";
      if (category.length !== 0) {
        for (let j = 0; j < category.length; j++) {
          if (cid === "") {
            cid = category[j].value;
          } else {
            cid = cid + "," + category[j].value;
          }
        }
      }

      let lanid = "";

      if (interestlang.length !== 0) {
        for (let j = 0; j < interestlang.length; j++) {
          if (lanid === "") {
            lanid = interestlang[j].value;
          } else {
            lanid = lanid + "," + interestlang[j].value;
          }
        }
      }

      const tempData = new FormData();
      tempData.append("user_uid", localStorage.getItem("Userid"));

      tempData.append("first_name", fname);
      tempData.append("last_name", lname);
      tempData.append("email", email);
      tempData.append("primary_mobile", primobonly);
      tempData.append("primary_mob_country_code", primobcode);
      tempData.append("gender", gender);
      tempData.append("date_of_birth", dob);
      tempData.append("street_no", addr1);
      tempData.append("address_2", addr2);
      tempData.append("pincode", pincode);
      tempData.append("country", country);
      tempData.append("state", state);
      tempData.append("city", city);
      tempData.append("photo", formphoto);
      tempData.append("identity", formid);
      tempData.append("interested_category_uid", cid);
      tempData.append("language_uid", lanid);

      juryupdate(tempData).then((response) => {
        if (response.status === true) {
          window.location.reload();
        } else {
          setErrorMessage(response.data);
        }
      });
    }
  }

  const hangleInterest = (e) => {
    setCategory(e);
  };

  const hangleLanguage = (e) => {
    setLanguage(e);
  };

  const navigate = useNavigate();
  let carr = [];
  let larr = [];
  const [catname, setCatName] = useState("");
  const [category, setCategory] = useState("");
  const [catlist, setCatList] = useState([]);

  const [interestlang, setLanguage] = useState("");
  const [langname, setLangName] = useState("");
  const [langlist, setLanglist] = useState([]);
  useEffect(() => {
    masterlist();

    juryviewdetail(localStorage.getItem("Userid")).then((response) => {
      if (response.status === true) {
        setFName(response.data.first_name);

        if (response.data.last_name !== null) {
          setLName(response.data.last_name);
        }
        if (response.data.email !== null) {
          setEmail(response.data.email);
        }
        if (response.data.primary_mobile !== null) {
          setPriOnly(response.data.primary_mobile);
        }

        if (response.data.primary_mob_country_code !== null) {
          setPriCode(response.data.primary_mob_country_code);
          setPrimobile(
            "(+" +
              response.data.primary_mob_country_code +
              ") " +
              response.data.primary_mobile
          );
        } else {
          setPrimobile(response.data.primary_mobile);
        }

        if (response.data.gender_uid !== null) {
          setGender(response.data.gender_uid);
          setGenderName(response.data.gender);
        }

        if (response.data.date_of_birth !== null) {
          if (response.data.date_of_birth !== "--") {
            setDOB(moment(response.data.date_of_birth).format("YYYY-MM-DD"));
            setDOBView(
              moment(response.data.date_of_birth).format("DD-MM-YYYY")
            );
          }
        }

        if (response.data.street_no !== null) {
          setAddress1(response.data.street_no);
          setAddress2(response.data.address_2);
        }
        if (response.data.country !== null) {
          setCountry(response.data.country);
          setState(response.data.state);
          setCity(response.data.city);
          setPincode(response.data.pincode);

          setCoName(response.data.country_name);
          setSName(response.data.state_name);
          setCiName(response.data.city_name);
        }

        if (response.data.photo !== null) {
          setPhotoUpload(imageUrl + "jury/photo/" + response.data.photo);
        }
        if (response.data.identity !== null) {
          setIDUpload(imageUrl + "jury/id_proof/" + response.data.identity);
        }

        if (response.data.qualification_1 !== undefined) {
          setQual1(response.data.qualification_1);
        }
        if (response.data.qualification_2 !== undefined) {
          setQual2(response.data.qualification_2);
        }
        if (response.data.qualification_3 !== undefined) {
          setQual3(response.data.qualification_3);
        }
        if (response.data.qualification_4 !== undefined) {
          setQual4(response.data.qualification_4);
        }
        if (response.data.qualification_5 !== undefined) {
          setQual5(response.data.qualification_5);
        }
        if (response.data.country !== "") {
          statelist(response.data.country).then((response) => {
            setStatelist(response.states);
          });
        }

        if (response.data.state !== "") {
          citylist(response.data.state).then((response) => {
            setCitylist(response.cities);
          });
        }

        var ctname = "";
        setCategory("");

        if (response.data.category.length !== 0) {
          eventcategorylist().then((res) => {
            if (res.status === true) {
              if (res.data.length !== 0) {
                for (let j = 0; j < response.data.category.length; j++) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (
                      response.data.category[j] ===
                      res.data[i].category_uid + ""
                    ) {
                      if (ctname === "") {
                        ctname = res.data[i].category;
                        carr.push({
                          value: res.data[i].category_uid,
                          label: res.data[i].category,
                        });
                      } else {
                        ctname = ctname + " , " + res.data[i].category;
                        carr.push({
                          value: res.data[i].category_uid,
                          label: res.data[i].category,
                        });
                      }
                    }
                  }
                }
              }
              setCatName(ctname);
              setCategory(carr);
            }
          });
        }

        setLanguage("");

        if (response.data.language.length !== 0) {
          var lgname = "";
          juryslanguage().then((res) => {
            if (res.language.length !== 0) {
              for (let j = 0; j < response.data.language.length; j++) {
                for (let i = 0; i < res.language.length; i++) {
                  if (
                    response.data.language[j] ===
                    res.language[i].language_uid + ""
                  ) {
                    if (lgname === "") {
                      lgname = res.language[i].language;
                      larr.push({
                        value: res.language[i].language_uid,
                        label: res.language[i].language,
                      });
                    } else {
                      lgname = lgname + " , " + res.language[i].language;
                      larr.push({
                        value: res.language[i].language_uid,
                        label: res.language[i].language,
                      });
                    }
                  }
                }
              }
            }
            setLangName(lgname);

            setLanguage(larr);
          });
        }
      }
    });
  }, []);

  function errorhandle(ev) {
    ev.target.src = require("../../assets/images/user.png");
    ev.target.onerror = null;
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">MY PROFILE</h2>
        </div>
      </div>

      <Tab.Container
        id="center-tabs-example"
        defaultActiveKey="first"
        className="bg-gray-100"
      >
        <Row className="square">
          <Col lg={12} md={12}>
            <Card className="custom-card">
              <Card.Body>
                <div className="panel profile-cover">
                  <div className="profile-cover__img">
                    {formphotoupload !== "" && (
                      <img
                        src={formphotoupload}
                        alt="img"
                        onError={(e) => errorhandle(e)}
                      />
                    )}

                    {formphotoupload === "" && (
                      <img
                        src={require("../../assets/images/placeholder.png")}
                        alt="img"
                      />
                    )}
                    <h3 className="h3">{fname} {lname}</h3>
                  </div>

                  {/* <div className="profile-cover__action bg-img"></div> */}
                  <div className="profile-cover__info">
                    <ul className="nav">
                      <li>
                        <strong>0</strong>Total Awards
                      </li>
                    </ul>
                  </div>
                  <div className="profile-tab tab-menu-heading">
                    <Nav variant="pills" className="p-3 bg-primary-transparent">
                      <Nav.Item>
                        <Nav.Link eventKey="first">About</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="editprofile">
                          Edit Profile{" "}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="accountsetting">
                          Account Settings
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="row-sm">
          <Col md={12} lg={12}>
            <div className="card custom-card main-content-body-profile">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div lg={12} md={12}>
                    <Card className="custom-card main-content-body-profile">
                      <div className="tab-content">
                        <div className="main-content-body tab-pane p-sm-4 p-0 border-top-0 active">
                          <div className=" p-0 border p-0 rounded-10">
                            <div className="p-2 mb-4 main-content-label">
                              Personal Information
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">First Name</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {fname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Last Name</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {lname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">
                                        Email Address
                                      </b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {email}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">
                                        Mobile Number
                                      </b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {primob}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Gender</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {gendername}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">
                                        Date of Birth
                                      </b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {dobview}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div className="p-2 mb-4 main-content-label">
                              Primary Information
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Address 1</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {addr1}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Address 2</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {addr2}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Country</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {coname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">State</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {sname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">City</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {ciname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Pincode</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {pincode}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div className="p-2 mb-4 main-content-label">
                              Professional Information
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Category</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {catname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="profile-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <b className="text-inverse">Language</b>
                                    </div>
                                    <div className="col-md-8">
                                      <span className="text-inverse">
                                        {langname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <hr />

                            <div className="row">
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="link-btn">
                                      <b className="text-inverse">Photograph</b>
                                      <a>
                                        <img
                                          className="profile_img"
                                          src={formphotoupload}
                                          height="160"
                                          alt="photo"
                                          onError={(e) => errorhandle(e)}
                                        />
                                      </a>
                                      <div>
                                        <a
                                          onClick={(e) =>
                                            ImageShow(formphotoupload)
                                          }
                                          className="btn-small btn-s"
                                          target="_blank"
                                        >
                                          View
                                        </a>
                                        <a
                                          href={formphotoupload}
                                          className="btn-small"
                                          target="_blank"
                                          download
                                        >
                                          Download
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 bot-gap">
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="link-btn">
                                      <b className="text-inverse">
                                        Identity Proof
                                      </b>
                                      <a>
                                        <img
                                          className="profile_img"
                                          src={formidupload}
                                          height="160"
                                          alt="photo"
                                          onError={(e) => errorhandle(e)}
                                        />
                                      </a>
                                      <div>
                                        <a
                                          onClick={(e) =>
                                            ImageShow(formidupload)
                                          }
                                          className="btn-small btn-s"
                                          target="_blank"
                                        >
                                          View
                                        </a>
                                        <a
                                          href={formidupload}
                                          className="btn-small"
                                          target="_blank"
                                          download
                                        >
                                          Download
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Modal show={ishow} onHide={ImageClose}>
                                <Modal.Body className="hhh">
                                  <img
                                    className="profile_img prof-img"
                                    src={file}
                                    height="160"
                                    alt="photo"
                                    onError={(e) => errorhandle(e)}
                                  />
                                </Modal.Body>
                                <Modal.Footer>
                                  <a
                                    className="btn btn-next btn-secondary"
                                    style={{ float: "right" }}
                                    onClick={ImageClose}
                                  >
                                    Close
                                  </a>
                                </Modal.Footer>
                              </Modal>
                            </div>
                            <hr />

                            {/* {(qual1 || qual2 || qual3 || qual4 || qual5) && (
                              <div className="p-2 mb-4 main-content-label">
                                Qualification Documents
                              </div>
                            )}
                            <div className="row">
                              {qual1 !== "" && (
                                <div className="col-md-4">
                                  <div className="input-group">
                                    <a
                                      href={
                                        imageUrl + "jury/qualification/" + qual1
                                      }
                                      download
                                      target="_blank"
                                    >
                                      <img
                                        className="profile_img"
                                        src={require("../../assets/images/word.png")}
                                        width="100%"
                                        height="160"
                                        alt="photo"
                                      />
                                    </a>
                                  </div>
                                </div>
                              )}
                              {qual2 !== "" && (
                                <div className="col-md-4">
                                  <div className="input-group">
                                    <a
                                      href={
                                        imageUrl + "jury/qualification/" + qual2
                                      }
                                      download
                                      target="_blank"
                                    >
                                      <img
                                        className="profile_img"
                                        src={require("../../assets/images/word.png")}
                                        width="100%"
                                        height="160"
                                        alt="photo"
                                      />
                                    </a>
                                  </div>
                                </div>
                              )}
                              {qual3 !== "" && (
                                <div className="col-md-4">
                                  <div className="input-group">
                                    <a
                                      href={
                                        imageUrl + "jury/qualification/" + qual3
                                      }
                                      download
                                      target="_blank"
                                    >
                                      <img
                                        className="profile_img"
                                        src={require("../../assets/images/word.png")}
                                        width="100%"
                                        height="160"
                                        alt="photo"
                                      />
                                    </a>
                                  </div>
                                </div>
                              )}
                              {qual4 !== "" && (
                                <div className="col-md-4">
                                  <div className="input-group">
                                    <a
                                      href={
                                        imageUrl + "jury/qualification/" + qual4
                                      }
                                      download
                                      target="_blank"
                                    >
                                      <img
                                        className="profile_img"
                                        src={require("../../assets/images/word.png")}
                                        width="100%"
                                        height="160"
                                        alt="photo"
                                      />
                                    </a>
                                  </div>
                                </div>
                              )}
                              {qual5 !== "" && (
                                <div className="col-md-4">
                                  <div className="input-group">
                                    <a
                                      href={
                                        imageUrl + "jury/qualification/" + qual5
                                      }
                                      download
                                      target="_blank"
                                    >
                                      <img
                                        className="profile_img"
                                        src={require("../../assets/images/word.png")}
                                        width="100%"
                                        height="160"
                                        alt="photo"
                                      />
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="editprofile">
                  <div className="main-content-body tab-pane p-sm-4 p-0 border-top-0">
                    <div className="card-body border">
                      <div className="mb-4 main-content-label">
                        Personal Information
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">
                                  First Name :<b className="text-danger">*</b>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your First Name"
                                  onKeyPress={LetterOnly}
                                  onPaste={onPasteLetter}
                                  value={fname}
                                  onChange={(e) => {
                                    setFName(e.target.value);
                                  }}
                                />
                                {submit && !fname && (
                                  <span className="errortxt text-danger">
                                    First Name is required
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">Last Name :</label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Last Name"
                                  onKeyPress={LetterOnly}
                                  onPaste={onPasteLetter}
                                  value={lname}
                                  onChange={(e) => {
                                    setLName(e.target.value);
                                  }}
                                />
                                {/* {submit && !lname && (
                                  <span className="errortxt text-danger">
                                    Last Name is required
                                  </span>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">
                                  Email Address : <b className="text-danger">*</b>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  // onBlur={checkemail}
                                  placeholder="Enter Your Email Address"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                />
                                {submit && !email && (
                                  <span className="errortxt text-danger">
                                    Email Address is required
                                  </span>
                                )}
                                {emailerror !== "" && email && (
                                  <span className="errortxt text-danger">
                                    {emailerror}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">
                                  Mobile Number :<b className="text-danger">*</b>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <PhoneInput
                                  specialLabel={""}
                                  country={"in"}
                                  placeholder="Enter Primary Mobile Number"
                                  value={primob}
                                  onChange={handleprimary}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />

                                {submit && !primob && (
                                  <span className="errortxt text-danger">
                                    Primary Mobile is required
                                  </span>
                                )}
                                {moberror !== "" && primob && (
                                  <span className="errortxt text-danger">
                                    {moberror}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">
                                  Gender :<b className="text-danger">*</b>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <select
                                  value={gender}
                                  onChange={(e) => {
                                    setGender(e.target.value);
                                  }}
                                  disabled
                                >
                                  <option value="" disabled>
                                    Select Your Gender
                                  </option>
                                  {glist.map((data, i) => {
                                    return (
                                      <option value={data.gender_uid} key={i}>
                                        {data.gender}
                                      </option>
                                    );
                                  })}
                                </select>
                                {submit && !gender && (
                                  <span className="errortxt text-danger">
                                    Gender is required
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">
                                  Date of Birth :
                                </label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="date"
                                  value={dob}
                                  onChange={(e) => {
                                    setDOB(e.target.value);
                                  }}
                                  onKeyPress={(event) => {
                                    event.preventDefault();
                                  }}
                                  onKeyDown={(event) => {
                                    event.preventDefault();
                                  }}
                                />
                                {/* {submit && !dob && (
                                  <span className="errortxt text-danger">
                                    Date of birth is required
                                  </span>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="mb-4 main-content-label">
                        Primary Information
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">Address 1 :</label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address 1"
                                  value={addr1}
                                  onChange={(e) => {
                                    setAddress1(e.target.value);
                                  }}
                                />
                                {/* {submit && !addr1 && (
                                  <span className="errortxt text-danger">
                                    Primary address is required
                                  </span>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">Address 2 :</label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Address 2"
                                  value={addr2}
                                  onChange={(e) => {
                                    setAddress2(e.target.value);
                                  }}
                                />
                                {/* {submit && !addr2 && (
                                  <span className="errortxt text-danger">
                                    Secondary address is required
                                  </span>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">Country :</label>
                              </div>
                              <div className="col-md-8">
                                <select
                                  value={country}
                                  onChange={Selectcountry}
                                >
                                  <option value="" disabled>
                                    Select Your Country
                                  </option>
                                  {colist.map((data, i) => {
                                    return (
                                      <option value={data.country_uid} key={i}>
                                        {data.country_name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {/* {submit && !country && (
                                  <span className="errortxt text-danger">
                                    Country is required
                                  </span>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">State :</label>
                              </div>
                              <div className="col-md-8">
                                <select value={state} onChange={Selectstate}>
                                  <option value="" disabled>
                                    Select Your State
                                  </option>
                                  {slist.map((data, i) => {
                                    return (
                                      <option value={data.state_uid} key={i}>
                                        {data.state_name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {/* {submit && !state && (
                                  <span className="errortxt text-danger">
                                    State is required
                                  </span>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">City :</label>
                              </div>
                              <div className="col-md-8">
                                <select
                                  value={city}
                                  onChange={(e) => {
                                    setCity(e.target.value);
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Your City
                                  </option>
                                  {clist.map((data, i) => {
                                    return (
                                      <option value={data.city_uid} key={i}>
                                        {data.city_name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {/* {submit && !city && (
                                  <span className="errortxt text-danger">
                                    City is required
                                  </span>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">Pincode:</label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  placeholder="Enter Your Pincode"
                                  maxLength={6}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={pincode}
                                  onChange={(e) => {
                                    setPincode(e.target.value);
                                  }}
                                />
                                {/* {submit && !pincode && (
                                  <span className="errortxt text-danger">
                                    Pincode is required
                                  </span>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="mb-4 main-content-label">
                        Professional Information
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">
                                  Category : <b className="text-danger">*</b>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <Select
                                  placeholder="Select Your Category"
                                  value={category}
                                  options={catlist}
                                  onChange={hangleInterest}
                                  isMulti
                                  isClearable
                                />
                                {submit && !category && (
                                  <span className="errortxt text-danger">
                                    Category is required
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-6 max-wi">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="username">
                                  Language : <b className="text-danger">*</b>
                                </label>
                              </div>
                              <div className="col-md-8">
                                <Select
                                  placeholder="Select Your Language"
                                  value={interestlang}
                                  options={langlist}
                                  onChange={hangleLanguage}
                                  isMulti
                                  isClearable
                                />
                                {submit && !interestlang && (
                                  <span className="errortxt text-danger">
                                    Language is required
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="mb-4 main-content-label">
                        File Uploads{" "}
                        <span>
                          ( Accepted Files are <b>.png, .jpg, .jpeg</b> & Max
                          Upload Size <b>3MB</b> )
                        </span>
                      </div>
                      <div className="row">
                        <div className="input-group form-upload">
                          <div className="row">
                            <div className="col-md-8">
                              <label htmlFor="dob">1. Add Photograph : </label>

                              <div className="mt-4">
                                <input
                                  type="file"
                                  name="file"
                                  id="file"
                                  accept=".png,.jpg,.jpeg"
                                  onChange={photofile}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="imagecontainer">
                                <img
                                  src={formphotoupload}
                                  width="100%"
                                  height="auto"
                                  onError={(e) => errorhandle(e)}
                                />
                              </div>
                            </div>
                          </div>
                          {formphotoerror !== "" && (
                            <span className="errortxt text-danger">
                              {formphotoerror}
                            </span>
                          )}
                        </div>

                        <div className="input-group form-upload">
                          <div className="row">
                            <div className="col-md-8">
                              <label htmlFor="dob">
                                2. Add Identity Profile :{" "}
                              </label>
                              <div className="mt-4">
                                <input
                                  type="file"
                                  name="file"
                                  id="file"
                                  accept=".png,.jpg,.jpeg"
                                  onChange={idfile}
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="imagecontainer">
                                <img
                                  src={formidupload}
                                  width="100%"
                                  height="auto"
                                  onError={(e) => errorhandle(e)}
                                />
                              </div>
                            </div>
                          </div>
                          {formiderror !== "" && (
                            <span className="errortxt text-danger">
                              {formiderror}
                            </span>
                          )}
                          {errorMessage !== "" && (
                            <span className="errortxt text-danger">
                              {errorMessage}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <hr /> */}
                    </div>
                  </div>
                  <div className="update-detail">
                    <a
                      type="submit"
                      onClick={addsubmit}
                      className="btn btn-success"
                      style={{ float: "right" }}
                    >
                      Update
                    </a>

                    <a
                      className="btn btn-prev btn-secondary"
                      style={{ float: "right" }}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Back
                    </a>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="accountsetting">
                  <div className="main-content-body tab-pane p-sm-4 p-0 border-top-0">
                    <div className="card-body border" data-select2-id="12">
                      <Form className="form-horizontal" data-select2-id="11">
                        <div className="mb-4 main-content-label">
                          Security Settings
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Current Password
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    placeholder="Enter Your Current Password"
                                    value={curpass}
                                    onChange={(e) => {
                                      setCurPass(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">New Password</b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    placeholder="Enter Your New Password"
                                    title="Your password should be minimum 8 character with atleast 1 number, 1 lowercase character (a-z) and 1 uppercase character (A-Z)"
                                    value={newpass}
                                    onChange={(e) => {
                                      setNewPass(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <b className="text-inverse">
                                    Confirm Password
                                  </b>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    placeholder="Enter Your Confirm Password"
                                    value={confirmpass}
                                    onChange={(e) => {
                                      setConfirmPass(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {conerrorMessage !== "" && (
                          <span className="errortxt text-danger">
                            {conerrorMessage}
                          </span>
                        )}

                        <br />
                        <br />
                        <div className="text-center">
                          <a
                            className="btn btn-prev btn-secondary"
                            onClick={() => {
                              window.location.reload();
                            }}
                          >
                            Back
                          </a>
                          <a
                            className="btn btn-prev btn-success"
                            onClick={changepass}
                          >
                            Submit
                          </a>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Col>
        </Row>
      </Tab.Container>
    </Fragment>
  );
};

export default JuryProfile;
