import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
  evententrieslist,
  delEntry,
  listeventcategory,
  EventJurylist,
  viewevent,
  removeassignjury,
  juryevententrycount,
  categorywisejurylist,
  jurycountbasedoncategory,
  categorylistsaved,
  categorywisejuryadded,
  juryassandnonass,
  juryaddandremove,
  totaljuryforentry,
  totaljuryandcategory,
} from "../../../services/admin";
import Swal from "sweetalert2";
import imageUrl from "../../../ImageUrl";
import Select from "react-select";
import {
  addjurytoevent,
  // addselectedjury,
  assignedjurylist,
  deleteEntries,
  deletejuryevent,
  notassignedjurylist,
} from "../../../services/jury";
import { toast } from "react-toastify";

const EventwiseCategorylist = () => {

  const { id } = useParams();

  const [filterProduct, setFilterProduct] = useState([]);
  const [assfilterProduct, setAssFilterProduct] = useState([]);
  const [totaljuryProduct, setTotaljuryProduct] = useState([]);
  const [notassign, setNotassign] = useState("");
  const [assigned, setAssigned] = useState("");

  const [count, setCount] = useState("");
  const [catadd, setCatadd] = useState("");
  const [catlist, setCatlist] = useState([]);
  const [ejadd, setEjadd] = useState("");
  const [addjlist, setJlist] = useState([]);
  
  const [sall, setSAll] = useState(false);
  const [usall, setUSAll] = useState(false);

  const [sassall, setSAssAll] = useState(false);
  const [usassall, setUSAssAll] = useState(false);
  const [name, setEventName] = useState("");
  const [venue, setEventVenue] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");

  const [clistsaved, setCListSaved] = useState([]);
  const [catsaved, setCatSaved] = useState(0);
  const [jlistsaved, setJListSaved] = useState([]);
  const [jurysaved, setJurySaved] = useState(0);

  const [catcount, setCatCount] = useState([]);

  const [eventData, setEventData] = useState([]);
  const [juryData, setjuryData] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  let arr = [];
  let catarr = [];

  const columnDefs = [
    // {
    //   headerName: "Actions",
    //   width: 100,
    //   field: "entry_uid",
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: (params) => {
    //     return (
    //       <div className="text-center">
    //
    //         {/* <a
    //           onClick={(e) => deletejury(e, data.value)}
    //           className="btn-delete"
    //         >
    //           Delete
    //         </a> */}
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Actions",
      field: "entry_uid",
      width: 200,
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <a
              onClick={() => deleteEntry(params.value)}
              className="btn-sm btn-danger me-2"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
            <Link
              className="btn-sm btn-info me-2"
              to={"/guestmembereventview/" + params.value}
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          </div>
        );
      },
    },
    {
      headerName: "User Name",
      field: "user_name",
      width: 250,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      field: "category",
      width: 250,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Usertype",
      field: "usertype",
      width: 250,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnAssDefs = [
    {
      headerName: "Jury Photo",
      field: "photo",
      filter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            src={imageUrl + "jury/photo/" + params.data.photo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },

    {
      headerName: "Jury Name",
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "User Name",
      field: "user_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Usertype",
      field: "usertype",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const totaldefs = [
    {
      headerName: "Jury Photo",
      field: "photo",
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            src={imageUrl + "jury/photo/" + params.data.photo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },

    {
      headerName: "Jury Name",
      field: "jury_firstname",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Assigned Entries",
      field: "total",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Actions",
      field: "jury_uid",
      resizable: true,
      cellStyle: { textAlign: "left" },
      filter: false,
      cellRenderer: function (params) {
        // console.log(params);
        return (
          <div className="text-center">
            <a
              onClick={() => deleteJury(params.data.category_uid, params.value)}
              className="btn-sm btn-danger me-2"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a>
          </div>
        );
      },
    },
  ];

  function deleteEntry(eid) {
    // console.log(cid);
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEntries(eid).then((response) => {
          if (response.status === true) {
            addjurylist();
            // window.location.reload();
            toast.success(response.message);
          }
        });
      }
    });
  }

  function deleteJury(cid, jid) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deletejuryevent(id, cid, jid).then((response) => {
          if (response.status === true) {
            juryreport();
            userreload();
            window.location.reload();
            toast.success(response.message);
          }
        });
      }
    });
  }

  function juryreport() {
    totaljuryforentry(id).then((res) => {
      if (res.status === true) {
        setTotaljuryProduct(res.data);
      }
    });
  }

  useEffect(() => {
    userreload();

    juryreport();

    totaljuryandcategory(id).then((res) => {
      if (res.status === true) {
        setEventData(res.data.entry_details);
        setjuryData(res.data.jury_details);
      }
    });

    categorylistsaved(id).then((res) => {
      if (res.status === true) {
        setCListSaved(res.data);
      }
    });

    listeventcategory(id).then((response) => {
      if (response.status === true) {
        setCatlist(response.data);
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            categorywisejurylist(id, response.data[i].category_uid).then(
              (res) => {
                if (res.status === true) {
                  catarr.push({
                    catname: res.data.category,
                    totcatcont: res.data.total_category_list,
                    asscatcont: res.data.assign_category_count,
                    nonasscatcont: res.data.not_assign_category_count,
                  });
                  setCatCount([...catarr]);
                }
              }
            );
          }
        }
      }
    });

    juryevententrycount(id).then((res) => {
      if (res.status === true) {
        setNotassign(res.data.without_assigning_count);
        setAssigned(res.data.assign_entrie_count);
      }
    });

    viewevent(id).then((res) => {
      if (res.status === true) {
        setEventName(res.data.event_name);
        setEventVenue(res.data.event_venue);
        setSDate(moment(res.data.event_start).format("DD-MM-YYYY"));
        setEDate(moment(res.data.event_end).format("DD-MM-YYYY"));
        setCDate(moment(res.data.event_entry_close).format("DD-MM-YYYY"));
      }
    });
  }, []);

  let jarr = [];
  let arr1 = [];
  let arr2 = [];
  let arr3 = [];

  function userreload() {
    addjurylist(catsaved, jurysaved);
  }

  function addjurylist(cid, jid) {
    setSAll(false);
    setUSAll(false);
    setSAssAll(false);
    setUSAssAll(false);
    setFilterProduct([]);
    setAssFilterProduct([]);
    arr = [];
    arr1 = [];
    juryassandnonass(id, cid, jid).then((response) => {
      if (response.status === true) {
        if (response.data.with_assign.length !== 0) {
          for (let i = 0; i < response.data.with_assign.length; i++) {
            var user = "";
            var navi = "";
            if (response.data.with_assign[i].guest_uid !== 0) {
              user = "Guest";
              navi = "/eventguestview/" + response.data.with_assign[i].guest_uid;
            } else if (response.data.with_assign[i].user_uid !== 0) {
              user = "User";
              navi = "/eventuserview/" + response.data.with_assign[i].user_uid;
            }
            var name = response.data.with_assign[i].jury_firstname;
            if (response.data.with_assign[i].jury_lastname !== null) {
              name = name + " " + response.data.with_assign[i].jury_lastname;
            }

            var uname = response.data.with_assign[i].participant_firstname;
            if (response.data.with_assign[i].participant_lastname !== null) {
              uname =
                uname + " " + response.data.with_assign[i].participant_lastname;
            }
            arr.push({
              first_name: name,
              user_name: uname,
              event_uid: response.data.with_assign[i].event_uid,
              entry_uid: response.data.with_assign[i].entry_uid,
              guest_uid: response.data.with_assign[i].guest_uid,
              user_uid: response.data.with_assign[i].user_uid,
              photo: response.data.with_assign[i].photo,
              category: response.data.with_assign[i].category,
              usertype: user,
              chkbox: false,
            });
          }
        }

        if (response.data.without_assign.length !== 0) {
          for (let i = 0; i < response.data.without_assign.length; i++) {
            var user = "";
            var navi = "";
            if (response.data.without_assign[i].guest_uid !== 0) {
              user = "Guest";
              navi =
                "/eventguestview/" + response.data.without_assign[i].guest_uid;
            } else if (response.data.without_assign[i].user_uid !== 0) {
              user = "User";
              navi =
                "/eventuserview/" + response.data.without_assign[i].user_uid;
            }
            var name = response.data.without_assign[i].jury_firstname;
            if (response.data.without_assign[i].jury_lastname !== null) {
              name = name + " " + response.data.without_assign[i].jury_lastname;
            }

            var uname = response.data.without_assign[i].participant_firstname;
            if (response.data.without_assign[i].participant_lastname !== null) {
              uname =
                uname +
                " " +
                response.data.without_assign[i].participant_lastname;
            }
            arr1.push({
              first_name: name,
              user_name: uname,
              event_uid: response.data.without_assign[i].event_uid,
              entry_uid: response.data.without_assign[i].entry_uid,
              guest_uid: response.data.without_assign[i].guest_uid,
              user_uid: response.data.without_assign[i].user_uid,
              photo: response.data.without_assign[i].photo,
              category: response.data.without_assign[i].category,
              usertype: user,
              chkbox: false,
            });
          }
        }
        setAssFilterProduct(arr);
        setFilterProduct(arr1);
      }
    });
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            <span>{name}</span>
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="row">
                <h4 className="col-md-10">Entries Category</h4>
                <div className="col-md-2 pull-right">
                  <Link to={"/eventlist"} className="btn btn-secondary">
                    Back
                  </Link>
                </div>
              </div>

              <hr />

              <div className="panel panel-primary tabs-style-2 mt-4">
                <div className=" tab-menu-heading">
                  <div className="tabs-menu1">
                    {/*  */}
                    <Tab.Container
                      defaultActiveKey="assigned"
                      onSelect={() => addjurylist(catsaved, jurysaved.value)}
                    >
                      <Nav
                        variant="pills"
                        className="nav panel-tabs main-nav-line"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="assigned" className="mt-1">
                            Non-Assigned Participants
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="all" className="mt-1">
                            Assigned Participants
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="total" className="mt-1">
                            Total Juries For Assigned Participants
                          </Nav.Link>
                        </Nav.Item>

                        {/* <Nav.Item>
                          <Nav.Link eventKey="nonassigned" className="mt-1">
                            Non-Assigned Jury
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                      <Tab.Content>
                        <Tab.Content>
                          <Tab.Pane eventKey="assigned" className="border">
                            <div className="responsive">
                              <div className="App">
                                <div
                                  className="ag-theme-alpine ag-style"
                                  style={{ height: 600, width: "100%" }}
                                >
                                  <AgGridReact
                                    columnDefs={columnDefs}
                                    suppressDragLeaveHidesColumns={true}
                                    rowData={filterProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                  />
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>

                        <Tab.Pane eventKey="all" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  columnDefs={columnAssDefs}
                                  suppressDragLeaveHidesColumns={true}
                                  rowData={assfilterProduct}
                                  pagination="true"
                                  paginationPageSize="10"
                                  domLayout="autoWidth"
                                  onGridReady={(params) => {
                                    params.api.sizeColumnsToFit();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="total" className="border">
                          <div className="responsive">
                            <div
                              className="ag-theme-alpine ag-style"
                              style={{ height: 300, width: "100%" }}
                            >
                              <AgGridReact
                                columnDefs={totaldefs}
                                suppressDragLeaveHidesColumns={true}
                                rowData={totaljuryProduct}
                                pagination="true"
                                paginationPageSize="10"
                                domLayout="autoWidth"
                                onGridReady={(params) => {
                                  params.api.sizeColumnsToFit();
                                }}
                              />
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default EventwiseCategorylist;
