import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { userdatalist } from "../../../services/admin";
import Swal from "sweetalert2";
import "./users.css";
import { Link, useNavigate } from "react-router-dom";
import { deluser } from "../../../services/admin";
const Userlist = () => {
  const gridRef = useRef();
  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 200,
      field: "user_uid",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="row">
            <div className="col-md-5 col-right">
              <Link to={"/users/userview/" + params.value} className="btn-view">
                View
              </Link>
            </div>
            {/* <div className="col-md-5">
              <Link to={"/users/pendingusers/"} className="btn-delete">
                <span onClick={(e) => delUser(e, params.value)}>Delete</span>
              </Link>
            </div> */}
          </div>
        );
      },
    },
    {
      headerName: "Fullname",
      width: 200,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 150,
      field: "gender",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Country",
      width: 200,
      field: "country_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "State",
      width: 220,
      field: "state_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Registered Date",
      width: 200,
      field: "reg_date",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
        // return new Date(data.value).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
      },
    },
  ];

  function delUser(e, uid) {
    e.preventDefault();

    Swal.fire({
      title: `Are you sure want to Delete this user?`,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deluser(uid).then((response) => {
          if (response.status === true) {
            userreload();
          }
        });
      }
    });
  }

  useEffect(() => {
    userreload();
  }, []);
  let arr = [];
  function userreload() {
    setFilterProduct([]);
    userdatalist(2, 0).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              first_name:
                response.data[i].first_name + " " + response.data[i].last_name,
              gender: response.data[i].gender,
              country_name: response.data[i].country_name,
              state_name: response.data[i].state_name,
              reg_date: response.data[i].reg_date,
              user_uid: response.data[i].user_uid,
            });
          }
          setFilterProduct(arr);
        }
      }
    });
  }
  //   const onBtnExport = useCallback(() => {
  //     gridRef.current.api.exportDataAsCsv();
  //   }, []);

  //   const onBtnUpdate = useCallback(() => {
  //     document.querySelector(
  //       '#csvResult'
  //     ).value = gridRef.current.api.getDataAsCsv();
  //   }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            PENDING MEMBERS LIST
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="App">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600, width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      suppressDragLeaveHidesColumns={true}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Userlist;
