import React, { Fragment } from "react";
import { Dropdown, Container, Form, Navbar, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../../services/login";
// FuScreen-start
// function Fullscreen() {
//     if (
//         (document.fullScreenElement && document.fullScreenElement === null) ||
//     (!document.mozFullScreen && !document.webkitIsFullScreen)
//     ) {
//         if (document.documentElement.requestFullScreen) {
//             document.documentElement.requestFullScreen();
//         } else if (document.documentElement.mozRequestFullScreen) {
//             document.documentElement.mozRequestFullScreen();
//         } else if (document.documentElement.webkitRequestFullScreen) {
//             document.documentElement.webkitRequestFullScreen(
//                 Element.ALLOW_KEYBOARD_INPUT
//             );
//         }
//     } else {
//         if (document.cancelFullScreen) {
//             document.cancelFullScreen();
//         } else if (document.mozCancelFullScreen) {
//             document.mozCancelFullScreen();
//         } else if (document.webkitCancelFullScreen) {
//             document.webkitCancelFullScreen();
//         }
//     }
// }

// FullScreen-end
function Header() {
  const navigate = useNavigate();
  function signout() {
    logout().then((response) => {
      if (response.status === true) {
        localStorage.clear();
        navigate("/login");
      } else {
        toast.error(response.message);
      }
    });
  }

  // const openCloseSidebar1 = () => {
  //     document.querySelector('.header-settings').classList.toggle('show');
  //     document.querySelector('.sidebar-right').classList.toggle('sidebar-open');
  // };
  //  headerToggleButton
  const headerToggleButton = () => {
    let body = document.querySelector("body");
    let innerWidth = window.innerWidth;
    if (body !== !body) {
      if (innerWidth >= 992) {
        document.querySelector("body")?.classList.toggle("main-sidebar-hide");
        document.querySelector("body")?.classList.remove("main-sidebar-show");
      } else if (document.body.classList.contains("horizontalmenu")) {
        document.querySelector("body")?.classList.toggle("main-navbar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-hide");
      } else {
        document.querySelector("body")?.classList.toggle("main-sidebar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-hide");
      }
    }
  };

//   function changepass(){
//     if(localStorage.getItem('Roleid') === '1'){
//         navigate('/accountsetting');
//     }else if(localStorage.getItem('Roleid') === '2'){
//         navigate('/user/accountsetting');
//     }
// }

  return (
    <Fragment>
      <Navbar
        expand="lg"
        className="main-header side-header sticky"
        // style={{ marginBottom: "-64px" }}
      >
        <Container fluid className="main-container container-fluid">
          <div className="main-header-left">
            <Link
              to="#"
              className="main-header-menu-icon"
              id="mainSidebarToggle"
              onClick={() => headerToggleButton()}
            >
              <span></span>
            </Link>
            <div className="hor-logo">
              <Link to={"/dashboard"} className="main-logo">
                <img
                  src={require("../../assets/images/logo/logo.png")}
                  className="header-brand-img desktop-logo"
                  alt="logo"
                />
                <img
                  src={require("../../assets/images/logo/logo.png")}
                  className="header-brand-img desktop-logo-dark"
                  alt="logo"
                />
              </Link>
            </div>
          </div>

          <div className="main-header-right">
            <Navbar.Toggle
              aria-controls="navbarSupportedContent-4"
              className="navresponsive-toggler"
            >
              <i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
            </Navbar.Toggle>
            <div className="navbar navbar-expand-lg nav nav-item navbar-nav-right responsive-navbar navbar-dark">
              <Navbar.Collapse
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2 align-items-center ms-auto">
                  {/* <Dropdown className="header-search">
                    <Dropdown.Toggle variant="default" className="px-0">
                      <i className="fe fe-search header-icons fs-18 px-2 lh-5"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="p-2">
                      <InputGroup>
                        <Form.Control
                          type="search"
                          className="rounded-0"
                          placeholder="Search for anything..."
                        />
                        <InputGroup.Text className="btn search-btn">
                          <i className="fe fe-search"></i>
                        </InputGroup.Text>
                      </InputGroup>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <Dropdown className="main-profile-menu">
                    <Dropdown.Toggle className="d-flex p-0" variant="default">
                      <span className="main-img-user mx-1">
                        <img
                          alt="avatar"
                          src={require("../../assets/images/user.png")}
                        />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ margin: "0px" }}>
                      <div className="header-navheading">
                        <h6 className="main-notification-title">
                          {localStorage.getItem("Name")}
                        </h6>
                        <p className="main-notification-text">
                          {localStorage.getItem("User")}
                        </p>
                      </div>
                      {/* <Dropdown.Item
                                                className="border-top">
                                                <i className="fe fe-user"></i> My Profile
                                            </Dropdown.Item>*/}
                      {/* {localStorage.getItem('Roleid') === '2'
                      
                      } */}
                      {/* <Dropdown.Item
                        onClick={changepass}
                      >
                        <i className="fe fe-settings"></i> Account Settings
                      </Dropdown.Item> */}
                      <Dropdown.Item onClick={signout}>
                        <i className="fe fe-power"></i> Sign Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </Fragment>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
