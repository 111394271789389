import { Component } from "react";
import { Link } from "react-router-dom";

class Usericon extends Component
{
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div>
                <Link to={'/users/userview/' + this.props.value} className="text-info"><i className="ti ti-eye"></i></Link>
                {/* <Link to={'/agegroup/editagegroup/' + this.props.value} className="btn text-warning"><i className="ti ti-pencil"></i></Link> */}
               
            </div>
        )
    }
}

export default Usericon