import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  donarpaymentdone,
  facategorylist,
  famaincategory,
  faparcatelist,
  fapaymentdone,
  fastatelist,
  fasubcategory,
  fasumbit,
  statelist,
  countrylist,
  festcouponcode,
} from "../../services/register";
import "./guest.css";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { toast } from "react-toastify";
import { viewevent } from "../../services/admin";

export const Faregister = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lstep, setStep] = useState(1);
  const [step1submit, setStep1] = useState(false);
  const [step2submit, setStep2] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [mobilecode, setMobcode] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [age, setAge] = useState("");
  const [amountdec, setAmountDesc] = useState("");

  const [mcat, setMaincat] = useState("");
  const [mcatlist, setMaincatlist] = useState([]);
  const [mcattype, setMcattype] = useState([]);

  const [category, setCategory] = useState("");
  const [catelist, setCatelist] = useState([]);
  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);
  const [state, setState] = useState("");
  const [salist, setSalist] = useState([]);

  const [perform, setPerform] = useState("");
  const [performlist, setPerformlist] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);
  const [customAttribute, setCustomAttribute] = useState("");
  const [customvalue, setCustomValue] = useState("");
  const [cattype, setSelectedtype] = useState("");

  const [ename, setEName] = useState("");
  const [vname, setVName] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");

  const [cpnstatus, setStatus] = useState("");
  const [discount, setDiscount] = useState("");
  const [amount, setAmount] = useState("");
  const [disamt, setDisamt] = useState("");
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  function Selectcategory(e) {
    setCategory(e.target.value);
    setAmountDesc("");
    setPCount("");
    setPerformlist([]);
    setTVisible(false);
    setPerform("");
    if (e.target.value !== "") {
      const selectedOption = catelist.find(
        (data) => data.fest_category_uid === parseInt(e.target.value)
      );
      if (selectedOption) {
        const customAttribute = selectedOption.main_category;
        const categorytype = selectedOption.category_type;
        // setSelectedtype(categorytype)
        faparcatelist(categorytype).then((response) => {
          setPerformlist(response.data);
        });
        setCustomAttribute(customAttribute);
      }
    }
  }

  function SelectMaincategory(e) {
    setCategory("");
    setPerform("");
    setPCount("");
    setAmountDesc("");
    setCustomAttribute("");
    setPerformlist([]);
    setMaincat(e.target.value);
    if (e.target.value !== "") {
      const mcatfilter = mcatlist.find(
        (data) => data.fest_category_uid === parseInt(e.target.value)
      );

      if (mcatfilter !== "") {
        const mcategorytype = mcatfilter.fest_category_uid;
        fasubcategory(mcategorytype).then((response) => {
          setCatelist(response.data);
        });
      }
    }
  }
  const [tvisible, setTVisible] = useState(false);
  const [pcnt, setPCount] = useState("");
  function Selectperform(e) {
    setPerform(e.target.value);
    setAmountDesc("");
    setAmount("");
    setDisamt("");
    setCoupon("");
    setDiscount("");
    setStatus("");
    setPCount("");
    setTVisible(false);

    if (e.target.value !== "") {
      const pfilter = performlist.filter(
        (data) => data.category_type_uid === parseInt(e.target.value)
      );
      var pname = pfilter[0].category_type_name.toLowerCase();
      // setAmountDesc(pfilter[0].amount_desc);
      setAmountDesc(pfilter[0].amount);
      setAmount(pfilter[0].amount);
      setDisamt(pfilter[0].amount);
      setDiscount(0);
      if (pname === "solo" || pname === "individual sport") {
        setPCount(1);
        setTVisible(false);
      } else if (pname === "duet") {
        setPCount(2);
        setTVisible(false);
      } else {
        setTVisible(true);
      }
    } else {
      setTVisible(true);
    }
  }

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    // setStatelist([]);
    // setCitylist([]);
    // statelist(e.target.value).then((response) => {
    //   setStatelist(response.states);
    // });
  }

  function Selectstate(e) {
    setState(e.target.value);
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    // if (e.target.value !== "") {
    //   getemail(e.target.value).then((response) => {
    //     if (response.status === false) {
    //       setValEmail(response.message);
    //       Swal.fire({
    //         title:
    //           response.message +
    //           " - " +
    //           response.data.username +
    //           " has been already have this Email",
    //         allowOutsideClick: false,
    //       });
    //     }
    //   });
    // }
  }
  const [mobonly, setMobileonly] = useState("");
  const handle = (value, data, event, formattedValue) => {
    setMobcode(data.dialCode);
    setMobile(value);
    setMobileonly(value.slice(data.dialCode.length));
  };

  const [ageerro, setAgeError] = useState("");
  const checkAge = () => {
    const parsedAge = parseInt(age, 10);
    if (isNaN(parsedAge) || parsedAge < 1 || parsedAge > 99) {
      setAgeError("Age must be a number between 1 and 99.");
    } else {
      setAgeError("");
    }
  };

  //popup function

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [submitted, setSubmitted] = useState(false);

  function NextStep() {
    if (lstep === 1) {
      setStep1(true);
      // setSubmitted(true);
      setErrorMessage("");
      setAgeError("");
      setEmailError("");
      setMobError("");
      let evalid = "";
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        evalid = "Please Enter a valid Email Address";
        setEmailError("Please Enter a valid Email Address");
      }
      if (
        fname !== "" &&
        email !== "" &&
        mobile !== "" &&
        age !== "" &&
        country !== "" &&
        state !== "" &&
        mcat !== "" &&
        category !== "" &&
        evalid === ""
      ) {
        setStep(2);
      }
    }
  }

  function guestreg(e) {
    e.preventDefault();
    // setStep2(true);
    setSubmitted(true);
    setErrorMessage("");
    setAgeError("");
    setEmailError("");
    setMobError("");
    let evalid = "";
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      evalid = "Please Enter a valid Email Address";
      setEmailError("Please Enter a valid Email Address");
    }

    if (weight !== "" && perform !== "" && category !== "") {
      setLoading(true);
      const tempData = new FormData();
      tempData.append("full_name", fname);
      tempData.append("email", email);
      tempData.append("mobile", mobile);
      tempData.append("age", age);
      tempData.append("weight", weight);
      tempData.append("fest_category_uid", category);
      tempData.append("state", state);
      tempData.append("participant_type", perform);
      tempData.append("participant_count", 0);
      tempData.append("top_category_uid", mcat);
      tempData.append("country_uid", country);
      tempData.append("payment_amount", disamt);
      tempData.append("promocode", coupon);
      tempData.append("height", height);
      tempData.append("category_type_uid", perform);
      if (customvalue !== "") {
        tempData.append("event", customvalue);
      } else {
        tempData.append("event", 0);
      }

      fasumbit(tempData).then((res) => {
        if (res.status === true) {
          setSubmitted(false);
          paynow(res.data.fest_part_uid, res.data.order_uid);
          setLoading(false);
        } else {
          toast.error("Process Failed");
        }
      });
    }
  }

  function previous() {
    setStep(lstep - 1);
  }

  // payment

  async function paynow(uid, orderuid) {
    const options = {
      // key: "rzp_test_XFnDXpEfL0bcLT",
      key: "rzp_live_EtyIA627A0V6mO",
      //   payment_amount: amt,
      //   payment_currency: currency,
      order_id: orderuid,
      name: "Pythian Games",
      description: "Event Registeration",
      image: require("../../assets/images/logo/logonew.png"),
      handler: function (response) {
        const temp1 = new FormData();
        temp1.append("fest_part_uid", uid);
        temp1.append("order_uid", orderuid);
        temp1.append("payment_uid", response.razorpay_payment_id);
        temp1.append("payment_sign", response.razorpay_signature);

        fapaymentdone(temp1).then((responsepay) => {
          if (responsepay.status === true) {
            Swal.fire({
              title: "Successfully Registered",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#6259ca",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                window.open("https://pythiangames.org/", "_blank");
              }
            });
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  function handlecoupon(e) {
    e.preventDefault();
    setDiscount("");
    setDisamt("");
    setStatus("");
    const tempData = new FormData();
    tempData.append("category_type_uid", perform);
    tempData.append("promocode", coupon);
    if (e.target.value !== "") {
      festcouponcode(tempData).then((res) => {
        if (res.status === true) {
          if (res.data.status == 0) {
            setStatus(`Invalid Coupon Code`);
            setDiscount(res.data.discount);
            // setAmount(amountdec);
            setDisamt(res.data.discount_amount);
          } else {
            setStatus("Coupon Applied !");
            setDiscount(res.data.discount);
            setAmount(res.data.amount);
            setDisamt(res.data.discount_amount);
          }
        }
      });
    } else {
      toast.error("Enter Coupon Code");
    }
  }

  useEffect(() => {
    countrylist().then((response) => {
      setCountrylist(response.country);
    });
    fastatelist(101).then((res) => {
      setSalist(res.states);
    });
    // facategorylist().then((res) => {
    //   setCatelist(res.data);
    // });
    famaincategory().then((res) => {
      setMaincatlist(res.data);
    });
    // viewevent(id).then((response) => {
    //   if (response.status === true) {
    //     setEName("Event Name:- " + response.data.event_name);
    //     setVName("Event Venue:- " + response.data.event_venue);
    //     setSDate(
    //       "Start Date:- " +
    //         moment(response.data.event_start).format("DD-MM-YYYY")
    //     );
    //     setEDate(
    //       "End Date:- " + moment(response.data.event_end).format("DD-MM-YYYY")
    //     );
    //     setCDate(
    //       "Result Declare:- " +
    //         moment(response.data.event_entry_close).format("DD-MM-YYYY")
    //     );
    //   }
    // });
  }, []);

  return (
    <div id="registrationpage">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}

      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            {" "}
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
        </div>

        <div className="registrationright">
          <form action="#" className="form">
            <h2 className="text-center">Festival Registration</h2>

            {/* Step One */}

            {lstep === 1 && (
              <div className="guestreg row">
                {/* <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Arts Categories - Solo</th>
                      <th>Arts Categories - Team</th>
                      <th>Martial Arts</th>
                      <th>Traditonal Games and Sports</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> ONLINE PAYMENT</td>
                      <td>500</td>
                      <td>1000</td>
                      <td>1000</td>
                      <td>5000</td>
                    </tr>
                    <tr>
                      <td>VENUE PAYMENT</td>
                      <td>500</td>
                      <td>2000</td>
                      <td>2000</td>
                      <td>Need to pay 3000 per person</td>
                    </tr>
                  </tbody>
                </table> */}
                <div className="col-md-12">{/* <h6>{ename}</h6> */}</div>
                <div className="col-md-4">{/* <h6>{sdate}</h6> */}</div>
                <div className="col-md-4">{/* <h6>{edate}</h6> */}</div>
                <div className="col-md-4">{/* <h6>{cdate}</h6> */}</div>
              </div>
            )}

            {lstep === 1 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <h4>Personal Information</h4>
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {" "}
                          Name :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your  Name"
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          maxLength={25}
                          value={fname}
                          onChange={(e) => {
                            setFName(e.target.value);
                          }}
                        />
                        {step1submit && !fname && (
                          <span className="errortxt text-danger">
                            Name is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Email Address :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          onBlur={checkemail}
                          placeholder="Enter Your Email Address"
                          maxLength={50}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        {step1submit && !email && (
                          <span className="errortxt text-danger">
                            Email is required
                          </span>
                        )}
                        {emailerror !== "" && email && (
                          <span className="errortxt text-danger">
                            {emailerror}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Mobile Number :<b className="text-danger">*</b>{" "}
                        </label>
                        <PhoneInput
                          specialLabel={""}
                          country={"in"}
                          placeholder="Enter Mobile Number"
                          value={mobile}
                          countryCodeEditable={false}
                          onChange={handle}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {step1submit && !age && (
                          <span className="errortxt text-danger">
                            Mobile number is required
                          </span>
                        )}
                        {mobile.length !== 12 && mobile && (
                          <span className="errortxt text-danger">
                            {moberror}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Age :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          onBlur={checkAge}
                          placeholder="Enter Your Age (1-99)"
                          maxLength={2}
                          value={age}
                          onChange={(e) => {
                            setAge(e.target.value);
                          }}
                        />
                        {step1submit && !age && (
                          <span className="errortxt text-danger">
                            Age is required
                          </span>
                        )}
                        {ageerro !== "" && age && (
                          <span className="errortxt text-danger">
                            {ageerro}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Country :<b className="text-danger">*</b>{" "}
                        </label>
                        <select value={country} onChange={Selectcountry}>
                          <option value="" disabled>
                            Select Your Country
                          </option>
                          {colist.map((data, i) => {
                            return (
                              <option value={data.country_uid} key={i}>
                                {data.country_name}
                              </option>
                            );
                          })}
                        </select>
                        {step1submit && !country && (
                          <span className="errortxt text-danger">
                            Country is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          State :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your State"
                          value={state}
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                        />
                        {/* <select value={state} onChange={Selectstate}>
                          <option value="" disabled>
                            Select Your State
                          </option>
                          {salist.map((data, i) => {
                            return (
                              <option value={data.state_uid} key={i}>
                                {data.state_name}
                              </option>
                            );
                          })}
                        </select> */}
                        {step1submit && !state && (
                          <span className="errortxt text-danger">
                            State is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Arts & Sports Category :
                          <b className="text-danger">*</b>
                        </label>
                        <select value={mcat} onChange={SelectMaincategory}>
                          <option value="" disabled>
                            Select Your Main Category
                          </option>
                          {mcatlist.map((data, i) => {
                            return (
                              <option
                                value={data.fest_category_uid}
                                key={i}
                                // data-custom-attribute={data.main_category}
                              >
                                {data.top_category}
                              </option>
                            );
                          })}
                        </select>
                        {step1submit && !mcat && (
                          <span className="errortxt text-danger">
                            Arts & sports category is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Arts & Sports Sub Category :
                          <b className="text-danger">*</b>
                        </label>
                        <select value={category} onChange={Selectcategory}>
                          <option value="" disabled>
                            Select Your Sub Category
                          </option>

                          {catelist.map((data, i) => {
                            return (
                              <option
                                value={data.fest_category_uid}
                                key={i}
                                data-custom-attribute={data.main_category}
                              >
                                {data.fest_category}
                              </option>
                            );
                          })}
                        </select>
                        {step1submit && !category && (
                          <span className="errortxt text-danger">
                            Arts & sports sub category is required
                          </span>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="custom-attribute-input username">
                          Enter Your {customAttribute} :
                          <b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          id="custom-attribute-input"
                          placeholder={`Enter your ${customAttribute}`}
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          maxLength={25}
                          value={customvalue}
                          onChange={(e) => {
                            setCustomValue(e.target.value);
                          }}
                        />
                        {step1submit && !customvalue && (
                          <span className="errortxt text-danger">
                            {customAttribute} is required
                          </span>
                        )}
                      </div>
                    </div> */}

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="custom-attribute-input username">
                          Arts & Sports Category Name (others) :
                          {/* <b className="text-danger">*</b> */}
                        </label>
                        <input
                          type="text"
                          id="custom-attribute-input"
                          placeholder={`Enter your ${customAttribute}`}
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          maxLength={25}
                          value={customvalue}
                          onChange={(e) => {
                            setCustomValue(e.target.value);
                          }}
                        />
                        {/* {step1submit && !customvalue && (
                          <span className="errortxt text-danger">
                            Arts & sports category name (others) is required
                          </span>
                        )} */}
                      </div>
                    </div>

                    {/* {tvisible && (
                      <div className="col-md-6 col-lg-6">
                        <div className="input-group">
                          <label htmlFor="username">
                            Performance Count :<b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Your Performance Count"
                            value={pcnt}
                            maxLength={5}
                            onChange={(e) => {
                              setPCount(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    )} */}
                    {/* {!tvisible && (
                      <div className="col-md-6 col-lg-6">
                        <div className="input-group">
                          <label htmlFor="username">
                            Performance Count :<b className="text-danger">*</b>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Your Performance Count"
                            value={pcnt}
                            disabled
                          />
                          {step1submit && !perform && (
                            <span className="errortxt text-danger">
                              Performance Count is required
                            </span>
                          )}
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>

                {errorMessage !== "" && (
                  <span className="errortxt text-danger">{errorMessage}</span>
                )}

                <div className="row">
                  <div className="col-md-12 text-center">
                    <a className="btn btn-primary btn-next" onClick={NextStep}>
                      Next
                    </a>
                  </div>
                </div>
              </div>
            )}

            {/* Step Two */}

            {lstep === 2 && (
              <div className="form-step form-step-active ">
                <div className="form-inner secondpage">
                  <h4>Details</h4>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 sm-6">
                      <div className="input-group">
                        <label htmlFor="username">Height :</label>
                        <input
                          type="text"
                          placeholder="Enter Your Height"
                          maxLength={3}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={height}
                          onChange={(e) => {
                            setHeight(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6 sm-6">
                      <div className="input-group">
                        <label htmlFor="username">Weight :</label>
                        <input
                          type="text"
                          placeholder="Enter Your Weight"
                          maxLength={3}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={weight}
                          onChange={(e) => {
                            setWeight(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-5 col-lg-5 col-sm-12">
                      <div className="input-group">
                        <label htmlFor="username">
                          Solo or Team :
                          <b className="text-danger">* {amountdec}</b>
                        </label>
                        <select value={perform} onChange={Selectperform}>
                          <option value="" disabled>
                            Select Your Performance type
                          </option>
                          {performlist.map((data, i) => {
                            return (
                              <option value={data.category_type_uid} key={i}>
                                {data.category_type_name}
                              </option>
                            );
                          })}
                        </select>
                        {submitted && !perform && (
                          <span className="errortxt text-danger">
                            Solo or Team is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-5 col-lg-5 col-sm-12">
                      <div className="input-group">
                        <label htmlFor="username">
                          {" "}
                          Coupon Code :
                          <b className="text-danger"> {cpnstatus}</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Coupon Code"
                          // onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          maxLength={25}
                          value={coupon}
                          onChange={(e) => {
                            setCoupon(e.target.value);
                          }}
                        />
                        {/* {discount == 0 && (
                          <span className="errortxt text-danger">
                            Invalid Coupon Code
                          </span>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-2 col-lg-2 col-sm-12">
                      <a
                        className="coupon btn-success  btn-m-auto"
                        onClick={handlecoupon}
                      >
                        Apply Coupon
                      </a>
                    </div>
                  </div>

                  <hr />
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="col-md-12">
                      <div className="row">
                        <h6 className="col-md-8 col-sm-8" >Amount</h6>
                        <h6 className="col-md-4  col-sm-4 text-right">{amount}</h6>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <h6 className="col-md-8 col-sm-8">Will Be Paid On Venue</h6>
                        <h6 className="col-md-4  col-sm-4 text-danger text-right">
                          {-discount}
                        </h6>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <h6 className="col-md-8 col-sm-8">Total Amount</h6>
                        <h6 className="col-md-4  col-sm-4 text-right">{disamt}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {errorMessage !== "" && (
                  <span className="errortxt text-danger">{errorMessage}</span>
                )}

                </div>
                {/* <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <h4 className="text-center">{amountdec}</h4>
                  </div>
                </div> */}
                
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12 text-center">
                    <a
                      className="btn btn-secondary btn-next"
                      // style={{ float: "right" }}
                      onClick={previous}
                    >
                      Back
                    </a>
                    <a
                      className="btn btn-primary btn-next"
                      // style={{ float: "right" }}
                      onClick={guestreg}
                    >
                      Submit & Paynow
                    </a>
                  </div>
                </div>
               
              </div>
            )}

            <Modal
              show={show}
              onHide={handleClose}
              size="large"
              backdrop="static"
            >
              <Modal.Header>
                <h4>Payment Successful!</h4>
                <i
                  className="fa fa-window-close fa-lg"
                  aria-hidden="true"
                  onClick={handleClose}
                ></i>
              </Modal.Header>
              <Modal.Body>
                <p>Registration completed</p>
              </Modal.Body>
              <Modal.Footer>
                <a
                  className="btn btn-success mr-3"
                  style={{ float: "right" }}
                  onClick={handleClose}
                >
                  ok
                </a>
              </Modal.Footer>
            </Modal>
          </form>
        </div>
      </div>
    </div>
  );
};
