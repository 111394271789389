import moment from "moment/moment";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import imageUrl from "../../ImageUrl";
import {
  addjuryscore,
  evententryview,
  eventuserentryview,
  userentryview,
  jurygradeoptions,
} from "../../services/jury";
import "./jurydashboard.css";
import Swal from "sweetalert2";

const JuryEventwiseview = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [eid, setEventid] = useState("");
  const [adma, setadma] = useState("");
  const [gradenum, setgradenum] = useState(false);
  const [name, setEventName] = useState("");
  const [venue, setEventVenue] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [member, setMember] = useState("");
  const [gender, setGender] = useState("");
  const [catid, setCatid] = useState("");

  const [files, setFiles] = useState([]);
  let arr = [];
  const [uname, setUser] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDOB] = useState("");
  const [username, setUsername] = useState("");
  const [utype, setUsertupe] = useState("");

  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  var efile = "";
  //min max value
  const min = 0.5;
  const max = 10;
  const [mark, setMark] = useState(null);
  const [showmark, setShowmark] = useState(null);
  const [showremark, setShowremark] = useState(null);
  const [remark, setRemark] = useState("");
  const [allocatedmark, setAllocatedMark] = useState("");
  const [allocatedgrade, setAllocatedgrade] = useState("");
  const [gradevalue, setgradevalue] = useState("");
  const [gradeid, setgradeid] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [addMarkval, setaddMark] = useState(false);

  const [ishow, setImage] = useState(false);
  const [file, setFile] = useState(false);
  const [show, setShow] = useState(false);

  const [vshow, setVideo] = useState(false);
  const [vfile, setVfile] = useState(false);

  const [status, setStatus] = useState("");
  const [isPointsEnabled, setIsPointsEnabled] = useState(true);
  const [isRemarksEnabled, setIsRemarksEnabled] = useState(true);

  // const letsum = (e) => {
  //   e.preventDefault();
  //   const regex = /^\d{0,2}(\.\d{0,1})?$/;
  //   var numbers = e.target.value.substring(0, 3);
  //   if (e.target.value <= 10) {
  //     if (numbers === "10.") {
  //       return false;
  //     } else if (numbers === "00") {
  //       return false;
  //     } else if (numbers === "0.1") {
  //       return false;
  //     } else if (numbers === "0.2") {
  //       return false;
  //     } else if (numbers === "0.3") {
  //       return false;
  //     } else if (numbers === "0.4") {
  //       return false;
  //     } else {
  //       if (regex.test(e.target.value)) {
  //         setMark(e.target.value);
  //       }
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  // const letsum = (e) => {
  //     e.preventDefault();
  //     // const regex = /^\d{0,2}?$/;
  //     // const regex = /^\d{0,2}(\.\d{0,0})?$/
  //     const regex = /^(?:[0-9]|10)$/;
  //     var numbers = e.target.value.substring(0, 2);
  //     if (e.target.value <= 10) {
  //       if (numbers === "10.") {
  //         return false;
  //       } else if (numbers === "00") {
  //         return false;
  //       } else if (numbers === "0.") {
  //         return false;
  //       } else {
  //         if (regex.test(e.target.value)) {
  //           setMark(e.target.value);
  //         }
  //       }
  //     } else {
  //       return false;
  //     }
  //   };

  function handleShow() {
    setShow(true);
  }

  function handleClose() {
    setShow(false);
  }

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }

  function ImageClose() {
    setImage(false);
  }

  function VideoShow(vfile) {
    setVfile(vfile);
    setVideo(true);
  }

  function VideoClose() {
    setVideo(false);
  }

  // const handleGrade = (event) => {
  //   let gradeval =
  //     grade && grade.filter((e) => e.score_grade_uid == event.target.value);

  //   setgradeid(event.target.value);

  //   if (gradeval[0].grade_name) {
  //     setgradevalue(gradeval[0].grade_name);
  //   }
  // };

  useEffect(() => {
    localStorage.setItem("eid", eid);

    // jurygradeoptions().then((response) => {
    //   if (response.status === true) {
    //     setgrade(response.data);
    //   }
    // });
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/");
    const viewlist = last[last.length - 2];
    setUsertupe(viewlist);
    // if (viewlist === "eventguestview") {
    //   evententryview(id).then((response) => {
    //     setEventid(response.data.event_entry_files.event_uid);
    //     setCatid(response.data.event_entry_files.category_uid);
    //     setEventName(response.data.event_entry_files.event_name);
    //     setEventVenue(response.data.event_entry_files.event_venue);
    //     setSDate(
    //       moment(response.data.event_entry_files.event_start).format(
    //         "DD-MM-YYYY"
    //       )
    //     );
    //     setEDate(
    //       moment(response.data.event_entry_files.event_end).format("DD-MM-YYYY")
    //     );
    //     setCDate(
    //       moment(response.data.event_entry_files.event_entry_close).format(
    //         "DD-MM-YYYY"
    //       )
    //     );
    //     setMember(response.data.event_entry_files.membership_type);
    //     setGender(response.data.event_entry_files.gender);
    //     setAddress(response.data.event_entry_files.address_1);
    //     setCountry(response.data.event_entry_files.country_name);
    //     setState(response.data.event_entry_files.state_name);
    //     setCity(response.data.event_entry_files.city_name);
    //     setPincode(response.data.event_entry_files.pincode);
    //     setMark(response.data.event_entry_files.mark);
    //     setUser(
    //       response.data.event_entry_files.guest_firstname +
    //         " " +
    //         response.data.event_entry_files.guest_lastname
    //     );
    //     if (response.data.event_entry_files.mobile_country_code !== null) {
    //       setMobile(
    //         "(+" +
    //           response.data.event_entry_files.mobile_country_code +
    //           ") " +
    //           response.data.event_entry_files.guest_mobile
    //       );
    //     } else {
    //       setMobile(response.data.event_entry_files.guest_mobile);
    //     }
    //     setEmail(response.data.event_entry_files.guest_email);
    //     setDOB(
    //       moment(response.data.event_entry_files.guest_dob).format("DD-MM-YYYY")
    //     );
    //     arr = [];
    //     setFiles([]);
    //     if (response.data.event_entry_files.entry_files.length !== 0) {
    //       for (
    //         let i = 0;
    //         i < response.data.event_entry_files.entry_files.length;
    //         i++
    //       ) {
    //         if (
    //           efile !== response.data.event_entry_files.entry_files[i].category
    //         ) {
    //           efile = response.data.event_entry_files.entry_files[i].category;
    //         } else {
    //           efile = "";
    //         }
    //         arr.push({
    //           category: efile,
    //           name: response.data.event_entry_files.entry_files[i].file_name,
    //           type: response.data.event_entry_files.entry_files[i].file_type,
    //         });
    //       }
    //     }
    //     setFiles(arr);
    //   });
    // }
    userentryview(id).then((response) => {
      setEventid(response.data.event_entry_files.event_uid);
      setCatid(response.data.event_entry_files.category_uid);
      setEventName(response.data.eventdetails.event_name);
      setEventVenue(response.data.eventdetails.event_venue);
      setSDate(
        moment(response.data.eventdetails.event_start).format("DD-MM-YYYY")
      );
      setEDate(
        moment(response.data.eventdetails.event_end).format("DD-MM-YYYY")
      );
      setCDate(
        moment(response.data.eventdetails.event_entry_close).format(
          "DD-MM-YYYY"
        )
      );
      setMember(response.data.event_entry_files.membership_type);
      setGender(response.data.userdetails.gender);
      setAddress(response.data.event_entry_files.address_1);
      setCountry(response.data.event_entry_files.country_name);
      setState(response.data.event_entry_files.state_name);
      setCity(response.data.event_entry_files.city_name);
      setPincode(response.data.event_entry_files.pincode);
      setShowmark(response.data.details.score);
      setShowremark(response.data.details.points_remark);
      setUser(
        response.data.userdetails.first_name +
          " " +
          response.data.userdetails.last_name
      );
      if (response.data.event_entry_files.mobile_country_code !== null) {
        setMobile(response.data.userdetails.mobile);
      } else {
        setMobile(response.data.event_entry_files.guest_mobile);
      }
      setEmail(response.data.userdetails.email);
      setDOB(moment(response.data.userdetails.dob).format("DD-MM-YYYY"));
      arr = [];
      setFiles([]);
      if (response.data.event_entry_files.length !== 0) {
        for (let i = 0; i < response.data.event_entry_files.length; i++) {
          if (efile !== response.data.event_entry_files[i].category_uid) {
            efile = response.data.event_entry_files[i].category_uid;
          } else {
            efile = "";
          }
          arr.push({
            category: efile,
            name: response.data.event_entry_files[i].file_name,
            type: response.data.event_entry_files[i].file_type,
          });
        }
      }
      setFiles(arr);
    });
  }, []);

  // function addmark(e) {
  //   e.preventDefault();
  //   if (mark !== "" && remark !== "") {
  //     if (mark <= 10) {
  //       const temp = new FormData();
  //       temp.append("jury_user_uid", id);
  //       temp.append("points_remark", remark);
  //       temp.append("point", mark);

  //       addjuryscore(temp).then((response) => {
  //         if (response.status === true) {
  //           Swal.fire({
  //             title: "score submitted successfully",
  //             showCancelButton: false,
  //             confirmButtonColor: "#6259ca",
  //             confirmButtonText: "Ok",
  //             allowOutsideClick: false,
  //           }).then((result) => {
  //             if (result.isConfirmed) {
  //               navigate("/jury/userlist/" + localStorage.getItem("Entry_id"));
  //             }
  //           });
  //         }
  //       });
  //     } else {
  //       setErrorMessage("Please Enter Your Points From 0 To 10");
  //     }
  //   } else {
  //     setErrorMessage("Please Enter Your Points and Remarks");
  //   }
  // }

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
    setIsPointsEnabled(selectedStatus === "1");
    // setIsRemarksEnabled(selectedStatus === "1");
  };

  function Mark(e) {
    let value = e.target.value.trim();
    value = value.replace(/\D+/g, "");
    if (value === "") {
      setMark("");
    } else {
      value = parseInt(value, 10);
      if (isNaN(value) || value < 0) {
        setMark("0");
      } else if (value > 10) {
        setMark("10");
      } else {
        setMark(value.toString());
      }
    }
  }

  // function addmark(e) {
  //   e.preventDefault();
  //   if (status !== "") {
  //     if (status === 0) {
  //       if (remark !== "") {
  //         const temp = new FormData();
  //         temp.append("jury_user_uid", id);
  //         temp.append("points_remark", remark);
  //         temp.append("point", 0);
  //         temp.append("status", status);
  //         alert("Reject OK");
  //         // addjuryscore(temp).then((response) => {
  //         //   if (response.status === true) {
  //         //     Swal.fire({
  //         //       title: "score submitted successfully",
  //         //       showCancelButton: false,
  //         //       confirmButtonColor: "#6259ca",
  //         //       confirmButtonText: "Ok",
  //         //       allowOutsideClick: false,
  //         //     }).then((result) => {
  //         //       if (result.isConfirmed) {
  //         //         navigate(
  //         //           "/jury/userlist/" + localStorage.getItem("Entry_id")
  //         //         );
  //         //       }
  //         //     });
  //         //   }
  //         // });
  //       } else {
  //         setErrorMessage("Please Enter Your Remarks");
  //       }
  //     } else if (status === 1) {
  //       if (mark !== "") {
  //         if (remark !== "") {
  //           const temp = new FormData();
  //           temp.append("jury_user_uid", id);
  //           temp.append("points_remark", remark);
  //           temp.append("point", mark);
  //           temp.append("status", status);
  //           alert("Approve OK");
  //           // addjuryscore(temp).then((response) => {
  //           //   if (response.status === true) {
  //           //     Swal.fire({
  //           //       title: "score submitted successfully",
  //           //       showCancelButton: false,
  //           //       confirmButtonColor: "#6259ca",
  //           //       confirmButtonText: "Ok",
  //           //       allowOutsideClick: false,
  //           //     }).then((result) => {
  //           //       if (result.isConfirmed) {
  //           //         navigate(
  //           //           "/jury/userlist/" + localStorage.getItem("Entry_id")
  //           //         );
  //           //       }
  //           //     });
  //           //   }
  //           // });
  //         } else {
  //           setErrorMessage("Please Enter Your Remarks");
  //         }
  //       } else {
  //         setErrorMessage("Please Enter Your Marks");
  //       }
  //     }
  //   } else {
  //     setErrorMessage("Please Select Your Status");
  //   }
  // }

  function addmark(e) {
    e.preventDefault();
    setErrorMessage("");

    if (status === "") {
      setErrorMessage("Please Select Your Status");
      return;
    }

    if (status === "0") {
      if (remark === "") {
        setErrorMessage("Please Enter Your Remarks");
        return;
      }

      const temp = new FormData();
      temp.append("jury_user_uid", id);
      temp.append("points_remark", remark);
      temp.append("point", 0);
      temp.append("status", status);
      // alert("Reject OK");

      addjuryscore(temp).then((response) => {
        if (response.status === true) {
          Swal.fire({
            title: "score submitted successfully",
            showCancelButton: false,
            confirmButtonColor: "#6259ca",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/jury/userlist/" + localStorage.getItem("Entry_id"));
            }
          });
        }
      });
    }

    if (status === "1") {
      if (mark !== "" && remark !== "") {
        const temp = new FormData();
        temp.append("jury_user_uid", id);
        temp.append("points_remark", remark);
        temp.append("point", mark);
        temp.append("status", status);
        // alert("Approve OK");

        addjuryscore(temp).then((response) => {
          if (response.status === true) {
            Swal.fire({
              title: "score submitted successfully",
              showCancelButton: false,
              confirmButtonColor: "#6259ca",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/jury/userlist/" + localStorage.getItem("Entry_id"));
              }
            });
          }
        });
      } else {
        setErrorMessage("Please Enter Your Points & Remarks");
      }
    }
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            User Registration View
          </h2>
        </div>
      </div>

      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="row">
                  <h4>Event Details</h4>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Name</b>
                    </div>
                    <div className="col-md-8">
                      <span>{name}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Venue</b>
                    </div>
                    <div className="col-md-8">
                      <span>{venue}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Start Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{sdate}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event End Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{edate}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Result Declare Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{cdate}</span>
                    </div>
                  </div>
                </div>
                {/* <hr />
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Name</b>
                      </div>
                      <div className="col-md-8">
                        <span>{uname}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Date of Birth</b>
                      </div>
                      <div className="col-md-8">
                        <span>{dob}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Mobile</b>
                      </div>
                      <div className="col-md-8">
                        <span>{mobile}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Email</b>
                      </div>
                      <div className="col-md-8">
                        <span>{email}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Gender</b>
                      </div>
                      <div className="col-md-8">
                        <span>{gender}</span>
                      </div>
                    </div>
                  </div>
                </div> */}

                <hr />

                <h4>File Details</h4>
                <div className="row">
                  {files.map((data, i) => {
                    return (
                      <div className="col-md-6" key={i}>
                        {data.type === 1 && (
                          // <div className="row">
                          //   <div className="col-md-4">
                          //     <b className="text-inverse">Video</b>
                          //   </div>
                          //   <div className="col-md-8">
                          //     <a
                          //       href={
                          //         imageUrl + "evententry/video/" + data.name
                          //       }
                          //     >
                          //       <video width="100%" height="100%" controls>
                          //         <source
                          //           src={
                          //             imageUrl + "evententry/video/" + data.name
                          //           }
                          //           type="video/mp4"
                          //         />
                          //       </video>
                          //     </a>
                          //   </div>
                          // </div>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Video</b>
                                <a
                                  href={
                                    imageUrl + "evententry/video/" + data.name
                                  }
                                >
                                  <video width="100%" height="100%" controls>
                                    <source
                                      src={
                                        imageUrl +
                                        "evententry/video/" +
                                        data.name
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                </a>
                                <div>
                                  <a
                                    onClick={(e) =>
                                      VideoShow(
                                        imageUrl +
                                          "evententry/video/" +
                                          data.name
                                      )
                                    }
                                    className="btn-small btn-s"
                                    target="_blank"
                                  >
                                    View
                                  </a>
                                  <a
                                    href={
                                      imageUrl + "evententry/video/" + data.name
                                    }
                                    className="btn-small"
                                    target="_blank"
                                    download
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.type === 2 && (
                          // <div className="row">
                          //   <div className="col-md-4">
                          //     <b className="text-inverse">Audio</b>
                          //   </div>
                          //   <div className="col-md-8">
                          //     <a
                          //       href={
                          //         imageUrl + "evententry/audio/" + data.name
                          //       }
                          //     >
                          //       <video width="100%" height="100%" controls>
                          //         <source
                          //           src={
                          //             imageUrl + "evententry/audio/" + data.name
                          //           }
                          //           type="Audio/mp3"
                          //         />
                          //       </video>
                          //     </a>
                          //   </div>
                          // </div>
                          <div className="row">
                            <div className="col-md-8">
                              <div className="link-btn">
                                <b className="text-inverse">Audio</b>
                                <a
                                  href={
                                    imageUrl + "evententry/audio/" + data.name
                                  }
                                >
                                  <video width="100%" height="100%" controls>
                                    <source
                                      src={
                                        imageUrl +
                                        "evententry/audio/" +
                                        data.name
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                </a>
                                <div>
                                  <a
                                    href={
                                      imageUrl + "evententry/audio/" + data.name
                                    }
                                    className="btn-small"
                                    target="_blank"
                                    download
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {data.type === 3 && (
                          <div className="row">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="link-btn">
                                  <b className="text-inverse">Photograph</b>
                                  <a>
                                    <img
                                      className="profile_img"
                                      src={
                                        imageUrl +
                                        "evententry/image/" +
                                        data.name
                                      }
                                      height="160"
                                      alt="photo"
                                    />
                                  </a>
                                  <div>
                                    <a
                                      onClick={(e) =>
                                        ImageShow(
                                          imageUrl +
                                            "evententry/image/" +
                                            data.name
                                        )
                                      }
                                      className="btn-small btn-s"
                                      target="_blank"
                                    >
                                      View
                                    </a>
                                    <a
                                      href={
                                        imageUrl +
                                        "evententry/image/" +
                                        data.name
                                      }
                                      className="btn-small"
                                      target="_blank"
                                      download
                                    >
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Modal show={ishow} onHide={ImageClose}>
                              <Modal.Body className="hhh">
                                <img
                                  className="profile_img prof-img"
                                  src={file}
                                  height="160"
                                  alt="photo"
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                <a
                                  className="btn btn-next btn-secondary"
                                  style={{ float: "right" }}
                                  onClick={ImageClose}
                                >
                                  Close
                                </a>
                              </Modal.Footer>
                            </Modal>
                            <Modal show={vshow} onHide={VideoClose}>
                              <Modal.Body className="hhh">
                                {/* <img
                                  className="profile_img prof-img"
                                  src={vfile}
                                  height="160"
                                  alt="photo"
                                /> */}
                                <video width="100%" height="100%" controls>
                                  <source src={vfile} type="video/mp4" />
                                </video>
                              </Modal.Body>
                              <Modal.Footer>
                                <a
                                  className="btn btn-next btn-secondary"
                                  style={{ float: "right" }}
                                  onClick={VideoClose}
                                >
                                  Close
                                </a>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        )}

                        {data.type === 4 && (
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Document</b>
                            </div>
                            <div className="col-md-8">
                              <a
                                href={imageUrl + "evententry/docs" + data.name}
                              >
                                <img
                                  className="profile_img"
                                  src={require("../../assets/images/word.png")}
                                  height="160"
                                  alt="photo"
                                />
                              </a>
                            </div>
                          </div>
                        )}
                        {i % 2 === 1 && <br />}
                      </div>
                    );
                  })}
                </div>

                <hr />

                {showmark === null && <h4>Judging</h4>}

                {showmark === null && (
                  <div className="row-hr">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-group point-input">
                          <div className="col-md-2">
                            <b className="text-inverse">
                              Status<b className="text-danger">*</b>
                            </b>
                          </div>
                          <div className="col-md-8">
                            <select
                              value={status}
                              onChange={handleStatusChange}
                            >
                              <option value="" disabled>
                                Select Status
                              </option>
                              <option value="0">Reject</option>
                              <option value="1">Accept</option>
                            </select>
                            {/* {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-group point-input">
                          <div className="col-md-2">
                            <b className="text-inverse">
                              Points<b className="text-danger">*</b>
                            </b>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              placeholder="Enter Your Points From 0 To 10"
                              value={mark}
                              max={10}
                              disabled={!isPointsEnabled}
                              onChange={Mark}
                            />
                            {/* {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )} */}
                            {/* {mark === '10' && (
                              <input
                                type="text"
                                placeholder="Enter Point"
                                value={mark}
                                max={10}
                                maxLength="2"
                                onChange={(e) => setMark(e.target.value)}
                              />
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-rt">
                        <div className="input-group point-input">
                          <div className="col-md-2">
                            <b className="text-inverse">
                              Remarks<b className="text-danger">*</b>
                            </b>
                          </div>
                          <div className="col-md-8">
                            <textarea
                              rows={3}
                              placeholder="Enter Your Remarks"
                              value={remark}
                              // disabled={!isRemarksEnabled}
                              onChange={(e) => setRemark(e.target.value)}
                            />
                            {/* {errorMessage !== "" && (
                              <span className="errortxt text-danger">
                                {errorMessage}
                              </span>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {showmark !== null && (
                  <div className="row-hr">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-group">
                          <div className="col-md-2">
                            <b className="text-inverse">Point</b>
                          </div>
                          <div className="col-md-8">
                            <span>{showmark}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-rt">
                        <div className="input-group">
                          <div className="col-md-2">
                            <b className="text-inverse">Remark</b>
                          </div>
                          <div className="col-md-8">
                            <span>{showremark}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {errorMessage !== "" && (
                  <span className="errortxt text-danger">{errorMessage}</span>
                )}

                <hr />

                {showmark === null && (
                  <a
                    className="btn btn-next btn-success"
                    style={{ float: "right" }}
                    onClick={addmark}
                  >
                    Add Marks
                  </a>
                )}

                <a
                  className="btn btn-next btn-secondary"
                  style={{ float: "right" }}
                  onClick={() => {
                    navigate(
                      "/jury/userlist/" + localStorage.getItem("Entry_id")
                    );
                  }}
                >
                  Back
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default JuryEventwiseview;
