import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  citylist,
  countrylist,
  genderlist,
  getemail,
  getmobile,
  interestcategory,
  juryslanguage,
  statelist,
} from "../../services/register";
import "./guest.css";
import { viewevent, sendguestmail } from "../../services/admin";
import {
  guestregistration,
  getguesteventpay,
  guestpaymentresponse,
} from "../../services/register";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import imageUrl from "../../ImageUrl";
import Maillink from "../../guestmail";
import Wordlink from "../../wordpresslink";

const NewEventRegister = () => {
  const [step1submit, setStep1] = useState(false);
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");

  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [email, setEmail] = useState("");
  const [mobilecode, setMobcode] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [glist, setGenderlist] = useState([]);
  const [eventcat, setEventcat] = useState("");

  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);
  const [interestlang, setLanguage] = useState("");
  const [langlist, setLanglist] = useState([]);

  const hangleLanguage = (e) => {
    setLanguage(e);
  };

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  return (
    <div id="registrationpage">
      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            {" "}
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
        </div>
        <div className="registrationright">
          <form action="#" className="form">
            <h2 className="text-right">Event Registration</h2>
            <div className="guestreg row">
              <div className="col-md-12">
                <h6>Event Name : Pythian Games Festival 2023</h6>
              </div>
              <div className="col-md-12">
                <h6>Event Venue : Thyagaraj Sports Complex, New Delhi</h6>
              </div>
              <div className="col-md-12">
                <h6>Category : Art, Culture, Sports & Martial Arts</h6>
              </div>
              <div className="col-md-4">
                <h6>Start Date : 19-DEC-2023</h6>
              </div>
              <div className="col-md-4">
                <h6>End Date : 21-DEC-2023</h6>
              </div>
              <div className="col-md-4">
                <h6>Result Date : 29-DEC-2023</h6>
              </div>
            </div>

            <div className="form-step form-step-active">
              <div className="form-inner">
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        {" "}
                        First Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your First Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={fname}
                        onChange={(e) => {
                          setFName(e.target.value);
                        }}
                      />
                      {step1submit && !fname && (
                        <span className="errortxt text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Last Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Last / Family Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={lname}
                        onChange={(e) => {
                          setLName(e.target.value);
                        }}
                      />

                      {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Email Address :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Email Address"
                        maxLength={50}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {step1submit && !email && (
                        <span className="errortxt text-danger">
                          Email is required
                        </span>
                      )}
                      {emailerror !== "" && email && (
                        <span className="errortxt text-danger">
                          {emailerror}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Mobile Number :{/* <b className="text-danger">*</b> */}{" "}
                      </label>
                      <PhoneInput
                        specialLabel={""}
                        country={"in"}
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        countryCodeEditable={false}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {mobile.length !== 12 && mobile && (
                        <span className="errortxt text-danger">{moberror}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Gender :<b className="text-danger">*</b>
                      </label>
                      <select
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your Gender
                        </option>
                        {glist.map((data, i) => {
                          return (
                            <option value={data.gender_uid} key={i}>
                              {data.gender}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !gender && (
                        <span className="errortxt text-danger">
                          Gender is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Date of Birth :<b className="text-danger">*</b>
                      </label>

                      <div className="row">
                        <div className="col-md-4 col-sm-4 xpr-0">
                          <select
                            value={daygroup}
                            onChange={(e) => {
                              setDateDay(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Day
                            </option>
                            {dateday.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpx-0">
                          <select
                            value={monthgroup}
                            onChange={(e) => {
                              setDateMonth(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Month
                            </option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpl-0">
                          <select
                            value={yeargroup}
                            onChange={(e) => {
                              setDateYear(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Year
                            </option>
                            {dateyear.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {step1submit &&
                        (!daygroup || !monthgroup || !yeargroup) && (
                          <span className="errortxt text-danger">
                            Date of Birth is required
                          </span>
                        )}
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Event Category :<b className="text-danger">*</b>
                      </label>
                      <Select
                        placeholder="Select Your Event Category"
                        value={eventcat}
                        isMulti
                        isClearable
                      />
                      {step1submit && !eventcat && (
                        <span className="errortxt text-danger">
                          Event Category is required
                        </span>
                      )}
                    </div>
                  </div> */}

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Category :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Sports Category"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={lname}
                        onChange={(e) => {
                          setLName(e.target.value);
                        }}
                      />

                      {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Arts :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Arts / Culture"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={lname}
                        onChange={(e) => {
                          setLName(e.target.value);
                        }}
                      />

                      {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Language :<b className="text-danger">*</b>{" "}
                      </label>

                      <Select
                        placeholder="Select Your Language"
                        value={interestlang}
                        options={langlist}
                        onChange={hangleLanguage}
                        isMulti
                        isClearable
                      />
                      {step1submit && !interestlang && (
                        <span className="errortxt text-danger">
                          Language is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Country :<b className="text-danger">*</b>
                      </label>
                      <select value={country}>
                        <option value="" disabled>
                          Select Your Country
                        </option>
                        {colist.map((data, i) => {
                          return (
                            <option value={data.country_uid} key={i}>
                              {data.country_name}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !country && (
                        <span className="errortxt text-danger">
                          Country is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        State :<b className="text-danger">*</b>
                      </label>
                      <select value={state}>
                        <option value="" disabled>
                          Select Your State
                        </option>
                        {slist.map((data, i) => {
                          return (
                            <option value={data.state_uid} key={i}>
                              {data.state_name}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !state && (
                        <span className="errortxt text-danger">
                          State is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        City :<b className="text-danger">*</b>
                      </label>
                      <select
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your City
                        </option>
                        {clist.map((data, i) => {
                          return (
                            <option value={data.city_uid} key={i}>
                              {data.city_name}
                            </option>
                          );
                        })}
                      </select>
                      {step1submit && !city && (
                        <span className="errortxt text-danger">
                          City is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-9">
                  <div className="checkbox checkbox-1">
                    <input type="checkbox" id="checkbox-1" />
                    <label htmlFor="checkbox-1">
                      Agree to our{" "}
                      <a
                        target="_blank"
                        href="https://www.pythiangames.org/privacy"
                      >
                        Privacy Policy
                      </a>{" "}
                      ,{" "}
                      <a
                        target="_blank"
                        href="https://www.pythiangames.org/terms"
                      >
                        Terms & Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        // href={require("../../assets/images/Poetry_and_Painting_art_Compition.pdf")}
                      >
                        Submission & Guidelines
                      </a>
                      <b className="text-danger">*</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="member">
                    <label>Entry Fee: Free</label>
                  </div>
                </div>
              </div>

              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}

              <div className="">
                <Link to={"/login"} className="btn btn-secondary">
                  Back
                </Link>
                <a
                  className="btn btn-primary btn-next"
                  style={{ float: "right" }}
                >
                  Submit
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewEventRegister;
