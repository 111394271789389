import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import baseUrl from "../Api";

export async function logindata(data) {
  try {
    return await axios
      .post(`${baseUrl}/login`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function logout() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/logout`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function forgotpass(data) {
  try {
    return await axios
      .get(`${baseUrl}/user-forgotemail/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function guestloginotpemail(data) {
  try {
    return await axios
      .post(`${baseUrl}/guestotpmail`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}


export async function otplogindata(data) {
  try {
    return await axios
      .post(`${baseUrl}/guestotpvalidate`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}



function Catcherror(error) {
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/Pythian/#/login");
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>

  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    console.log("Error", error.message);
  }
}
