import React, { useState } from "react";
import "../login/login.css";
import { Link, useNavigate } from "react-router-dom";
import { forgotpass } from "../../services/login";

function Forgotpassword() {
  const [user, setUser] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  function Submitforgot(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage("");
    if (user !== "") {
      forgotpass(user).then((response) => {
        if (response.status === true) {
          setErrorMessage(response.message);
        } else {
          setErrorMessage(response.message);
        }
      });
    }
  }
  return (
    <div>
      <main id="loginpage">
        <div className="container">
          <div className="loginpage_section">
            <div className="loginpage_secionone">
              <div className="loginpage_sectionone_left">
                <div className="loginpage_logo">
                  <a href="https://pythiangames.org/" target="_blank">
                    <img
                      src={require("../../assets/images/logo/logonew.png")}
                      height="160"
                      className="logo-image"
                    />
                  </a>
                </div>

                <img
                  src={require("../../assets/images/imageleft.png")}
                  className="loginleftimg"
                />
              </div>
            </div>

            <div className="loginpage_seciontwo">
              <div className="loginpage_sectiontwo_form">
                <form className="form">
                  <h5 className="text-center"> Welcome to</h5>
                  <h5 className="text-center"> Modern Pythian Games</h5>
                  <h2 className="text-center">Membership Forgot Password</h2>
                  <fieldset className="username">
                    <label>Username</label>
                    <input
                      type="text"
                      placeholder="Enter your Username"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                    />
                    {submitted && !user && (
                      <span className="errortext">Username is required</span>
                    )}
                  </fieldset>

                  {errorMessage && (
                    <span className="errortext"> {errorMessage} </span>
                  )}

                  <button type="submit" className="btn" onClick={Submitforgot}>
                    {" "}
                    Forgot Password
                  </button>
                  <div className="text-center mt-4">
                    <a className="text-primary">
                      <Link to={"/login"}>Login</Link>
                    </a>
                  </div>
                </form>
              </div>
            </div>
            <div className="loginpage_footer">
              <div className="copyrights">
                <span>
                  © {new Date().getFullYear()} Pythian Games | All Rights
                  Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Forgotpassword;
