import moment from "moment/moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate, useParams } from "react-router-dom";
import imageUrl from "../../ImageUrl";
import { evententryview } from "../../services/admin";
import "../dashboard/users/users.css";

const Guestdashview = () => {
  const navigate = useNavigate();

  const [fname, setFirst] = useState("");
  const [lname, setLast] = useState("");
  const [member, setMember] = useState("");
  const [email, setEmail] = useState("");
  const [pmobile, setPrimary] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [photo, setPhoto] = useState("");
  const [identity, setIdentity] = useState("");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [resdate, setResDate] = useState("");

  const [eventname, setIEventName] = useState("");
  const [venue, setVenue] = useState("");

  const [files, setFiles] = useState([]);
  var arr = [];

  useEffect(() => {
    if (localStorage.getItem("Roleid") === null) {
      navigate("/guestlogin");
    } else {
      evententryview(localStorage.getItem("Guestid")).then((response) => {
        if (response.status === true) {
          setFirst(response.data.event_entry_files.guest_firstname);
          setLast(response.data.event_entry_files.guest_lastname);
          setMember(response.data.event_entry_files.membership_type);
          setEmail(response.data.event_entry_files.guest_email);
          if (response.data.event_entry_files.mobile_country_code !== null) {
            setPrimary(
              "(+" +
                response.data.event_entry_files.mobile_country_code +
                ") " +
                response.data.event_entry_files.guest_mobile
            );
          } else {
            setPrimary(response.data.event_entry_files.guest_mobile);
          }
          //setSecondary(response.data.secondary_mobile);
          setGender(response.data.event_entry_files.gender);
          setDOB(moment(response.data.date_of_birth).format("DD-MM-YYYY"));

          setAdd1(response.data.event_entry_files.address_1);
          setAdd2(response.data.event_entry_files.address_2);
          setCountry(response.data.event_entry_files.country_name);
          setState(response.data.event_entry_files.state_name);
          setCity(response.data.event_entry_files.city_name);
          setPincode(response.data.event_entry_files.pincode);

          setPhoto(
            imageUrl +
              "user/photo/" +
              response.data.event_entry_files.guest_photo
          );
          setIdentity(
            imageUrl +
              "user/id_proof/" +
              response.data.event_entry_files.identity_proof
          );
          setStartDate(
            moment(response.data.event_entry_files.event_start).format(
              "DD-MM-YYYY"
            )
          );
          setEndDate(
            moment(response.data.event_entry_files.event_end).format(
              "DD-MM-YYYY"
            )
          );
          setResDate(
            moment(response.data.event_entry_files.event_entry_close).format(
              "DD-MM-YYYY"
            )
          );
          setIEventName(response.data.event_entry_files.event_name);
          setVenue(response.data.event_entry_files.event_venue);

          arr = [];
          setFiles([]);
          var efile = "";
          if (response.data.event_entry_files.entry_files.length !== 0) {
            for (
              let i = 0;
              i < response.data.event_entry_files.entry_files.length;
              i++
            ) {
              if (
                efile !==
                response.data.event_entry_files.entry_files[i].category
              ) {
                efile = response.data.event_entry_files.entry_files[i].category;
              } else {
                efile = "";
              }
              arr.push({
                category: efile,
                name: response.data.event_entry_files.entry_files[i].file_name,
                type: response.data.event_entry_files.entry_files[i].file_type,
              });
            }
          }
          setFiles(arr);
        }
      });
    }
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Guest User Information{" "}
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <h5>Personal Information</h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">First Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{fname}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{lname}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Membership Type</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{member}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email Address</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Primary Mobile</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pmobile}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Date of Birth</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dob}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>Primary Information</h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 1</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{add1}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Address 2</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{add2}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Country</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{country}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{state}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">City</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{city}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pincode}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>Additional Information</h5>

                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Photograph</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={photo}
                          height="160"
                          alt="photo"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <b className="text-inverse">Identity Proof</b>
                      </div>
                      <div className="col-md-8">
                        <img
                          className="profile_img"
                          src={identity}
                          height="160"
                          alt="photo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>Event Information</h5>
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-2">
                          <b className="text-inverse">Event Name</b>
                        </div>
                        <div className="col-md-10">
                          <span className="text-inverse">{eventname}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Event Venue</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{venue}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Event Start Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{startdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Event End Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{enddate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Result Declare Date</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{resdate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>File Uploads</h5>
                <div className="row">
                  {files.map((data, i) => {
                    return (
                      <div className="col-md-6" key={i}>

                        {data.type === 1 && (
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Video</b>
                            </div>
                            <div className="col-md-8">
                              <video width="100%" height="100%" controls>
                                <source
                                  src={
                                    imageUrl + "evententry/video/" + data.name
                                  }
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          </div>
                        )}

                        {data.type === 2 && (
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Audio</b>
                            </div>
                            <div className="col-md-8">
                              <video width="100%" height="100%" controls>
                                <source
                                  src={
                                    imageUrl + "evententry/audio/" + data.name
                                  }
                                  type="Audio/mp3"
                                />
                              </video>
                            </div>
                          </div>
                        )}

                        {data.type === 3 && (
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Image</b>
                            </div>
                            <div className="col-md-8">
                              <img
                                className="profile_img"
                                src={imageUrl + "evententry/image/" + data.name}
                                height="160"
                                alt="photo"
                              />
                            </div>
                          </div>
                        )}

                        {data.type === 4 && (
                          <div className="row">
                            <div className="col-md-4">
                              <b className="text-inverse">Document</b>
                            </div>
                            <div className="col-md-8">
                              <a
                                href={imageUrl + "evententry/docs" + data.name}
                              >
                                <img
                                  className="profile_img"
                                  src={require("../../assets/images/word.png")}
                                  height="160"
                                  alt="photo"
                                />
                              </a>
                            </div>
                          </div>
                        )}
                        {i % 2 === 1 && <br />}

                      </div>
                    );
                  })}
                </div>

                {/* <div className="text-center">
                  <a
                    className="btn btn-prev btn-secondary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </a>
                  
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Guestdashview;
