import moment from "moment/moment";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import imageUrl from "../../../ImageUrl";
import { juryviewdetail } from "../../../services/jury";
import {
  approveuser,
  deljury,
  eventcategorylist,
  reasonlist,
} from "../../../services/admin";
import Swal from "sweetalert2";
import "./jury.css";
import { toast } from "react-toastify";
import { juryslanguage } from "../../../services/register";
const Juryview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [uname, setUName] = useState("");
  const [upass, setUPass] = useState("");

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [intcat, setIntcat] = useState("");
  const [lang, setLang] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");

  const [photo, setPhoto] = useState("");
  const [idproof, setIDProof] = useState("");

  const [qual1, setQual1] = useState("");
  const [qual2, setQual2] = useState("");
  const [qual3, setQual3] = useState("");
  const [qual4, setQual4] = useState("");
  const [qual5, setQual5] = useState("");
  const [ishow, setImage] = useState(false);
  const [file, setFile] = useState(false);
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }
  function delJury(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure want to Delete?",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deljury(id).then((response) => {
          if (response.status === true) {
            navigate("/jury/pendingjury/");
          }
        });
      }
    });
  }

  function approveduser(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure want to Approve?",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#6259ca",
      cancelButtonColor: "#dc3545",
      confirmButtonText: "Approve",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const appr = {
          user_uid: id,
          admin_uid: localStorage.getItem("Userid"),
          role_uid: role,
          verified_status: 1,
          verified_reason: "Approved",
        };

        approveuser(appr).then((response) => {
          if (response.status === true) {
            navigate("/jury/approvedjury/");
          } else {
            toast.error(response.message);
          }
        });
      }
    });
  }

  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }

  const [removerea, setRemoveres] = useState("");
  const [relist, setReasonlist] = useState([]);
  const [removemessage, setRemovemess] = useState("");

  function submitremove(e) {
    e.preventDefault();
    setRemovemess("");
    const temp = {
      user_uid: id,
      admin_uid: localStorage.getItem("Userid"),
      role_uid: role,
      verified_status: 2,
      verified_reason: removerea,
    };
    if (removerea !== "") {
      approveuser(temp).then((response) => {
        if (response.status === true) {
          setShow(false);
          navigate(-1);
        } else {
          toast.error(response.message);
        }
      });
    } else {
      setRemovemess("Please Select Your Reason");
    }
  }

  const [bshow, setBlock] = useState(false);
  function BlockShow() {
    setBlock(true);
  }
  function BlockClose() {
    setBlock(false);
  }

  const [blockrea, setBlockres] = useState("");
  const [blockmessage, setBlockmess] = useState("");

  function submitblock(e) {
    e.preventDefault();
    setBlockmess("");
    const temp = {
      user_uid: id,
      admin_uid: localStorage.getItem("Userid"),
      role_uid: role,
      verified_status: 3,
      verified_reason: blockrea,
    };
    if (blockrea !== "") {
      approveuser(temp).then((response) => {
        if (response.status === true) {
          setBlock(false);
          navigate(-1);
        } else {
          toast.error(response.message);
        }
      });
    } else {
      setBlockmess("Please Select Your Reason");
    }
  }

  function submitunblock(e) {
    e.preventDefault();

    const temp = {
      user_uid: id,
      admin_uid: localStorage.getItem("Userid"),
      role_uid: role,
      verified_status: 4,
      verified_reason: "unblock",
    };

    approveuser(temp).then((response) => {
      if (response.status === true) {
        toast.success("Jury has been unblocked successfully");
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    });
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  useEffect(() => {
    juryviewdetail(id).then((response) => {
      if (response.data !== null) {
        setAddress1(response.data.street_no);
        setAddress2(response.data.address_2);
        setUName(response.data.unique_uid);
        setUPass(response.data.refnonhash);
        if (response.data.verified_status === 1) {
          setStatus("Approved");
        } else if (response.data.verified_status === 2) {
          setStatus("Removed");
        } else if (response.data.verified_status === 3) {
          setStatus("Blocked");
        } else {
          setStatus("Pending");
        }
        setRole(response.data.role_uid);

        setCountry(response.data.country_name);
        setState(response.data.state_name);
        setCity(response.data.city_name);
        setFirst(response.data.first_name);
        setLast(response.data.last_name);
        if (response.data.date_of_birth !== null) {
          if (response.data.date_of_birth !== "--") {
            setDOB(moment(response.data.date_of_birth).format("DD-MM-YYYY"));
          }
        }

        setGender(response.data.gender);

        if (response.data.primary_mob_country_code !== null) {
          setMobile(response.data.primary_mob_country_code);
          setMobile(
            "(+" +
              response.data.primary_mob_country_code +
              ") " +
              response.data.primary_mobile
          );
        } else {
          setMobile(response.data.primary_mobile);
        }
        setPincode(response.data.pincode);
        setEmail(response.data.email);
        if (response.data.photo !== null) {
          setPhoto(imageUrl + "jury/photo/" + response.data.photo);
        }
        if (response.data.identity !== null) {
          setIDProof(imageUrl + "jury/id_proof/" + response.data.identity);
        }

        if (response.data.qualification_1 !== undefined) {
          setQual1(response.data.qualification_1);
        }
        if (response.data.qualification_2 !== undefined) {
          setQual2(response.data.qualification_2);
        }
        if (response.data.qualification_3 !== undefined) {
          setQual3(response.data.qualification_3);
        }
        if (response.data.qualification_4 !== undefined) {
          setQual4(response.data.qualification_4);
        }
        if (response.data.qualification_5 !== undefined) {
          setQual5(response.data.qualification_5);
        }
        var catname = "";
        setIntcat("");

        if (response.data.category.length !== 0) {
          eventcategorylist().then((res) => {
            if (res.status === true) {
              if (res.data.length !== 0) {
                for (let j = 0; j < response.data.category.length; j++) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (
                      response.data.category[j] ===
                      res.data[i].category_uid + ""
                    ) {
                      if (catname === "") {
                        catname = res.data[i].category;
                      } else {
                        catname = catname + " , " + res.data[i].category;
                      }
                    }
                  }
                }
              }
              setIntcat(catname);
            }
          });
        }
        setLang("");

        if (response.data.language.length !== 0) {
          var langname = "";
          juryslanguage().then((res) => {
            if (res.language.length !== 0) {
              for (let j = 0; j < response.data.language.length; j++) {
                for (let i = 0; i < res.language.length; i++) {
                  if (
                    response.data.language[j] ===
                    res.language[i].language_uid + ""
                  ) {
                    if (langname === "") {
                      langname = res.language[i].language;
                    } else {
                      langname = langname + " , " + res.language[i].language;
                    }
                  }
                }
              }
            }
            setLang(langname);
          });
        }
      }
    });

    reasonlist().then((response) => {
      if (response.status === true) {
        setReasonlist(response.data);
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            JURY REGISTRATION VIEW
          </h2>
        </div>
      </div>

      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">First Name</b>
                      </div>
                      <div className="col-md-8">
                        <span>{first}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Last Name</b>
                      </div>
                      <div className="col-md-8">
                        <span>{last}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Date of Birth</b>
                      </div>
                      <div className="col-md-8">
                        <span>{dob}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Gender</b>
                      </div>
                      <div className="col-md-8">
                        <span>{gender}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Mobile</b>
                      </div>
                      <div className="col-md-8">
                        <span>{mobile}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Email</b>
                      </div>
                      <div className="col-md-8">
                        <span>{email}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {status === "Approved" && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Username</b>
                        </div>
                        <div className="col-md-8">
                          <span>{uname}</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Password</b>
                        </div>
                        <div className="col-md-8">
                          <span>{upass}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <hr />
                <h4>Primary Information</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Address 1</b>
                      </div>
                      <div className="col-md-8">
                        <span>{address1}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Address 2</b>
                      </div>
                      <div className="col-md-8">
                        <span>{address2}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Country</b>
                      </div>
                      <div className="col-md-8">
                        <span>{country}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">State</b>
                      </div>
                      <div className="col-md-8">
                        <span>{state}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">City</b>
                      </div>
                      <div className="col-md-8">
                        <span>{city}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Pincode</b>
                      </div>
                      <div className="col-md-8">
                        <span>{pincode}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>Professional Information</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Category</b>
                      </div>
                      <div className="col-md-8">
                        <span>{intcat}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Language</b>
                      </div>
                      <div className="col-md-8">
                        <span>{lang}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>File Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-8">
                        <div className="link-btn">
                          <b className="text-inverse">Photograph</b>
                          <a>
                            <img
                              className="profile_img"
                              src={photo}
                              height="160"
                              alt="photo"
                              onError={(e) => errorhandle(e)}
                            />
                          </a>
                          <div>
                            <a
                              onClick={(e) => ImageShow(photo)}
                              className="btn-small btn-s"
                              target="_blank"
                            >
                              View
                            </a>
                            <a
                              href={photo}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-group">
                      <div className="col-md-8">
                        <div className="link-btn">
                          <b className="text-inverse">Identity Proof</b>
                          <a>
                            <img
                              className="profile_img"
                              src={idproof}
                              height="160"
                              alt="photo"
                              onError={(e) => errorhandle(e)}
                            />
                          </a>
                          <div>
                            <a
                              onClick={(e) => ImageShow(idproof)}
                              className="btn-small btn-s"
                              target="_blank"
                            >
                              View
                            </a>
                            <a
                              href={idproof}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  {qual1 !== "" && (
                    <div className="col-md-4">
                      <div className="input-group">
                        <div className="link-btn">
                          <b className="text-inverse">Document</b>
                          <a href={imageUrl + "jury/qualification/" + qual1}>
                            <img
                              className="profile_img"
                              src={require("../../../assets/images/word.png")}
                              height="160"
                              alt="photo"
                            />
                          </a>
                          <div>
                            <a
                              href={imageUrl + "jury/qualification/" + qual1}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {qual2 !== "" && (
                    <div className="col-md-4">
                      <div className="input-group">
                        <div className="link-btn">
                          <b className="text-inverse">Document</b>
                          <a href={imageUrl + "jury/qualification/" + qual2}>
                            <img
                              className="profile_img"
                              src={require("../../../assets/images/word.png")}
                              height="160"
                              alt="photo"
                            />
                          </a>
                          <div>
                            <a
                              href={imageUrl + "jury/qualification/" + qual2}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {qual3 !== "" && (
                    <div className="col-md-4">
                      <div className="input-group">
                        <div className="link-btn">
                          <b className="text-inverse">Document</b>
                          <a href={imageUrl + "jury/qualification/" + qual3}>
                            <img
                              className="profile_img"
                              src={require("../../../assets/images/word.png")}
                              height="160"
                              alt="photo"
                            />
                          </a>
                          <div>
                            <a
                              href={imageUrl + "jury/qualification/" + qual3}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {qual4 !== "" && (
                    <div className="col-md-4">
                      <div className="input-group">
                        <div className="link-btn">
                          <b className="text-inverse">Document</b>
                          <a href={imageUrl + "jury/qualification/" + qual4}>
                            <img
                              className="profile_img"
                              src={require("../../../assets/images/word.png")}
                              height="160"
                              alt="photo"
                            />
                          </a>
                          <div>
                            <a
                              href={imageUrl + "jury/qualification/" + qual4}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {qual5 !== "" && (
                    <div className="col-md-4">
                      <div className="input-group">
                        <div className="link-btn">
                          <b className="text-inverse">Document</b>
                          <a href={imageUrl + "jury/qualification/" + qual5}>
                            <img
                              className="profile_img"
                              src={require("../../../assets/images/word.png")}
                              height="160"
                              alt="photo"
                            />
                          </a>
                          <div>
                            <a
                              href={imageUrl + "jury/qualification/" + qual5}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <br />
                <hr />
                <div className="bt-center bt-centeralign">
                  <a
                    className="btn btn-next btn-secondary"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </a>
                  {status === "Pending" && (
                    <a
                      className="btn btn-prev btn-success"
                      onClick={(e) => approveduser(e)}
                    >
                      Approve
                    </a>
                  )}
                  <a className="btn btn-prev btn-danger" onClick={delJury}>
                    Delete
                  </a>
                  <a
                    className="btn btn-prev btn-info"
                    onClick={() => {
                      navigate("/jury/editjury/" + id);
                    }}
                  >
                    Edit
                  </a>

                  {status === "Approved" && (
                    <a className="btn btn-prev btn-primary" onClick={BlockShow}>
                      Block
                    </a>
                  )}
                  {status !== "Removed" && (
                    <a className="btn btn-prev btn-danger" onClick={handleShow}>
                      Remove
                    </a>
                  )}
                  {status === "Blocked" && (
                    <a
                      className="btn btn-prev btn-primary"
                      onClick={submitunblock}
                    >
                      Unblock
                    </a>
                  )}
                </div>
              </div>
              <Modal show={ishow} onHide={ImageClose}>
                <Modal.Body className="hhh">
                  <img
                    className="profile_img prof-img"
                    src={file}
                    height="160"
                    alt="photo"
                    onError={(e) => errorhandle(e)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={ImageClose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header>
                  <h4>Remove Jury</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">Remove Reason</label>
                      </div>
                      <div className="col-md-8">
                        <select
                          value={removerea}
                          onChange={(e) => {
                            setRemoveres(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Reason
                          </option>
                          {relist.map((data, i) => {
                            return (
                              <option value={data.reason} key={i}>
                                {data.reason}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">Removed By </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          value={localStorage.getItem("Name")}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  {removemessage !== "" && (
                    <span className="errortxt text-danger">
                      {removemessage}
                    </span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-prev btn-danger"
                    style={{ float: "right" }}
                    onClick={submitremove}
                  >
                    Remove
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={bshow} size="lg" onHide={BlockClose}>
                <Modal.Header>
                  <h4>Block Jury</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">Block Reason</label>
                      </div>
                      <div className="col-md-8">
                        <select
                          value={blockrea}
                          onChange={(e) => {
                            setBlockres(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Reason
                          </option>
                          {relist.map((data, i) => {
                            return (
                              <option value={data.reason} key={i}>
                                {data.reason}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">Blocked By </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          value={localStorage.getItem("Name")}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  {blockmessage !== "" && (
                    <span className="errortxt text-danger">{blockmessage}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-prev btn-danger"
                    style={{ float: "right" }}
                    onClick={submitblock}
                  >
                    Block
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={BlockClose}
                  >
                    Cancel
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Juryview;
