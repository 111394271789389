import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  citylist,
  countrylist,
  educationlist,
  genderlist,
  getemail,
  getmobile,
  memberlist,
  occupationlist,
  registerdata,
  statelist,
} from "../../../services/register";
import { interestcategory } from "../../../services/register";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "../../registration/registration.css";
import "./users.css";

const Useradd = () => {
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [memtype, setMember] = useState("");
  const [memlist, setMemberlist] = useState([]);

  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [email, setEmail] = useState("");
  // const [primob, setPrimobile] = useState("");
  // const [secmob, setSecMobile] = useState("");

  const [primob, setPrimobile] = useState("");
  const [primobcode, setPriCode] = useState("");
  const [primobonly, setPriOnly] = useState("");
  const [secmob, setSecMobile] = useState("");
  const [secmobcode, setSecCode] = useState("");
  const [secmobonly, setSecOnly] = useState("");

  const [gender, setGender] = useState("");
  const [glist, setGenderlist] = useState([]);
  const [intcategory, setIntcategory] = useState("");
  const [intlist, setIntlist] = useState([]);

  const [education, setEducation] = useState("");
  const [elist, setEdulist] = useState([]);

  const [occu, setOccu] = useState("");
  const [olist, setOcculist] = useState([]);

  const [house, setHouse] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);

  const [portfolio, setPortfolio] = useState("");
  const [shortinfo, setShort] = useState("");
  const [brief, setBrief] = useState("");

  const [order, setOrder] = useState("");
  const [payment, setPayment] = useState("");
  const [sign, setSign] = useState("");
  const [amount, setAmt] = useState("");
  const [arttype, setArttype] = useState("");
  const [add, setAdd] = useState(true);

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  function datelist() {
    var day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }
    setDaylist(day);

    var year = [];

    for (let i = 1930; i <= new Date().getFullYear() - 5; i++) {
      year.push(i);
    }
    setYearlist(year);
  }

  const [interestcat, setEventinterest] = useState("");
  const hangleInterest = (e) => {
    setEventinterest(e);
  };
  const handleprimary = (value, data, event, formattedValue) => {
    setPriCode(data.dialCode);
    setPrimobile(value);
    setPriOnly(value.slice(data.dialCode.length));
  };

  const handlesecondary = (value, data, event, formattedValue) => {
    setSecCode(data.dialCode);
    setSecMobile(value);
    setSecOnly(value.slice(data.dialCode.length));
  };

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }
  var arr1 = [];
  function masterlist() {
    genderlist().then((response) => {
      // if (response.status === true) {
      setGenderlist(response.genders);
      // }
    });

    memberlist().then((response) => {
      setMemberlist(response.memshiptypes);
    });

    occupationlist().then((response) => {
      setOcculist(response.occupation);
    });

    educationlist().then((response) => {
      setEdulist(response.education);
    });

    countrylist().then((response) => {
      setCountrylist(response.country);
    });
    interestcategory().then((response) => {
      if (response.data.length !== 0) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
          });
        }
        setIntlist(arr1);
      }
    });
  }

  const [valmob, setValMob] = useState("");
  function checkmobile(e) {
    e.preventDefault();
    setValMob("");
    setMobError("");
    if (e.target.value !== "") {
      getmobile(e.target.value).then((response) => {
        if (response.status === false) {
          setValMob(response.message);
          Swal.fire({
            title:
              response.message +
              " - " +
              response.data.username +
              " has been already have this Mobile Number",
            allowOutsideClick: false,
          });
        }
      });
    }
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    if (e.target.value !== "") {
      getemail(e.target.value).then((response) => {
        if (response.status === false) {
          setValEmail(response.message);
          Swal.fire({
            title:
              response.message +
              " - " +
              response.data.username +
              " has been already have this Email",
            allowOutsideClick: false,
          });
        }
      });
    }
  }

  const [valsecmob, setValSecMob] = useState("");
  function checksecmobile(e) {
    e.preventDefault();
    setValSecMob("");
    if (secmob.length < 10 && secmob !== "") {
      setValSecMob("Secondary Mobile Number must be 10 characters");
    }
    if (primob !== "") {
      if (secmob !== "") {
        if (secmob.length >= 10) {
          if (primob === secmob) {
            setValSecMob("Primary and Secondary Mobile should not be same");
          }
        } else {
          setValSecMob("Secondary Mobile Number must be 10 characters");
        }
      }
    }
  }

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  const [formiderror, setIdErrors] = useState("");
  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setErrorMessage("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));
        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Photograph size should be less than 3MB");
      }
    }
  }

  function idfile(event) {
    setIdErrors("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(event.target.files[0]));
        setIDFile(event.target.files[0]);
      } else {
        setIdErrors("Identity Profile should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setIdErrors("Identity Profile size should be less than 3MB");
      }
    }
  }

  function addsubmit(e) {
    e.preventDefault();
    setErrorMessage("");

    if (formphoto === "" && formid === "") {
      setPhotoErrors("Photograph is required");
      setIdErrors("DOB Proof is required");
    }
    if (daygroup !== "" && monthgroup !== "" && yeargroup !== "") {
      setDOB(yeargroup + "-" + monthgroup + "-" + daygroup);
    }
    if (formphoto === "") {
      setPhotoErrors("Photograph is required");
    } else if (formid === "") {
      setIdErrors("DOB Proof is required");
    }
    // if (
    //   formphoto !== "" &&
    //   formid !== "" &&
    //   formphotoerror === "" &&
    //   formiderror === ""
    // ) {
    //   setSubmit(true);
    // }

    setEmailError("");
    setMobError("");
    if (daygroup !== "" && monthgroup !== "" && yeargroup !== "") {
      setDOB(yeargroup + "-" + monthgroup + "-" + daygroup);
    }
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      setEmailError("Please Enter a valid Email Address");
    }

    if (primob.length < 10) {
      setMobError("Primary Mobile Number must be 10 characters");
    }

    if (
      fname !== "" &&
      lname !== "" &&
      memtype !== "" &&
      daygroup !== "" &&
      monthgroup !== "" &&
      arttype !== "" &&
      yeargroup !== "" &&
      email !== "" &&
      valemail === "" &&
      emailerror === "" &&
      valmob === "" &&
      (interestcat !== "" || artadd1 !== "") &&
      valsecmob === "" &&
      moberror === "" &&
      primob !== "" &&
      primob.length > 10 &&
      gender !== "" &&
      education !== "" &&
      occu !== "" &&
      formphoto !== "" &&
      formid !== "" &&
      formphotoerror === "" &&
      formiderror === "" &&
      order !== "" &&
      payment !== "" &&
      sign !== "" &&
      amount !== ""
    ) {
      setAdd(false);
      let intid = "";
      if (interestcat.length !== 0) {
        for (let j = 0; j < interestcat.length; j++) {
          if (intid === "") {
            intid = interestcat[j].value;
          } else {
            intid = intid + "," + interestcat[j].value;
          }
        }
      }
      const tempData = new FormData();
      tempData.append("first_name", fname);
      tempData.append("last_name", lname);
      tempData.append("membership_type", memtype);
      tempData.append("email_id", email);
      tempData.append("primary_mobile", primob);
      tempData.append("secondary_mobile", secmob);
      tempData.append("primary_mob_country_code", primobcode);
      tempData.append("sec_mob_country_code", secmobcode);

      tempData.append("user_types", arttype);

      tempData.append("artist_count", artadd);
      for (let i = 1; i <= artadd; i++) {
        if (i == 1) {
          tempData.append("artist_art_type_" + i, artadd1);
        } else if (i == 2) {
          tempData.append("artist_art_type_" + i, artadd2);
        } else if (i == 3) {
          tempData.append("artist_art_type_" + i, artadd3);
        }
      }

      tempData.append("interested_category_uid", intid);
      tempData.append("gender", gender);
      tempData.append(
        "date_of_birth",
        yeargroup + "-" + monthgroup + "-" + daygroup
      );
      tempData.append("education", education);
      tempData.append("occupation", occu);
      tempData.append("street_no", house);
      tempData.append("pincode", pincode);
      tempData.append("country", country);
      tempData.append("state", state);
      tempData.append("city", city);
      tempData.append("portfolio", portfolio);
      tempData.append("short_info", shortinfo);
      tempData.append("introductory_brief", brief);
      tempData.append("photo", formphoto);
      tempData.append("identity", formid);
      tempData.append("register_by", 1);
      tempData.append("payment_id", payment);
      tempData.append("order_id", order);
      tempData.append("payment_sign", sign);
      tempData.append("amount", amount);
      tempData.append("currency", "INR");

      registerdata(tempData).then((response) => {
        if (response.status === true) {
          setSubmit(false);
          navigate("/users/pendingusers/");
        } else {
          setErrorMessage(response.data);
        }
      });
    } else {
      setSubmit(true);
    }
  }
  const [artadd, setArtadd] = useState(1);
  const [artadd1, setArtadd1] = useState("");
  const [artadd2, setArtadd2] = useState("");
  const [artadd3, setArtadd3] = useState("");

  function addmulti() {
    if (artadd < 3) {
      setArtadd(artadd + 1);
    }
  }

  function changetype(e) {
    setArttype(e.target.value);
    if (e.target.value === "Artist") {
      setIntcategory("");
    } else if (e.target.value === "Non-Artist") {
      setArtadd(1);
      setArtadd1("");
      setArtadd2("");
      setArtadd3("");
    }
  }
  function addremove() {
    if (artadd == 1) {
      setArtadd2("");
      setArtadd3("");
    } else if (artadd == 2) {
      setArtadd2("");
    } else if (artadd == 3) {
      setArtadd3("");
    }
    setArtadd(artadd - 1);
  }
  useEffect(() => {
    datelist();
    masterlist();
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header"> 
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">ADD MEMBERS</h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <h5>Personal Information</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            First Name :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your First Name"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            maxLength={25}
                            value={fname}
                            onChange={(e) => {
                              setFName(e.target.value);
                            }}
                          />
                          {submit && !fname && (
                            <span className="errortxt text-danger">
                              First Name is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Last Name :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Last Name"
                            onKeyPress={LetterOnly}
                            maxLength={25}
                            onPaste={onPasteLetter}
                            value={lname}
                            onChange={(e) => {
                              setLName(e.target.value);
                            }}
                          />
                          {submit && !lname && (
                            <span className="errortxt text-danger">
                              Last Name is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Membership Type :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={memtype}
                            onChange={(e) => {
                              setMember(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Membership Type
                            </option>
                            {memlist.map((data, i) => {
                              return (
                                <option
                                  value={data.membership_type_uid}
                                  key={i}
                                >
                                  {data.membership_type_name}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !memtype && (
                            <span className="errortxt text-danger">
                              Membership Type is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Email Address :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            onBlur={checkemail}
                            placeholder="Enter Your Email Address"
                            value={email}
                            maxLength={50}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          {submit && !email && (
                            <span className="errortxt text-danger">
                              Email Address is required
                            </span>
                          )}
                          {emailerror !== "" && email && (
                            <span className="errortxt text-danger">
                              {emailerror}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Primary Mobile :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <PhoneInput
                            specialLabel={""}
                            country={"in"}
                            placeholder="Enter Primary Mobile Number"
                            value={primob}
                            onChange={handleprimary}
                            countryCodeEditable={false}
                            onBlur={checkmobile}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />

                          {submit && !primob && (
                            <span className="errortxt text-danger">
                              Primary Mobile is required
                            </span>
                          )}
                          {moberror !== "" && primob && (
                            <span className="errortxt text-danger">
                              {moberror}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">Secondary Mobile :</label>
                        </div>
                        <div className="col-md-8">
                          <PhoneInput
                            specialLabel={""}
                            country={"in"}
                            placeholder="Enter Secondary Mobile Number"
                            value={secmob}
                            countryCodeEditable={false}
                            onChange={handlesecondary}
                            onBlur={checksecmobile}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {valsecmob !== "" && secmob && (
                            <span className="errortxt text-danger">
                              {valsecmob}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Gender :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Gender
                            </option>
                            {glist.map((data, i) => {
                              return (
                                <option value={data.gender_uid} key={i}>
                                  {data.gender}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !gender && (
                            <span className="errortxt text-danger">
                              Gender is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Date of Birth :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <div className="row rm">
                            <div className="col-md-4 col-sm-3 xpr-0">
                              <select
                                value={daygroup}
                                onChange={(e) => {
                                  setDateDay(e.target.value);
                                }}
                              >
                                <option value="" disabled>
                                  Day
                                </option>
                                {dateday.map((data, i) => {
                                  return (
                                    <option value={data} key={i}>
                                      {data}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-md-4 col-sm-4 xpx-0 pd-z">
                              <select
                                value={monthgroup}
                                onChange={(e) => {
                                  setDateMonth(e.target.value);
                                }}
                              >
                                <option value="" disabled>
                                  Month
                                </option>
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                            </div>
                            <div className="col-md-4 col-sm-5 xpl-0 pd-z">
                              <select
                                value={yeargroup}
                                onChange={(e) => {
                                  setDateYear(e.target.value);
                                }}
                              >
                                <option value="" disabled>
                                  Year
                                </option>
                                {dateyear.map((data, i) => {
                                  return (
                                    <option value={data} key={i}>
                                      {data}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          {submit &&
                            (!daygroup || !monthgroup || !yeargroup) && (
                              <span className="errortxt text-danger">
                                Date of Birth is required
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <h5>Primary Information</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Education :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={education}
                            onChange={(e) => {
                              setEducation(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Qualification
                            </option>
                            {elist.map((data, i) => {
                              return (
                                <option value={data.education_uid} key={i}>
                                  {data.education}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !education && (
                            <span className="errortxt text-danger">
                              Education is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Occupation :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={occu}
                            onChange={(e) => {
                              setOccu(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Occupation
                            </option>
                            {olist.map((data, i) => {
                              return (
                                <option value={data.occupation_uid} key={i}>
                                  {data.occupation}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !occu && (
                            <span className="errortxt text-danger">
                              Occupation is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            House No / Street No :
                            <b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter House / Street Number"
                            value={house}
                            onChange={(e) => {
                              setHouse(e.target.value);
                            }}
                          />
                          {submit && !house && (
                            <span className="errortxt text-danger">
                              House number or street is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Pincode:<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Pincode"
                            maxLength={6}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={pincode}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                          />
                          {submit && !pincode && (
                            <span className="errortxt text-danger">
                              Pincode is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Country :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select value={country} onChange={Selectcountry}>
                            <option value="" disabled>
                              Select Your Country
                            </option>
                            {colist.map((data, i) => {
                              return (
                                <option value={data.country_uid} key={i}>
                                  {data.country_name}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !country && (
                            <span className="errortxt text-danger">
                              Country is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            State :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select value={state} onChange={Selectstate}>
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.state_uid} key={i}>
                                  {data.state_name}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !state && (
                            <span className="errortxt text-danger">
                              State is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            City :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your City
                            </option>
                            {clist.map((data, i) => {
                              return (
                                <option value={data.city_uid} key={i}>
                                  {data.city_name}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !city && (
                            <span className="errortxt text-danger">
                              City is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <div className="input-group">
                      <label htmlFor="username">
                        User Type :<b className="text-danger">*</b>
                      </label>
                      <select value={arttype} onChange={changetype}>
                        <option value="" disabled>
                          Select Your User Type
                        </option>

                        <option value="Artist">Artist</option>
                        <option value="Non Artist">Non Artist</option>
                      </select>
                      {submit && !arttype && (
                        <span className="errortxt text-danger">
                          Art type is required
                        </span>
                      )}
                    </div>
                  </div>
                  {arttype == "Non Artist" && (
                    <div className="col-md-12 col-lg-12">
                      <div className="input-group">
                        <label htmlFor="username">
                          Interesting Category :<b className="text-danger">*</b>
                        </label>

                        <Select
                          placeholder="Select Your Event Category"
                          value={interestcat}
                          options={intlist}
                          onChange={hangleInterest}
                          isMulti
                          isClearable
                        />
                        {submit && !interestcat && (
                          <span className="errortxt text-danger">
                            Category is required
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {arttype == "Artist" && (
                  <div className="row">
                    <div className="col-md-10 col-lg-10">
                      <div className="input-group">
                        <label htmlFor="username">
                          Arts 1 :<b className="text-danger">*</b>
                        </label>

                        <input
                          type="text"
                          placeholder="Enter Your Arts 1"
                          value={artadd1}
                          onChange={(e) => {
                            setArtadd1(e.target.value);
                          }}
                        />
                        {submit && !artadd1 && (
                          <span className="errortxt text-danger">
                            Art type is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-1 text-right">
                      <a
                        type="button"
                        className="btn btn-success btn-sm mr-2"
                        style={{
                          minWidth: "50px",
                          height: "30px",
                          marginTop: "35px",
                        }}
                        onClick={addmulti}
                      >
                        <i
                          className="fa fa-plus"
                          style={{ marginTop: "5px" }}
                        ></i>
                      </a>
                    </div>
                    <div className="col-md-1 text-right"></div>
                  </div>
                )}
                {arttype == "Artist" && artadd >= 2 && (
                  <div className="row">
                    <div className="col-md-10 col-lg-10">
                      <div className="input-group">
                        <label htmlFor="username">Arts 2 :</label>

                        <input
                          type="text"
                          placeholder="Enter Your Arts 2"
                          value={artadd2}
                          onChange={(e) => {
                            setArtadd2(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-1 text-right">
                      <a
                        type="button"
                        className="btn btn-success btn-sm mr-2"
                        style={{
                          minWidth: "50px",
                          height: "30px",
                          marginTop: "35px",
                        }}
                        onClick={addmulti}
                      >
                        <i
                          className="fa fa-plus"
                          style={{ marginTop: "5px" }}
                        ></i>
                      </a>
                    </div>
                    {artadd == 2 && (
                      <div className="col-md-1 text-right">
                        <a
                          type="button"
                          className="btn btn-danger btn-sm mr-2"
                          style={{
                            minWidth: "50px",
                            height: "30px",
                            marginTop: "35px",
                          }}
                          onClick={addremove}
                        >
                          <i
                            className="fa fa-remove"
                            style={{ marginTop: "5px" }}
                          ></i>
                        </a>
                      </div>
                    )}
                  </div>
                )}
                {arttype == "Artist" && artadd >= 3 && (
                  <div className="row">
                    <div className="col-md-10 col-lg-10">
                      <div className="input-group">
                        <label htmlFor="username">Arts 3 :</label>

                        <input
                          type="text"
                          placeholder="Enter Your Arts 3"
                          value={artadd3}
                          onChange={(e) => {
                            setArtadd3(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {artadd == 3 && (
                      <div className="col-md-1 text-right">
                        <a
                          type="button"
                          className="btn btn-danger btn-sm mr-2"
                          style={{
                            minWidth: "50px",
                            height: "30px",
                            marginTop: "35px",
                          }}
                          onClick={addremove}
                        >
                          <i
                            className="fa fa-remove"
                            style={{ marginTop: "5px" }}
                          ></i>
                        </a>
                      </div>
                    )}
                  </div>
                )}
                <hr />

                <h5>Additional Information</h5>
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="username">Portfolio :</label>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            rows={3}
                            placeholder="Enter Your Portfolio"
                            value={portfolio}
                            onChange={(e) => {
                              setPortfolio(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="username">Short Info :</label>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            rows={3}
                            placeholder="Enter Your Short Info"
                            value={shortinfo}
                            onChange={(e) => {
                              setShort(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="username">Introductory Brief :</label>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            rows={3}
                            placeholder="Enter Your Introductory Brief"
                            value={brief}
                            onChange={(e) => {
                              setBrief(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <h5>
                  File Uploads{" "}
                  <span>
                    ( Accepted Files are <b>.png, .jpg, .jpeg</b> & Max Upload
                    Size <b>3MB</b> )
                  </span>
                </h5>
                <div className="row">
                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-8">
                        <label htmlFor="dob">
                          1. Add Photograph : <b className="text-danger">*</b>
                        </label>

                        <div className="mt-4">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept=".png,.jpg,.jpeg"
                            onChange={photofile}
                          />
                        </div>
                      </div>

                      {formphotoupload !== "" && (
                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={formphotoupload}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                      {formphotoupload === "" && (
                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={require("../../../assets/images/placeholder.png")}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {formphotoerror !== "" && (
                      <span className="errortxt text-danger">
                        {formphotoerror}
                      </span>
                    )}
                  </div>

                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-8">
                        <label htmlFor="dob">
                          2. Add Identity Profile :{" "}
                          <b className="text-danger">*</b>
                        </label>
                        <div className="mt-4">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept=".png,.jpg,.jpeg"
                            onChange={idfile}
                          />
                        </div>
                      </div>

                      {formidupload !== "" && (
                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={formidupload}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                      {formidupload === "" && (
                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={require("../../../assets/images/placeholder.png")}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {formiderror !== "" && (
                      <span className="errortxt text-danger">
                        {formiderror}
                      </span>
                    )}
                    {errorMessage !== "" && (
                      <span className="errortxt text-danger">
                        {errorMessage}
                      </span>
                    )}
                  </div>
                </div>
                <hr />
                <h5>Payment Detail</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Order Id :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Order Id"
                            value={order}
                            onChange={(e) => {
                              setOrder(e.target.value);
                            }}
                          />
                          {submit && !order && (
                            <span className="errortxt text-danger">
                              Order Id is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Payment Id :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Payment Id"
                            value={payment}
                            onChange={(e) => {
                              setPayment(e.target.value);
                            }}
                          />
                          {submit && !payment && (
                            <span className="errortxt text-danger">
                              Payment Id is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Payment Signature :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Payment Signature"
                            value={sign}
                            onChange={(e) => {
                              setSign(e.target.value);
                            }}
                          />
                          {submit && !sign && (
                            <span className="errortxt text-danger">
                              Payment Signature is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Amount :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Amount"
                            value={amount}
                            onChange={(e) => {
                              setAmt(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {submit && !amount && (
                            <span className="errortxt text-danger">
                              Amount is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {add === true && (
                  <a
                    type="submit"
                    onClick={addsubmit}
                    className="btn btn-success"
                    style={{ float: "right" }}
                  >
                    Submit
                  </a>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Useradd;
