import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Header from "./dashboard/header";
import Footer from "./dashboard/footer";
import Switcher from "./dashboard/switcher/switcher";
import GuestSideBar from "./guestdashboard/sidebar/guestsidebar";
// import '../index.scss';

const GuestDashboardapp = () => {
  document.querySelector("body").classList.remove("error-1");
  document
    .querySelector("body")
    .classList.remove("app", "sidebar-mini", "landing-page", "horizontalmenu");
  document.querySelector("body").classList.add("main-body", "leftmenu");

  const remove = () => {
    document.querySelector(".sidebar-right").classList.remove("sidebar-open");
    document.querySelector("body").classList.remove("main-sidebar-show");
    document.querySelector(".demo_changer").classList.remove("active");
    document.querySelector(".demo_changer").style.right = "-270px";
  };

  return (
    <Fragment>
      <div className="horizontalMenucontainer">
        <Switcher />
        <div className="page">
          <Header />
          <GuestSideBar />
          <div className="main-content side-content">
            <div
              className="main-container container-fluid"
              onClick={remove}
            >
              <div className="inner-body">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};
export default GuestDashboardapp;
