import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { viewadmin, updateadmin } from "../../../services/admin";
import PhoneInput from "react-phone-input-2";
const Editadmin = () => {
  const navigate = useNavigate();
  const [step1submit, setStep1] = useState(false);
  const [adname, setName] = useState("");
  const [lname, setLame] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [status, setStatus] = useState(1);
  const [mobilecode, setMobcode] = useState("");
  const [emailerror, setEmailerror] = useState(null);
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }
  const [mobonly, setMobileonly] = useState("");
  const handle = (value, data, event, formattedValue) => {
    setMobcode(data.dialCode);
    setMobile(value);
    setMobileonly(value.slice(data.dialCode.length));
  };
  //validate email
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setEmailerror("Email is invalid");
    } else {
      setEmailerror(null);
    }
    setEmail(event.target.value);
  };
  function UpAdmin(e) {
    e.preventDefault();
    setStep1(true);
    setErrorMessage("");
    // setEmailerror("");

    // if (!validator.isEmail(email)) {
    //   setEmailerror("invalid email");
    // }
    // if (mobile.length < 12) {
    //   setErrorMessage("Mobile Number must be 10 digits");
    // } else {
    //   setErrorMessage("");
    // }

    const temp = {
      first_name: adname,
      email_id: email,
      is_active: status,
      updated_by: localStorage.getItem("Userid"),
      user_uid: id,
      last_name: lname,
      username: username,
      password: password,
      mobile: mobile,
    };
    if (
      adname !== "" &&
      lname !== "" &&
      password !== "" &&
      mobile.length === 12 &&
      username !== "" &&
      mobile !== "" &&
      email !== "" &&
      emailerror === null
    ) {
      updateadmin(temp).then((response) => {
        if (response.status === true) {
          navigate("/master/pgadmin");
        } else {
          setErrorMessage(response.data);
        }
      });
    }
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }
  useEffect(() => {
    viewadmin(id).then((response) => {
      if (response.status === true) {
        setName(response.data.first_name);
        setEmail(response.data.email);
        setStatus(response.data.is_active);
        setUsername(response.data.username);
        setPassword(response.data.refnonhash);
        setMobile(response.data.primary_mobile);
        setLame(response.data.last_name);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Edit Admin</h2>
        </div>
      </div>

      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="row">
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Admin name <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Your Admin Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={adname}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      {step1submit && !adname && (
                        <span className="errortxt text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Lastname <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Lastname"
                        value={lname}
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        onChange={(e) => {
                          setLame(e.target.value);
                        }}
                      />
                      {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Mobile <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <PhoneInput
                        specialLabel={""}
                        country={"in"}
                        placeholder="Enter Mobile Number"
                        countryCodeEditable={false}
                        value={mobile}
                        onChange={handle}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {step1submit && !mobile && (
                        <span className="errortxt text-danger">
                          Mobile is required
                        </span>
                      )}
                      {step1submit && mobile.length < 12 && (
                        <span className="errortxt text-danger">
                          Mobile Number must be 10 digits
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Email <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Email"
                        value={email}
                        maxLength={60}
                        onChange={handleChange}
                      />
                      {step1submit && !email && (
                        <span className="errortxt text-danger">
                          Email is required
                        </span>
                      )}
                      {emailerror !== "" && email && (
                        <span className="errortxt text-danger">
                          {emailerror}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Username <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Username"
                        value={username}
                        maxLength={10}
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                      />
                      {step1submit && !username && (
                        <span className="errortxt text-danger">
                          Username is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Password <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Password"
                        maxLength={8}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      {step1submit && !password && (
                        <span className="errortxt text-danger">
                          Password is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Status <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="radio"
                            name="status"
                            value="1"
                            checked={status == "1"}
                            onChange={Selectstatus}
                          />
                          &nbsp;&nbsp;
                          <label
                            className="custom-control-label-md"
                            style={{ paddingTop: "10px" }}
                          >
                            Active
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="radio"
                            name="status"
                            value="0"
                            checked={status == "0"}
                            onChange={Selectstatus}
                          />
                          &nbsp;&nbsp;
                          <label
                            className="custom-control-label-md"
                            style={{ paddingTop: "10px" }}
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    {errorMessage !== "" && (
                      <span className="errortxt text-danger text-left">
                        {errorMessage}
                      </span>
                    )}
                  </div>
                </div> */}
                <br />
                <br />

                <hr />
                <a
                  className="btn btn-prev btn-success"
                  style={{ float: "right" }}
                  onClick={UpAdmin}
                >
                  Update
                </a>
                <a
                  className="btn btn-next btn-secondary"
                  style={{ float: "right" }}
                  onClick={() => {
                    navigate("/master/pgadmin");
                  }}
                >
                  Cancel
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Editadmin;
