import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { paymentway } from "../../services/admin";

const PaymentWay = () => {
  const [amount1, setAmount1] = useState("");
  const [amount2, setAmount2] = useState("");
  const [amount3, setAmount3] = useState("");
  const [amount4, setAmount4] = useState("");

  function PaySubmit(amount, currency) {
    if (amount !== "") {
      paymentway(localStorage.getItem("Userid"), currency, amount).then(
        (response) => {
          paynow(
            response.order_details.amount,
            response.order_details.currency,
            response.order_details.id
          );
        }
      );
    } else {
      toast.error("Please Enter Amount");
    }
  }

  async function paynow(amt, currency, orderid) {
    const options = {
      // key: "rzp_test_XFnDXpEfL0bcLT",
      key: "rzp_live_EtyIA627A0V6mO",
      //   payment_amount: amt,
      payment_currency: currency,
      order_id: orderid,
      name: "Pythian Games",
      description: "Event Registeration",
      image: require("../../assets/images/logo/logonew.png"),
      handler: function (response) {},
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);
    paymentObj.open();
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">PAYMENT GATEWAY</h2>
        </div>
      </div>
      <div className="row">
        {/*  */}
        <div className="col-md-3">
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="form-inner">
                <h5>Payment in INR</h5>
                <h6>( Indian Rupess )</h6>
                <hr />
                <div className="input-group">
                  <label htmlFor="username">
                    {" "}
                    Amount : <b className="text-danger">*</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Amount"
                    value={amount1}
                    maxLength={10}
                    onChange={(e) => {
                      setAmount1(e.target.value);
                    }}
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                  />
                </div>
                <hr />
                <div className="text-center">
                  <a
                    className="btn btn-primary btn-next"
                    onClick={() => PaySubmit(amount1, "INR")}
                  >
                    Pay Now
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-3">
        <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div className="form-inner">
                  <h5>Payment in USD</h5>
                  <h6>( United States Dollar )</h6>
                  <hr />
                  <div className="input-group">
                    <label htmlFor="username">
                      {" "}
                      Amount : <b className="text-danger">*</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Amount"
                      value={amount2}
                      maxLength={10}
                      onChange={(e) => {
                        setAmount2(e.target.value);
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <hr />
                  <div className="text-center">
                    <a
                      className="btn btn-primary btn-next"
                      onClick={() => PaySubmit(amount2, "USD")}
                    >
                      Pay Now
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
        </div>
        <div className="col-md-3">
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="form-inner">
                <h5>Payment in AUD</h5>
                <h6>( Australia Dollar )</h6>
                <hr />
                <div className="input-group">
                  <label htmlFor="username">
                    {" "}
                    Amount : <b className="text-danger">*</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Amount"
                    value={amount3}
                    maxLength={10}
                    onChange={(e) => {
                      setAmount3(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <hr />
                <div className="text-center">
                  <a
                    className="btn btn-primary btn-next"
                    onClick={() => PaySubmit(amount3, "AUD")}
                  >
                    Pay Now
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-3">
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="form-inner">
                <h5>Payment in RUB</h5>
                <h6>( Russian Ruble )</h6>
                <hr />
                <div className="input-group">
                  <label htmlFor="username">
                    {" "}
                    Amount : <b className="text-danger">*</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Amount"
                    value={amount4}
                    maxLength={10}
                    onChange={(e) => {
                      setAmount4(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <hr />
                <div className="text-center">
                  <a
                    className="btn btn-primary btn-next"
                    onClick={() => PaySubmit(amount4, "RUB")}
                  >
                    Pay Now
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentWay;
