import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import baseUrl from "../Api";

export async function countlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/user-counts`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//jury count
export async function jurycountlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/jury-counts`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//del event entry
export async function delEntry(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-entry-delete/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//delete user
export async function deluser(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/user-delete/${data}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
// delete guest
export async function delguest(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/guest-delete/${data}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//delete jury
export async function deljury(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/jury-delete/${data}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function userdatalist(role, status) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/user-list/${role}/${status}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//master role list
export async function masterrolelist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/rolelist`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//pg admin list
export async function pgadminlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/pgadminlist`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//viewadmin
export async function viewadmin(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/pgadmindetails/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//update admin
export async function updateadmin(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/editpgadmin`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//add pgadmin
export async function addpgadmin(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/addpgadmin`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function userdataview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/user-details/${data}`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function reasonlist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/blocked-reasons`, { headers: header })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function approveuser(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/user-approval`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function edituserupdate(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/user-update`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function editimageupdate(data) {
  try {
    return await axios
      .post(`${baseUrl}/superadmin/user-proof-update`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function categorylist() {
  try {
    return await axios
      .get(`${baseUrl}/get-allcategory`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function eventcategorylist() {
  try {
    return await axios
      .get(`${baseUrl}/get-category`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function addcategory(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/create-category`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function viewcategory(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-categorydetail/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function updatecategory(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/update-category`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//all events
export async function admineventlist() {
  try {
    return await axios
      .get(`${baseUrl}/get-all-events`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//upcoming event list
export async function upcomingeventlist() {
  try {
    return await axios
      .get(`${baseUrl}/get-upcoming-events`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//upcoming entry event list
export async function upcomingentryeventlist() {
  try {
    return await axios
      .get(`${baseUrl}/get-upcoming-entry-events`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//event entry add
export async function evententryadd(data) {
  try {
    return await axios
      .post(`${baseUrl}/event-entry`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
// get guest details after guest register

//sending mail after guest registration
export async function sendguestmail(data) {
  try {
    return await axios
      .post(`${baseUrl}/send-guest-event-mail`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Already exists mail
export async function alreadyexistsmail(data) {
  try {
    return await axios
      .get(`${baseUrl}/check-guestid-exists/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Already exists mail user
export async function alreadyexistsusermail(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/user/check-userid-exists/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function addeventdata(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/create-event`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function updateeventdata(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/update-event`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//guest user edit
export async function guestedit(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/guest-update`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function viewevent(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-event-detail/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//get guest details
export async function getdetails(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-guest-details/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function categorywisejurylist(id, cid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/jury-event-category-entry/${id}/${cid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//get guest details by passing hash id
export async function getguesthashdetails(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-guest-details-by-hash?guest_id_hash=${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function evententrieslist(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-entry-list/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Count based on Category,Jury and event wise
export async function jurycountbasedoncategory(eid, cid, jid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(
        `${baseUrl}/superadmin/jury-event-assign-count/${eid}/${cid}/${jid}`,
        {
          headers: header,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function juryevententrycount(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/jury-event-entry/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function evententrylist(J_id, data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/jury/jury-event-files/${J_id}/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function evententryview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-entry-files/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//remove jury form list
export async function removeassignjury(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/remove-assign-jury/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//event jurylist
export async function EventJurylist(id) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/jury-intereted-list/${id}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function eventwiseentryview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-entry-details/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function categorydetailmail(data, data1) {
  try {
    return await axios
      .get(`${baseUrl}/entry-file-type/${data}/${data1}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function guestuserdatalist(role, status) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/guest-user-list/${role}/${status}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//guest list with file
export async function guestlistwithfile() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/guest-list-withfile`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//guest list with file
export async function guestlistwithoutfile() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/guest-list-withoutfile`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//result wothout mark
export async function resultwithoutmark(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-files-withoutmark/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//result with mark
export async function resultwithmark(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-files-withmark/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//listing event category
export async function listeventcategory(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-category/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Export for result tables
export async function ExportResultData(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/event-result`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//Jury add or remove
export async function juryaddandremove(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/add-jury-to-entries`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Total jury per entry
export async function totaljuryforentry(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/jury-assign-summary/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Total partcipants and Total jury counts
export async function totaljuryandcategory(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-category-details/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Total partcipants , juries , languages and categories
export async function totalsummarylist(data) {
  try {
    return await axios
      .get(`${baseUrl}/event-summary/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Jury selection by category and language
export async function selectedjury(eid, cid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/get-jury-language-category/${eid}/${cid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//listing event category stored in db by event wi
export async function categorylistsaved(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/added-category-in-event/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//listing jury stored in db by event wise with category
export async function categorywisejuryadded(eid, cid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/added-jury-in-event/${eid}/${cid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//listing jury entries for assigned and non-assigned
export async function juryassandnonass(eid, cid, jid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-entries-jury/${eid}/${cid}/${jid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//listing event categorywise mark in superadmin
export async function listeventcategorymark(data, data1) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-files-categorymark/${data}/${data1}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function guestuserdataview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/guest-user-details/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function guestmembereventview(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-entry-details/${data}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function paymentlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/payment-history`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function paymentway(uid, cry, amt) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/test_payment_order/${uid}/${cry}/${amt}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

// Age category multi select
export async function agecategorylist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/get-agecategory`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Quiz Event Register
export async function addquizevent(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/create-quiz-event`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Donar list
export async function donaralllist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/get-all-doners`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Round Submit API
export async function addround(data) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .post(`${baseUrl}/superadmin/create-round`, data, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Round selection API
export async function roundselection(eid, cid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/get-event-category-round/${eid}/${cid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//festival event list
export async function festeventlist() {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/get-festival-list`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Category based round marks
export async function CateRoundMarks(eid, cid, rid) {
  try {
    const header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };
    return await axios
      .get(`${baseUrl}/superadmin/event-entry-roundlist/${eid}/${cid}/${rid}`, {
        headers: header,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

function Catcherror(error) {
  // console.log(error.response);
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/Pythian/#/login");
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    // toast.error(error.response.statusText);
    console.log("Error", error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    console.log("Error", error.message);
  }
}
