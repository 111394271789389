import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { updatecategory, viewcategory } from "../../../services/admin";

const Editcategory = () => {
  const navigate = useNavigate();
  const [catname, setName] = useState("");
  const [catcode, setCode] = useState("");
  const [status, setStatus] = useState(1);

  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  function addcate() {
    const temp = {
      user_uid: localStorage.getItem("Userid"),
      category_name: catname,
      category_code: catcode,
      is_active: status,
      category_uid: id,
    };
    if (catname !== "") {
      if (catcode !== "") {
        updatecategory(temp).then((response) => {
          if (response.status === true) {
            navigate("/event/categorylist");
          }
        });
      } else {
        setErrorMessage("Please Enter Your Category Code");
      }
    } else {
      setErrorMessage("Please Enter Your Category Name");
    }
  }

  function Selectstatus(e) {
    setStatus(e.target.value);
  }
  useEffect(() => {
    viewcategory(id).then((response) => {
      if (response.status === true) {
        setName(response.data.category);
        setCode(response.data.category_code);
        setStatus(response.data.is_active);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Edit Category</h2>
        </div>
      </div>

      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="row">
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Category Name <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Your Category Name"
                        value={catname}
                        maxLength={15}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Category Code <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Your Category Code"
                        value={catcode}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Category Status <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="radio"
                            name="status"
                            value="1"
                            checked={status == "1"}
                            onChange={Selectstatus}
                          />
                          &nbsp;&nbsp;
                          <label
                            className="custom-control-label-md"
                            style={{ paddingTop: "10px" }}
                          >
                            Active
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="radio"
                            name="status"
                            value="0"
                            checked={status == "0"}
                            onChange={Selectstatus}
                          />
                          &nbsp;&nbsp;
                          <label
                            className="custom-control-label-md"
                            style={{ paddingTop: "10px" }}
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    {errorMessage !== "" && (
                      <span className="errortxt text-danger text-left">
                        {errorMessage}
                      </span>
                    )}
                  </div>
                </div>
                <br />
                <br />

                <hr />
                <a
                  className="btn btn-prev btn-success"
                  style={{ float: "right" }}
                  onClick={addcate}
                >
                  Update
                </a>
                <a
                  className="btn btn-next btn-secondary"
                  style={{ float: "right" }}
                  onClick={() => {
                    navigate("/event/categorylist");
                  }}
                >
                  Cancel
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Editcategory;
