import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";

export const AddQuizEvent = () => {
  // State to store all questions
  const [questions, setQuestions] = useState([
    {
      question: "",
      options: ["", "", "", ""],
      selectedAnswer: -1,
    },
  ]);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 130,
      // field: "event_uid",
      filter: false,

      // cellRenderer: function (params) {
      //   return (
      //     <Link to={"/eventmark/" + params.value} className="btn-view">
      //       View
      //     </Link>
      //   );
      // },
    },
    {
      headerName: "Event Name",
      width: 330,
      // field: "event_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event Venue",
      width: 170,
      // field: "event_venue",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Start Date",
      width: 150,
      // field: "event_start",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: (data) => {
      //   return moment(data.value).format("DD-MM-YYYY");
      // },
    },
    {
      headerName: "End Date",
      width: 150,
      // field: "event_end",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: (data) => {
      //   return moment(data.value).format("DD-MM-YYYY");
      // },
    },

    {
      headerName: "Result Declare Date",
      width: 220,
      // field: "event_entry_close",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      // cellRenderer: (data) => {
      //   return moment(data.value).format("DD-MM-YYYY");
      // },
    },
  ];

  // Event handler to update the question state
  const handleQuestionChange = (e, index) => {
    const newQuestions = [...questions];
    newQuestions[index].question = e.target.value;
    setQuestions(newQuestions);
  };

  // Event handler to update the options state
  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = e.target.value;
    setQuestions(newQuestions);
  };

  // Event handler to handle radio button selection
  const handleRadioChange = (questionIndex, index) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].selectedAnswer = index;
    setQuestions(newQuestions);
  };

  // Event handler to add a new question
  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        question: "",
        options: ["", "", "", ""],
        selectedAnswer: -1,
      },
    ]);
  };

  // Event handler to delete an option
  const handleDeleteOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    // newQuestions[questionIndex].options.splice(optionIndex, 1);
    // setQuestions(newQuestions);
    if (newQuestions[questionIndex].options.length > 2) {
      newQuestions[questionIndex].options.splice(optionIndex, 1);
      setQuestions(newQuestions);
    }
  };

  // Event handler to delete a question
  const handleDeleteQuestion = (questionIndex) => {
    const newQuestions = [...questions];
    // newQuestions.splice(questionIndex, 1);
    // setQuestions(newQuestions);
    if (newQuestions.length > 1) {
      newQuestions.splice(questionIndex, 1);
      setQuestions(newQuestions);
    }
  };

  //Event handler to add an option
  const handleAddOption = (questionIndex) => {
    const newQuestions = [...questions];
    if (newQuestions[questionIndex].options.length < 3) {
      newQuestions[questionIndex].options.push("", "");
      setQuestions(newQuestions);
    }
  };

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">QUIZ COMPETITION</h2>
        </div>
      </div>

      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="row">
                  <div className="col-lg-12">
                    {questions.map((questionData, questionIndex) => (
                      <div key={questionIndex}>
                        <br />
                        <h5>Question {questionIndex + 1}</h5>
                        <div className="col-lg-12">
                          <input
                            type="text"
                            placeholder={`Question ${questionIndex + 1}`}
                            value={questionData.question}
                            onChange={(e) =>
                              handleQuestionChange(e, questionIndex)
                            }
                          />
                        </div>
                        <br />
                        <h5>Options</h5>
                        {questionData.options.map((option, optionIndex) => (
                          <div key={optionIndex} style={{ display: "flex" }}>
                            <div className="col-lg-2">
                              <input
                                type="radio"
                                name={`answer-${questionIndex}`}
                                checked={
                                  questionData.selectedAnswer === optionIndex
                                }
                                onChange={() =>
                                  handleRadioChange(questionIndex, optionIndex)
                                }
                              />
                            </div>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                placeholder={`Option ${optionIndex + 1}`}
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(
                                    e,
                                    questionIndex,
                                    optionIndex
                                  )
                                }
                              />
                            </div>
                            {questionData.options.length < 3 && (
                              <a
                                style={{ marginBottom: 12 }}
                                className="btn btn-prev btn-success col-lg-2"
                                onClick={() => handleAddOption(questionIndex)}
                              >
                                Add Option
                              </a>
                            )}
                            {questionData.options.length > 2 && (
                              <a
                                className="btn btn-prev btn-danger col-lg-2"
                                style={{ marginBottom: 12 }}
                                onClick={() =>
                                  handleDeleteOption(questionIndex, optionIndex)
                                }
                              >
                                Delete Option
                              </a>
                            )}
                          </div>
                        ))}

                        <br />

                        <a
                          className="btn btn-prev btn-success"
                          onClick={() => handleAddQuestion()}
                        >
                          Add Question
                        </a>
                        {questions.length > 1 && (
                          <a
                            className="btn btn-prev btn-danger"
                            onClick={() => handleDeleteQuestion(questionIndex)}
                          >
                            Delete Question
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <br />
                <hr />
                <div className="row">
                  <a
                    className="btn btn-prev btn-success pull-left"
                    // style={{ float: "right" }}
                    //   onClick={addcate}
                  >
                    Submit
                  </a>
                  <a
                    className="btn btn-next btn-secondary pull-right"
                    // style={{ float: "right" }}
                    //   onClick={() => {
                    //     navigate("/eventlist");
                    //   }}
                  >
                    Cancel
                  </a>
                </div>

                <hr />

                <div className="App">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600, width: "100%" }}
                  >
                    <AgGridReact
                      // ref={gridRef}
                      columnDefs={columnDefs}
                      suppressDragLeaveHidesColumns={true}
                      // rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};


// for single quiz questions submit 

// export const AddQuizEvent = () => {
//   const [question, setQuestion] = useState("");
//   const [options, setOptions] = useState(["", "", "", ""]);
//   const [selectedAnswer, setSelectedAnswer] = useState(-1);

//   const handleOptionChange = (e, index) => {
//     const newOptions = [...options];
//     newOptions[index] = e.target.value;
//     setOptions(newOptions);
//   };

//   const handleRadioChange = (index) => {
//     setSelectedAnswer(index);
//   };

//   const handleAddOption = () => {
//     if (options.length < 4) {
//       setOptions([...options, ""]);
//     }
//   };

//   const handleDeleteOption = (index) => {
//     if (options.length > 2) {
//       const newOptions = [...options];
//       newOptions.splice(index, 1);
//       setOptions(newOptions);
//     }
//   };

//   const handleSubmit = () => {
//     // Validate that there is at least one option selected
//     if (selectedAnswer === -1) {
//       alert("Please select an answer.");
//       return;
//     }

//     // Prepare data to send to the backend
//     const questionData = {
//       question,
//       options,
//       selectedAnswer,
//     };

//     // Send 'questionData' to your backend API here

//     // Reset the form
//     setQuestion("");
//     setOptions(["", "", "", ""]);
//     setSelectedAnswer(-1);
//   };

//   return (
//     <div>
//       <h2>QUIZ COMPETITION</h2>
//       <div>
//         <h3>Question</h3>
//         <input
//           type="text"
//           placeholder="Enter your question"
//           value={question}
//           onChange={(e) => setQuestion(e.target.value)}
//         />
//       </div>
//       <div>
//         <h3>Options</h3>
//         {options.map((option, index) => (
//           <div key={index}>
//             <input
//               type="radio"
//               name="answer"
//               checked={selectedAnswer === index}
//               onChange={() => handleRadioChange(index)}
//             />
//             <input
//               type="text"
//               placeholder={`Option ${index + 1}`}
//               value={option}
//               onChange={(e) => handleOptionChange(e, index)}
//             />
//             {options.length > 2 && (
//               <button onClick={() => handleDeleteOption(index)}>Delete</button>
//             )}
//           </div>
//         ))}
//         {options.length < 4 && (
//           <button onClick={handleAddOption}>Add Option</button>
//         )}
//       </div>
//       <button onClick={handleSubmit}>Submit</button>
//     </div>
//   );
// };

