import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { masterrolelist } from "../../../services/admin";
import Categorystatus from "../event/categorystatus";

import "../users/users.css";

const MasterRole = () => {
  const gridRef = useRef();

  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Role Name",
      width: 300,
      field: "role_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Role Code",
      width: 150,
      field: "role_code",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Role ID",
      width: 150,
      field: "role_uid",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Role Display Name",
      width: 250,
      field: "role_display_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 120,
      field: "is_active",
      // sortable: true,
      // filter: true,
      // floatingFilter: true,
      cellRenderer: Categorystatus,
    },
  ];

  useEffect(() => {
    masterrolelist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">ROLE MASTER</h2>
        </div>
      </div>
      <Row className="row-sm row-left">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden overflow-short">
            <Card.Body>
              <div className="responsive">
                <div className="App">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600, width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      suppressDragLeaveHidesColumns={true}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MasterRole;
