import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-data-table-component-extensions/dist/index.css";
import { pgadminlist, addpgadmin } from "../../../services/admin";
import Usericon from "../users/usericon";
import Categorystatus from "../event/categorystatus";
import "../users/users.css";
import { Link } from "react-router-dom";
import { deluser } from "../../../services/admin";
import Swal from "sweetalert2";
const EditNumber = (props) => {
  return (
    <Link to={"/editadmin/" + props.value} className="btn-view">
      Edit
    </Link>
  );
};

const MasterPgadmin = () => {
  const gridRef = useRef();

  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Action",
      width: 200,
      field: "user_uid",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="row">
            <div className="col-md-5 col-right">
              <Link to={"/editadmin/" + params.value} className="btn-view">
                Edit
              </Link>
            </div>
            <div className="col-md-5 col-left">
              <Link to={"/master/pgadmin/"} className="btn-delete">
                <span onClick={(e) => delUser(e, params.value)}>Delete</span>
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Firstname",
      width: 150,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Lastname",
      width: 150,
      field: "last_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username",
      width: 150,
      field: "username",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      width: 200,
      field: "email",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Password",
      width: 200,
      field: "refnonhash",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile",
      width: 200,
      field: "primary_mobile",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 100,
      field: "is_active",
      filter: true,
      cellRenderer: Categorystatus,
    },
  ];
  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
    setName("");
    setLame("");
    setEmail("");
    setUsername("");
    setPassword("");
    setMobile("");
    window.location.reload();
  }

  //const navigate = useNavigate();
  const [step1submit, setStep1] = useState(false);
  const [adname, setName] = useState("");
  const [lname, setLame] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobilecode, setMobcode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailerror] = useState(null);
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }
  const [mobonly, setMobileonly] = useState("");
  const handle = (value, data) => {
    setMobcode(data.dialCode);
    setMobile(value);
    setMobileonly(value.slice(data.dialCode.length));
  };
  //validate email
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setEmailerror("Email is invalid");
    } else {
      setEmailerror(null);
    }
    setEmail(event.target.value);
  };
  function addAdmin(e) {
    e.preventDefault();
    setStep1(true);
    setErrorMessage("");

    if (mobile.length < 12) {
      setErrorMessage("Mobile Number must be 10 digits");
    } else {
      setErrorMessage("");
    }

    const temp = {
      register_by: localStorage.getItem("Userid"),
      email_id: email,
      mobile: mobile,
      username: username,
      password: password,
      first_name: adname,
      last_name: lname,
    };

    if (
      adname !== "" &&
      lname !== "" &&
      password !== "" &&
      mobile.length === 12 &&
      emailerror === null &&
      username !== "" &&
      mobile !== "" &&
      email !== ""
    ) {
      addpgadmin(temp).then((response) => {
        if (response.status === true) {
          setShow(false);
          //window.location.reload();
          userreload();
        } else {
          setErrorMessage(response.data);
        }
      });
    }
  }
  function delUser(e, uid) {
    e.preventDefault();
    Swal.fire({
      title: `Are you sure want to Delete this admin?`,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deluser(uid).then((response) => {
          if (response.status === true) {
            userreload();
          }
        });
      }
    });
  }
  useEffect(() => {
    userreload();
  }, []);
  function userreload() {
    pgadminlist().then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }

  //   const onBtnExport = useCallback(() => {
  //     gridRef.current.api.exportDataAsCsv();
  //   }, []);

  //   const onBtnUpdate = useCallback(() => {
  //     document.querySelector(
  //       '#csvResult'
  //     ).value = gridRef.current.api.getDataAsCsv();
  //   }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">PG ADMIN</h2>
        </div>
        <div className="res-right abs">
          <a className="btn-exp" onClick={handleShow}>
            Add Admin
          </a>
        </div>
      </div>
      <Row className="row-sm row-left">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden overflow-short">
            <Card.Body>
              {/* <div className="res-right abs">
                <a className="btn-exp" onClick={handleShow}>
                  Add Adimn
                </a>
              </div> */}
              <div className="responsive rel">
                <div className="App">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600, width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      suppressDragLeaveHidesColumns={true}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header>
                <h4>Add admin </h4>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Firstname <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Firstname"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={adname}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      {step1submit && !adname && (
                        <span className="errortxt text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Lastname <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Lastname"
                        value={lname}
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        onChange={(e) => {
                          setLame(e.target.value);
                        }}
                      />

                      {step1submit && !lname && (
                        <span className="errortxt text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Mobile <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <PhoneInput
                        specialLabel={""}
                        country={"in"}
                        placeholder="Enter Mobile Number"
                        countryCodeEditable={false}
                        value={mobile}
                        onChange={handle}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {step1submit && !mobile && (
                        <span className="errortxt text-danger">
                          Mobile is required
                        </span>
                      )}
                      {step1submit && mobile.length < 12 && (
                        <span className="errortxt text-danger">
                          Mobile Number must be 10 digits
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Email <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Email"
                        value={email}
                        onChange={handleChange}
                      />
                      {step1submit && !email && (
                        <span className="errortxt text-danger">
                          Email is required
                        </span>
                      )}
                      {emailerror !== "" && email && (
                        <span className="errortxt text-danger">
                          {emailerror}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Username <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter Your Username"
                        value={username}
                        maxLength={10}
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                      />
                      {step1submit && !username && (
                        <span className="errortxt text-danger">
                          Username is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <label htmlFor="username">
                        Password <b className="text-danger">*</b>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        placeholder="Enter password"
                        maxLength={8}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      {step1submit && !password && (
                        <span className="errortxt text-danger">
                          Password is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* {errorMessage !== "" && (
                  <span className="errortxt text-danger">{errorMessage}</span>
                )} */}
              </Modal.Body>
              <Modal.Footer>
                <a
                  className="btn btn-prev btn-primary"
                  style={{ float: "right" }}
                  onClick={addAdmin}
                >
                  Submit
                </a>
                <a
                  className="btn btn-next btn-secondary"
                  style={{ float: "right" }}
                  onClick={handleClose}
                >
                  Cancel
                </a>
              </Modal.Footer>
            </Modal>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MasterPgadmin;
