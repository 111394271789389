import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { countlist, jurycountlist } from "../../services/admin";
import "./dashboard.scss";
function Dashboard() {
  const [applieduser, setApplieduser] = useState("");
  const [approveduser, setApproveduser] = useState("");
  const [pendinguser, setPendinguser] = useState("");
  const [rejecteduser, setRejecteduser] = useState("");
  const [appliedguest, setAppliedguest] = useState("");
  const [appliedjury, setAppliedjury] = useState("");
  const [approvedjury, setApprovedjury] = useState("");
  const [pendingjury, setPendingjury] = useState("");
  const [rejectedjury, setRejectedjury] = useState("");

  useEffect(() => {
    countlist().then((response) => {
      setApplieduser(response.data.appliedusers);
      setApproveduser(response.data.approvedusers);
      setPendinguser(response.data.pendingusers);
      setRejecteduser(response.data.rejectedusers);
      setAppliedguest(response.data.appliedguests);
    });
    jurycountlist().then((response) => {
      setAppliedjury(response.data.appliedjury);
      setApprovedjury(response.data.approvedjury);
      setPendingjury(response.data.pendingjury);
      setRejectedjury(response.data.rejectedjury);
    });
  }, []);
  return (
    <Fragment>
      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            WELCOME TO PYTHIAN GAMES
          </h2>
        </div>
      </div>
      {/* <!-- End Page Header --> */}

      {/* <!--Row--> */}
      <Row className="row-sm">
        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className=" custom-card overflow-hidden bg-none shadow-none">
            <Card.Header className="border-bottom-0 py-2">
              <label className="main-content-label my-auto text-primary">
                Users Lists
              </label>
            </Card.Header>
            <ul className="crypto-transcation list-unstyled mg-b-0 px-0">
              <li className="list-item mt-1 mb-0 px-3 mt-0">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Applied Users</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {applieduser}
                  </h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-0 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Approved Users</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {approveduser}
                  </h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-0 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Pending Users</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {pendinguser}
                  </h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-3 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Removed Users</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {rejecteduser}
                  </h5>
                </div>
              </li>
            </ul>
          </Card>
        </Col>

        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className=" custom-card overflow-hidden bg-none shadow-none">
            <Card.Header className=" border-bottom-0 py-2">
              <label className="main-content-label my-auto text-primary">
                Guest User Lists
              </label>
            </Card.Header>
            <ul className="crypto-transcation list-unstyled mg-b-0 px-0">
              <li className="list-item mt-1 mb-0 px-3 mt-0">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Applied Guest User</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {appliedguest}
                  </h5>
                </div>
              </li>
              {/* <li className="list-item mt-1 mb-0 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-16">Approved Officials</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-22 mb-0">03485</h5>
                                </div>
                            </li>
                            <li className="list-item mt-1 mb-0 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-16">Pending Officials</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-22 mb-0">03485</h5>
                                </div>
                            </li>
                            <li className="list-item mt-1 mb-3 px-3">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <p className="tx-medium mg-b-3 tx-16">Rejected Officials</p>
                                    </div>
                                </div>
                                <div className="text-end ms-auto my-auto">
                                    <h5 className="font-weight-semibold tx-22 mb-0">03485</h5>  
                                </div>
                            </li> */}
            </ul>
          </Card>
        </Col>

        <Col sm={12} md={4} lg={4} xl={4}>
          <Card className=" custom-card overflow-hidden bg-none shadow-none">
            <Card.Header className=" border-bottom-0 py-2">
              <label className="main-content-label my-auto text-primary">
                Jury Lists
              </label>
            </Card.Header>
            <ul className="crypto-transcation list-unstyled mg-b-0 px-0">
              <li className="list-item mt-1 mb-0 px-3 mt-0">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Applied Jury</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {appliedjury}
                  </h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-0 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Approved Jury</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {approvedjury}
                  </h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-0 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Pending Jury</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {pendingjury}
                  </h5>
                </div>
              </li>
              <li className="list-item mt-1 mb-3 px-3">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="tx-medium mg-b-3 tx-16">Rejected Jury</p>
                  </div>
                </div>
                <div className="text-end ms-auto my-auto">
                  <h5 className="font-weight-semibold tx-22 mb-0">
                    {rejectedjury}
                  </h5>
                </div>
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
      {/* <!-- Row end --> */}
    </Fragment>
  );
}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
