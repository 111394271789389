import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import {
  Card,
  Col,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
  Accordion,
  Button,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { render } from "react-dom";
import {
  evententrieslist,
  delEntry,
  listeventcategory,
  EventJurylist,
  viewevent,
  removeassignjury,
  juryevententrycount,
  categorywisejurylist,
  jurycountbasedoncategory,
  categorylistsaved,
  categorywisejuryadded,
  juryassandnonass,
  juryaddandremove,
  totaljuryforentry,
  totaljuryandcategory,
  totalsummarylist,
  selectedjury,
  addround,
  roundselection,
  CateRoundMarks,
  CateRoundMarksExport,
} from "../../../services/admin";
import Swal from "sweetalert2";
import "./list.css";
import imageUrl from "../../../ImageUrl";
import Select from "react-select";
import {
  addjurytoevent,
  // addselectedjury,
  assignedjurylist,
  deleteEntries,
  deletejuryevent,
  notassignedjurylist,
} from "../../../services/jury";
import { toast } from "react-toastify";
import { juryslanguage } from "../../../services/register";
import { ColumnGroup } from "ag-grid-community";

const Eventwiselist = () => {
  const { gridRef, ctyref, gridRefNotAllot, gridRefAllot } = useRef();
  const { id } = useParams();
  const navigate = useNavigate();

  const [filterProduct, setFilterProduct] = useState([]);
  const [assfilterProduct, setAssFilterProduct] = useState([]);
  const [totaljuryProduct, setTotaljuryProduct] = useState([]);
  const [totalsummaryProduct, setTotalSummaryProduct] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [markfilterProduct, setMarkfilterProduct] = useState([]);
  const [nomarkfilterProduct, setNomarkfilterProduct] = useState([]);

  const [notassign, setNotassign] = useState("");
  const [assigned, setAssigned] = useState("");
  const [interestlang, setLanguage] = useState("");
  const [langlist, setLanglist] = useState([]);
  const [sall, setSAll] = useState(false);
  const [usall, setUSAll] = useState(false);
  const [sassall, setSAssAll] = useState(false);
  const [usassall, setUSAssAll] = useState(false);

  const [name, setEventName] = useState("");
  const [venue, setEventVenue] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [catcount, setCatCount] = useState([]);
  const [clistsaved, setCListSaved] = useState([]);
  const [catsaved, setCatSaved] = useState(0);
  const [jlistsaved, setJListSaved] = useState([]);
  const [jurysaved, setJurySaved] = useState(0);
  const [eventData, setEventData] = useState([]);
  const [juryData, setjuryData] = useState([]);
  const [count, setCount] = useState("");
  const [catadd, setCatadd] = useState("");
  const [rcatadd, setRCatadd] = useState("");
  const [catlist, setCatlist] = useState([]);
  const [ejadd, setEjadd] = useState("");
  const [addjlist, setJlist] = useState([]);
  const [uadd, setUadd] = useState([]);
  const [ulist, setUlist] = useState([]);
  const [show, setShow] = useState(false);
  const [roundshow, setRoundShow] = useState(false);
  const [round, setRound] = useState("");
  const [rndmark, setRndmark] = useState("");
  const [rprevscore, setPrevScore] = useState("");
  const [around, setAround] = useState("");
  const [rlist, setRlist] = useState([]);
  const [proundlist, setProundlist] = useState([]);
  const [pround, setPround] = useState("");
  const [juryass, setAssJury] = useState("");
  const [jurypen, setPenJury] = useState("");
  const [juryshow, setJuryShow] = useState(false);
  const [ushow, setUShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [catevalue, setCateValue] = useState("");
  const [roundvalue, setRoundValue] = useState("");

  const countrydefs = [
    {
      headerName: "S No",
      field: "serialNumber",
      cellStyle: { textAlign: "left" },
      valueGetter: function (params) {
        return params.node.rowIndex + 1;
      },
    },
    {
      headerName: "Country",
      field: "name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Painting",
      field: "cate_1",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Poem",
      field: "cate_2",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const summarydefs = [
    {
      headerName: "S No",
      field: "serialNumber",
      cellStyle: { textAlign: "left" },
      valueGetter: function (params) {
        return params.node.rowIndex + 1;
      },
    },
    {
      headerName: "Language",
      field: "name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Painting Jury",
      field: "jury_1",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Painting",
      field: "cate_1",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Poem Jury",
      field: "jury_2",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Poem",
      field: "cate_2",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const WithoutMark = [
    {
      headerName: "User Name",
      // width: 250,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (params) => {
        if (params.data.last_name !== null) {
          return params.data.first_name + " " + params.data.last_name;
        } else {
          return params.data.first_name;
        }
      },
    },
    {
      headerName: "Category",
      width: 250,
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Jury Name",
      width: 250,
      field: "jury_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Round",
      width: 250,
      field: "round_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Score",
      width: 150,
      field: "score",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const WithMark = [
    {
      headerName: "Name",
      width: 250,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 250,
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Jury Name",
      width: 250,
      field: "jury_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Round",
      width: 150,
      field: "round_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Total Score",
      width: 150,
      field: "total_score",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Average Score",
      width: 150,
      field: "average_score",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnDefs = [
    // {
    //   headerName: "Actions",
    //   width: 100,
    //   field: "entry_uid",
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: (params) => {
    //     return (
    //       <div className="text-center">
    //
    //         {/* <a
    //           onClick={(e) => deletejury(e, data.value)}
    //           className="btn-delete"
    //         >
    //           Delete
    //         </a> */}
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Actions",
      field: "entry_uid",
      width: 200,
      resizable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div className="text-left">
            {/* <input
              type="checkbox"
              checked={params.data.chkbox}
              onChange={(e) => checkentry(e, params.value)}
            /> */}
            {/* <a
              onClick={() => deleteEntry(params.value)}
              className="btn-sm btn-danger me-2"
            >
              <i className="fa fa-trash fa-lg"></i>
            </a> */}
            <Link
              className="btn-sm btn-info me-2"
              to={"/guestmembereventview/" + params.value}
            >
              <i className="fa fa-eye fa-lg"></i>
            </Link>
          </div>
        );
      },
    },
    // {
    //   headerName: "Jury Photo",
    //   field: "photo",
    //   width: 150,
    //   filter: false,
    //   cellRenderer: function (params) {
    //     return (
    //       <img
    //         style={{ width: "40px", height: "40px" }}
    //         src={imageUrl + "jury/photo/" + params.data.photo}
    //         onError={(e) => errorhandle(e)}
    //       />
    //     );
    //   },
    // },
    // {
    //   headerName: "Jury Name",
    //   field: "first_name",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "User Name",
      field: "user_name",
      width: 250,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      field: "category",
      width: 250,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Usertype",
      field: "usertype",
      width: 250,
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnAssDefs = [
    // {
    //   headerName: "Actions",
    //   width: 100,
    //   field: "entry_uid",
    //   cellStyle: { textAlign: "left" },
    //   cellRenderer: (data) => {
    //     return (
    //       <div className="text-center">
    //         <input
    //           type="checkbox"
    //           checked={data.data.chkbox}
    //           onChange={(e) => checkassentry(e, data.value)}
    //         />
    //         {/* <a
    //           onClick={(e) => deletejury(e, data.value)}
    //           className="btn-delete"
    //         >
    //           Delete
    //         </a> */}
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Jury Photo",
      field: "photo",
      filter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            src={imageUrl + "jury/photo/" + params.data.photo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },

    {
      headerName: "Jury Name",
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "User Name",
      field: "user_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Usertype",
      field: "usertype",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const totaldefs = [
    {
      headerName: "Jury Photo",
      field: "photo",
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            src={imageUrl + "jury/photo/" + params.data.photo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },

    {
      headerName: "Jury Name",
      field: "jury_firstname",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Assigned Entries",
      field: "total",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Actions",
    //   field: "jury_uid",
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
    //   filter: false,
    //   cellRenderer: function (params) {
    //     return (
    //       <div className="text-left">
    //         <a
    //           onClick={() => deleteJury(params.data.category_uid, params.value)}
    //           className="btn-sm btn-danger me-2"
    //         >
    //           <i className="fa fa-trash fa-lg"></i>
    //         </a>
    //       </div>
    //     );
    //   },
    // },
  ];

  const gridOptions = {
    domLayout: "autoWidth",
  };

  function totallist() {
    totalsummarylist(id).then((res) => {
      if (res.status === true) {
        if (res.data.summary.length > 0) {
          const firstObject = res.data.summary[0];
          setTableHeaders(Object.keys(firstObject));
          setTotalSummaryProduct(res.data.summary);
        }
      }
    });
  }

  function CategorySelect(e) {
    setEjadd("");
    setJlist([]);
    setRlist([]);
    setAround("");
    // setProundlist([]);
    const selectedValue = e.target.value;
    roundselection(id, selectedValue).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          setRlist(res.data);
          // setProundlist(res.data);
        }
      }
    });
    if (selectedValue !== "") {
      if (selectedValue === "1") {
        const jarr = [];
        EventJurylist(selectedValue).then((response) => {
          if (response.status === true) {
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                var name = response.data[i].first_name;
                if (response.data[i].last_name !== null) {
                  name = name + " " + response.data[i].last_name;
                }
                jarr.push({
                  value: response.data[i].user_uid,
                  label: name,
                });
              }
            }
            setJlist(jarr);
            setCatadd(selectedValue);
            setLanguage("");
          }
        });
        // setCatadd("");
      } else if (selectedValue === "3") {
        const jarr = [];
        EventJurylist(selectedValue).then((response) => {
          if (response.status === true) {
            if (response.data.length !== 0) {
              for (let i = 0; i < response.data.length; i++) {
                var name = response.data[i].first_name;
                if (response.data[i].last_name !== null) {
                  name = name + " " + response.data[i].last_name;
                }
                jarr.push({
                  value: response.data[i].user_uid,
                  label: name,
                });
              }
            }
            setJlist(jarr);
            setCatadd(selectedValue);
            setLanguage("");
          }
        });
        // setCatadd("");
      } else {
        setCatadd(selectedValue);
      }
    }
  }

  function roundcategory(e) {
    setPrevScore("");
    setProundlist([]);
    setRCatadd(e.target.value);
    roundselection(id, e.target.value).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          setProundlist(res.data);
        }
      }
    });
  }

  function LanguageSelect(e) {
    let arr5 = [];
    setLanguage(e.target.value);
    selectedjury(e.target.value, catadd).then((res) => {
      if (res.status === true) {
        if (res.data.length !== 0) {
          for (let i = 0; i < res.data.length; i++) {
            var name = res.data[i].first_name;
            if (res.data[i].last_name !== null) {
              name = name + " " + res.data[i].last_name;
            }
            arr5.push({
              value: res.data[i].user_uid,
              label: name,
            });
          }
        }
        setJlist(arr5);
      }
    });
  }

  const hangleLanguage = (e) => {
    setLanguage(e);
  };

  function checkentry(e, eid) {
    if (e.target.checked === true) {
      if (filterProduct.length !== 0) {
        for (let j = 0; j < filterProduct.length; j++) {
          if (filterProduct[j].entry_uid === eid) {
            filterProduct[j].chkbox = true;
            setFilterProduct([...filterProduct]);
          }
        }
      }
    } else {
      if (filterProduct.length !== 0) {
        for (let j = 0; j < filterProduct.length; j++) {
          if (filterProduct[j].entry_uid === eid) {
            filterProduct[j].chkbox = false;
            setFilterProduct([...filterProduct]);
          }
        }
      }
    }
  }

  function checkassentry(e, eid) {
    if (e.target.checked === true) {
      if (assfilterProduct.length !== 0) {
        for (let j = 0; j < assfilterProduct.length; j++) {
          if (assfilterProduct[j].entry_uid === eid) {
            assfilterProduct[j].chkbox = true;
            setAssFilterProduct([...assfilterProduct]);
          }
        }
      }
    } else {
      if (assfilterProduct.length !== 0) {
        for (let j = 0; j < assfilterProduct.length; j++) {
          if (assfilterProduct[j].entry_uid === eid) {
            assfilterProduct[j].chkbox = false;
            setAssFilterProduct([...assfilterProduct]);
          }
        }
      }
    }
  }

  function nonassselectall(e) {
    setSAll(e.target.checked);
    setUSAll(false);

    if (filterProduct.length !== 0) {
      for (let i = 0; i < filterProduct.length; i++) {
        if (e.target.checked === true) {
          filterProduct[i].chkbox = true;
        } else {
          filterProduct[i].chkbox = false;
        }
        setFilterProduct([...filterProduct]);
      }
    }
  }

  function nonassunselect(e) {
    setUSAll(e.target.checked);
    setSAll(false);
    if (filterProduct.length !== 0) {
      for (let j = 0; j < filterProduct.length; j++) {
        filterProduct[j].chkbox = false;
        setFilterProduct([...filterProduct]);
      }
    }
  }

  function assselectall(e) {
    setSAssAll(e.target.checked);
    setUSAssAll(false);

    if (assfilterProduct.length !== 0) {
      for (let i = 0; i < assfilterProduct.length; i++) {
        if (e.target.checked === true) {
          assfilterProduct[i].chkbox = true;
        } else {
          assfilterProduct[i].chkbox = false;
        }
        setAssFilterProduct([...assfilterProduct]);
      }
    }
  }

  function assunselect(e) {
    setUSAssAll(e.target.checked);
    setSAssAll(false);
    if (assfilterProduct.length !== 0) {
      for (let j = 0; j < assfilterProduct.length; j++) {
        assfilterProduct[j].chkbox = false;
        setAssFilterProduct([...assfilterProduct]);
      }
    }
  }

  function deleteEntry(eid) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEntries(eid).then((response) => {
          if (response.status === true) {
            addjurylist();
            // window.location.reload();
            toast.success(response.message);
          }
        });
      }
    });
  }

  function deleteJury(cid, jid) {
    Swal.fire({
      title: "Are you sure want to delete?",
      showCancelButton: true,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deletejuryevent(id, cid, jid).then((response) => {
          if (response.status === true) {
            juryreport();
            userreload();
            window.location.reload();
            toast.success(response.message);
          }
        });
      }
    });
  }

  // function deletjury(e, jid) {
  //   e.preventDefault();
  //   Swal.fire({
  //     title: `Are you sure want to Delete this jury?`,
  //     icon: "success",
  //     showCancelButton: true,
  //     confirmButtonColor: "#dc3545",
  //     cancelButtonColor: "#6c757d",
  //     confirmButtonText: "Delete",
  //     allowOutsideClick: false,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       removeassignjury(jid).then((response) => {
  //         if (response.status === true) {
  //           addjurylist(catsaved, jurysaved.value);
  //         }
  //       });
  //     }
  //   });
  // }

  // function ShowEulist(e) {
  //   e.preventDefault();
  //   setErrorMessage("");
  //   if (catadd !== "") {
  //     if (ejadd.length !== 0) {
  //       var valid = "";

  //       if (ejadd.length >= 3) {
  //         if (count <= ejadd.length) {
  //           if (count % 2 !== 0) {
  //             if (count >= 3 && count <= ejadd.length) {
  //             } else {
  //               valid = "required";
  //               setErrorMessage(
  //                 "You Can Only Assign Either 3 or " +
  //                   ejadd.length +
  //                   " Entries Per Participant"
  //               );
  //             }
  //           } else {
  //             valid = "required";
  //             setErrorMessage("Entry Count Must be 3 or More Than 3 in odd");
  //           }
  //         }

  //         if (count > 3 && count > ejadd.length) {
  //           valid = "required";
  //           setErrorMessage("You have selected " + ejadd.length + " juries");
  //         } else {
  //         }
  //         if (valid === "") {
  //           if (count % 2 !== 0) {
  //           } else {
  //             valid = "required";
  //             setErrorMessage("Entry Count Must be 3 or More Than 3 in odd");
  //           }
  //         }

  //         let lanid = "";

  //         if (interestlang.length !== 0) {
  //           for (let j = 0; j < interestlang.length; j++) {
  //             if (lanid === "") {
  //               lanid = interestlang[j].value;
  //             } else {
  //               lanid = lanid + "," + interestlang[j].value;
  //             }
  //           }
  //         }

  //         if (valid === "") {
  //           let cid = "";
  //           if (ejadd.length !== "") {
  //             for (let i = 0; i < ejadd.length; i++) {
  //               if (cid === "") {
  //                 cid = ejadd[i].value;
  //               } else {
  //                 cid = cid + "," + ejadd[i].value;
  //               }
  //             }
  //           }

  //           const temp = new FormData();
  //           temp.append("admin_uid", localStorage.getItem("Userid"));
  //           temp.append("event_uid", id);
  //           temp.append("jury_uid", cid);
  //           temp.append("category_uid", catadd);
  //           temp.append("count", count);
  //           temp.append("language_uid", lanid);

  //           // alert("Hiii")
  //           addjurytoevent(temp).then((response) => {
  //             if (response.status === true) {
  //               handleClose();
  //               setCatadd("");
  //               setEjadd("");
  //               setCount("");
  //               addjurylist(catsaved, jurysaved.value);
  //               window.location.reload();
  //             }
  //           });
  //         }
  //       } else {
  //         setErrorMessage("Select Minimum 3 Juries");
  //       }
  //     } else {
  //       setErrorMessage("Please Select the Jury");
  //     }
  //   } else {
  //     setErrorMessage("Please Select the Category");
  //   }
  // }

  function ShowEulist(e) {
    e.preventDefault();
    setErrorMessage("");
    if (catadd !== "") {
      if (catadd === "1" || catadd === "3") {
        if (ejadd !== "") {
          if (ejadd.length >= 3) {
            if (count !== "") {
              if (count <= 50) {
                let cid = "";
                if (ejadd.length !== "") {
                  for (let i = 0; i < ejadd.length; i++) {
                    if (cid === "") {
                      cid = ejadd[i].value;
                    } else {
                      cid = cid + "," + ejadd[i].value;
                    }
                  }
                }

                const temp = new FormData();
                temp.append("admin_uid", localStorage.getItem("Userid"));
                temp.append("event_uid", id);
                temp.append("jury_uid", cid);
                temp.append("category_uid", catadd);
                // if (interestlang === "") {
                //   temp.append("language_uid", 0);
                // }else{
                //   temp.append("language_uid", interestlang);
                // }
                temp.append("count", count);
                temp.append("round_uid", around);
                // alert("Hiii")
                addjurytoevent(temp).then((response) => {
                  if (response.status === true) {
                    handleClose();
                    setCatadd("");
                    setEjadd("");
                    setCount("");
                    setJlist([]);
                    // addjurylist(catsaved, jurysaved.value);
                    // window.location.reload();
                  }
                });
              } else {
                setErrorMessage(
                  "Maximum 50 Participants Per Jury Only Allowed"
                );
              }
            } else {
              setErrorMessage("Please Enter the Count");
            }
          } else {
            setErrorMessage("Please Select Minimum 3 Juries");
          }
        } else {
          setErrorMessage("Please Select the Jury");
        }
      } else {
        if (interestlang !== "") {
          if (ejadd !== "") {
            if (ejadd.length >= 3) {
              if (count !== "") {
                if (count <= 50) {
                  let cid = "";
                  if (ejadd.length !== "") {
                    for (let i = 0; i < ejadd.length; i++) {
                      if (cid === "") {
                        cid = ejadd[i].value;
                      } else {
                        cid = cid + "," + ejadd[i].value;
                      }
                    }
                  }

                  const temp = new FormData();
                  temp.append("admin_uid", localStorage.getItem("Userid"));
                  temp.append("event_uid", id);
                  temp.append("jury_uid", cid);
                  temp.append("category_uid", catadd);
                  temp.append("language_uid", interestlang);
                  temp.append("count", count);
                  temp.append("round_uid", around);
                  // alert("Hiii")
                  addjurytoevent(temp).then((response) => {
                    if (response.status === true) {
                      handleClose();
                      setCatadd("");
                      setEjadd("");
                      setCount("");
                      setJlist([]);
                      // addjurylist(catsaved, jurysaved.value);
                      // window.location.reload();
                    }
                  });
                } else {
                  setErrorMessage(
                    "Maximum 50 Participants Per Jury Only Allowed"
                  );
                }
              } else {
                setErrorMessage("Please Enter the Count");
              }
            } else {
              setErrorMessage("Please Select Minimum 3 Juries");
            }
          } else {
            setErrorMessage("Please Select the Jury");
          }
        } else {
          setErrorMessage("Please Select The Language");
        }
      }
    } else {
      setErrorMessage("Please Select The Category");
    }
  }

  function assignround(e) {
    e.preventDefault();
    setErrorMessage("");
    if (rcatadd !== "") {
      if (rprevscore !== "") {
        if (round !== "") {
          if (rndmark !== "") {
            const temp = new FormData();
            temp.append("admin_uid", localStorage.getItem("Userid"));
            temp.append("event_uid", id);
            temp.append("category_uid", rcatadd);
            temp.append("round_name", round);
            temp.append("round_min_score", rndmark);
            temp.append("previous_round_uid", rprevscore);

            addround(temp).then((response) => {
              if (response.status === true) {
                handleCloseRounds();
                setRCatadd("");
                setRndmark("");
                setRound("");
                setPrevScore("");
                toast.success("Rounds Added Successfully");
              }
            });
          } else {
            setErrorMessage("Enter the minimum round score");
          }
        } else {
          setErrorMessage("Enter the round name");
        }
      } else {
        setErrorMessage("Please Select the Previous Round");
      }
    } else {
      setErrorMessage("Select the category");
    }
  }

  function DelEntry(e, uid) {
    e.preventDefault();
    Swal.fire({
      title: `Are you sure want to Delete this Entry?`,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        delEntry(uid).then((response) => {
          if (response.status === true) {
            userreload();
          }
        });
      }
    });
  }

  let arr = [];
  function selectcatjury(e) {
    e.preventDefault();
    setCatSaved(e.target.value);
    addjurylist(e.target.value, jurysaved);
    categorywisejuryadded(id, e.target.value).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          jarr = [];
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            jarr.push({
              value: response.data[i].user_uid,
              text: name,
              icon: (
                <img
                  src={imageUrl + "jury/photo/" + response.data[i].photo}
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                  onError={(e) => errorhandle(e)}
                />
              ),
            });
          }
        }
        setJListSaved(jarr);
      }
    });
  }

  function juryChange(e) {
    setJurySaved(e);
    addjurylist(catsaved, e.value);
  }

  function juryreport() {
    totaljuryforentry(id).then((res) => {
      if (res.status === true) {
        setTotaljuryProduct(res.data);
      }
    });
  }

  const [accorlist, setAccorList] = useState([]);
  const [sumlanglist, setSumLangList] = useState([]);
  const [headernme, setHeadernme] = useState([]);
  const [ctylist, setCtyList] = useState([]);

  useEffect(() => {
    userreload();
    juryreport();
    totallist();
    summarylist();

    let catarr = [];
    let accor = [];

    juryslanguage().then((response) => {
      setLanglist(response.language);
    });

    totaljuryandcategory(id).then((res) => {
      if (res.status === true) {
        setEventData(res.data.entry_details);
        setjuryData(res.data.jury_details);
      }
    });

    categorylistsaved(id).then((res) => {
      if (res.status === true) {
        setCListSaved(res.data);
      }
    });

    // listeventcategory(id)
    //   .then((response) => {
    //     if (response.status === true && response.data.length !== 0) {
    //       setCatlist(response.data);
    //       // Array to store promises for categorywisejurylist calls
    //       const categoryPromises = response.data.map((cat) =>
    //         categorywisejurylist(id, cat.category_uid).then((res) => {
    //           if (res.status === true) {
    //             return {
    //               catname: res.data.category,
    //               totcatcont: res.data.total_category_list,
    //               asscatcont: res.data.assign_category_count,
    //               nonasscatcont: res.data.not_assign_category_count,
    //             };
    //           }
    //         })
    //       );
    //       // Use Promise.all to wait for all categorywisejurylist calls to complete
    //       return Promise.all(categoryPromises);
    //     }
    //   })
    //   .then((catarr) => {
    //     // Set catCount after all categorywisejurylist calls have completed
    //     setCatCount([...catarr]);
    //   })
    //   .then(() => {
    //     return listeventcategory(id);
    //   })
    //   .then((response) => {
    //     if (response.status === true && response.data.length !== 0) {
    //       // Array to store promises for roundselection calls
    //       const roundPromises = response.data.map((cat) =>
    //         roundselection(id, cat.category_uid).then((res) => {
    //           if (res.status === true && res.data.length !== 0) {
    //             let catenameData = {
    //               value: cat.category_uid,
    //               catename: cat.category,
    //               rounds: [],
    //             };

    //             // Array to store promises for CateRoundMarks calls
    //             const cateRoundMarksPromises = res.data.map((round) =>
    //               CateRoundMarks(
    //                 id,
    //                 cat.category_uid,
    //                 round.event_round_uid
    //               ).then((respo) => {
    //                 console.log(round.event_round_uid, respo);
    //                 if (respo.status === true) {
    //                   console.log(respo.data.withoutscore);
    //                   console.log(respo.data.withscore);
    //                   setNomarkfilterProduct(respo.data.withoutscore);
    //                   setMarkfilterProduct(respo.data.withscore);
    //                 }
    //                 return {
    //                   round: round.round_name,
    //                   rndvalue: round.event_round_uid,
    //                 };
    //               })
    //             );

    //             // Use Promise.all to wait for all CateRoundMarks calls to complete
    //             return Promise.all(cateRoundMarksPromises).then((rounds) => {
    //               catenameData.rounds = rounds;
    //               accor.push(catenameData);
    //             });
    //           }
    //         })
    //       );

    //       // Use Promise.all to wait for all roundselection calls to complete
    //       return Promise.all(roundPromises);
    //     }
    //   })
    //   .then(() => {
    //     // Set accorList after all roundselection calls have completed
    //     setAccorList(accor);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });

    listeventcategory(id).then((response) => {
      if (response.status === true && response.data.length !== 0) {
        setCatlist(response.data);
        for (let i = 0; i < response.data.length; i++) {
          categorywisejurylist(id, response.data[i].category_uid).then(
            (res) => {
              if (res.status === true) {
                catarr.push({
                  catname: res.data.category,
                  totcatcont: res.data.total_category_list,
                  asscatcont: res.data.assign_category_count,
                  nonasscatcont: res.data.not_assign_category_count,
                });
                setCatCount([...catarr]);
              }
            }
          );
          roundselection(id, response.data[i].category_uid).then((res) => {
            if (res.status === true && res.data.length !== 0) {
              let catenameData = {
                value: response.data[i].category_uid,
                catename: response.data[i].category,
                rounds: [],
              };
              for (let k = 0; k < res.data.length; k++) {
                catenameData.rounds.push({
                  round: res.data[k].round_name,
                  rndvalue: res.data[k].event_round_uid,
                });
              }
              accor.push(catenameData);
              setAccorList(accor);
            }
          });
        }
      }
    });

    juryevententrycount(id).then((res) => {
      if (res.status === true) {
        setNotassign(res.data.without_assigning_count);
        setAssigned(res.data.assign_entrie_count);
      }
    });

    viewevent(id).then((res) => {
      if (res.status === true) {
        setEventName(res.data.event_name);
        setEventVenue(res.data.event_venue);
        setSDate(moment(res.data.event_start).format("DD-MM-YYYY"));
        setEDate(moment(res.data.event_end).format("DD-MM-YYYY"));
        setCDate(moment(res.data.event_entry_close).format("DD-MM-YYYY"));
      }
    });
  }, []);

  let jarr = [];
  let arr1 = [];
  let arr2 = [];

  function summarylist() {
    totalsummarylist(id).then((res) => {
      if (res.status === true) {
        let countrysummary = res.data.country_summary.slice(1);
        let summary = res.data.summary;
        let updatedsummary = summary.slice(1);
        let heading = summary.slice(0, 1);
        setCtyList(countrysummary);
        setSumLangList(updatedsummary);
        setHeadernme(heading);
      }
    });
  }

  function userreload() {
    addjurylist(catsaved, jurysaved);
    // evententrieslist(id).then((response) => {
    //   if (response.status === true) {
    //     if (response.data.length !== 0) {
    //       for (let i = 0; i < response.data.length; i++) {
    //         var atype = "";
    //         var type = "";
    //         atype = response.data[i].file_type;
    //         var name = atype.split(",");
    //         if (name.length !== 0) {
    //           for (let j = 0; j < name.length; j++) {
    //             if (type === "") {
    //               if (name[j] === "1") {
    //                 type = "Video";
    //               } else if (name[j] === "2") {
    //                 type = "Audio";
    //               } else if (name[j] === "3") {
    //                 type = "Image";
    //               }
    //             } else {
    //               if (name[j] === "1") {
    //                 type = type + ", Video";
    //               } else if (name[j] === "2") {
    //                 type = type + ", Audio";
    //               } else if (name[j] === "3") {
    //                 type = type + ", Image";
    //               }
    //             }
    //           }
    //         }

    //         var user = "";
    //         var navi = "";
    //         if (response.data[i].guest_uid !== 0) {
    //           user = "Guest";
    //           navi = "/eventguestview/" + response.data[i].guest_uid;
    //         } else if (response.data[i].user_uid !== 0) {
    //           user = "User";
    //           navi = "/eventuserview/" + response.data[i].user_uid;
    //         }
    //         arr1.push({
    //           value: response.data[i].entry_uid,
    //           label:
    //             response.data[i].first_name + " " + response.data[i].last_name,
    //         });
    //         arr.push({
    //           first_name:
    //             response.data[i].first_name + " " + response.data[i].last_name,
    //           file_type: type,
    //           user_name: response.data[i].user_name,
    //           event_uid: response.data[i].event_uid,
    //           entry_uid: response.data[i].entry_uid,
    //           guest_uid: response.data[i].guest_uid,
    //           user_uid: response.data[i].user_uid,
    //           usertype: user,
    //         });
    //       }
    //     }
    //     setUlist(arr1);
    //     setFilterProduct(arr);
    //   }
    // });
  }

  function addjurylist(cid, jid) {
    setSAll(false);
    setUSAll(false);
    setSAssAll(false);
    setUSAssAll(false);
    setFilterProduct([]);
    setAssFilterProduct([]);
    arr = [];
    arr1 = [];
    juryassandnonass(id, cid, jid).then((response) => {
      if (response.status === true) {
        if (response.data.with_assign.length !== 0) {
          for (let i = 0; i < response.data.with_assign.length; i++) {
            var user = "";
            var navi = "";
            if (response.data.with_assign[i].guest_uid !== 0) {
              user = "Guest";
              navi =
                "/eventguestview/" + response.data.with_assign[i].guest_uid;
            } else if (response.data.with_assign[i].user_uid !== 0) {
              user = "User";
              navi = "/eventuserview/" + response.data.with_assign[i].user_uid;
            }
            var name = response.data.with_assign[i].jury_firstname;
            if (response.data.with_assign[i].jury_lastname !== null) {
              name = name + " " + response.data.with_assign[i].jury_lastname;
            }

            var uname = response.data.with_assign[i].participant_firstname;
            if (response.data.with_assign[i].participant_lastname !== null) {
              uname =
                uname + " " + response.data.with_assign[i].participant_lastname;
            }
            arr.push({
              first_name: name,
              user_name: uname,
              event_uid: response.data.with_assign[i].event_uid,
              entry_uid: response.data.with_assign[i].entry_uid,
              guest_uid: response.data.with_assign[i].guest_uid,
              user_uid: response.data.with_assign[i].user_uid,
              photo: response.data.with_assign[i].photo,
              category: response.data.with_assign[i].category,
              usertype: user,
              chkbox: false,
            });
          }
        }

        if (response.data.without_assign.length !== 0) {
          for (let i = 0; i < response.data.without_assign.length; i++) {
            var user = "";
            var navi = "";
            if (response.data.without_assign[i].guest_uid !== 0) {
              user = "Guest";
              navi =
                "/eventguestview/" + response.data.without_assign[i].guest_uid;
            } else if (response.data.without_assign[i].user_uid !== 0) {
              user = "User";
              navi =
                "/eventuserview/" + response.data.without_assign[i].user_uid;
            }
            var name = response.data.without_assign[i].jury_firstname;
            if (response.data.without_assign[i].jury_lastname !== null) {
              name = name + " " + response.data.without_assign[i].jury_lastname;
            }

            var uname = response.data.without_assign[i].participant_firstname;
            if (response.data.without_assign[i].participant_lastname !== null) {
              uname =
                uname +
                " " +
                response.data.without_assign[i].participant_lastname;
            }
            arr1.push({
              first_name: name,
              user_name: uname,
              event_uid: response.data.without_assign[i].event_uid,
              entry_uid: response.data.without_assign[i].entry_uid,
              guest_uid: response.data.without_assign[i].guest_uid,
              user_uid: response.data.without_assign[i].user_uid,
              photo: response.data.without_assign[i].photo,
              category: response.data.without_assign[i].category,
              usertype: user,
              chkbox: false,
            });
          }

          // let paintcnt = 0;
          // response.data.without_assign.forEach((entry) => {
          //   if (entry.category === "Painting") {
          //     paintcnt++;
          //   }
          // });
          // setPaintingCount(paintcnt);

          // let poemcnt = 0;
          // response.data.without_assign.forEach((entry) => {
          //   if (entry.category === "Poem") {
          //     poemcnt++;
          //   }
          // });
          // setpoemCount(poemcnt);

          // let musiqcnt = 0;
          // response.data.without_assign.forEach((entry) => {
          //   if (entry.category === "Music") {
          //     musiqcnt++;
          //   }
          // });
          // setMusicCount(musiqcnt);

          // let dancecnt = 0;
          // response.data.without_assign.forEach((entry) => {
          //   if (entry.category === "Dance") {
          //     dancecnt++;
          //   }
          // });
          // setDanceCount(dancecnt);

          // let tartscnt = 0;
          // response.data.without_assign.forEach((entry) => {
          //   if (entry.category === "Theatre Arts") {
          //     tartscnt++;
          //   }
          // });
          // setTheatreartsCount(tartscnt);
        }
        setAssFilterProduct(arr);
        setFilterProduct(arr1);
      }
    });
    // assignedjurylist(id).then((response) => {
    //   if (response.status === true) {
    //     if (response.data.length !== 0) {
    //       for (let i = 0; i < response.data.length; i++) {
    //         var name = response.data[i].jury_first_name;
    //         if (response.data[i].jury_last_name !== null) {
    //           name = name + " " + response.data[i].jury_last_name;
    //         }
    //         arr2.push({
    //           jury_first_name: name,
    //           first_name:
    //             response.data[i].first_name + " " + response.data[i].last_name,
    //           category: response.data[i].category,
    //           username: response.data[i].username,
    //           photo: response.data[i].photo,
    //           usertype: response.data[i].usertype,
    //           user_uid: response.data[i].user_uid,
    //           jury_user_uid: response.data[i].jury_user_uid,
    //         });
    //       }
    //     }
    //     setAssFilterProduct(arr2);
    //   }
    // });
  }

  function addremove(str) {
    if (catsaved !== 0 && jurysaved !== 0) {
      var aid = "";
      if (str === "add") {
        if (filterProduct.length !== 0) {
          for (let i = 0; i < filterProduct.length; i++) {
            // if (filterProduct[i].chkbox === true) {
            if (aid === "") {
              aid = filterProduct[i].entry_uid;
            } else {
              aid = aid + "," + filterProduct[i].entry_uid;
            }
            // }
          }
        }
      } else {
        if (assfilterProduct.length !== 0) {
          for (let i = 0; i < assfilterProduct.length; i++) {
            // if (assfilterProduct[i].chkbox === true) {
            if (aid === "") {
              aid = assfilterProduct[i].entry_uid;
            } else {
              aid = aid + "," + assfilterProduct[i].entry_uid;
            }
            // }
          }
        }
      }
      if (aid !== "") {
        const temp = new FormData();
        temp.append("event_uid", id);
        temp.append("category_uid", catsaved);
        temp.append("jury_uid", jurysaved.value);
        temp.append("admin_uid", localStorage.getItem("Userid"));
        temp.append("entry_uid", aid);
        temp.append("action", str);

        juryaddandremove(temp).then((res) => {
          if (res.status === true) {
            addjurylist(catsaved, jurysaved.value);
            juryreport();
          }
        });
      } else {
        toast.error("Please Select Entries");
      }
    } else {
      toast.error("Please Select Category and Jury");
    }
  }

  // function notassignlist () {
  //   notassignedjurylist(id).then( (response) => {
  //     if ( response.status === true) {
  //       if ( response.data.length !== 0) {
  //         for (let i = 0 ; i < response.data.length ; i++) {}
  //       }
  //     }
  //   })
  // }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  function handleShow() {
    setShow(true);
    setAssJury("");
    setPenJury("");
    setErrorMessage("");
    addjlist([]);
    ejadd("");
    setJuryShow(false);
    // setLanguage("");
  }

  function handleClose() {
    setShow(false);
    setJuryShow(false);
    setErrorMessage("");
    setCatadd("");
    setCount("");
    setEjadd("");
    setAssJury("");
    setPenJury("");
    setLanguage("");
    setAround("");
  }

  function handleShowRounds() {
    setRoundShow(true);
    setAssJury("");
    setPenJury("");
    setErrorMessage("");
    addjlist([]);
    ejadd("");
    setJuryShow(false);
    // setLanguage("");
  }

  function handleCloseRounds() {
    setRoundShow(false);
    setJuryShow(false);
    setErrorMessage("");
    setRCatadd("");
    setProundlist([]);
    setPrevScore("");
    setCount("");
    setEjadd("");
    setPenJury("");
    setRound("");
    setRndmark("");
  }

  function handleUShow() {
    setUShow(true);
  }

  function handleUClose() {
    setUShow(false);
    setCatadd("");
    setCount("");
    setUadd([]);
  }

  function handleChange(e) {
    setEjadd(e);
    setAssJury("");
    setPenJury("");
    let itemcount = 0;
    itemcount = e.length;
    setCount(itemcount);
  }

  function letsum(e) {
    let value = e.target.value.trim();
    value = value.replace(/\D+/g, "");
    if (value === "") {
      setCount("");
    } else {
      value = parseInt(value, 10);
      if (isNaN(value) || value < 0) {
        setCount("0");
      } else if (value > 50) {
        setCount("50");
      } else {
        setCount(value.toString());
      }
    }
  }
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function Rletsum(event) {
    let value = event.target.value.trim();
    value = value.replace(/[,.]/g, "");
    const pattern = /^[a-zA-Z0-9\s]*$/;
    if (!pattern.test(value)) {
      value = value.replace(/[^a-zA-Z0-9\s]/g, "");
    }
    setRound(value);
  }

  const rndsum = (e) => {
    e.preventDefault();
    const regex = /^\d{0,2}(\.\d{0,1})?$/;
    var numbers = e.target.value.substring(0, 3);
    if (e.target.value <= 10) {
      if (numbers === "10.") {
        return false;
      } else if (numbers === "00") {
        return false;
      } else if (numbers === "0.1") {
        return false;
      } else if (numbers === "0.2") {
        return false;
      } else if (numbers === "0.3") {
        return false;
      } else if (numbers === "0.4") {
        return false;
      } else {
        if (regex.test(e.target.value)) {
          setRndmark(e.target.value);
        }
      }
    } else {
      return false;
    }
  };

  function handleUser(e) {
    setUadd(e);
  }

  function selelctjury(e) {
    setCatadd(e.target.value);
    setJlist([]);
    jarr = [];
    EventJurylist(e.target.value).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].first_name;
            if (response.data[i].last_name !== null) {
              name = name + " " + response.data[i].last_name;
            }
            jarr.push({
              value: response.data[i].user_uid,
              text: name,
              // icon: (
              //   <img
              //     src={imageUrl + "jury/photo/" + response.data[i].photo}
              //     style={{
              //       height: "30px",
              //       width: "30px",
              //       borderRadius: "50%",
              //       marginRight: "10px",
              //     }}
              //     onError={(e) => errorhandle(e)}
              //   />
              // ),
            });
          }
        }
        setJlist(jarr);
      }
    });
  }

  function gotojuryreg() {
    navigate("/juryregister");
  }

  const [activeIndex, setActiveIndex] = useState(null);
  const [nestedActiveIndex, setNestedActiveIndex] = useState(null);

  function handleParentAccordionClick(index, value) {
    setCateValue("");
    setActiveIndex(activeIndex === index ? null : index);
    setCateValue(value);
  }

  function handleNestedAccordionClick(parentIndex, nestedIndex) {
    setRoundValue("");
    setNestedActiveIndex(
      nestedActiveIndex === parentIndex ? null : parentIndex
    );
    setRoundValue(nestedIndex);
    CateRoundMarks(id, catevalue, nestedIndex).then((response) => {
      if (response.status === true) {
        setNomarkfilterProduct(response.data.withoutscore);
        setMarkfilterProduct(response.data.withscore);
      }
    });
  }

  function handleExport() {
    window.open(
      "https://events.pythiangames.org/backend/api/event-entry-roundexcel/" +
        id +
        "/" +
        catevalue + 
        "/" +
        roundvalue,
      "_blank"
    );
  }

  const [sumactiveI, setSumActiveI] = useState(null);

  function SumhandleParentAccordionClick(index, value) {
    setSumActiveI(sumactiveI === index ? null : index);
  }

  const [ctyactiveI, setCtyActiveI] = useState(null);

  function CtyhandleParentAccordionClick(index, value) {
    setCtyActiveI(ctyactiveI === index ? null : index);
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h6 className="main-content-title tx-24 mg-b-5">
            {name.toUpperCase()}
          </h6>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="eve-btn">
                <h4 className="col-md-5 mt-2">Event Summary</h4>
                <div className="col-md-2 pull-right">
                  <a className="btn btn-info" onClick={handleShowRounds}>
                    Rounds
                  </a>
                </div>
                <div className="col-md-2 pull-right">
                  <a className="btn btn-success" onClick={handleShow}>
                    <i className="fa fa-plus"></i> Assign
                  </a>
                </div>
                <div className="col-md-2 pull-right">
                  <Link to={"/eventlist"} className="btn btn-secondary">
                    Back
                  </Link>
                </div>
              </div>

              <hr />

              {/* accordion */}

              <div className="row">
                <div className="col-md-12">
                  <div className="accordion">
                    {headernme.map((data, i) => (
                      <div className="section" key={i}>
                        <div
                          className={
                            "section-title" +
                            (ctyactiveI === i ? " active" : "")
                          }
                          onClick={() => CtyhandleParentAccordionClick(i)}
                        >
                          <h5 style={{ marginTop: "8px", fontSize: "21px" }}>
                            ENTRIES BY COUNTRY
                            <span className="arrow-icon pull-right">
                              {ctyactiveI === i ? "▲" : "▼"}
                            </span>
                          </h5>
                        </div>
                        {ctyactiveI === i && (
                          <div className="section-content">
                            <div className="section-content">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="responsive">
                                    <div
                                      className="ag-theme-alpine ag-style"
                                      style={{
                                        height: 550,
                                        width: "100%",
                                      }}
                                    >
                                      <AgGridReact
                                        ref={ctyref}
                                        columnDefs={countrydefs}
                                        rowData={ctylist}
                                        gridOptions={gridOptions}
                                        pagination="true"
                                        paginationPageSize="10"
                                        onGridReady={(params) => {
                                          params.api.sizeColumnsToFit();
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="accordion">
                    {headernme.map((data, i) => (
                      <div className="section" key={i}>
                        <div
                          className={
                            "section-title" +
                            (sumactiveI === i ? " active" : "")
                          }
                          onClick={() => SumhandleParentAccordionClick(i)}
                        >
                          <h5 style={{ marginTop: "8px", fontSize: "21px" }}>
                            ENTRIES BY LANGUAGE
                            <span className="arrow-icon pull-right">
                              {sumactiveI === i ? "▲" : "▼"}
                            </span>
                          </h5>
                        </div>
                        {sumactiveI === i && (
                          <div className="section-content">
                            <div className="section-content">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="responsive">
                                    <div
                                      className="ag-theme-alpine ag-style"
                                      style={{
                                        height: 550,
                                        width: "100%",
                                      }}
                                    >
                                      <AgGridReact
                                        ref={gridRef}
                                        columnDefs={summarydefs}
                                        rowData={sumlanglist}
                                        pagination="true"
                                        paginationPageSize="10"
                                        gridOptions={gridOptions}
                                        onGridReady={(params) => {
                                          params.api.sizeColumnsToFit();
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {accorlist !== null && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="accordion">
                      {accorlist.map((data, i) => (
                        <div className="section" key={i}>
                          <div
                            className={
                              "section-title" +
                              (activeIndex === i ? " active" : "")
                            }
                            onClick={() =>
                              handleParentAccordionClick(i, data.value)
                            }
                          >
                            <h5 style={{ marginTop: "8px", fontSize: "21px" }}>
                              {data.catename.toUpperCase()}- ROUNDS & DETAILS
                              <span className="arrow-icon pull-right">
                                {activeIndex === i ? "▲" : "▼"}
                              </span>
                            </h5>
                          </div>
                          {activeIndex === i && (
                            <div className="section-content">
                              <div className="accordion">
                                {data.rounds.map((round, j) => (
                                  <div className="section" key={j}>
                                    <div
                                      className={
                                        "section-title" +
                                        (nestedActiveIndex === j
                                          ? " active"
                                          : "")
                                      }
                                      onClick={() =>
                                        handleNestedAccordionClick(
                                          j,
                                          round.rndvalue
                                        )
                                      }
                                    >
                                      <p style={{ fontSize: "larger" }}>
                                        {round.round.toUpperCase()}{" "}
                                        <span className="text-danger">
                                          (Min Mark 5)
                                        </span>
                                        <span className="arrow-icon pull-right">
                                          {activeIndex === i ? "▲" : "▼"}
                                        </span>
                                      </p>
                                    </div>
                                    {nestedActiveIndex === j && (
                                      <div className="section-content">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="panel panel-primary tabs-style-2">
                                              <div className="tab-menu-heading">
                                                <div className="tabs-menu1">
                                                  <a
                                                    className="btn-sm btn-primary pull-right"
                                                    onClick={handleExport}
                                                  >
                                                    Export
                                                  </a>
                                                  <Tab.Container defaultActiveKey="notallot">
                                                    <Nav
                                                      variant="pills"
                                                      className="nav panel-tabs main-nav-line"
                                                    >
                                                      <Nav.Item
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <Nav.Link
                                                          eventKey="notallot"
                                                          className="mt-1"
                                                        >
                                                          Without Mark
                                                        </Nav.Link>
                                                        <Nav.Link
                                                          eventKey="allot"
                                                          className="mt-1"
                                                        >
                                                          With Mark
                                                        </Nav.Link>
                                                      </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                      <Tab.Pane
                                                        eventKey="notallot"
                                                        className="border"
                                                      >
                                                        <div className="responsive">
                                                          <div
                                                            className="ag-theme-alpine ag-style"
                                                            style={{
                                                              height: 500,
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <AgGridReact
                                                              ref={
                                                                gridRefNotAllot
                                                              }
                                                              columnDefs={
                                                                WithoutMark
                                                              }
                                                              rowData={
                                                                nomarkfilterProduct
                                                              }
                                                              pagination="true"
                                                              paginationPageSize="10"
                                                            />
                                                          </div>
                                                        </div>
                                                      </Tab.Pane>
                                                      <Tab.Pane
                                                        eventKey="allot"
                                                        className="border"
                                                      >
                                                        <div className="responsive">
                                                          <div
                                                            className="ag-theme-alpine ag-style"
                                                            style={{
                                                              height: 500,
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <AgGridReact
                                                              ref={gridRefAllot}
                                                              columnDefs={
                                                                WithMark
                                                              }
                                                              rowData={
                                                                markfilterProduct
                                                              }
                                                              pagination="true"
                                                              paginationPageSize="10"
                                                            />
                                                          </div>
                                                        </div>
                                                      </Tab.Pane>
                                                    </Tab.Content>
                                                  </Tab.Container>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* <br /> */}

              <div className="row">
                {/* <div className="input-group">
                  <div className="col-md-4">
                    <b className="text-inverse">Not Assigned Jury Entries</b>
                  </div>
                  <div className="col-md-8">
                    <span>{notassign}</span>
                  </div>
                </div> */}
                {/* <div className="input-group">
                  <div className="col-md-4">
                    <b className="text-inverse">
                      Assigned Jury Entries for Users/Guest
                    </b>
                  </div>
                  <div className="col-md-8">
                    <span>{assigned}</span>
                  </div>
                </div> */}
              </div>

              {/* Filter By Category & Jury */}

              {/* <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <b className="text-inverse">Filter By Category</b>
                    <br />
                    <select value={catsaved} onChange={selectcatjury}>
                      <option value={0} disabled>
                        Select Category
                      </option>
                      {clistsaved.map((data, i) => {
                        return (
                          <option value={data.category_uid} key={i}>
                            {data.category}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-group">
                    <b className="text-inverse">Filter By Jury</b>
                    <Select
                      placeholder="Select Jury"
                      value={jurysaved}
                      options={jlistsaved}
                      onChange={juryChange}
                      isSearchable={true}
                      style={{ margin: "-4px" }}
                      className="s-jury"
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.icon}
                          <span style={{ marginLeft: 5 }}>{e.text}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div> */}

              <hr />

              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-primary tabs-style-2 mt-4">
                    <div className=" tab-menu-heading">
                      <div className="tabs-menu1">
                        {/*  */}
                        <Tab.Container
                          defaultActiveKey="assigned"
                          onSelect={() =>
                            addjurylist(catsaved, jurysaved.value)
                          }
                        >
                          <Nav
                            variant="pills"
                            className="nav panel-tabs main-nav-line"
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="assigned" className="mt-1">
                                Non-Assigned Participants
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="all" className="mt-1">
                                Assigned Participants
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="total" className="mt-1">
                                Total Juries For Assigned Participants
                              </Nav.Link>
                            </Nav.Item>

                            {/* <Nav.Item>
                          <Nav.Link eventKey="nonassigned" className="mt-1">
                            Non-Assigned Jury
                          </Nav.Link>
                        </Nav.Item> */}
                          </Nav>
                          <Tab.Content>
                            <Tab.Content>
                              <Tab.Pane eventKey="assigned" className="border">
                                <div className="responsive">
                                  {/* <div align="right">
                                <input
                                  type="checkbox"
                                  checked={sall}
                                  onChange={nonassselectall}
                                />{" "}
                                Select All &nbsp;
                                <input
                                  type="checkbox"
                                  checked={usall}
                                  onChange={nonassunselect}
                                />{" "}
                                UnSelect All
                                <a
                                  className="btn btn-success"
                                  onClick={() => addremove("add")}
                                >
                                  Add
                                </a>
                              </div> */}
                                  <div className="App">
                                    <div
                                      className="ag-theme-alpine ag-style"
                                      style={{ height: 550, width: "100%" }}
                                    >
                                      <AgGridReact
                                        columnDefs={columnDefs}
                                        suppressDragLeaveHidesColumns={true}
                                        rowData={filterProduct}
                                        pagination="true"
                                        paginationPageSize="10"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>

                            <Tab.Pane eventKey="all" className="border">
                              <div className="responsive">
                                {/* <div align="right">
                              <input
                                type="checkbox"
                                checked={sassall}
                                onChange={assselectall}
                              />{" "}
                              Select All &nbsp;
                              <input
                                type="checkbox"
                                checked={usassall}
                                onChange={assunselect}
                              />{" "}
                              UnSelect All
                              <a
                                className="btn btn-danger"
                                onClick={() => addremove("remove")}
                              >
                                Remove
                              </a>
                            </div> */}

                                <div className="App">
                                  <div
                                    className="ag-theme-alpine ag-style"
                                    style={{ height: 550, width: "100%" }}
                                  >
                                    <AgGridReact
                                      columnDefs={columnAssDefs}
                                      suppressDragLeaveHidesColumns={true}
                                      rowData={assfilterProduct}
                                      pagination="true"
                                      paginationPageSize="10"
                                      domLayout="autoWidth"
                                      onGridReady={(params) => {
                                        params.api.sizeColumnsToFit();
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="total" className="border">
                              <div className="responsive">
                                <div
                                  className="ag-theme-alpine ag-style"
                                  style={{ height: 550, width: "100%" }}
                                >
                                  <AgGridReact
                                    columnDefs={totaldefs}
                                    suppressDragLeaveHidesColumns={true}
                                    rowData={totaljuryProduct}
                                    pagination="true"
                                    paginationPageSize="10"
                                    gridOptions={gridOptions}
                                    onGridReady={(params) => {
                                      params.api.sizeColumnsToFit();
                                    }}
                                  />
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>

                          {/* <Tab.Content>
                        <Tab.Pane eventKey="nonassigned" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  columnDefs={columnDefs}
                                  suppressDragLeaveHidesColumns={true}
                                  rowData={filterProduct}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content> */}
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header>
                  <h4>Assign Jury</h4>
                  <a className="btn btn-success" onClick={gotojuryreg}>
                    <i className="fa fa-plus"></i> Add Jury
                  </a>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Catagory <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <select value={catadd} onChange={CategorySelect}>
                          <option value="" disabled>
                            Select Category
                          </option>
                          {catlist.map((data, i) => {
                            return (
                              <option value={data.category_uid} key={i}>
                                {data.category}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  {catadd !== "1" && catadd !== "3" && (
                    <div className="row">
                      <div className="input-group">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Language <b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={interestlang}
                            onChange={LanguageSelect}
                          >
                            <option value="" disabled>
                              Select Language
                            </option>
                            {langlist.map((language, i) => {
                              return (
                                <option value={language.language_uid} key={i}>
                                  {language.language}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Language <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <Select
                          placeholder="Select Your Language"
                          value={interestlang}
                          options={langlist}
                          onChange={hangleLanguage}
                          isMulti
                          isClearable
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Jury <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <Select
                          placeholder="Select Jury"
                          value={ejadd}
                          options={addjlist}
                          onChange={(e) => setEjadd(e)}
                          isSearchable={true}
                          isMulti
                          isClearable
                          // getOptionLabel={(e) => (
                          //   <div
                          //     style={{ display: "flex", alignItems: "center" }}
                          //   >
                          //     {e.icon}
                          //     <span style={{ marginLeft: 5 }}>{e.text}</span>
                          //   </div>
                          // )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Round Name <b className="text-danger">*</b>
                        </label>
                      </div>

                      <div className="col-md-8">
                        <select
                          value={around}
                          onChange={(e) => setAround(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Round
                          </option>
                          {rlist.map((data, i) => {
                            return (
                              <option value={data.event_round_uid} key={i}>
                                {data.round_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Count <b className="text-danger">*</b>
                        </label>
                      </div>

                      <div className="col-md-8">
                        <input
                          type="text"
                          id="numberInput"
                          placeholder="Enter Max 50 Participant Per Jury"
                          value={count}
                          onInput={letsum}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Count <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Max 50 Participant Per Jury"
                          value={count}
                          // onChange={letsum}
                          // min={1}
                          // max={50}
                          onInput={(e) => {
                            let value = e.target.value.trim();
                            value = value.replace(/\D+/g, "");
                            if (value === "") {
                              e.target.value = "";
                            } else {
                              value = parseInt(value, 10);
                              if (isNaN(value) || value < 0) {
                                e.target.value = "0";
                              } else if (value > 50) {
                                e.target.value = "50";
                              } else {
                                e.target.value = value.toString();
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* {juryshow && (
                    <div className="row">
                      <div className="input-group">
                        <div className="col-md-4">
                          <label htmlFor="username">Already Assigned</label>
                        </div>
                        <div className="col-md-8">{juryass}</div>
                      </div>

                      <div className="input-group">
                        <div className="col-md-4">
                          <label htmlFor="username">Need to be Assigned</label>
                        </div>
                        <div className="col-md-8">{jurypen}</div>
                      </div>
                    </div>
                  )} */}

                  {errorMessage !== "" && (
                    <span className="errortxt text-danger">{errorMessage}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-prev btn-primary"
                    style={{ float: "right" }}
                    onClick={ShowEulist}
                  >
                    Assign
                  </a>

                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={roundshow} size="lg" onHide={handleCloseRounds}>
                <Modal.Header>
                  <h4>Event Rounds</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Category <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <select value={rcatadd} onChange={roundcategory}>
                          <option value="" disabled>
                            Select Category
                          </option>
                          {catlist.map((data, i) => {
                            return (
                              <option value={data.category_uid} key={i}>
                                {data.category}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Previous Round <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <select
                          value={rprevscore}
                          onChange={(e) => setPrevScore(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Previous Round
                          </option>
                          <option value="0">Round 0</option>
                          {proundlist.map((data, i) => {
                            return (
                              <option value={data.event_round_uid} key={i}>
                                {data.round_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Round Name <b className="text-danger">*</b>
                        </label>
                      </div>

                      <div className="col-md-8">
                        <input
                          type="text"
                          id="numberInput"
                          placeholder="Enter The Round Name"
                          value={round}
                          onInput={Rletsum}
                          // onChange={(e)=>
                          //   setRound(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Round Minimum Score <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="number"
                          placeholder="Enter The Minimum Score Of Round"
                          value={rndmark}
                          onInput={rndsum}
                          onKeyPress={(event) => {
                            if (
                              !/[0-9]/.test(event.key) ||
                              (event.target.value.length >= 2 &&
                                event.keyCode !== 8)
                            ) {
                              event.preventDefault();
                            }
                          }}
                          max={10}
                        />
                      </div>
                    </div>
                  </div>

                  {errorMessage !== "" && (
                    <span className="errortxt text-danger">{errorMessage}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-prev btn-primary"
                    style={{ float: "right" }}
                    onClick={assignround}
                  >
                    Assign Rounds
                  </a>

                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={handleCloseRounds}
                  >
                    Cancel
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Eventwiselist;
