import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./guest.css";
import {
  getdetails,
  evententryadd,
  alreadyexistsmail,
  categorydetailmail,
  viewevent,
  getguesthashdetails,
} from "../../services/admin";
import { Container, Modal, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import imageUrl from "../../ImageUrl";
import axios from "axios";
import { Upload, Progress } from "antd";

const EntryregisterTwo = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [submitted, setSubmitted] = useState(false);

  const [check, setCheck] = useState(false);
  function checkterm(e) {
    setCheck(e.target.checked);
  }
  //image file
  const [formphotoerror, setPhotoErrors] = useState("");

  function photofile(e, cat, file, index) {
    // e.preventDefault();
    // setPhotoErrors("");
    // setErrorMessage("");
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            if (e.target.files.length !== 0) {
              multifile[i].fileerror = "";
              if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                multifile[i].fileload = e.target.files[0];
                setMulti([...multifile]);

                var image = document.getElementById("image");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    image.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
              } else {
                multifile[i].fileload = e.target.files[0];

                multifile[i].fileerror =
                  "Image should be jpg or png or jpeg format";
                setMulti([...multifile]);

                setPhotoErrors("Image should be jpg or png or jpeg format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 2) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror = "Image size should be less than 2MB";
                } else {
                  multifile[i].fileerror =
                    "Image should be jpg or png or jpeg format and file size should be less than 2MB";
                }
                setMulti([...multifile]);
                setPhotoErrors("Image size should be less than 2MB");
              }
            }
          }
        }
      }
    }
  }
  //audio file
  const [formaudio, setAudioFile] = useState("");
  const [formaudioupload, setAudioUpload] = useState("");
  const [formaudioerror, setAudioErrors] = useState("");

  function audiofile(e, cat, file, index) {
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";

            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(mp3|wav)$/i)) {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                setMulti([...multifile]);

                var audio = document.getElementById("audio");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    audio.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
              } else {
                multifile[i].fileload = e.target.files[0];

                multifile[i].fileerror = "Audio should be mp3 or wav format";
                setMulti([...multifile]);

                setAudioErrors("Audio should be mp3 or wav format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 50) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Audio size should be less than 50MB";
                } else {
                  multifile[i].fileerror =
                    "Audio should be mp3 or wav format and file size should be less than 50MB";
                }
                setMulti([...multifile]);

                setAudioErrors("Audio size should be less than 50MB");
              }
            }
          }
        }
      }
    }

    // e.preventDefault();
    // setPhotoErrors("");
    // setErrorMessage("");
    // if (e.target.files.length !== 0) {
    //   if (e.target.files[0].name.match(/\.(mp3|wav)$/i)) {
    //     setAudioUpload(URL.createObjectURL(e.target.files[0]));
    //     setAudioFile(e.target.files[0]);
    //   } else {
    //     setAudioErrors("audio should be mp3 or wav format");
    //   }
    //   var size = e.target.files[0].size / 1024 / 1024;
    //   if (size > 3) {
    //     setAudioErrors("Audio size should be less than 3MB");
    //   }
    // }
  }

  const [formworderror, setWordErrors] = useState("");

  function wordfile(e, cat, file, index) {
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";
            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(doc|docx)$/i)) {
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                multifile[i].fileload = e.target.files[0];
                setMulti([...multifile]);

                var worddoc = document.getElementById("word");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    worddoc.src =
                      "https://view.officeapps.live.com/op/embed.aspx?src=" +
                      URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
                // setVideoFile(e.target.files[0]);
              } else {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileerror =
                  "Document should be .doc or .docx format";
                setMulti([...multifile]);

                setWordErrors("Document should be .docx format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 2) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Document size should be less than 2MB";
                } else {
                  multifile[i].fileerror =
                    "Document should be .doc or .docx format and file size should be less than 2MB";
                }
                setMulti([...multifile]);
                setWordErrors("Document size should be less than 2MB");
              }
            }
          }
        }
      }
    }
  }

  // const [defaultFileList, setDefaultFileList] = useState([]);
  // const [progress, setProgress] = useState(0);

  // const handleOnChange = ({ file, fileList, event }) => {
  //   if (fileList[0].percent === 100) {
  //     setDefaultFileList(fileList);
  //     console.log(fileList);
  //   }
  // };

  // const uploadImage = async (options) => {
  //   const { onSuccess, onError, action, file, onProgress } = options;
  //   const url = action;
  //   const fmData = new FormData();
  //   const config = {
  //     headers: { "content-type": "multipart/form-data" },
  //     onUploadProgress: (event) => {
  //       const percent = Math.floor((event.loaded / event.total) * 100);
  //       setProgress(percent);
  //       // if (percent === 100) {
  //       //   setTimeout(() => setProgress(0), 1000);
  //       // }
  //       onProgress({ percent: (event.loaded / event.total) * 100 });
  //     },
  //   };
  //   fmData.append("image", file);
  //   try {
  //     const res = await axios.post(
  //       "https://jsonplaceholder.typicode.com/posts",
  //       fmData,
  //       config
  //     );

  //     onSuccess("Ok");
  //   } catch (err) {
  //     const error = new Error("Some error");
  //     onError({ err });
  //   }
  // };

  //video file
  const [formvideo, setVideoFile] = useState("");
  const [formvideoerror, setVideoErrors] = useState("");

  function videofile(e, cat, file, index) {
    // e.preventDefault();
    // setVideoErrors("");
    // setErrorMessage("");
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";
            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(mp4)$/i)) {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                setMulti([...multifile]);
                var video = document.getElementById("video");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    video.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
              } else {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileerror = "Video should be mp4 format";
                setMulti([...multifile]);

                setVideoErrors("Video should be mp4 format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 50) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Video size should be less than 50MB";
                } else {
                  multifile[i].fileerror =
                    "Video should be mp4 format and file size should be less than 50MB";
                }
                setMulti([...multifile]);
                setVideoErrors("Video size should be less than 50MB");
              }
            }
          }
        }
      }
    }
  }
  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  const handleClose = () => setShow(false);
  function eventreg(e) {
    e.preventDefault();
    setErrorMessage("");
    setSubmitted(true);

    let filecheck = true;
    // if (multifile.length !== 0) {
    //   for (let i = 0; i < multifile.length; i++) {
    //     if (multifile[i].fileload !== "") {
    //       if (multifile[i].fileerror !== "") {
    //         filecheck = false;
    //         setErrorMessage(
    //           multifile[i].category + " - " + multifile[i].fileerror
    //         );
    //         break;
    //       }
    //     }else{
    //       filecheck = false
    //     }
    //   }
    // }
    // if (multifile.length !== 0) {
    //   for (let i = 0; i < multifile.length; i++) {
    //       if (multifile[i].fileload !== "") {
    //         if (multifile[i].fileerror !== "") {
    //           filecheck = false;
    //           setErrorMessage(
    //             multifile[i].category + " - " + multifile[i].fileerror
    //           );
    //           break;
    //         }
    //       } else {
    //         if (multifile[i].filetype === "1") {
    //           filecheck = false;
    //           setErrorMessage(
    //             multifile[i].category + " - Video file is required"
    //           );
    //         } else if (multifile[i].filetype === "2") {
    //           filecheck = false;
    //           setErrorMessage(
    //             multifile[i].category + " - Audio file is required"
    //           );
    //         } else if (multifile[i].filetype === "3") {
    //           filecheck = false;
    //           setErrorMessage(
    //             multifile[i].category + " - Image file is required"
    //           );
    //         } else if (multifile[i].filetype === "4") {
    //           filecheck = false;
    //           setErrorMessage(
    //             multifile[i].category + " - Document file is required"
    //           );
    //         }
    //         break;
    //     }
    //   }
    // }
    if (filecheck === true) {
      if (check === false) {
        setErrorMessage("Please Agree our terms and conditions");
      }
      if (check === true) {
        setLoading(true);

        const tempData = new FormData();
        tempData.append("event_uid", event);
        tempData.append("user_uid", "0");
        tempData.append("guest_uid", guest);

        var gct = guestcategory.split(",");
        if (gct.length > 1) {
          tempData.append("category_uid", category);
        }
        if (multifile.length !== 0) {
          for (let i = 0; i < multifile.length; i++) {
            if (gct.length === 1) {
              tempData.append("category_uid", multifile[i].category_uid);
            }
            if (multifile[i].file_type !== "") {
              tempData.append(
                "file_type_" + multifile[i].category_uid,
                multifile[i].file_type
              );
            }

            tempData.append(
              "file_name_" +
                multifile[i].category_uid +
                "_" +
                multifile[i].filetype,
              multifile[i].fileload
            );
          }
        }

        const interval = setInterval(() => {
          setLoadingPer((prevProgress) =>
            prevProgress >= 90 ? 5 : prevProgress + 5
          );
        }, 2500);

        evententryadd(tempData).then((responsedata) => {
          if (responsedata.status === true) {
            setLoadingPer(100);

            setLoading(false);

            handleShow();
          } else {
            setErrorMessage(responsedata.data);
          }
        });
      }
    }
  }
  const [ename, setEName] = useState("");
  const [vname, setVName] = useState("");
  const [guest, setGuest] = useState("");
  const [event, setEvent] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [amount, setAmt] = useState("");
  const [category, setCategory] = useState("");
  const [guestcategory, setGCategory] = useState("");

  const [filetypes, setFiletypes] = useState("");
  const [status, setStatus] = useState("0");
  const [already, setAlready] = useState("");
  const [multifile, setMulti] = useState([]);
  const [filecir, setFilecir] = useState("");

  const arr = [];
  useEffect(() => {
    getguesthashdetails(id).then((response) => {
      if (response.status === true) {
        setEName("Event Name:- " + response.data.event_name);
        setVName("Event Venue:- " + response.data.event_venue);
        setSDate(
          "Start Date:- " +
            moment(response.data.event_start).format("DD-MM-YYYY")
        );
        setEDate(
          "End Date:- " + moment(response.data.event_end).format("DD-MM-YYYY")
        );
        setCDate(
          "Result Declare:- " +
            moment(response.data.event_entry_close).format("DD-MM-YYYY")
        );
        // setFiletypes(response.data.event_file_type);
        // setFiletypes("1,2");
        setCategory(response.data.event_category_uid);
        setGCategory(response.data.guest_category);
        if (response.data.event_uid !== 0) {
          viewevent(response.data.event_uid).then((res) => {
            if (res.data.SelectedEventCategories.length !== 0) {
              for (
                let i = 0;
                i < res.data.SelectedEventCategories.length;
                i++
              ) {
                var icat = response.data.guest_category.split(",");
                for (let k = 0; k < icat.length; k++) {
                  if (
                    parseInt(icat[k]) ===
                    res.data.SelectedEventCategories[i].category_uid
                  ) {
                    var ftype =
                      res.data.SelectedEventCategories[i].file_type.split(",");
                    if (ftype !== "") {
                      for (let j = 0; j < ftype.length; j++) {
                        var type =
                          res.data.SelectedEventCategories[i].file_type;
                        var head =
                          "Attach Files for " +
                          res.data.SelectedEventCategories[i].category;
                        if (j !== 0) {
                          type = "";
                          head = "";
                        }
                        arr.push({
                          category_uid:
                            res.data.SelectedEventCategories[i].category_uid,
                          file_type: type,
                          heading: head,
                          filetype: ftype[j],
                          fileload: "",
                          fileurl: "",
                          fileerror: "",
                          category:
                            res.data.SelectedEventCategories[i].category,
                        });
                      }
                    }
                  }
                }
              }
              setMulti(arr.sort((a, b) => a.category_uid - b.category_uid));
            }
          });
        }
        // var cat = response.data.category_uid.split(",");
        // if (cat.length !== 0) {
        //   for (let i = 0; i < cat.length; i++) {
        //     if (cat[i] !== "") {
        //       categorydetailmail(response.data.event_uid, cat[i]).then(
        //         (res) => {
        //           if (res.data !== null) {
        //             var ftype = res.data.file_type.split(",");
        //             if (ftype !== "") {
        //               for (let j = 0; j < ftype.length; j++) {
        //                 var type = res.data.file_type;
        //                 var head = "Attach Files for " + res.data.category;
        //                 if (j !== 0) {
        //                   type = "";
        //                   head = "";
        //                 }
        //                 arr.push({
        //                   category_uid: res.data.category_uid,
        //                   file_type: type,
        //                   heading: head,
        //                   filetype: ftype[j],
        //                   fileload: "",
        //                   fileurl: "",
        //                 });
        //               }
        //             }
        //             // arr.push({
        //             //   category_uid: res.data.category_uid,
        //             //   file_type: res.data.file_type,
        //             //   heading: "Attach Files for " + res.data.category,
        //             //   file,
        //             // });
        //           }
        //           array = arr.sort((a, b) => a.category_uid - b.category_uid);
        //           setMulti(arr.sort((a, b) => a.category_uid - b.category_uid));
        //         }
        //       );
        //     }
        //   }
        // }

        setGuest(response.data.guest_uid);
        if (response.data.guest_uid !== null) {
        } else {
          window.open("https://pythiangames.org/", "_self");
        }
        setEvent(response.data.event_uid);
        setAmt("Entry Fee:- " + response.data.payment_amount + " INR");
        setFilecir(imageUrl + "evententry/circular/" + response.data.circular);
        if (response.data.payment_status !== null) {
          setStatus(response.data.payment_status);
        } else {
          setStatus("0");
        }

        alreadyexistsmail(response.data.guest_uid).then((res) => {
          if (res.status === false) {
            setAlready(res.message);
          }
        });
      }
    });
  }, []);
  const [loading, setLoading] = useState(false);
  const [loadingper, setLoadingPer] = useState(0);

  return (
    <div>
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          {/* <h3>Loading...</h3> */}
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: "90%" }}
            aria-valuenow={loadingper}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {loadingper}%
          </div>
        </div>
      )}

      {guest && (
        <div id="registrationpage">
          <div className="registrationmain">
            <div className="registrationleft">
              <img
                src={require("../../assets/images/logo/logonew.png")}
                height="160"
                className="logo-image"
              />

              <div className="deshtop-screen">
                <img
                  src={require("../../assets/images/art-image.png")}
                  width="440"
                  height=""
                />
              </div>
            </div>
            <div className="registrationright">
              <form action="#" className="form">
                <h2 className="text-right">Event Submission</h2>
                <div className="guestreg row">
                  <div className="col-md-12">
                    <h6>{ename}</h6>
                  </div>
                  {/* <div className="col-md-6">
                    <h6>{vname}</h6>
                  </div> */}
                  {/* <div className="col-md-6"> <h6>{cname}</h6> </div> */}
                  <div className="col-md-4">
                    <h6>{sdate}</h6>
                  </div>
                  <div className="col-md-4">
                    <h6>{edate}</h6>
                  </div>
                  <div className="col-md-4">
                    <h6>{cdate}</h6>
                  </div>
                </div>

                {already !== "" && (
                  <div align="center">
                    <br />
                    <br />
                    <h3>{already}</h3>
                  </div>
                )}
                {already === "" && (
                  <div className="form-step form-step-active">
                    {multifile.map((data, j) => {
                      return (
                        <div key={j}>
                          {data.file_type !== "" && (
                            <div className="form-inner form-innertwo">
                              <h4>{data.heading}</h4>
                              {data.file_type.includes("1") && (
                                <div className="input-group form-upload">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label htmlFor="dob">
                                        Add Video File :{" "}
                                        <b className="text-danger">*</b>{" "}
                                        <span>
                                          (Accepted Files are .mp4, .wav & Max
                                          Upload Size 50MB )
                                        </span>
                                      </label>

                                      <div className="mt-4">
                                        <input
                                          type="file"
                                          name="file"
                                          id="file"
                                          accept=".mp4"
                                          onChange={(e) =>
                                            videofile(
                                              e,
                                              data.category_uid,
                                              "1",
                                              j
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="mt-3">
                                        {/* {progress > 0 ? (
                                          <Progress percent={progress} />
                                        ) : null} */}
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="imagecontainer">
                                        <video
                                          id="video"
                                          width="100%"
                                          height="110px"
                                          controls
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {data.fileload === "" && submitted && (
                                    <span className="errortxt text-danger">
                                      Video file is required
                                    </span>
                                  )}
                                </div>
                              )}
                              {data.file_type.includes("2") && (
                                <div className="input-group form-upload">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label htmlFor="dob">
                                        Add Audio File :{" "}
                                        <b className="text-danger">*</b>{" "}
                                        <span>
                                          (Accepted Files are .mp3 & Max Upload
                                          Size 50MB )
                                        </span>
                                      </label>

                                      <div className="mt-4">
                                        <input
                                          type="file"
                                          name="file"
                                          id="file"
                                          accept=".mp3,.wav"
                                          onChange={(e) =>
                                            audiofile(
                                              e,
                                              data.category_uid,
                                              "2",
                                              j
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="imagecontainer">
                                        <audio
                                          id="audio"
                                          controls
                                          style={{ width: "100px" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {data.fileload === "" && submitted && (
                                    <span className="errortxt text-danger">
                                      Audio file is required
                                    </span>
                                  )}
                                </div>
                              )}
                              {data.file_type.includes("3") && (
                                <div className="input-group form-upload">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label htmlFor="dob">
                                        Add Image File :{" "}
                                        <b className="text-danger">*</b>{" "}
                                        <span>
                                          (Accepted Files are .jpg,.jpeg,.png &
                                          Max Upload Size 2MB )
                                        </span>
                                      </label>

                                      <div className="mt-4">
                                        <input
                                          type="file"
                                          name="file"
                                          id="file"
                                          accept=".png,.jpg,.jpeg"
                                          onChange={(e) =>
                                            photofile(
                                              e,
                                              data.category_uid,
                                              "3",
                                              j
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="imagecontainer">
                                        <img
                                          id="image"
                                          width="100%"
                                          height="auto"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {data.fileload === "" && submitted && (
                                    <span className="errortxt text-danger">
                                      Image file is required
                                    </span>
                                  )}
                                </div>
                              )}

                              {data.file_type.includes("4") && (
                                <div className="input-group form-upload">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label htmlFor="dob">
                                        Add Word File :{" "}
                                        <b className="text-danger">*</b>{" "}
                                        <span>
                                          (Accepted Files are .doc,.docx & Max
                                          Upload Size 2MB )
                                        </span>
                                      </label>

                                      <div className="mt-4">
                                        <input
                                          type="file"
                                          name="file"
                                          id="file"
                                          accept=".doc,.docx"
                                          onChange={(e) =>
                                            wordfile(
                                              e,
                                              data.category_uid,
                                              "4",
                                              j
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="imagecontainer">
                                        {/* <iframe
                                          id="word"
                                          width="100%"
                                          height="110px"
                                        /> */}
                                        <img
                                          src={require("../../assets/images/word.png")}
                                          width="100%"
                                          height="auto"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {data.fileload === "" && submitted && (
                                    <span className="errortxt text-danger">
                                      Word file is required
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}

                    <div className="row">
                      <div className="col-md-9">
                        <div className="checkbox checkbox-1">
                          <input
                            type="checkbox"
                            id="checkbox-1"
                            onChange={checkterm}
                            checked={check}
                          />
                          <label htmlFor="checkbox-1">
                            Agree to our{" "}
                            <a
                              target="_blank"
                              href="https://www.pythiangames.org/privacy"
                            >
                              Privacy Policy
                            </a>{" "}
                            ,{" "}
                            <a
                              target="_blank"
                              href="https://www.pythiangames.org/terms"
                            >
                              Terms & Conditions
                            </a>{" "}
                            and{" "}
                            <a
                              target="_blank"
                              // href={require("../../assets/images/Poetry_and_Painting_art_Compition.pdf")}
                              href={filecir}
                            >
                              Submission & Guidelines
                            </a>
                            <b className="text-danger">*</b>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        {status === "1" && (
                          <div className="member">
                            <label>{amount}</label>
                          </div>
                        )}
                        {status === "0" && (
                          <div className="member">
                            <label>Entry Fee: Free</label>
                          </div>
                        )}
                      </div>
                    </div>

                    {errorMessage !== "" && (
                      <span className="errortxt text-danger">
                        {errorMessage}
                      </span>
                    )}

                    <div className="">
                      <a
                        className="btn btn-primary btn-next"
                        style={{ float: "right" }}
                        onClick={eventreg}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                )}

                <Modal
                  show={show}
                  onHide={handleClose}
                  size="large"
                  backdrop="static"
                >
                  <Modal.Header>
                    <h4>Submission Successful!</h4>
                    <i
                      className="fa fa-window-close fa-lg"
                      aria-hidden="true"
                      onClick={handleClose}
                    ></i>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Event Submitted Successfully!</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Link
                      className="btn btn-success mr-3"
                      style={{ float: "right" }}
                      to="/login"
                    >
                      ok
                    </Link>
                  </Modal.Footer>
                </Modal>
              </form>
            </div>
          </div>
        </div>
      )}
      {!guest && (
        <div id="registrationpage">
          <div className="registrationmain">
            <div className="registrationleft">
              <a href="https://pythiangames.org/" target="_blank">
                {" "}
                <img
                  src={require("../../assets/images/logo/logonew.png")}
                  height="160"
                  className="logo-image"
                />
              </a>

              <div className="deshtop-screen">
                <img
                  src={require("../../assets/images/art-image.png")}
                  width="440"
                  height=""
                />
              </div>
            </div>
            <div className="registrationright">
              <form action="#" className="form">
                <div className="text-right">
                  <a
                    className="btn btn-primary mr-2"
                    href="https://pythiangames.org/"
                    target="_self"
                  >
                    Back
                  </a>
                </div>
                <Container fluid className="p-3 text-center mt-5">
                  <div className="text-center text-white background-text ">
                    <h1 className="mb-3 tx-46 font-weight-semibold text-primary">
                      Invalid Guest
                    </h1>
                  </div>
                </Container>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EntryregisterTwo;
