import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { logindata } from "../../services/login";
import { useEffect } from "react";

function Login() {
  const [user, setUser] = useState("");
  const [pass, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
  });
  function Submitlogin(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage("");
    localStorage.clear();
    const tempData = {
      username: user,
      password: pass,
    };
    if (tempData.username !== "" && tempData.password !== "") {
      if (tempData.password.length < 6) {
        setErrorMessage("Password must be minimum 6 characters");
      } else {
        logindata(tempData).then((response) => {
          if (response.status === true) {
            localStorage.setItem("Userid", response.data.user_uid);
            localStorage.setItem("Roleid", response.data.role_uid);
            localStorage.setItem("Token", response.data.token);
            localStorage.setItem("User", response.data.username);
            if (response.data.last_name !== null) {
              localStorage.setItem(
                "Name",
                response.data.first_name + " " + response.data.last_name
              );
            } else {
              localStorage.setItem("Name", response.data.first_name);
            }
            if (response.data.role_uid === 1) {
              navigate("/dashboard");
            } else if (response.data.role_uid === 2) {
              navigate("/user/dashboard");
            } else if (response.data.role_uid === 3) {
              navigate("/dashboard");
            }else if (response.data.role_uid === 4) {
              navigate("/jury/dashboard");
            }
          } else {
            setErrorMessage(response.message);
          }
        });
      }
    }
  }
  return (
    <div>
      <main id="loginpage">
        <div className="container">
          <div className="loginpage_section">
            <div className="loginpage_secionone">
              <div className="loginpage_sectionone_left">
                <div className="loginpage_logo">
                  <a href="https://pythiangames.org/" target="_blank">
                    <img
                      src={require("../../assets/images/logo/logonew.png")}
                      height="150"
                    />
                  </a>
                </div>

                <img
                  src={require("../../assets/images/imageleft.png")}
                  className="loginleftimg"
                />
              </div>
            </div>

            <div className="loginpage_seciontwo">
              <div className="loginpage_sectiontwo_form">
                <form className="form">
                  <h5 className="text-center"> Welcome to</h5>
                  <h5 className="text-center"> Modern Pythian Games</h5>
                  <h2 className="text-center">Membership Login</h2>
                  <fieldset className="username">
                    <label>Username</label>
                    <input
                      type="text"
                      placeholder="Enter your Username"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                    />
                    {submitted && !user && (
                      <span className="errortext">Username is required</span>
                    )}
                  </fieldset>

                  <fieldset className="password">
                    <label>Password</label>
                    <input
                      type="password"
                      placeholder="Enter your Password"
                      value={pass}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {submitted && !pass && (
                      <span className="errortext">Password is required</span>
                    )}
                  </fieldset>
                  {errorMessage && (
                    <span className="errortext"> {errorMessage} </span>
                  )}
                  <div className="text-right">
                    <Link to={"/forgotpassword"}>Forgot Password?</Link>
                  </div>
                  <button type="submit" className="btn" onClick={Submitlogin}>
                    {" "}
                    Login
                  </button>
                  {/* <div className="text-center mt-4">
                    <h6 className="mb-0">Are you New User?</h6>
                    <Link to={"/register"} className="text-primary">
                      Register Here!
                    </Link>
                  </div> */}
                </form>
              </div>
            </div>
            <div className="loginpage_footer">
              <div className="copyrights">
                <span>
                  © {new Date().getFullYear()} Pythian Games | All Rights
                  Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
