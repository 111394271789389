import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  addeventdata,
  eventcategorylist,
  updateeventdata,
  viewevent,
  listeventcatagory,
  removeassignjury,
} from "../../../services/admin";
import Select from "react-select";
import moment from "moment";
import imageUrl from "../../../ImageUrl";
import "./list.css";
import { assignedjurylist } from "../../../services/jury";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
const typearr = [
  {
    id: 1,
    type: "Video",
    sele: 0,
  },
  {
    id: 2,
    type: "Audio",
    sele: 0,
  },
  {
    id: 3,
    type: "Image",
    sele: 0,
  },
  {
    id: 4,
    type: "Doc",
    sele: 0,
  },
];
const Addevent = () => {
  const navigate = useNavigate();
  const [eventname, setEventName] = useState("");
  const [eventvenue, setEventVenue] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [close, setClose] = useState("");
  const [amt, setAmt] = useState("");
  const [evetype, setEType] = useState("");
  const [viewtype, setViewEType] = useState([]);

  const [category, setCategory] = useState("");
  const [catname, setCatName] = useState("");
  const [catlist, setCatlist] = useState([]);
  const arr = [];
  const arr1 = [];

  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState("");

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setPhotoUpload("");
    setPhotoFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));

        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Logo should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Logo size should be less than 3MB");
      }
    }
  }

  const [formid, setIDFile] = useState("");
  const [formiderror, setIDErrors] = useState("");

  function idfile(e) {
    e.preventDefault();
    setIDErrors("");
    setIDFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(pdf)$/i)) {
        setIDFile(e.target.files[0]);
      } else {
        setIDErrors("Circular should be pdf format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setIDErrors("Circular size should be less than 3MB");
      }
    }
  }
  function addcate() {
    setErrorMessage("");
    var noshow = "";
    var entype = "";

    if (selectedValue.length !== 0) {
      for (let j = 0; j < selectedValue.length; j++) {
        if (selectedValue[j].vsele === 1) {
          entype = "";
        } else if (selectedValue[j].asele === 2) {
          entype = "";
        } else if (selectedValue[j].isele === 3) {
          entype = "";
        } else if (selectedValue[j].dsele === 4) {
          entype = "";
        } else {
          entype = "required error";
          break;
        }
      }
    }

    if (eventname !== "") {
      if (eventvenue !== "") {
        if (selectedValue.length !== 0) {
          if (sdate !== "") {
            if (edate !== "") {
              if (edate >= sdate) {
                if (cdate !== "") {
                  if (cdate >= edate) {
                    if (status !== "") {
                      if (status === "1") {
                        if (amt !== "") {
                          noshow = "";
                        } else {
                          noshow = "Please Enter Your Entry Fee";
                          setErrorMessage("Please Enter Your Entry Fee");
                        }
                      } else {
                        setAmt("0");
                      }
                      if (noshow === "") {
                        if (entype === "") {
                          let etype = "";
                          if (typearr.length !== 0) {
                            for (let i = 0; i < typearr.length; i++) {
                              if (typearr[i].sele !== 0) {
                                if (etype === "") {
                                  etype = typearr[i].id;
                                } else {
                                  etype = etype + "," + typearr[i].id;
                                }
                              }
                            }
                          }

                          let cid = "";
                          if (selectedValue.length !== 0) {
                            for (let j = 0; j < selectedValue.length; j++) {
                              if (cid === "") {
                                cid = selectedValue[j].value;
                              } else {
                                cid = cid + "," + selectedValue[j].value;
                              }
                            }
                          }
                          if (add === "0") {
                            const temp = new FormData();
                            temp.append("event_name", eventname);
                            temp.append("event_venue", eventvenue);
                            temp.append("category_uid", cid);
                            temp.append("event_start", sdate);
                            temp.append("event_end", edate);
                            temp.append("event_entry_close", cdate);
                            temp.append("payment_status", status);
                            temp.append("payment_amount", amt);
                            temp.append("payment_currency", "INR");
                            temp.append(
                              "user_uid",
                              localStorage.getItem("Userid")
                            );
                            temp.append("logo", formphoto);
                            temp.append("circular", formid);
                            if (selectedValue.length !== 0) {
                              let svalue = "";
                              for (let j = 0; j < selectedValue.length; j++) {
                                if (selectedValue[j].vsele === 1) {
                                  svalue = selectedValue[j].vid;
                                }
                                if (selectedValue[j].asele === 2) {
                                  if (svalue === "") {
                                    svalue = selectedValue[j].aid;
                                  } else {
                                    svalue =
                                      svalue + "," + selectedValue[j].aid;
                                  }
                                }
                                if (selectedValue[j].isele === 3) {
                                  if (svalue === "") {
                                    svalue = selectedValue[j].iid;
                                  } else {
                                    svalue =
                                      svalue + "," + selectedValue[j].iid;
                                  }
                                }
                                if (selectedValue[j].dsele === 4) {
                                  if (svalue === "") {
                                    svalue = selectedValue[j].did;
                                  } else {
                                    svalue =
                                      svalue + "," + selectedValue[j].did;
                                  }
                                }
                                temp.append(
                                  "event_file_type_" + selectedValue[j].value,
                                  svalue
                                );
                                svalue = "";
                              }
                            }
                            addeventdata(temp).then((response) => {
                              if (response.status === true) {
                                navigate("/eventlist");
                              }
                            });
                          } else if (add === "1") {
                            const temp = new FormData();
                            temp.append("event_uid", id);
                            temp.append("event_name", eventname);
                            temp.append("event_venue", eventvenue);
                            temp.append("category_uid", cid);
                            temp.append("event_start", sdate);
                            temp.append("event_end", edate);
                            temp.append("event_entry_close", cdate);
                            temp.append("payment_status", status);
                            temp.append("payment_amount", amt);
                            temp.append("payment_currency", "INR");
                            temp.append(
                              "user_uid",
                              localStorage.getItem("Userid")
                            );
                            temp.append("logo", formphoto);
                            temp.append("circular", formid);
                            if (selectedValue.length !== 0) {
                              let svalue = "";
                              for (let j = 0; j < selectedValue.length; j++) {
                                if (selectedValue[j].vsele === 1) {
                                  svalue = selectedValue[j].vid;
                                }
                                if (selectedValue[j].asele === 2) {
                                  if (svalue === "") {
                                    svalue = selectedValue[j].aid;
                                  } else {
                                    svalue =
                                      svalue + "," + selectedValue[j].aid;
                                  }
                                }
                                if (selectedValue[j].isele === 3) {
                                  if (svalue === "") {
                                    svalue = selectedValue[j].iid;
                                  } else {
                                    svalue =
                                      svalue + "," + selectedValue[j].iid;
                                  }
                                }
                                if (selectedValue[j].dsele === 4) {
                                  if (svalue === "") {
                                    svalue = selectedValue[j].did;
                                  } else {
                                    svalue =
                                      svalue + "," + selectedValue[j].did;
                                  }
                                }
                                temp.append(
                                  "event_file_type_" + selectedValue[j].value,
                                  svalue
                                );
                                svalue = "";
                              }
                            }
                            updateeventdata(temp).then((response) => {
                              if (response.status === true) {
                                navigate("/eventlist");
                              }
                            });
                          }
                        } else {
                          setErrorMessage("Please Select Your Entry Type");
                        }
                      }
                    } else {
                      setErrorMessage("Please Select Your Entry Payment");
                    }
                  } else {
                    setErrorMessage(
                      "Result Declare Date must be greater than Event End Date"
                    );
                  }
                } else {
                  setErrorMessage("Please Enter Your Result Declare Date");
                }
              } else {
                setErrorMessage(
                  "Event End Date must be greater than Event Start Date"
                );
              }
            } else {
              setErrorMessage("Please Enter Your Event End Date");
            }
          } else {
            setErrorMessage("Please Enter Your Event Start Date");
          }
        } else {
          setErrorMessage("Please Enter Your Event Category");
        }
      } else {
        setErrorMessage("Please Enter Your Event Venue");
      }
    } else {
      setErrorMessage("Please Enter Your Event Name");
    }
  }

  const [selectedValue, setSelectedValue] = useState([]);

  function handleChange(e) {
    setSelectedValue(e);
  }

  function Selectstatus(type, index) {
    if (type === 1) {
      if (selectedValue[index].vsele === 1) {
        selectedValue[index].vsele = 0;
      } else {
        selectedValue[index].vsele = 1;
      }
    } else if (type === 2) {
      if (selectedValue[index].asele === 2) {
        selectedValue[index].asele = 0;
      } else {
        selectedValue[index].asele = 2;
      }
    } else if (type === 3) {
      if (selectedValue[index].isele === 3) {
        selectedValue[index].isele = 0;
      } else {
        selectedValue[index].isele = 3;
      }
    } else if (type === 4) {
      if (selectedValue[index].dsele === 4) {
        selectedValue[index].dsele = 0;
      } else {
        selectedValue[index].dsele = 4;
      }
    }
    setSelectedValue([...selectedValue]);
  }

  const [status, setStatus] = useState("0");

  function Selectpay(event) {
    setStatus(event.target.value);
  }

  function viewedit() {
    navigate("/editevent/" + id);
    window.location.reload();
  }

  function ending(e) {
    setErrorMessage("");
    setEDate(e.target.value);
    if (e.target.value < sdate) {
      setErrorMessage("Event End Date must be greater than Event Start Date");
    } else if (e.target.value === sdate) {
      setErrorMessage("");
    }
  }
  const [isLoading, setIsLoading] = useState(false);
  const [evelogo, setEventlogo] = useState("");
  const [evecircular, setEventCir] = useState("");

  const [add, setAdd] = useState("1");
  let arr2 = "";
  let arr3 = [];
  const [heading, setHeading] = useState("");

  const [selectcat, setSelectcat] = useState([]);

  const [filterProduct, setFilterProduct] = useState([]);

  function deletejury(e, jid) {
    e.preventDefault();
    Swal.fire({
      title: `Are you sure want to Delete this jury?`,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        removeassignjury(jid).then((response) => {
          if (response.status === true) {
            eventjurylist(id);
          }
        });
      }
    });
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  function eventjurylist(eid) {
    setFilterProduct([]);
    assignedjurylist(eid).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].jury_first_name;
            if (response.data[i].jury_last_name !== null) {
              name = name + " " + response.data[i].jury_last_name;
            }
            arr1.push({
              jury_first_name: name,
              first_name:
                response.data[i].first_name + " " + response.data[i].last_name,
              category: response.data[i].category,
              username: response.data[i].username,
              photo: response.data[i].photo,
              usertype: response.data[i].usertype,
              user_uid: response.data[i].user_uid,
              jury_user_uid: response.data[i].jury_user_uid,
            });
          }
        }
        setFilterProduct([]);
        setFilterProduct(arr1);
      }
    });
  }

  const columnDefs = [
    {
      headerName: "Actions",
      width: 150,
      field: "jury_user_uid",

      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return (
          <div className="col-md-5">
            <a
              onClick={(e) => deletejury(e, data.value)}
              className="btn-delete"
            >
              Delete
            </a>
          </div>
        );
      },
    },
    {
      headerName: "Photo",
      width: 150,
      field: "photo",
      filter: false,
      cellRenderer: function (params) {
        return (
          <img
            style={{ width: "40px", height: "40px" }}
            src={imageUrl + "jury/photo/" + params.data.photo}
            onError={(e) => errorhandle(e)}
          />
        );
      },
    },

    {
      headerName: "Jury Name",
      width: 320,
      field: "jury_first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "User Name",
      width: 320,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 310,
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Usertype",
      width: 300,
      field: "usertype",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
  ];
  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/");
    const menuviewedit = last[last.length - 2];
    if (menuviewedit === "viewevent") {
      setIsLoading(true);
      setHeading("View Event");
    } else if (menuviewedit === "addevent" || menuviewedit === "#") {
      setAdd("0");
      setHeading("Add Event");
    } else if (menuviewedit === "editevent") {
      setHeading("Edit Event");
      setAdd("1");
    }

    eventcategorylist().then((response) => {
      if (response.status === true) {
        for (let i = 0; i < response.data.length; i++) {
          arr.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
            vid: 1,
            video: "Video",
            vsele: 0,
            aid: 2,
            audio: "Audio",
            asele: 0,
            iid: 3,
            image: "Image",
            isele: 0,
            did: 4,
            doc: "Doc",
            dsele: 0,
          });
        }
        setCatlist(arr);
      }
    });
    if (menuviewedit !== "addevent") {
      var earray = [];

      viewevent(id).then((response) => {
        if (response.status === true) {
          if (response.data !== null) {
            setEventName(response.data.event_name);
            setEventlogo(imageUrl + "evententry/logo/" + response.data.logo);
            setEventCir(
              imageUrl + "evententry/circular/" + response.data.circular
            );

            setEventVenue(response.data.event_venue);
            setStart(moment(response.data.event_start).format("DD-MM-YYYY"));
            setEnd(moment(response.data.event_end).format("DD-MM-YYYY"));
            setClose(
              moment(response.data.event_entry_close).format("DD-MM-YYYY")
            );
            setSDate(response.data.event_start);
            setEDate(response.data.event_end);
            setCDate(response.data.event_entry_close);

            setSelectcat(response.data.SelectedEventCategories);
            if (response.data.SelectedEventCategories.length !== 0) {
              for (
                let i = 0;
                i < response.data.SelectedEventCategories.length;
                i++
              ) {
                if (arr2 === "") {
                  arr2 = response.data.SelectedEventCategories[i].category;
                } else {
                  arr2 =
                    arr2 +
                    " , " +
                    response.data.SelectedEventCategories[i].category;
                }

                if (
                  response.data.SelectedEventCategories[i].file_type !== null
                ) {
                  var atype = "";

                  atype = response.data.SelectedEventCategories[i].file_type;
                  var name = atype.split(",");
                  if (name.length !== 0) {
                    var type = "";
                    for (let j = 0; j < name.length; j++) {
                      if (type === "") {
                        if (name[j] === "1") {
                          type = "Video";
                        } else if (name[j] === "2") {
                          type = "Audio";
                        } else if (name[j] === "3") {
                          type = "Image";
                        } else if (name[j] === "4") {
                          type = "Doc";
                        }
                      } else {
                        if (name[j] === "1") {
                          type = type + ", Video";
                        } else if (name[j] === "2") {
                          type = type + ", Audio";
                        } else if (name[j] === "3") {
                          type = type + ", Image";
                        } else if (name[j] === "4") {
                          type = type + ", Doc";
                        }
                      }
                    }

                    earray.push({
                      id: response.data.SelectedEventCategories[i].category_uid,
                      name:
                        "Entry type for " +
                        response.data.SelectedEventCategories[i].category,
                      etype: type,
                    });

                    arr3.push({
                      value:
                        response.data.SelectedEventCategories[i].category_uid,
                      label: response.data.SelectedEventCategories[i].category,
                      vid: 1,
                      video: "Video",
                      vsele: 0,
                      aid: 2,
                      audio: "Audio",
                      asele: 0,
                      iid: 3,
                      image: "Image",
                      isele: 0,
                      did: 4,
                      doc: "Doc",
                      dsele: 0,
                    });
                  }
                }
              }
            }

            if (earray.length !== 0) {
              if (arr3.length !== 0) {
                for (let i = 0; i < earray.length; i++) {
                  for (let j = 0; j < arr3.length; j++) {
                    if (earray[i].id === arr3[j].value) {
                      var arrtype = earray[i].etype.split(",");
                      if (arrtype.length !== 0) {
                        for (let k = 0; k < arrtype.length; k++) {
                          if (arrtype[k].trim() === "Video") {
                            arr3[j].vsele = 1;
                          } else if (arrtype[k].trim() === "Audio") {
                            arr3[j].asele = 2;
                          } else if (arrtype[k].trim() === "Image") {
                            arr3[j].isele = 3;
                          } else if (arrtype[k].trim() === "Doc") {
                            arr3[j].dsele = 4;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setSelectedValue(arr3);
            setViewEType(earray);
            setCatName(arr2);

            setCategory(response.data.category_uid);
            if (response.data.payment_status !== null) {
              let st = response.data.payment_status;
              setStatus(st + "");
            } else {
              setStatus("0");
            }
            setAmt(response.data.payment_amount);
          }
        }
      });
    }

    eventjurylist(id);
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">{heading.toUpperCase()}</h2>
        </div>
      </div>

      {isLoading && (
        <Row className="row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div className="responsive">
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Event Name</b>
                      </div>
                      <div className="col-md-8">
                        <span>{eventname}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Event Venue</b>
                      </div>
                      <div className="col-md-8">
                        <span>{eventvenue}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Event Category</b>
                      </div>
                      <div className="col-md-8">
                        <span>{catname}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Start Date</b>
                      </div>
                      <div className="col-md-8">
                        <span>{start}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">End Date</b>
                      </div>
                      <div className="col-md-8">
                        <span>{end}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Result Declare Date</b>
                      </div>
                      <div className="col-md-8">
                        <span>{close}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Payment</b>
                      </div>
                      <div className="col-md-8">
                        {status === "0" && <span>Free</span>}
                        {status === "1" && <span>Paid</span>}
                      </div>
                    </div>
                    {status === "1" && (
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Entry Fee</b>
                        </div>
                        <div className="col-md-8">
                          <span>{amt} /- INR</span>
                        </div>
                      </div>
                    )}

                    {viewtype.map((data, i) => {
                      return (
                        <div className="input-group">
                          <div className="col-md-4">
                            <b className="text-inverse">{data.name}</b>
                          </div>
                          <div className="col-md-8">
                            <span>{data.etype}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <hr />
                </div>
                <br />

                {/* jury list */}
                <div className="row jlist">
                  <div className="col-md-10">
                    <h2 className="main-content-title tx-24 mg-b-5">
                      Jury List
                    </h2>
                  </div>
                </div>

                <div className="responsive">
                  <div className="App app-vh">
                    <div
                      className="ag-theme-alpine ag-style"
                      style={{ height: 600, width: "100%" }}
                    >
                      <AgGridReact
                        columnDefs={columnDefs}
                        rowData={filterProduct}
                        suppressDragLeaveHidesColumns={true}
                        pagination="true"
                        paginationPageSize="10"
                      />
                    </div>
                  </div>
                </div>
                {/* jurylist end */}
              </Card.Body>
            </Card>
            <div className="bot-c">
              {localStorage.getItem("Roleid") !== "3" && (
                <a
                  className="btn btn-prev btn-primary"
                  style={{ float: "right" }}
                  onClick={viewedit}
                >
                  Edit
                </a>
              )}
              <a
                className="btn btn-next btn-secondary"
                style={{ float: "right" }}
                onClick={() => {
                  navigate("/eventlist");
                }}
              >
                Back
              </a>
            </div>
          </Col>
        </Row>
      )}

      {!isLoading && (
        <Row className="row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div className="responsive">
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Event Name <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your Event Name"
                          value={eventname}
                          onChange={(e) => {
                            setEventName(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Event Venue <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your Event Venue"
                          value={eventvenue}
                          onChange={(e) => {
                            setEventVenue(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Event Category <b className="text-danger">*</b>
                        </label>
                      </div>
                      {add === "0" && (
                        <div className="col-md-8">
                          {/* <select
                          value={category}
                          onChange={(e) => {
                            setCategory(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Your Category
                          </option>
                          {catlist.map((data, i) => {
                            return (
                              <option value={data.category_uid} key={i}>
                                {data.category}
                              </option>
                            );
                          })}
                        </select> */}
                          <Select
                            placeholder="Select Category"
                            value={selectedValue}
                            options={catlist}
                            onChange={handleChange}
                            isMulti
                            isClearable
                          />
                        </div>
                      )}
                      {add === "1" && (
                        <div className="col-md-8">
                          <span>{catname}</span>
                        </div>
                      )}
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Start Date <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="date"
                          placeholder="Enter Your Start Date"
                          value={sdate}
                          onChange={(e) => {
                            setSDate(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          End Date <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="date"
                          placeholder="Enter Your End Date"
                          value={edate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={ending}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Result Declare Date <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="date"
                          placeholder="Enter Your Result Declare Date"
                          value={cdate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setCDate(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Entry Payment <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="radio"
                          name="pay"
                          value="0"
                          checked={status == "0"}
                          onChange={Selectpay}
                        />
                        &nbsp;&nbsp;
                        <label
                          className="custom-control-label-md"
                          style={{ paddingTop: "10px" }}
                        >
                          Free
                        </label>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="radio"
                          name="pay"
                          value="1"
                          checked={status == "1"}
                          onChange={Selectpay}
                        />
                        &nbsp;&nbsp;
                        <label
                          className="custom-control-label-md"
                          style={{ paddingTop: "10px" }}
                        >
                          Paid
                        </label>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    {status === "1" && (
                      <div className="input-group">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Entry Fee <b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Entry Fee"
                            value={amt}
                            onChange={(e) => {
                              setAmt(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {selectedValue.map((data, i) => {
                      return (
                        <div className="input-group" key={i}>
                          <div className="col-md-4">
                            <label htmlFor="username">
                              Event Type for {data.label}
                              <b className="text-danger">*</b>
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-3">
                                <input
                                  type="checkbox"
                                  id={data.video}
                                  value={data.vid}
                                  checked={data.vsele === data.vid}
                                  onChange={() => Selectstatus(data.vid, i)}
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="custom-control-label-md"
                                  style={{ paddingTop: "10px" }}
                                >
                                  {data.video}
                                </label>
                              </div>
                              <div className="col-md-3">
                                <input
                                  type="checkbox"
                                  id={data.audio}
                                  value={data.aid}
                                  checked={data.asele === data.aid}
                                  onChange={() => Selectstatus(data.aid, i)}
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="custom-control-label-md"
                                  style={{ paddingTop: "10px" }}
                                >
                                  {data.audio}
                                </label>
                              </div>
                              <div className="col-md-3">
                                <input
                                  type="checkbox"
                                  id={data.image}
                                  value={data.iid}
                                  checked={data.isele === data.iid}
                                  onChange={() => Selectstatus(data.iid, i)}
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="custom-control-label-md"
                                  style={{ paddingTop: "10px" }}
                                >
                                  {data.image}
                                </label>
                              </div>

                              <div className="col-md-3">
                                <input
                                  type="checkbox"
                                  id={data.doc}
                                  value={data.did}
                                  checked={data.dsele === data.did}
                                  onChange={() => Selectstatus(data.did, i)}
                                />
                                &nbsp;&nbsp;
                                <label
                                  className="custom-control-label-md"
                                  style={{ paddingTop: "10px" }}
                                >
                                  {data.doc}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {add === "1" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-group">
                            <div className="col-md-4">
                              <label htmlFor="username">Circular</label>
                            </div>
                            <div className="col-md-8 imagefile">
                              <img
                                src={require("../../../assets/images/pdf.png")}
                                alt="Circular"
                                onClick={() =>
                                  window.open(evecircular, "_blank")
                                }
                              />

                              <input
                                className="mt-3"
                                type="file"
                                name="file"
                                id="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={idfile}
                              />
                              {formiderror !== "" && (
                                <span className="errortxt text-danger">
                                  {formiderror}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-group">
                            <div className="col-md-4">
                              <label htmlFor="username">Logo</label>
                            </div>
                            <div className="col-md-8 imagefile">
                              <div className="imagecontainer">
                                {/* {formphotoupload !== "" && (
                                  <img src={formphotoupload} alt="Logo" />
                                )}

                                {formphotoupload === "" && ( */}
                                <img src={evelogo} alt="Logo" />
                                {/* )} */}
                                <input
                                  className="mt-4"
                                  type="file"
                                  name="file"
                                  id="file"
                                  accept=".png,.jpg,.jpeg"
                                  onChange={photofile}
                                />
                                {formphotoerror !== "" && (
                                  <span className="errortxt text-danger">
                                    {formphotoerror}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {add === "0" && (
                      <div className="input-group form-upload">
                        <div className="row">
                          <div className="col-md-8">
                            <label htmlFor="dob">
                              {/* Circular : <b className="text-danger">*</b> */}
                              Circular :
                            </label>

                            <div className="mt-4">
                              <input
                                type="file"
                                name="file"
                                id="file"
                                accept=".pdf"
                                onChange={idfile}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="imagecontainer">
                              <img
                                src={require("../../../assets/images/pdf.png")}
                                width="100%"
                                height="auto"
                              />
                            </div>
                          </div>
                        </div>
                        {formiderror !== "" && (
                          <span className="errortxt text-danger">
                            {formiderror}
                          </span>
                        )}
                      </div>
                    )}
                    {add === "0" && (
                      <div className="input-group form-upload">
                        <div className="row">
                          <div className="col-md-8">
                            <label htmlFor="dob">
                              {/* Add Logo : <b className="text-danger">*</b> */}
                              Add Logo :
                            </label>

                            <div className="mt-4">
                              <input
                                type="file"
                                name="file"
                                id="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={photofile}
                              />
                            </div>
                          </div>

                          {formphotoupload !== "" && (
                            <div className="col-md-4">
                              <div className="imagecontainer">
                                <img
                                  src={formphotoupload}
                                  width="100%"
                                  height="auto"
                                />
                              </div>
                            </div>
                          )}

                          {formphotoupload === "" && (
                            <div className="col-md-4">
                              <div className="imagecontainer">
                                <img
                                  src={require("../../../assets/images/placeholder.png")}
                                  width="100%"
                                  height="auto"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {formphotoerror !== "" && (
                          <span className="errortxt text-danger">
                            {formphotoerror}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8">
                      {errorMessage !== "" && (
                        <span className="errortxt text-danger text-left">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                  </div>
                  <br />
                  <br />

                  <hr />

                  {add === "0" && (
                    <div>
                      <a
                        className="btn btn-prev btn-success"
                        style={{ float: "right" }}
                        onClick={addcate}
                      >
                        Submit
                      </a>
                      <a
                        className="btn btn-next btn-secondary"
                        style={{ float: "right" }}
                        onClick={() => {
                          navigate("/eventlist");
                        }}
                      >
                        Cancel
                      </a>
                    </div>
                  )}
                  {add === "1" && (
                    <div>
                      <a
                        className="btn btn-prev btn-success"
                        style={{ float: "right" }}
                        onClick={addcate}
                      >
                        Update
                      </a>
                      <a
                        className="btn btn-next btn-secondary"
                        style={{ float: "right" }}
                        onClick={() => {
                          navigate("/viewevent/" + id);
                          window.location.reload();
                        }}
                      >
                        Cancel
                      </a>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default Addevent;
