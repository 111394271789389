export const MENUITEMS = [
  {
    menutitle: "Navigation",
    Items: [
      {
        path: "/guest/details",
        icon: "ti-home",
        type: "link",
        active: true,
        selected: true,
        title: "Event",
      },
    
    ],
  },
];
