export const MENUITEMS = [
  {
    menutitle: "Navigation",
    Items: [
      {
        path: "/user/dashboard",
        icon: "ti-home",
        type: "link",
        active: false,
        selected: false,
        title: "Dashboard",
      },
      {
        path: "/user/profile",
        icon: "ti-user",
        type: "link",
        active: false,
        selected: false,
        title: "Profile",
      },
      {
        title: "Event",
        icon: "ti-user",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/user/eventlist",
            type: "link",
            active: false,
            selected: false,
            title: "Upcoming Event List",
          },
          {
            path: "/user/entryeventlist",
            type: "link",
            active: false,
            selected: false,
            title: "Upcoming Entry Event List",
          },
        ],
      },
    ],
  },
];
