import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import imageUrl from "../../ImageUrl";
import { viewevent } from "../../services/admin";

const Eventview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventname, setEventName] = useState("");
  const [eventvenue, setEventVenue] = useState("");

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [close, setClose] = useState("");
  const [amt, setAmt] = useState("");
  const [viewtype, setViewEType] = useState([]);
  const [status, setStatus] = useState("0");

  const [category, setCategory] = useState("");
  const [catname, setCatName] = useState("");
  const [evelogo, setEventlogo] = useState("");
  const [evecircular, setEventCir] = useState("");
  let arr2 = "";

  let earray = [];
  let arr3 = [];
  useEffect(() => {
    viewevent(id).then((response) => {
      if (response.status === true) {
        if (response.data !== null) {
          setEventName(response.data.event_name);
          setEventlogo(imageUrl + "evententry/logo/" + response.data.logo);
          setEventCir(
            imageUrl + "evententry/circular/" + response.data.circular
          );

          setEventVenue(response.data.event_venue);
          setStart(moment(response.data.event_start).format("DD-MM-YYYY"));
          setEnd(moment(response.data.event_end).format("DD-MM-YYYY"));
          setClose(
            moment(response.data.event_entry_close).format("DD-MM-YYYY")
          );

          if (response.data.SelectedEventCategories.length !== 0) {
            for (
              let i = 0;
              i < response.data.SelectedEventCategories.length;
              i++
            ) {
              if (arr2 === "") {
                arr2 = response.data.SelectedEventCategories[i].category;
              } else {
                arr2 =
                  arr2 +
                  " , " +
                  response.data.SelectedEventCategories[i].category;
              }

              if (response.data.SelectedEventCategories[i].file_type !== null) {
                var atype = "";

                atype = response.data.SelectedEventCategories[i].file_type;
                var name = atype.split(",");
                if (name.length !== 0) {
                  var type = "";
                  for (let j = 0; j < name.length; j++) {
                    if (type === "") {
                      if (name[j] === "1") {
                        type = "Video";
                      } else if (name[j] === "2") {
                        type = "Audio";
                      } else if (name[j] === "3") {
                        type = "Image";
                      } else if (name[j] === "4") {
                        type = "Doc";
                      }
                    } else {
                      if (name[j] === "1") {
                        type = type + ", Video";
                      } else if (name[j] === "2") {
                        type = type + ", Audio";
                      } else if (name[j] === "3") {
                        type = type + ", Image";
                      } else if (name[j] === "4") {
                        type = type + ", Doc";
                      }
                    }
                  }

                  earray.push({
                    id: response.data.SelectedEventCategories[i].category_uid,
                    name:
                      "Entry type for " +
                      response.data.SelectedEventCategories[i].category,
                    etype: type,
                  });

                  arr3.push({
                    value:
                      response.data.SelectedEventCategories[i].category_uid,
                    label: response.data.SelectedEventCategories[i].category,
                    vid: 1,
                    video: "Video",
                    vsele: 0,
                    aid: 2,
                    audio: "Audio",
                    asele: 0,
                    iid: 3,
                    image: "Image",
                    isele: 0,
                    did: 4,
                    doc: "Doc",
                    dsele: 0,
                  });
                }
              }
            }
          }
          setViewEType(earray);
          setCatName(arr2);

          setCategory(response.data.category_uid);
          if (response.data.payment_status !== null) {
            let st = response.data.payment_status;
            setStatus(st + "");
          } else {
            setStatus("0");
          }
          setAmt(response.data.payment_amount);
        }
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Event Details</h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="row">
                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Name</b>
                    </div>
                    <div className="col-md-8">
                      <span>{eventname}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Venue</b>
                    </div>
                    <div className="col-md-8">
                      <span>{eventvenue}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Event Category</b>
                    </div>
                    <div className="col-md-8">
                      <span>{catname}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Start Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{start}</span>
                    </div>
                  </div>
                  

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">End Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{end}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Result Declare Date</b>
                    </div>
                    <div className="col-md-8">
                      <span>{close}</span>
                    </div>
                  </div>

                  <div className="input-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Entry Payment</b>
                    </div>
                    <div className="col-md-8">
                      {status === "0" && <span>Free</span>}
                      {status === "1" && <span>Paid</span>}
                    </div>
                  </div>
                  {status === "1" && (
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Fee</b>
                      </div>
                      <div className="col-md-8">
                        <span>{amt} /- INR</span>
                      </div>
                    </div>
                  )}

                  {viewtype.map((data, i) => {
                    return (
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">{data.name}</b>
                        </div>
                        <div className="col-md-8">
                          <span>{data.etype}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <hr />

                <a
                  className="btn btn-next btn-secondary"
                  style={{ float: "right" }}
                  onClick={() => {
                    navigate("/jury/eventlist");
                  }}
                >
                  Back
                </a>
              </div>
              <br />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Eventview;
