import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { donaralllist } from "../../services/admin";
const Donarlist = () => {
  const gridRef = useRef();
  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Fullname",
      width: 200,
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Mobile No",
      width: 200,
      field: "mobile_no",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Country",
      width: 150,
      field: "country_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Amount",
      width: 150,
      field: "amount",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Currency",
      width: 150,
      field: "currency",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      width: 150,
      field: "payment_status",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value === 0) {
          return (
            <div className="text-center">
              <a className="text-danger">
                <b>UN PAID</b>
              </a>
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <a className="text-success">
                <b>PAID</b>
              </a>
            </div>
          );
        }
      },
    },
  ];

  useEffect(() => {
    userreload();
  }, []);
  let arr = [];
  function userreload() {
    setFilterProduct([]);
    donaralllist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var name = response.data[i].doner_first_name;
            if (response.data[i].doner_last_name !== null) {
              name = name + " " + response.data[i].doner_last_name;
            }
            arr.push({
              first_name: name,
              mobile_no: response.data[i].doner_mobile,
              country_name: response.data[i].country_name,
              amount: response.data[i].paid_amount,
              currency: response.data[i].paid_curreny,
              payment_status: response.data[i].payment_status,
            });
          }
          setFilterProduct(arr);
        }
      }
    });
  }

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            DONATED MEMBERS LIST
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="App">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600, width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      suppressDragLeaveHidesColumns={true}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Donarlist;
