import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { evententrylist } from "../../services/admin";
import { juryallocated } from "../../services/jury";
import { jurynotallocated } from "../../services/jury";

const JuryEventwiselist = () => {
  const [filterProduct, setFilterProduct] = useState([]);
  const [filterProducttwo, setFilterProducttwo] = useState([]);
  const [filterProductthree, setFilterProductthree] = useState([]);
  const [allocatedata, setallocatedata] = useState([]);
  const [notallocatedata, setnotallocatedata] = useState([]);
  const columnDefs = [
    {
      headerName: "Full Name",
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Usertype",
    //   field: "usertype",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Marks",
    //   width: 170,
    //   field: "usertype",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Actions",
      field: "entry_uid",
      filter: false,

      cellRenderer: function (params) {
        return (
          // <Link to={params.value} className="btn-view">
          //   View
          // </Link>
          <Link className="btn-sm btn-info me-2" to={params.value}>
            <i className="fa fa-eye fa-lg"></i>
          </Link>
        );
      },
    },
  ];
  const columnDefstwo = [
    {
      headerName: "Full Name",
      field: "first_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Usertype",
    //   field: "usertype",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Point",
      field: "score",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Grade",
    //   width: 170,
    //   field: "grade",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Actions",
      field: "entry_uid",
      filter: false,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return (
          // <Link to={params.value} className="btn-view">
          //   View
          // </Link>
          <Link className="btn-sm btn-info me-2" to={params.value}>
            <i className="fa fa-eye fa-lg"></i>
          </Link>
        );
      },
    },
  ];
  let arr = [];

  const { id } = useParams();

  localStorage.setItem("Entry_id", id);
  useEffect(() => {
    evententrylist(localStorage.getItem("Userid"), id).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var atype = "";
            var type = "";
            atype = response.data[i].file_type;
            var name = atype?.split(",");
            if (name?.length !== 0) {
              for (let j = 0; j < name?.length; j++) {
                if (type === "") {
                  if (name[j] === "1") {
                    type = "Video";
                  } else if (name[j] === "2") {
                    type = "Audio";
                  } else if (name[j] === "3") {
                    type = "Image";
                  }
                } else {
                  if (name[j] === "1") {
                    type = type + ", Video";
                  } else if (name[j] === "2") {
                    type = type + ", Audio";
                  } else if (name[j] === "3") {
                    type = type + ", Image";
                  }
                }
              }
            }

            var user = "";
            var navi = "";
            if (response.data[i].guest_uid !== 0) {
              user = "Guest";
              navi = "/jury/eventguestview/" + response.data[i].guest_uid;
            } else if (response.data[i].user_uid !== 0) {
              user = "User";
              navi = "/jury/eventuserview/" + response.data[i].user_uid;
            }

            arr.push({
              first_name:
                response.data[i].first_name + " " + response.data[i].last_name,
              file_type: type,
              category: response.data[i].category,

              entry_uid: navi,

              usertype: response.data[i].usertype,
            });
          }
        }
        setFilterProduct(arr);
      }
    });
    setFilterProducttwo([]);
    jurynotallocated(localStorage.getItem("Userid"), id).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var user = "";
            var navi = "";
            if (response.data[i].guest_uid !== 0) {
              user = "Guest";
              navi = "/jury/eventguestview/" + response.data[i].jury_user_uid;
            } else if (response.data[i].user_uid !== 0) {
              user = "User";
              navi = "/jury/eventuserview/" + response.data[i].jury_user_uid;
            }
            arr2.push({
              first_name:
                response.data[i].first_name + " " + response.data[i].last_name,
              category: response.data[i].category,
              jury_name:
                response.data[i].jury_first_name +
                " " +
                response.data[i].jury_last_name,
              entry_uid: navi,
              score: response.data[i].score,
              grade: response.data[i].grade,
              usertype: response.data[i].usertype,
            });
          }
          setFilterProducttwo(arr2);
        }
      }
    });
  }, []);
  let arr2 = [];
  let arr3 = [];
  function handleallocate() {
    setFilterProductthree([]);
    juryallocated(localStorage.getItem("Userid"), id).then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var user = "";
            var navi = "";
            if (response.data[i].guest_uid !== 0) {
              user = "Guest";
              navi = "/jury/eventguestview/" + response.data[i].jury_user_uid;
            } else if (response.data[i].user_uid !== 0) {
              user = "User";
              navi = "/jury/eventuserview/" + response.data[i].jury_user_uid;
            }
            arr3.push({
              first_name:
                response.data[i].first_name + " " + response.data[i].last_name,
              category: response.data[i].category,
              jury_name:
                response.data[i].jury_first_name +
                " " +
                response.data[i].jury_last_name,
              entry_uid: navi,
              score: response.data[i].score,
              grade: response.data[i].grade,
              usertype: response.data[i].usertype,
            });
          }
          setFilterProductthree(arr3);
        }
      }
    });
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            EVENTWISE PARTICIPANT LIST
          </h2>
        </div>
      </div>
      <Card className=" custom-card overflow-hidden">
        <Card.Body>
          <div align="right">
            <Link to={"/jury/eventlist"} className="btn btn-secondary">
              Back
            </Link>
          </div>
          <div className="panel panel-primary tabs-style-2">
            <div className=" tab-menu-heading">
              <div className="tabs-menu1">
                <Tab.Container defaultActiveKey="notallot">
                  <Nav variant="pills" className="nav panel-tabs main-nav-line">
                    <Nav.Item>
                      <Nav.Link eventKey="notallot" className="mt-1">
                        Not-Allocated Marks
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="allot"
                        className="mt-1"
                        onClick={() => handleallocate()}
                      >
                        Allocated Marks
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="notallot" className="border">
                      <div className="responsive">
                        <div className="App">
                          <div
                            className="ag-theme-alpine ag-style"
                            style={{ height: 600, width: "100%" }}
                          >
                            <AgGridReact
                              columnDefs={columnDefs}
                              suppressDragLeaveHidesColumns={true}
                              rowData={filterProducttwo}
                              pagination="true"
                              paginationPageSize="10"
                              domLayout="autoWidth"
                              onGridReady={(params) => {
                                params.api.sizeColumnsToFit();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  {/* Update Information */}
                  <Tab.Content>
                    <Tab.Pane eventKey="allot" className="border">
                      <div className="responsive">
                        <div className="App">
                          <div
                            className="ag-theme-alpine ag-style"
                            style={{ height: 600, width: "100%" }}
                          >
                            <AgGridReact
                              columnDefs={columnDefstwo}
                              rowData={filterProductthree}
                              suppressDragLeaveHidesColumns={true}
                              pagination="true"
                              paginationPageSize="10"
                              domLayout="autoWidth"
                              onGridReady={(params) => {
                                params.api.sizeColumnsToFit();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default JuryEventwiselist;
