import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../registration/guest.css";
import {
  viewevent,
  evententryadd,
  categorydetailmail,
  admineventlist,
  alreadyexistsusermail,
} from "../../services/admin";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import imageUrl from "../../ImageUrl";
import { Card, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const Entryregister = () => {
  const navigate = useNavigate();
  const docs = [{ uri: require("../../assets/images/Poetry.doc") }];
  const [errorMessage, setErrorMessage] = useState("");

  const { id } = useParams();

  const [check, setCheck] = useState(false);
  function checkterm(e) {
    setCheck(e.target.checked);
  }

  const [show, setShow] = useState(false);
  const [file, setFile] = useState("");
  function handleShow() {
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if ("4" === multifile[i].filetype) {
          setFile(multifile[i].fileurl);
        }
      }
    }
    setShow(true);
  }
  const handleClose = () => setShow(false);

  //image file
  const [formphotoerror, setPhotoErrors] = useState("");
  function photofile(e, cat, file, index) {
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            if (e.target.files.length !== 0) {
              multifile[i].fileerror = "";
              if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                multifile[i].fileload = e.target.files[0];
                setMulti([...multifile]);

                var image = document.getElementById("image");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    image.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
              } else {
                multifile[i].fileload = e.target.files[0];

                multifile[i].fileerror =
                  "Image should be jpg or png or jpeg format";
                setMulti([...multifile]);

                setPhotoErrors("Image should be jpg or png or jpeg format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 2) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror = "Image size should be less than 2MB";
                } else {
                  multifile[i].fileerror =
                    "Image should be jpg or png or jpeg format and file size should be less than 2MB";
                }
                setMulti([...multifile]);
                setPhotoErrors("Image size should be less than 2MB");
              }
            }
          }
        }
      }
    }
  }
  //audio file
  const [formaudioerror, setAudioErrors] = useState("");

  function audiofile(e, cat, file, index) {
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";

            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(mp3|wav)$/i)) {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                setMulti([...multifile]);

                var audio = document.getElementById("audio");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    audio.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
              } else {
                multifile[i].fileload = e.target.files[0];

                multifile[i].fileerror = "Audio should be mp3 or wav format";
                setMulti([...multifile]);

                setAudioErrors("Audio should be mp3 or wav format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 50) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Audio size should be less than 50MB";
                } else {
                  multifile[i].fileerror =
                    "Audio should be mp3 or wav format and file size should be less than 50MB";
                }
                setMulti([...multifile]);

                setAudioErrors("Audio size should be less than 50MB");
              }
            }
          }
        }
      }
    }
  }
  //video file
  const [formvideoerror, setVideoErrors] = useState("");

  function videofile(e, cat, file, index) {
    // e.preventDefault();
    // setVideoErrors("");
    // setErrorMessage("");
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";
            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(mp4)$/i)) {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                setMulti([...multifile]);

                var video = document.getElementById("video");
                var f = e.target.files[0];

                if (f) {
                  var r = new FileReader();
                  r.onload = function (e) {
                    var contents = e.target.result;
                    var uint8Array = new Uint8Array(contents);
                    var arrayBuffer = uint8Array.buffer;
                    var blob = new Blob([arrayBuffer]);
                    video.src = URL.createObjectURL(blob);
                  };
                  r.readAsArrayBuffer(f);
                }
                // setVideoFile(e.target.files[0]);
              } else {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileerror = "Video should be mp4 format";
                setMulti([...multifile]);
                setVideoErrors("Video should be mp4 format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 50) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Video size should be less than 50MB";
                } else {
                  multifile[i].fileerror =
                    "Video should be mp4 format and file size should be less than 50MB";
                }
                setMulti([...multifile]);
                setVideoErrors("Video size should be less than 50MB");
              }
            }
          }
        }
      }
    }
  }

  const [formworderror, setWordErrors] = useState("");

  function wordfile(e, cat, file, index) {
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (cat === multifile[i].category_uid) {
          if (file === multifile[i].filetype) {
            multifile[i].fileerror = "";
            if (e.target.files.length !== 0) {
              if (e.target.files[0].name.match(/\.(doc|docx)$/i)) {
                multifile[i].fileurl = URL.createObjectURL(e.target.files[0]);
                multifile[i].fileload = e.target.files[0];
                // "https://docs.google.com/viewer?url=" +
                // e.target.files[0] +
                // "&embedded=true";
                setMulti([...multifile]);
                // var worddoc = document.getElementById("word");
                // var f = e.target.files[0];

                // if (f) {
                //   var docxOptions = Object.assign(docx.defaultOptions, {
                //     useMathMLPolyfill: true,
                //   });
                //   var container = document.querySelector("#word");
                //   docx.renderAsync(f, container, null, docxOptions);
                // }
              } else {
                multifile[i].fileload = e.target.files[0];
                multifile[i].fileerror =
                  "Document should be .doc or .docx format";
                setMulti([...multifile]);

                setWordErrors("Document should be .docx format");
              }
              var size = e.target.files[0].size / 1024 / 1024;
              if (size > 2) {
                if (multifile[i].fileerror === "") {
                  multifile[i].fileerror =
                    "Document size should be less than 2MB";
                } else {
                  multifile[i].fileerror =
                    "Document should be .doc or .docx format and file size should be less than 2MB";
                }
                setMulti([...multifile]);
                setWordErrors("Document size should be less than 2MB");
              }
            }
          }
        }
      }
    }
  }

  const [submitted, setSubmitted] = useState(false);

  function eventreg(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage("");
    let filecheck = true;

    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        if (multifile[i].active === 1) {
          if (multifile[i].fileload !== "") {
            if (multifile[i].fileerror !== "") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - " + multifile[i].fileerror
              );
              break;
            }
          } else {
            if (multifile[i].filetype === "1") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - Video file is required"
              );
            } else if (multifile[i].filetype === "2") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - Audio file is required"
              );
            } else if (multifile[i].filetype === "3") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - Image file is required"
              );
            } else if (multifile[i].filetype === "4") {
              filecheck = false;
              setErrorMessage(
                multifile[i].category + " - Document file is required"
              );
            }
            break;
          }
        }
      }
    }
    if (eventcat.length !== 0) {
      if (filecheck === true) {
        if (check === false) {
          setErrorMessage("Please Agree our terms and conditions");
        }
        if (check === true) {
          //alert("Hi");
          setLoading(true);

          const tempData = new FormData();
          tempData.append("event_uid", id);
          tempData.append("user_uid", localStorage.getItem("Userid"));
          tempData.append("guest_uid", "0");

          // tempData.append("category_uid", category);

          let cid = "";
          if (eventcat.length !== 0) {
            for (let j = 0; j < eventcat.length; j++) {
              if (cid === "") {
                cid = eventcat[j].value;
              } else {
                cid = cid + "," + eventcat[j].value;
              }
            }
          }

          tempData.append("category_uid", cid);
          if (multifile.length !== 0) {
            for (let i = 0; i < multifile.length; i++) {
              if (multifile[i].file_type !== "") {
                tempData.append(
                  "file_type_" + multifile[i].category_uid,
                  multifile[i].file_type
                );
              }
              tempData.append(
                "file_name_" +
                  multifile[i].category_uid +
                  "_" +
                  multifile[i].filetype,
                multifile[i].fileload
              );
            }
          }
          evententryadd(tempData).then((responsedata) => {
            if (responsedata.status === true) {
              setLoading(false);
              toast.success(responsedata.message);
              navigate("/user/entryeventlist");
            } else {
              setErrorMessage(responsedata.data);
            }
          });
        }
      }
    } else {
      setErrorMessage("Please Select your Event Category");
    }
  }

  const [ename, setEName] = useState("");
  const [vname, setVName] = useState("");
  const [cname, setCName] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [amount, setAmt] = useState("");
  const [filetypes, setFiletypes] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("0");
  let arr = [];
  var arr1 = [];

  let arr2 = "";
  const [catlist, setCatlist] = useState([]);
  const [multifile, setMulti] = useState([]);
  const [eventcat, setEventcat] = useState("");
  const [filecir, setFilecir] = useState("");
  const [already, setAlready] = useState("");

  useEffect(() => {
    details();
    alreadyexistsusermail(localStorage.getItem("Userid")).then((res) => {
      if (res.status === false) {
        setAlready(res.message);
      }
    });
  }, []);

  function details() {
    viewevent(id).then((response) => {
      if (response.status === true) {
        if (response.data.SelectedEventCategories.length !== 0) {
          for (
            let i = 0;
            i < response.data.SelectedEventCategories.length;
            i++
          ) {
            arr.push({
              value: response.data.SelectedEventCategories[i].category_uid,
              label: response.data.SelectedEventCategories[i].category,
            });

            if (arr2 === "") {
              arr2 = response.data.SelectedEventCategories[i].category;
            } else {
              arr2 =
                arr2 +
                " , " +
                response.data.SelectedEventCategories[i].category;
            }

            var ftype =
              response.data.SelectedEventCategories[i].file_type.split(",");
            if (ftype !== "") {
              for (let j = 0; j < ftype.length; j++) {
                var type = response.data.SelectedEventCategories[i].file_type;
                var head =
                  "Attach Files for " +
                  response.data.SelectedEventCategories[i].category;
                if (j !== 0) {
                  type = "";
                  head = "";
                }
                arr1.push({
                  category_uid:
                    response.data.SelectedEventCategories[i].category_uid,
                  file_type: type,
                  heading: head,
                  filetype: ftype[j],
                  fileload: "",
                  fileurl: "",
                  fileerror: "",
                  category: response.data.SelectedEventCategories[i].category,
                  active: 0,
                });
              }
            }
          }
          setCatlist(arr);
          setMulti(arr1.sort((a, b) => a.category_uid - b.category_uid));
        }

        setEName("Event Name:- " + response.data.event_name);
        setVName("Event Venue:- " + response.data.event_venue);
        setSDate(
          "Start Date:- " +
            moment(response.data.event_start).format("DD-MM-YYYY")
        );
        setEDate(
          "End Date:- " + moment(response.data.event_end).format("DD-MM-YYYY")
        );
        setCDate(
          "Result Declare:- " +
            moment(response.data.event_entry_close).format("DD-MM-YYYY")
        );
        setFilecir(imageUrl + "evententry/circular/" + response.data.circular);

        setFiletypes(response.data.event_file_type);
        setCName("Category:- " + arr2);
        setCategory(response.data.category_uid);
        // var cat = response.data.category_uid.split(",");
        // if (cat.length !== 0) {
        //   for (let i = 0; i < cat.length; i++) {
        //     if (cat[i] !== "") {
        //       // catfile(response.data.event_uid, cat[i]);
        //     }
        //   }
        // }

        setAmt("Entry Fee:- " + response.data.payment_amount + " INR");
        if (response.data.payment_status !== null) {
          setStatus(response.data.payment_status);
        } else {
          setStatus("0");
        }
      }
    });
  }

  // function catfile(first, second) {
  //   categorydetailmail(first, second).then((res) => {
  //     if (res.data !== null) {
  //       var ftype = res.data.file_type.split(",");
  //       if (ftype !== "") {
  //         for (let j = 0; j < ftype.length; j++) {
  //           var type = res.data.file_type;
  //           var head = "Attach Files for " + res.data.category;
  //           if (j !== 0) {
  //             type = "";
  //             head = "";
  //           }
  //           arr1.push({
  //             category_uid: res.data.category_uid,
  //             file_type: type,
  //             heading: head,
  //             filetype: ftype[j],
  //             fileload: "",
  //             fileurl: "",
  //           });
  //         }
  //       }
  //     }
  //     setMulti(arr1.sort((a, b) => a.category_uid - b.category_uid));

  //   });
  // }

  const handleChange = (e) => {
    setEventcat(e);
    if (multifile.length !== 0) {
      for (let i = 0; i < multifile.length; i++) {
        multifile[i].active = 0;
      }
    }
    if (e.length !== 0) {
      for (let k = 0; k < e.length; k++) {
        if (multifile.length !== 0) {
          for (let i = 0; i < multifile.length; i++) {
            if (e[k].value === multifile[i].category_uid) {
              multifile[i].active = 1;
            }
          }
        }
      }
    }
  };
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}
      <Card className="custom-card overflow-hidden">
        <Card.Body>
          <form action="#" className="form">
            <h2 className="text-left">Entry Event Registration</h2>
            <div className="guestreg row">
              <div className="col-md-12 eve-mob">
                <h6>{ename}</h6>
              </div>

              <div className="col-md-12 eve-mob">
                <h6>{cname}</h6>
              </div>
              <div className="col-md-4 eve-mob">
                <h6>{sdate}</h6>
              </div>
              <div className="col-md-4 eve-mob">
                <h6>{edate}</h6>
              </div>
              <div className="col-md-4 eve-mob">
                <h6>{cdate}</h6>
              </div>
            </div>

            {already !== "" && (
              <div align="center">
                <br />
                <br />
                <h3>{already}</h3>
              </div>
            )}
            {already === "" && (
              <div>
                <div className="input-group">
                  <label htmlFor="username">
                    Event Category :<b className="text-danger">*</b>
                  </label>
                  <Select
                    placeholder="Select Your Event Category"
                    value={eventcat}
                    options={catlist}
                    onChange={handleChange}
                    isMulti
                    isClearable
                  />
                  {submitted && !eventcat && (
                    <span className="errortxt text-danger">
                      Event Category is required
                    </span>
                  )}
                </div>

                <div className="form-step form-step-active">
                  {multifile.map((data, j) => {
                    return (
                      <div key={j}>
                        {data.file_type !== "" && data.active === 1 && (
                          <div className="form-inner form-innertwo">
                            <h4>{data.heading}</h4>
                            {data.file_type.includes("1") && (
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-8">
                                    <label htmlFor="dob">
                                      Add Video File :{" "}
                                      <b className="text-danger">*</b>{" "}
                                      <span>
                                        (Accepted Files are .mp4, .wav & Max
                                        Upload Size 50MB )
                                      </span>
                                    </label>

                                    <div className="mt-4">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept=".mp4"
                                        // onChange={videofile}
                                        onChange={(e) =>
                                          videofile(
                                            e,
                                            data.category_uid,
                                            "1",
                                            j
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="imagecontainer">
                                      <video
                                        id="video"
                                        width="100%"
                                        height="110px"
                                        controls
                                      />
                                    </div>
                                  </div>
                                </div>
                                {data.fileload === "" && submitted && (
                                  <span className="errortxt text-danger">
                                    Video file is required
                                  </span>
                                )}
                              </div>
                            )}
                            {data.file_type.includes("2") && (
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-8">
                                    <label htmlFor="dob">
                                      Add Audio File :{" "}
                                      <b className="text-danger">*</b>{" "}
                                      <span>
                                        (Accepted Files are .mp3 & Max Upload
                                        Size 50MB )
                                      </span>
                                    </label>

                                    <div className="mt-4">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept=".mp3,.wav"
                                        onChange={(e) =>
                                          audiofile(
                                            e,
                                            data.category_uid,
                                            "2",
                                            j
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="imagecontainer">
                                      <audio
                                        id="audio"
                                        controls
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {data.fileload === "" && submitted && (
                                  <span className="errortxt text-danger">
                                    Audio file is required
                                  </span>
                                )}
                              </div>
                            )}
                            {data.file_type.includes("3") && (
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-8">
                                    <label htmlFor="dob">
                                      Add Image File :{" "}
                                      <b className="text-danger">*</b>{" "}
                                      <span>
                                        (Accepted Files are .jpg,.jpeg,.png &
                                        Max Upload Size 2MB )
                                      </span>
                                    </label>

                                    <div className="mt-4">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept=".png,.jpg,.jpeg"
                                        onChange={(e) =>
                                          photofile(
                                            e,
                                            data.category_uid,
                                            "3",
                                            j
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="imagecontainer">
                                      <img
                                        id="image"
                                        // src={require("../../assets/images/placeholder.png")}
                                        width="100%"
                                        height="auto"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {data.fileload === "" && submitted && (
                                  <span className="errortxt text-danger">
                                    Image file is required
                                  </span>
                                )}
                              </div>
                            )}

                            {data.file_type.includes("4") && (
                              <div className="input-group form-upload">
                                <div className="row">
                                  <div className="col-md-8">
                                    <label htmlFor="dob">
                                      Add Word File :{" "}
                                      <b className="text-danger">*</b>{" "}
                                      <span>
                                        (Accepted Files are .doc,.docx & Max
                                        Upload Size 2MB )
                                      </span>
                                    </label>

                                    <div className="mt-4">
                                      <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept=".doc,.docx"
                                        onChange={(e) =>
                                          wordfile(e, data.category_uid, "4", j)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="imagecontainer">
                                      {/* <iframe src = {require("../../assets/images/Poetry.doc")} width='100%' height='110px' allowfullscreen webkitallowfullscreen></iframe> */}
                                      {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} /> */}

                                      {/* <iframe
                                    src="https://docs.google.com/gview?url=http://remote.url.tld/path/to/document.doc&embedded=true"
                                    width="100%"
                                    height="110px"
                                    frameborder="0"
                                  ></iframe> */}
                                      {/* <iframe
                                    id="word"
                                    width="100%"
                                    height="110px"
                                  /> 
                                  */}
                                      {/* <a onClick={() => handleShow(j)}> */}
                                      <img
                                        src={require("../../assets/images/word.png")}
                                        width="100%"
                                        height="auto"
                                      />
                                      {/* </a> */}
                                    </div>
                                  </div>
                                </div>

                                <Modal
                                  show={show}
                                  onHide={handleClose}
                                  size="large"
                                  backdrop="static"
                                >
                                  <Modal.Body>
                                    {/* <iframe width="100%" height="600" frameborder="0" src={`https://docs.google.com/gview?url=${file}&embedded=true`}></iframe> */}

                                    {/* <iframe src={file} width="100%" height="110px" /> https://www.codexworld.com/files/Brochure.docx*/}
                                    {/* <iframe
                                  src="https://view.officeapps.live.com/op/embed.aspx?src=" file
                                  width="100%"
                                  height="auto"
                                  frameborder="0"
                                ></iframe> */}

                                    {/* <iframe
                                  src={
                                    "https://docs.google.com/viewer?url=" +
                                    file +
                                    "&embedded=true"
                                  }
                                  title="file"
                                  width="100%"
                                  height="600"
                                ></iframe> */}
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <a
                                      className="btn btn-success mr-3"
                                      style={{ float: "right" }}
                                      onClick={handleClose}
                                    >
                                      Close
                                    </a>
                                  </Modal.Footer>
                                </Modal>
                                {data.fileload === "" && submitted && (
                                  <span className="errortxt text-danger">
                                    Word file is required
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <div className="row">
                    <div className="col-md-9">
                      <div className="checkbox checkbox-1">
                        <input
                          type="checkbox"
                          id="checkbox-1"
                          onChange={checkterm}
                          checked={check}
                        />
                        <label htmlFor="checkbox-1">
                          Agree to our{" "}
                          <a
                            target="_blank"
                            href="https://www.pythiangames.org/privacy"
                          >
                            Privacy Policy
                          </a>{" "}
                          ,{" "}
                          <a
                            target="_blank"
                            href="https://www.pythiangames.org/terms"
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            target="_blank"
                            // href={require("../../assets/images/Poetry_and_Painting_art_Compition.pdf")}
                            href={filecir}
                          >
                            Submission & Guidelines
                          </a>
                          <b className="text-danger">*</b>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      {status === "1" && (
                        <div className="member">
                          <label>{amount}</label>
                        </div>
                      )}
                      {status === "0" && (
                        <div className="member">
                          <label>Entry Fee: Free</label>
                        </div>
                      )}
                    </div>
                  </div>

                  {check === false && (
                    <span className="errortxt text-danger">{errorMessage}</span>
                  )}

                  <div className="">
                    <a
                      className="btn btn-next btn-secondary"
                      style={{ float: "right" }}
                      onClick={() => navigate("/user/entryeventlist")}
                    >
                      Back
                    </a>
                    <a
                      className="btn btn-primary btn-next"
                      style={{ float: "right" }}
                      onClick={eventreg}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            )}
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Entryregister;
