import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  addeventdata,
  eventcategorylist,
  updateeventdata,
  viewevent,
  listeventcatagory,
  removeassignjury,
  addquizevent,
  agecategorylist,
} from "../../../services/admin";
import Select from "react-select";
import moment from "moment";
import imageUrl from "../../../ImageUrl";
import "./list.css";
import { assignedjurylist } from "../../../services/jury";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";

const Quizevent = () => {
  const navigate = useNavigate();

  const [eventname, setEventName] = useState("");
  const [sdate, setSDate] = useState("");
  const [edate, setEDate] = useState("");
  const [cdate, setCDate] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [close, setClose] = useState("");
  const [amt, setAmt] = useState("");
  const [evetype, setEType] = useState("");
  const [viewtype, setViewEType] = useState([]);

  const [agecatname, setAgeCatName] = useState("");
  const [agecatlist, setAgeCatlist] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  const [formid, setIDFile] = useState("");
  const [formiderror, setIDErrors] = useState("");
  const [status, setStatus] = useState("0");

  const [isLoading, setIsLoading] = useState(false);
  const [evelogo, setEventlogo] = useState("");
  const [evecircular, setEventCir] = useState("");

  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setPhotoUpload("");
    setPhotoFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));

        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Logo should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Logo size should be less than 3MB");
      }
    }
  }

  function idfile(e) {
    e.preventDefault();
    setIDErrors("");
    setIDFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(pdf)$/i)) {
        setIDFile(e.target.files[0]);
      } else {
        setIDErrors("Circular should be pdf format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setIDErrors("Circular size should be less than 3MB");
      }
    }
  }

  function addcate() {
    setErrorMessage("");
    var noshow = "";

    if (eventname !== "") {
      if (sdate !== "") {
        if (edate !== "") {
          if (edate >= sdate) {
            if (cdate !== "") {
              if (cdate >= edate) {
                if (agecatname.length !== 0) {
                  if (status !== "") {
                    if (status === "1") {
                      if (amt !== "") {
                        noshow = "";
                        let cid = "";
                        if (agecatname.length !== 0) {
                          for (let j = 0; j < agecatname.length; j++) {
                            if (cid === "") {
                              cid = agecatname[j].value;
                            } else {
                              cid = cid + "," + agecatname[j].value;
                            }
                          }
                        }

                        const temp = new FormData();
                        temp.append("event_name", eventname);
                        temp.append("category_uid", cid);
                        temp.append("event_start", sdate);
                        temp.append("event_end", edate);
                        temp.append("result_declared_date", cdate);
                        temp.append("payment_status", status);
                        temp.append("payment_amount", amt);
                        temp.append("payment_currency", "INR");
                        temp.append("user_uid", localStorage.getItem("Userid"));
                        temp.append("logo", formphoto);
                        temp.append("circular", formid);

                        addquizevent(temp).then((response) => {
                          if (response.status === true) {
                            navigate("/addquizevent");
                          }
                        });
                      } else {
                        noshow = "Please Enter Your Entry Fee";

                        setErrorMessage("Please Enter Your Entry Fee");
                      }
                    }
                  } else {
                    setErrorMessage("Please Select Your Entry Payment");
                  }
                } else {
                  setErrorMessage("Please Select Your Age Category");
                }
              } else {
                setErrorMessage(
                  "Result Declare Date must be greater than Event End Date"
                );
              }
            } else {
              setErrorMessage("Please Enter Your Result Declare Date");
            }
          } else {
            setErrorMessage(
              "Event End Date must be greater than Event Start Date"
            );
          }
        } else {
          setErrorMessage("Please Enter Your Event End Date");
        }
      } else {
        setErrorMessage("Please Enter Your Event Start Date");
      }
    } else {
      setErrorMessage("Please Enter Your Event Name");
    }
  }

  function addcate() {
    navigate("/addquizevent");
  }

  function handleChange(e) {
    setAgeCatName(e);
  }

  function Selectpay(event) {
    setStatus(event.target.value);
  }

  function ending(e) {
    setErrorMessage("");
    setEDate(e.target.value);
    if (e.target.value < sdate) {
      setErrorMessage("Event End Date must be greater than Event Start Date");
    } else if (e.target.value === sdate) {
      setErrorMessage("");
    }
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  useEffect(() => {
    const url = window.location.href;
    const last = url.split("/");
    const menuviewedit = last[last.length - 2];

    agecategorylist().then((response) => {
      if (response.status === true) {
        setAgeCatlist(response.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            QUIZ EVENT REGISTRATION
          </h2>
        </div>
      </div>

      {isLoading && (
        <Row className="row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div className="responsive">
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Event Name</b>
                      </div>
                      <div className="col-md-8">
                        <span>{eventname}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Start Date</b>
                      </div>
                      <div className="col-md-8">
                        <span>{start}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">End Date</b>
                      </div>
                      <div className="col-md-8">
                        <span>{end}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Result Declare Date</b>
                      </div>
                      <div className="col-md-8">
                        <span>{close}</span>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <b className="text-inverse">Entry Payment</b>
                      </div>
                      <div className="col-md-8">
                        {status === "0" && <span>Free</span>}
                        {status === "1" && <span>Paid</span>}
                      </div>
                    </div>
                    {status === "1" && (
                      <div className="input-group">
                        <div className="col-md-4">
                          <b className="text-inverse">Entry Fee</b>
                        </div>
                        <div className="col-md-8">
                          <span>{amt} /- INR</span>
                        </div>
                      </div>
                    )}

                    {viewtype.map((data, i) => {
                      return (
                        <div className="input-group">
                          <div className="col-md-4">
                            <b className="text-inverse">{data.name}</b>
                          </div>
                          <div className="col-md-8">
                            <span>{data.etype}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <hr />
                </div>
                <br />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {!isLoading && (
        <Row className="row-sm">
          <Col lg={12}>
            <Card className="custom-card overflow-hidden">
              <Card.Body>
                <div className="responsive">
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Event Name <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter Your Event Name"
                          value={eventname}
                          onChange={(e) => {
                            setEventName(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Start Date <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="date"
                          placeholder="Enter Your Start Date"
                          value={sdate}
                          onChange={(e) => {
                            setSDate(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          End Date <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="date"
                          placeholder="Enter Your End Date"
                          value={edate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={ending}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Result Declare Date <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="date"
                          placeholder="Enter Your Result Declare Date"
                          value={cdate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setCDate(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            event.preventDefault();
                          }}
                          onKeyDown={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Age Category <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-8">
                        {/* <select
                          value={category}
                          onChange={(e) => {
                            setCategory(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Your Category
                          </option>
                          {catlist.map((data, i) => {
                            return (
                              <option value={data.category_uid} key={i}>
                                {data.category}
                              </option>
                            );
                          })}
                        </select> */}
                        <Select
                          placeholder="Select Age Category"
                          value={agecatname}
                          options={agecatlist}
                          onChange={handleChange}
                          isMulti
                          isClearable
                        />
                      </div>

                      {/* {add === "1" && (
                                                <div className="col-md-8">
                                                    <span>{catname}</span>
                                                </div>
                                            )} */}
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">
                          Entry Payment <b className="text-danger">*</b>
                        </label>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="radio"
                          name="pay"
                          value="0"
                          checked={status == "0"}
                          onChange={Selectpay}
                        />
                        &nbsp;&nbsp;
                        <label
                          className="custom-control-label-md"
                          style={{ paddingTop: "10px" }}
                        >
                          Free
                        </label>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="radio"
                          name="pay"
                          value="1"
                          checked={status == "1"}
                          onChange={Selectpay}
                        />
                        &nbsp;&nbsp;
                        <label
                          className="custom-control-label-md"
                          style={{ paddingTop: "10px" }}
                        >
                          Paid
                        </label>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                    {status === "1" && (
                      <div className="input-group">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Entry Fee <b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Entry Fee"
                            value={amt}
                            onChange={(e) => {
                              setAmt(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="input-group form-upload">
                      <div className="row">
                        <div className="col-md-8">
                          <label htmlFor="dob">Circular :</label>

                          <div className="mt-4">
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept=".pdf"
                              onChange={idfile}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={require("../../../assets/images/pdf.png")}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      </div>
                      {formiderror !== "" && (
                        <span className="errortxt text-danger">
                          {formiderror}
                        </span>
                      )}
                    </div>

                    <div className="input-group form-upload">
                      <div className="row">
                        <div className="col-md-8">
                          <label htmlFor="dob">Add Logo :</label>

                          <div className="mt-4">
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept=".png,.jpg,.jpeg"
                              onChange={photofile}
                            />
                          </div>
                        </div>

                        {formphotoupload !== "" && (
                          <div className="col-md-4">
                            <div className="imagecontainer">
                              <img
                                src={formphotoupload}
                                width="100%"
                                height="auto"
                              />
                            </div>
                          </div>
                        )}

                        {formphotoupload === "" && (
                          <div className="col-md-4">
                            <div className="imagecontainer">
                              <img
                                src={require("../../../assets/images/placeholder.png")}
                                width="100%"
                                height="auto"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {formphotoerror !== "" && (
                        <span className="errortxt text-danger">
                          {formphotoerror}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8">
                      {errorMessage !== "" && (
                        <span className="errortxt text-danger text-left">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />
                  <div>
                    <a
                      className="btn btn-prev btn-success"
                      style={{ float: "right" }}
                      onClick={addcate}
                    >
                      Submit
                    </a>
                    <a
                      className="btn btn-next btn-secondary"
                      style={{ float: "right" }}
                      onClick={() => {
                        navigate("/eventlist");
                      }}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default Quizevent;
