import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./guest.css";
import PhoneInput from "react-phone-input-2";
import {
  citylist,
  countrylist,
  genderlist,
  statelist,
  juryslanguage,
  interestcategory,
} from "../../services/register";
import { newregisterjury } from "../../services/jury";
import Swal from "sweetalert2";
import Select from "react-select";
import { eventcategorylist } from "../../services/admin";

const Juryregisteration = () => {
  const navigate = useNavigate();
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [email, setEmail] = useState("");
  const [mobilecode, setMobcode] = useState("");
  const [mobile, setMobile] = useState("");

  const [glist, setGenderlist] = useState([]);
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);
  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);
  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);

  const [category, setCategory] = useState("");
  const [catlist, setCatList] = useState([]);

  const [interestlang, setLanguage] = useState("");
  const [langlist, setLanglist] = useState([]);

  const hangleInterest = (e) => {
    setCategory(e);
  };

  const hangleLanguage = (e) => {
    setLanguage(e);
  };
  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  const [mobonly, setMobileonly] = useState("");
  const handle = (value, data, event, formattedValue) => {
    setMobcode(data.dialCode);
    setMobile(value);
    setMobileonly(value.slice(data.dialCode.length));
  };

  function datelist() {
    var day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }
    setDaylist(day);

    var year = [];

    for (let i = 1930; i <= new Date().getFullYear() - 5; i++) {
      year.push(i);
    }
    setYearlist(year);
  }

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setPhotoUpload("");
    setPhotoFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));
        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024;
      if (size > 900) {
        setPhotoErrors("Photograph size should be less than 900KB");
      }
    }
  }

  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  const [formiderror, setIDErrors] = useState("");

  function idfile(e) {
    e.preventDefault();
    setIDErrors("");
    setIDUpload("");
    setIDFile("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(e.target.files[0]));
        setIDFile(e.target.files[0]);
      } else {
        setIDErrors("Identity Proof should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024;
      if (size > 900) {
        setIDErrors("Identity Proof size should be less than 900KB");
      }
    }
  }

  const [submitted, setSubmitted] = useState(false);
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  function juryreg(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage("");
    setEmailError("");
    setMobError("");
    let evalid = "";
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      evalid = "Please Enter a valid Email Address";
      setEmailError("Please Enter a valid Email Address");
    }

    if (mobile !== "") {
      if (mobile.length < 10) {
        setMobError("Mobile Number must be 10 digits");
      } else {
        setMobError("");
      }
    }
    if (
      fname !== "" &&
      email !== "" &&
      evalid === "" &&
      gender !== "" &&
      mobile !== "" &&
      interestlang !== "" &&
      category !== "" &&
      mobile.length >= 10
    ) {
      setLoading(true);

      let cid = "";
      if (category.length !== 0) {
        for (let j = 0; j < category.length; j++) {
          if (cid === "") {
            cid = category[j].value;
          } else {
            cid = cid + "," + category[j].value;
          }
        }
      }

      let lanid = "";

      if (interestlang.length !== 0) {
        for (let j = 0; j < interestlang.length; j++) {
          if (lanid === "") {
            lanid = interestlang[j].value;
          } else {
            lanid = lanid + "," + interestlang[j].value;
          }
        }
      }
   
      const tempData = new FormData();
      tempData.append("first_name", fname);
      tempData.append("last_name", lname);
      tempData.append("gender", gender);
      tempData.append(
        "date_of_birth",
        yeargroup + "-" + monthgroup + "-" + daygroup
      );
      tempData.append("primary_mobile", mobonly);
      tempData.append("primary_mob_country_code", mobilecode);
      tempData.append("email_id", email);

      tempData.append("street_no", add1);
      tempData.append("address_2", add2);

      tempData.append("pincode", pincode);
      tempData.append("country", country);
      tempData.append("state", state);
      tempData.append("city", city);
      tempData.append("photo", formphoto);
      tempData.append("identity", formid);
      tempData.append("register_by", 0);
      tempData.append("interested_category_uid", cid);
      tempData.append("language_uid", lanid);

      newregisterjury(tempData).then((responsedata) => {
        if (responsedata.status === true) {
          setLoading(false);
          Swal.fire({
            title: responsedata.message,
            showCancelButton: false,
            confirmButtonColor: "#6259ca",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
        } else {
          setErrorMessage(responsedata.data);
        }
      });
    }
  }
  let arr1 = [];
  let arr2 = [];
  useEffect(() => {
    datelist();
    genderlist().then((response) => {
      setGenderlist(response.genders);
    });
    countrylist().then((response) => {
      setCountrylist(response.country);
    });
    eventcategorylist().then((response) => {
      if (response.data.length !== 0) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
          });
        }
        setCatList(arr1);
      }
    });

    juryslanguage().then((response) => {
      if (response.language.length !== 0) {
        for (let i = 0; i < response.language.length; i++) {
          arr2.push({
            value: response.language[i].language_uid,
            label: response.language[i].language,
          });
        }
        setLanglist(arr2);
      }
    });
  }, []);

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }

  const [loading, setLoading] = useState(false);
  return (
    <div id="registrationpage">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...</h3>
        </div>
      )}

      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            {" "}
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
        </div>
        <div className="registrationright">
          <form action="#" className="form">
            <h2 className="text-right">Jury Registration</h2>

            <div className="form-step form-step-active">
              <div className="form-inner">
                <h4>Personal Information</h4>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        {" "}
                        First Name :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your First Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={fname}
                        onChange={(e) => {
                          setFName(e.target.value);
                        }}
                      />
                      {submitted && !fname && (
                        <span className="errortxt text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Last Name :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Last / Family Name"
                        onKeyPress={LetterOnly}
                        onPaste={onPasteLetter}
                        maxLength={25}
                        value={lname}
                        onChange={(e) => {
                          setLName(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Email Address :<b className="text-danger">*</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Email Address"
                        maxLength={50}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {submitted && !email && (
                        <span className="errortxt text-danger">
                          Email is required
                        </span>
                      )}
                      {emailerror !== "" && email && (
                        <span className="errortxt text-danger">
                          {emailerror}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Mobile Number :<b className="text-danger">*</b>
                      </label>
                      <PhoneInput
                        specialLabel={""}
                        country={"in"}
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        countryCodeEditable={false}
                        onChange={handle}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      {submitted && !mobile && (
                        <span className="errortxt text-danger">
                          Mobile Number is required
                        </span>
                      )}
                      {mobile.length !== 12 && mobile && (
                        <span className="errortxt text-danger">{moberror}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Gender : <b className="text-danger">*</b>
                      </label>
                      <select
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your Gender
                        </option>
                        {glist.map((data, i) => {
                          return (
                            <option value={data.gender_uid} key={i}>
                              {data.gender}
                            </option>
                          );
                        })}
                      </select>
                      {submitted && !gender && (
                        <span className="errortxt text-danger">
                          Gender is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Date of Birth :</label>

                      <div className="row">
                        <div className="col-md-4 col-sm-4 xpr-0">
                          <select
                            value={daygroup}
                            onChange={(e) => {
                              setDateDay(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Day
                            </option>
                            {dateday.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpx-0">
                          <select
                            value={monthgroup}
                            onChange={(e) => {
                              setDateMonth(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Month
                            </option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-4 xpl-0">
                          <select
                            value={yeargroup}
                            onChange={(e) => {
                              setDateYear(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Year
                            </option>
                            {dateyear.map((data, i) => {
                              return (
                                <option value={data} key={i}>
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Address 1 :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Address 1"
                        value={add1}
                        onChange={(e) => {
                          setAdd1(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Address 2 :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Address 2"
                        value={add2}
                        onChange={(e) => {
                          setAdd2(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Country :</label>
                      <select value={country} onChange={Selectcountry}>
                        <option value="" disabled>
                          Select Your Country
                        </option>
                        {colist.map((data, i) => {
                          return (
                            <option value={data.country_uid} key={i}>
                              {data.country_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">State :</label>
                      <select value={state} onChange={Selectstate}>
                        <option value="" disabled>
                          Select Your State
                        </option>
                        {slist.map((data, i) => {
                          return (
                            <option value={data.state_uid} key={i}>
                              {data.state_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">City :</label>
                      <select
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select Your City
                        </option>
                        {clist.map((data, i) => {
                          return (
                            <option value={data.city_uid} key={i}>
                              {data.city_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">Pincode :</label>
                      <input
                        type="text"
                        placeholder="Enter Your Pincode"
                        value={pincode}
                        onChange={(e) => {
                          setPincode(e.target.value);
                        }}
                        maxLength={6}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Category : <b className="text-danger">*</b>
                      </label>
                      <Select
                        placeholder="Select Your Category"
                        value={category}
                        options={catlist}
                        onChange={hangleInterest}
                        isMulti
                        isClearable
                      />
                      {submitted && !category && (
                        <span className="errortxt text-danger">
                          Category is required
                        </span>
                      )}
                    </div>
                  </div> 

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Language :<b className="text-danger">*</b>{" "}
                      </label>

                      <Select
                        placeholder="Select Your Language"
                        value={interestlang}
                        options={langlist}
                        onChange={hangleLanguage}
                        isMulti
                        isClearable
                      />
                      {submitted && !interestlang && (
                        <span className="errortxt text-danger">
                          Language is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="dob">
                        Photograph : <span>(Maximum size 900KB)</span>
                      </label>

                      <div className="mt-4">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={photofile}
                        />
                      </div>
                    </div>

                    {formphotoupload !== "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={formphotoupload}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}

                    {formphotoupload === "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={require("../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formphotoerror !== "" && (
                    <span className="errortxt text-danger">
                      {formphotoerror}
                    </span>
                  )}
                </div>

                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="dob">
                        Identity Proof : <span>(Maximum size 900KB)</span>
                      </label>

                      <div className="mt-4">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={idfile}
                        />
                      </div>
                    </div>

                    {formidupload !== "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img src={formidupload} width="100%" height="auto" />
                        </div>
                      </div>
                    )}

                    {formidupload === "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={require("../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formiderror !== "" && (
                    <span className="errortxt text-danger">{formiderror}</span>
                  )}
                </div>
              </div>

              {errorMessage !== "" && (
                <span className="errortxt text-danger">{errorMessage}</span>
              )}

              <div className="">
                <Link to={"/login"} className="btn btn-secondary">
                  Back
                </Link>
                <a
                  className="btn btn-primary btn-next"
                  style={{ float: "right" }}
                  onClick={juryreg}
                >
                  Submit
                </a>
              </div>
            </div>

            {/* <Modal
              show={show}
              onHide={handleClose}
              size="large"
              backdrop="static"
            >
              <Modal.Header>
                <h4>Payment Successful!</h4>
                <i
                  className="fa fa-window-close fa-lg"
                  aria-hidden="true"
                  onClick={handleClose}
                ></i>
              </Modal.Header>
              <Modal.Body>
                <p>Registration completed</p>
              </Modal.Body>
              <Modal.Footer>
                <a
                  className="btn btn-success mr-3"
                  style={{ float: "right" }}
                  onClick={handleClose}
                >
                  ok
                </a>
              </Modal.Footer>
            </Modal> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Juryregisteration;
