import React, { Fragment } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "./dashboard.scss";
function Userdashboard() {
  return (
    <Fragment>
      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Welcome {localStorage.getItem('Name')}
          </h2>
        </div>
      </div>
      {/* <!-- End Page Header --> */}
      <div className="row row-sm">
      <div className="col-md-4">
            <Card className="custom-card me-4 ">
              <Card.Body>
                <div className="d-flex no-block align-items-center currency-item">
                  <div>
                    <span className="text-muted tx-13 mb-3">USERNAME</span>
                    <h3 className="m-0 mt-2">{localStorage.getItem('User')}</h3>
                  </div>
                  {/* <div className="ms-auto mt-auto">
                    <img
                      src={
                        require("../../assets/img/svgs/crypto-currencies/eth.svg")
                          .default
                      }
                      className="wd-30 hd-30 me-2"
                      alt="eth"
                    />
                  </div> */}
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-4 ">
            <Card className="custom-card me-4 ">
              <Card.Body>
                <div className="d-flex no-block align-items-center currency-item">
                  <div>
                    <span className="text-muted tx-13 mb-3">ART CATEGORIES</span>
                    <h3 className="m-0 mt-2">-</h3>
                  </div>
                  
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-4">
            <Card className="custom-card me-4 ">
              <Card.Body>
                <div className="d-flex no-block align-items-center currency-item">
                  <div>
                    <span className="text-muted tx-13 mb-3">PYTHIAN AWARDS</span>
                    <h3 className="m-0 mt-2">-</h3>
                  </div>
                  
                </div>
              </Card.Body>
            </Card>
          </div>
      </div>
    </Fragment>
  );
}

Userdashboard.propTypes = {};

Userdashboard.defaultProps = {};

export default Userdashboard;
