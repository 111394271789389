import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./registration.css";
import "../../assets/js/registration.js";
import {
  citylist,
  countrylist,
  educationlist,
  genderlist,
  getemail,
  getmobile,
  restrictdetails,
  getorderpay,
  interestcategory,
  memberlist,
  occupationlist,
  paymentgateway,
  registerdata,
  statelist,
} from "../../services/register";
import { Modal } from "react-bootstrap";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
//import PhoneInput from "react-phone-number-input";
import Select from "react-select";

function Register() {
  const navigate = useNavigate();
  const [dateday, setDaylist] = useState([]);
  const [dateyear, setYearlist] = useState([]);
  const [lstep, setStep] = useState(1);
  const [step1submit, setStep1] = useState(false);
  const [step2submit, setStep2] = useState(false);
  const [step3submit, setStep3] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [memtype, setMember] = useState("");
  const [memlist, setMemberlist] = useState([]);
  // const [fnameonly, setFNameonly] = useState("");
  // const [lnameonly, setLNameonly] = useState("");
  const [dob, setDOB] = useState("");
  const [daygroup, setDateDay] = useState("");
  const [monthgroup, setDateMonth] = useState("");
  const [yeargroup, setDateYear] = useState("");
  const [email, setEmail] = useState("");
  const [primob, setPrimobile] = useState("");
  const [primobcode, setPriCode] = useState("");
  const [primobonly, setPriOnly] = useState("");

  const [secmob, setSecMobile] = useState("");
  const [secmobcode, setSecCode] = useState("");
  const [secmobonly, setSecOnly] = useState("");

  const [gender, setGender] = useState("");
  const [glist, setGenderlist] = useState([]);

  const [intcategory, setIntcategory] = useState("");
  const [intlist, setIntlist] = useState([]);

  const [education, setEducation] = useState("");
  const [elist, setEdulist] = useState([]);

  const [occu, setOccu] = useState("");
  const [olist, setOcculist] = useState([]);

  const [arttype, setArttype] = useState("");

  const [house, setHouse] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);

  const [portfolio, setPortfolio] = useState("");
  const [shortinfo, setShort] = useState("");
  const [brief, setBrief] = useState("");
  const [memfee, setMemfee] = useState("750");

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  function datelist() {
    var day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }
    setDaylist(day);

    var year = [];

    for (let i = 1930; i <= new Date().getFullYear() - 5; i++) {
      year.push(i);
    }
    setYearlist(year);
  }

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }

  var arr1 = [];
  function masterlist() {
    genderlist().then((response) => {
      // if (response.status === true) {
      setGenderlist(response.genders);
      // }
    });

    memberlist().then((response) => {
      setMemberlist(response.memshiptypes);
    });

    occupationlist().then((response) => {
      setOcculist(response.occupation);
    });

    educationlist().then((response) => {
      setEdulist(response.education);
    });

    countrylist().then((response) => {
      setCountrylist(response.country);
    });

    interestcategory().then((response) => {
      if (response.data.length !== 0) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
          });
        }
        setIntlist(arr1);
      }
    });
  }

  const [interestcat, setEventinterest] = useState("");
  const hangleInterest = (e) => {
    setEventinterest(e);
  };

  const handleprimary = (value, data, event, formattedValue) => {
    setPriCode(data.dialCode);
    setPrimobile(value);
    setPriOnly(value.slice(data.dialCode.length));
  };

  const handlesecondary = (value, data, event, formattedValue) => {
    setSecCode(data.dialCode);
    setSecMobile(value);
    setSecOnly(value.slice(data.dialCode.length));
  };
  //validate email
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setEmailError("Email is invalid");
    } else {
      setEmailError(null);
    }
    setEmail(event.target.value);
  };
  function Next1step() {
    if (lstep === 1) {
      setStep1(true);
      //setEmailError("");
      setMobError("");

      if (daygroup !== "" && monthgroup !== "" && yeargroup !== "") {
        setDOB(yeargroup + "-" + monthgroup + "-" + daygroup);
      }
      if (daygroup !== "" || monthgroup !== "" || yeargroup !== "") {
        setMobError("");
      }
      // let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      // if (!regEmail.test(email)) {
      //   evalid = "Please Enter a valid Email Address";
      //   setEmailError("Please Enter a valid Email Address");
      // }

      if (primob.length < 10) {
        setMobError("Primary Mobile Number must be minimum 10 characters");
      }

      if (
        fname !== "" &&
        lname !== "" &&
        memtype !== "" &&
        daygroup !== "" &&
        monthgroup !== "" &&
        yeargroup !== "" &&
        email !== "" &&
        valemail === "" &&
        emailerror === null &&
        valmob === "" &&
        valsecmob === "" &&
        moberror === "" &&
        primob.length > 10 &&
        primob !== "" &&
        gender !== ""
      ) {
        setStep(2);
      }
    }

    if (lstep == 2) {
      setStep2(true);

      if (
        education !== "" &&
        occu !== "" &&
        country !== "" &&
        state !== "" &&
        (interestcat !== "" || artadd1 !== "") &&
        city !== "" &&
        pincode !== "" &&
        house !== "" &&
        arttype !== ""
      ) {
        setStep(3);
      }
    }

    if (lstep == 3) {
      setStep3(true);

      // if (portfolio !== "" && shortinfo !== "") {
      setStep(4);
      // }
    }
  }

  function previous() {
    setStep(lstep - 1);
  }

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  const [formiderror, setIdErrors] = useState("");
  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setErrorMessage("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));
        setPhotoFile(e.target.files[0]);
        // let files;
        // if (e.dataTransfer) {
        //     files = e.dataTransfer.files;
        // } else if (e.target) {
        //     files = e.target.files;
        // }
        // const reader = new FileReader();
        // reader.onload = () => {
        //     setImage(reader.result);
        // };
        // reader.readAsDataURL(files[0]);
        // handleShow();
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Photograph size should be less than 3MB");
      }
    }
  }

  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }

  const [imagesrc, setImage] = useState("");
  const [cropper, setCropper] = useState("");

  const getCropData = (e) => {
    e.preventDefault();
    if (typeof cropper !== "undefined") {
      const arr = cropper.getCroppedCanvas().toDataURL().split(",");
      // const mime = arr[0].match(/:(.*?);/)[1];
      const byteCharacters = atob(arr[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      let image1 = new Blob([byteArray], { type: "image/png" });
      let check = new File([byteNumbers], fname.trim() + ".png", {
        type: "image/png",
      });

      let imageUrl = URL.createObjectURL(image1);
      setPhotoUpload(imageUrl);
      setPhotoFile(check);
      handleClose();
    }
  };

  function idfile(event) {
    setIdErrors("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(event.target.files[0]));
        setIDFile(event.target.files[0]);
      } else {
        setIdErrors("Identity Profile should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setIdErrors("Identity Profile size should be less than 3MB");
      }
    }
  }

  const [valmob, setValMob] = useState("");
  const [valfname, setValfname] = useState("");
  const [vallname, setVallname] = useState("");

  function checkmobile(e) {
    e.preventDefault();
    setValMob("");
    setValfname("");
    setVallname("");
    setMobError("");

    if (primobonly !== "") {
      restrictdetails(primobonly, fname, lname).then((response) => {
        if (response.status === false) {
          setValMob(response.message);
          Swal.fire({
            title: "Firstname,Lastname and Primary Mobile number already exist",
            allowOutsideClick: true,
          });
        }
      });
    }
  }
  const [valsecmob, setValSecMob] = useState("");
  function checksecmobile(e) {
    e.preventDefault();
    setValSecMob("");
    if (secmob.length < 10 && secmob !== "") {
      setValSecMob("Secondary Mobile Number must be 10 characters");
    }
    if (primob !== "") {
      if (secmob !== "") {
        if (secmob.length >= 10) {
          if (primob === secmob) {
            setValSecMob("Primary and Secondary Mobile should not be same");
          }
        } else {
          setValSecMob("Secondary Mobile Number must be 10 characters");
        }
      }
    }
  }
  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    if (e.target.value !== "") {
      getemail(e.target.value).then((response) => {
        if (response.status === false) {
          setValEmail(response.message);
          Swal.fire({
            title:
              response.message +
              " - " +
              response.data.username +
              " has been already have this Email",
            allowOutsideClick: false,
          });
        }
      });
    }
  }
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  function checkterm(e) {
    setCheck(e.target.checked);
  }
  // if (check === true) {
  //   setErrorMessage("");
  // }
  function registersubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    if (formphoto === "" && formid === "") {
      setPhotoErrors("Photograph is required");
      setIdErrors("DOB Proof is required");
    }
    if (formphoto === "") {
      setPhotoErrors("Photograph is required");
    } else if (formid === "") {
      setIdErrors("DOB Proof is required");
    }

    if (check === false) {
      setErrorMessage("Please Agree our terms and conditions");
    }

    if (
      formphoto !== "" &&
      formid !== "" &&
      formphotoerror === "" &&
      formiderror === "" &&
      check === true
    ) {
      setSubmitted(true);
    }

    if (
      formphoto !== "" &&
      formid !== "" &&
      formphotoerror === "" &&
      formiderror === "" &&
      check === true
    ) {
      setLoading(true);
      let intid = "";
      if (interestcat.length !== 0) {
        for (let j = 0; j < interestcat.length; j++) {
          if (intid === "") {
            intid = interestcat[j].value;
          } else {
            intid = intid + "," + interestcat[j].value;
          }
        }
      }

      const tempData = new FormData();
      tempData.append("first_name", fname);
      tempData.append("last_name", lname);
      tempData.append("membership_type", memtype);
      tempData.append("email_id", email);
      tempData.append("primary_mobile", primobonly);
      tempData.append("secondary_mobile", secmobonly);
      tempData.append("primary_mob_country_code", primobcode);
      tempData.append("sec_mob_country_code", secmobcode);

      tempData.append("user_types", arttype);

      tempData.append("artist_count", artadd);
      for (let i = 1; i <= artadd; i++) {
        if (i == 1) {
          tempData.append("artist_art_type_" + i, artadd1);
        } else if (i == 2) {
          tempData.append("artist_art_type_" + i, artadd2);
        } else if (i == 3) {
          tempData.append("artist_art_type_" + i, artadd3);
        }
      }

      tempData.append("interested_category_uid", intid);
      // tempData.append("interested_category_uid", intid);

      tempData.append("gender", gender);
      tempData.append("date_of_birth", dob);
      tempData.append("education", education);
      tempData.append("occupation", occu);
      tempData.append("street_no", house);
      tempData.append("pincode", pincode);
      tempData.append("country", country);
      tempData.append("state", state);
      tempData.append("city", city);
      tempData.append("portfolio", portfolio);
      tempData.append("short_info", shortinfo);
      tempData.append("introductory_brief", brief);
      tempData.append("photo", formphoto);
      tempData.append("identity", formid);
      tempData.append("register_by", 0);
      registerdata(tempData).then((responsedata) => {
        if (responsedata.status === true) {
          setSubmitted(false);
          getorderpay(responsedata.data.user_uid, 1, memfee).then(
            (response) => {
             
              paynow(
                responsedata.data.user_uid,
                responsedata.data.role_uid,
                parseInt(memfee) * 100,
                response.payment_type.payment_currency,
                response.order_details.id,
                response.payment_type.payment_type,
                memfee
              );
            }
          );
        } else {
          setErrorMessage(responsedata.data);
        }
      });
    }
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function paynow(
    userid,
    roleid,
    amt,
    currency,
    orderid,
    paytype,
    payamt
  ) {
    const options = {
      // key: "rzp_test_XFnDXpEfL0bcLT",
      key: "rzp_live_EtyIA627A0V6mO",
      amount: amt,
      currency: currency,
      order_uid: orderid,
      name: "Pythian Games",
      description: paytype,
      image: require("../../assets/images/logo/logonew.png"),
      handler: function (response) {
        const paydet = {
          user_uid: userid,
          role_id: roleid,
          payment_type: 1,
          order_id: response.razorpay_order_id,
          payment_id: response.razorpay_payment_id,
          payment_sign: response.razorpay_signature,
          amount: payamt,
          currency: currency,
        };

        paymentgateway(paydet).then((responsepay) => {
          if (responsepay.status === true) {
            toast.success(responsepay.message);
            navigate("/login");
          }
        });
      },
      prefill: {
        email: "",
        phone_number: "",
      },
    };
    const paymentObj = new window.Razorpay(options);

    paymentObj.open();
  }

  const [artadd, setArtadd] = useState(1);
  const [artadd1, setArtadd1] = useState("");
  const [artadd2, setArtadd2] = useState("");
  const [artadd3, setArtadd3] = useState("");

  function addmulti() {
    if (artadd < 3) {
      setArtadd(artadd + 1);
    }
  }

  function changetype(e) {
    setArttype(e.target.value);
    if (e.target.value === "Artist") {
      setIntcategory("");
    } else if (e.target.value === "Non-Artist") {
      setArtadd(1);
      setArtadd1("");
      setArtadd2("");
      setArtadd3("");
    }
  }
  function addremove() {
    if (artadd == 1) {
      setArtadd2("");
      setArtadd3("");
    } else if (artadd == 2) {
      setArtadd2("");
    } else if (artadd == 3) {
      setArtadd3("");
    }
    setArtadd(artadd - 1);
  }
  useEffect(() => {
    datelist();
    masterlist();
  }, []);

  return (
    <div id="registrationpage">
      <div className="registrationmain">
        <div className="registrationleft">
          <a href="https://pythiangames.org/" target="_blank">
            <img
              src={require("../../assets/images/logo/logonew.png")}
              height="160"
              className="logo-image"
            />
          </a>

          <div className="deshtop-screen">
            <img
              src={require("../../assets/images/art-image.png")}
              width="440"
              height=""
            />
          </div>
          <div></div>
        </div>
        <div className="registrationright">
          <form action="#" className="form">
            <h2 className="text-right">Membership Registration</h2>
            <div className="progressbar">
              <div
                className="progress"
                id="progress"
                style={{
                  width:
                    lstep === 1
                      ? "0%"
                      : lstep === 2
                      ? "33.3%"
                      : lstep === 3
                      ? "66.6%"
                      : "100%",
                }}
              ></div>

              <div
                className="progress-step progress-step-active"
                data-title="Information"
              ></div>
              <div
                className={`progress-step ${
                  lstep >= 2 ? "progress-step-active" : ""
                }`}
                data-title="Address"
              ></div>
              <div
                className={`progress-step ${
                  lstep >= 3 ? "progress-step-active" : ""
                }`}
                data-title="Files"
              ></div>
              <div
                className={`progress-step ${
                  lstep >= 4 ? "progress-step-active" : ""
                }`}
                data-title="Password"
              ></div>
            </div>

            {/* Step One */}
            {lstep === 1 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <h4>Personal Information</h4>
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          {" "}
                          First Name :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your First Name"
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          maxLength={25}
                          onBlur={checkmobile}
                          value={fname}
                          onChange={(e) => {
                            setFName(e.target.value);
                          }}
                        />
                        {step1submit && !fname && (
                          <span className="errortxt text-danger">
                            First Name is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Last Name :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Last / Family Name"
                          onKeyPress={LetterOnly}
                          onPaste={onPasteLetter}
                          maxLength={25}
                          onBlur={checkmobile}
                          value={lname}
                          onChange={(e) => {
                            setLName(e.target.value);
                          }}
                        />

                        {step1submit && !lname && (
                          <span className="errortxt text-danger">
                            Last Name is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Membership Type :<b className="text-danger">*</b>{" "}
                        </label>
                        <select
                          value={memtype}
                          onChange={(e) => {
                            setMember(e.target.value);
                            setMemfee(e.target.value == "9" ? "250" : "750");
                          }}
                        >
                          <option value="" disabled>
                            Select Membership Type
                          </option>
                          {memlist.map((data, i) => {
                            return (
                              <option value={data.membership_type_uid} key={i}>
                                {data.membership_type_name}
                              </option>
                            );
                          })}
                        </select>
                        {step1submit && !memtype && (
                          <span className="errortxt text-danger">
                            Membership Type is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Email Address :<b className="text-danger">*</b>
                        </label>
                        <input
                          type="text"
                          // onBlur={checkemail}
                          placeholder="Enter Your Email Address"
                          value={email}
                          maxLength={50}
                          onChange={handleChange}
                        />
                        {step1submit && !email && (
                          <span className="errortxt text-danger">
                            Email is required
                          </span>
                        )}
                        {emailerror !== "" && email && (
                          <span className="errortxt text-danger">
                            {emailerror}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Primary Mobile :<b className="text-danger">*</b>{" "}
                        </label>
                        <PhoneInput
                          specialLabel={""}
                          country={"in"}
                          placeholder="Enter Primary Mobile Number"
                          value={primob}
                          onChange={handleprimary}
                          countryCodeEditable={false}
                          onBlur={checkmobile}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />

                        {step1submit && !primob && (
                          <span className="errortxt text-danger">
                            Primary Mobile Number is required
                          </span>
                        )}
                        {moberror !== "" && primob && (
                          <span className="errortxt text-danger">
                            {moberror}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">Secondary Mobile :</label>
                        <PhoneInput
                          specialLabel={""}
                          country={"in"}
                          placeholder="Enter Secondary Mobile Number"
                          value={secmob}
                          onChange={handlesecondary}
                          countryCodeEditable={false}
                          onBlur={checksecmobile}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />

                        {valsecmob !== "" && secmob && (
                          <span className="errortxt text-danger">
                            {valsecmob}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Gender :<b className="text-danger">*</b>
                        </label>
                        <select
                          value={gender}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Your Gender
                          </option>
                          {glist.map((data, i) => {
                            return (
                              <option value={data.gender_uid} key={i}>
                                {data.gender}
                              </option>
                            );
                          })}
                        </select>
                        {step1submit && !gender && (
                          <span className="errortxt text-danger">
                            Gender is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Date of Birth :<b className="text-danger">*</b>
                        </label>
                        {/* <input type="date" name="username" id="username" /> */}

                        <div className="row">
                          <div className="col-md-4 col-sm-4 xpr-0">
                            <select
                              value={daygroup}
                              onChange={(e) => {
                                setDateDay(e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Day
                              </option>
                              {dateday.map((data, i) => {
                                return (
                                  <option value={data} key={i}>
                                    {data}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-md-4 col-sm-4 xpx-0">
                            <select
                              value={monthgroup}
                              onChange={(e) => {
                                setDateMonth(e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Month
                              </option>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                          </div>
                          <div className="col-md-4 col-sm-4 xpl-0">
                            <select
                              value={yeargroup}
                              onChange={(e) => {
                                setDateYear(e.target.value);
                              }}
                            >
                              <option value="" disabled>
                                Year
                              </option>
                              {dateyear.map((data, i) => {
                                return (
                                  <option value={data} key={i}>
                                    {data}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {step1submit &&
                          (!daygroup || !monthgroup || !yeargroup) && (
                            <span className="errortxt text-danger">
                              Date of Birth is required
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Link to={"/login"} className="btn btn-secondary">
                    Back to Login
                  </Link>
                  <a
                    className="btn btn-primary btn-next"
                    style={{ float: "right" }}
                    onClick={Next1step}
                  >
                    Next
                  </a>
                </div>
              </div>
            )}

            {/* Step Two */}
            {lstep === 2 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <h4>Primary Information</h4>
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Education :<b className="text-danger">*</b>{" "}
                        </label>
                        <select
                          value={education}
                          onChange={(e) => {
                            setEducation(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Your Qualification
                          </option>
                          {elist.map((data, i) => {
                            return (
                              <option value={data.education_uid} key={i}>
                                {data.education}
                              </option>
                            );
                          })}
                        </select>
                        {step2submit && !education && (
                          <span className="errortxt text-danger">
                            Education is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Occupation :<b className="text-danger">*</b>{" "}
                        </label>
                        <select
                          value={occu}
                          onChange={(e) => {
                            setOccu(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Your Occupation
                          </option>
                          {olist.map((data, i) => {
                            return (
                              <option value={data.occupation_uid} key={i}>
                                {data.occupation}
                              </option>
                            );
                          })}
                        </select>
                        {step2submit && !occu && (
                          <span className="errortxt text-danger">
                            Occupation is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <div className="input-group">
                        <label htmlFor="username">
                          Country :<b className="text-danger">*</b>{" "}
                        </label>
                        <select value={country} onChange={Selectcountry}>
                          <option value="" disabled>
                            Select Your Country
                          </option>
                          {colist.map((data, i) => {
                            return (
                              <option value={data.country_uid} key={i}>
                                {data.country_name}
                              </option>
                            );
                          })}
                        </select>
                        {step2submit && !country && (
                          <span className="errortxt text-danger">
                            Country is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          State :<b className="text-danger">*</b>{" "}
                        </label>
                        <select value={state} onChange={Selectstate}>
                          <option value="" disabled>
                            Select Your State
                          </option>
                          {slist.map((data, i) => {
                            return (
                              <option value={data.state_uid} key={i}>
                                {data.state_name}
                              </option>
                            );
                          })}
                        </select>
                        {step2submit && !state && (
                          <span className="errortxt text-danger">
                            State is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          City :<b className="text-danger">*</b>{" "}
                        </label>
                        <select
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Your City
                          </option>
                          {clist.map((data, i) => {
                            return (
                              <option value={data.city_uid} key={i}>
                                {data.city_name}
                              </option>
                            );
                          })}
                        </select>
                        {step2submit && !city && (
                          <span className="errortxt text-danger">
                            City is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          House No / Street No :<b className="text-danger">*</b>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter House / Street Number"
                          value={house}
                          onChange={(e) => {
                            setHouse(e.target.value);
                          }}
                        />
                        {step2submit && !house && (
                          <span className="errortxt text-danger">
                            House no. is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6">
                      <div className="input-group">
                        <label htmlFor="username">
                          Pincode :<b className="text-danger">*</b>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Your Pincode"
                          maxLength={6}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={pincode}
                          onChange={(e) => {
                            setPincode(e.target.value);
                          }}
                        />
                        {step2submit && !pincode && (
                          <span className="errortxt text-danger">
                            Pincode is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <div className="input-group">
                        <label htmlFor="username">
                          User Type :<b className="text-danger">*</b>{" "}
                        </label>
                        <select value={arttype} onChange={changetype}>
                          <option value="" disabled>
                            Select Your User Type
                          </option>

                          <option value="Artist">Artist</option>
                          <option value="Non Artist">Non Artist</option>
                        </select>
                        {step2submit && !arttype && (
                          <span className="errortxt text-danger">
                            Art type is required
                          </span>
                        )}
                      </div>
                    </div>
                    {arttype == "Non Artist" && (
                      <div className="col-md-12 col-lg-12">
                        <div className="input-group">
                          <label htmlFor="username">
                            Interesting Category :
                            <b className="text-danger">*</b>{" "}
                          </label>

                          <Select
                            placeholder="Select Your Event Category"
                            value={interestcat}
                            options={intlist}
                            onChange={hangleInterest}
                            isMulti
                            isClearable
                          />
                          {step2submit && !interestcat && (
                            <span className="errortxt text-danger">
                              Category is required
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {arttype == "Artist" && (
                    <div className="row">
                      <div className="col-md-10 col-lg-10">
                        <div className="input-group">
                          <label htmlFor="username">
                            Arts 1 :<b className="text-danger">*</b>{" "}
                          </label>

                          <input
                            type="text"
                            placeholder="Enter Your Arts 1"
                            value={artadd1}
                            onChange={(e) => {
                              setArtadd1(e.target.value);
                            }}
                          />
                          {step2submit && !artadd1 && (
                            <span className="errortxt text-danger">
                              Art type is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-1 text-right">
                        <a
                          type="button"
                          className="btn btn-success btn-sm mr-2"
                          style={{
                            minWidth: "50px",
                            height: "30px",
                            marginTop: "35px",
                          }}
                          onClick={addmulti}
                        >
                          <i
                            className="fa fa-plus"
                            style={{ marginTop: "5px" }}
                          ></i>
                        </a>
                      </div>
                      <div className="col-md-1 text-right"></div>
                    </div>
                  )}
                  {arttype == "Artist" && artadd >= 2 && (
                    <div className="row">
                      <div className="col-md-10 col-lg-10">
                        <div className="input-group">
                          <label htmlFor="username">Arts 2 :</label>

                          <input
                            type="text"
                            placeholder="Enter Your Arts 2"
                            value={artadd2}
                            onChange={(e) => {
                              setArtadd2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 text-right">
                        <a
                          type="button"
                          className="btn btn-success btn-sm mr-2"
                          style={{
                            minWidth: "50px",
                            height: "30px",
                            marginTop: "35px",
                          }}
                          onClick={addmulti}
                        >
                          <i
                            className="fa fa-plus"
                            style={{ marginTop: "5px" }}
                          ></i>
                        </a>
                      </div>
                      {artadd == 2 && (
                        <div className="col-md-1 text-right">
                          <a
                            type="button"
                            className="btn btn-danger btn-sm mr-2"
                            style={{
                              minWidth: "50px",
                              height: "30px",
                              marginTop: "35px",
                            }}
                            onClick={addremove}
                          >
                            <i
                              className="fa fa-remove"
                              style={{ marginTop: "5px" }}
                            ></i>
                          </a>
                        </div>
                      )}
                    </div>
                  )}

                  {arttype == "Artist" && artadd >= 3 && (
                    <div className="row">
                      <div className="col-md-10 col-lg-10">
                        <div className="input-group">
                          <label htmlFor="username">Arts 3 :</label>

                          <input
                            type="text"
                            placeholder="Enter Your Arts 3"
                            value={artadd3}
                            onChange={(e) => {
                              setArtadd3(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {artadd == 3 && (
                        <div className="col-md-1 text-right">
                          <a
                            type="button"
                            className="btn btn-danger btn-sm mr-2"
                            style={{
                              minWidth: "50px",
                              height: "30px",
                              marginTop: "35px",
                            }}
                            onClick={addremove}
                          >
                            <i
                              className="fa fa-remove"
                              style={{ marginTop: "5px" }}
                            ></i>
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="">
                  <a className="btn btn-prev btn-secondary" onClick={previous}>
                    Previous
                  </a>
                  <a
                    className="btn btn-next btn-primary"
                    style={{ float: "right" }}
                    onClick={Next1step}
                  >
                    Next
                  </a>
                </div>
              </div>
            )}

            {/* Step Three */}

            {lstep === 3 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <h4>Additional Information</h4>
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="input-group">
                        <label htmlFor="username">
                          Portfolio :{/* <b className="text-danger">*</b> */}
                        </label>
                        <textarea
                          rows={3}
                          placeholder="Enter Your Portfolio"
                          value={portfolio}
                          onChange={(e) => {
                            setPortfolio(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <div className="input-group">
                        <label htmlFor="username">
                          Short Info :{/* <b className="text-danger">*</b> */}
                        </label>
                        <textarea
                          rows={3}
                          placeholder="Enter Your Short Info"
                          value={shortinfo}
                          onChange={(e) => {
                            setShort(e.target.value);
                          }}
                        />
                        {/* {step3submit && !shortinfo && (
                          <span className="errortxt text-danger">
                            Short Information is required
                          </span>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                      <div className="input-group">
                        <label htmlFor="username">Introductory Brief : </label>
                        <textarea
                          rows={3}
                          placeholder="Enter Your Introductory Brief"
                          value={brief}
                          onChange={(e) => {
                            setBrief(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <a className="btn btn-prev btn-secondary" onClick={previous}>
                    Previous
                  </a>
                  <a
                    className="btn btn-next btn-primary"
                    style={{ float: "right" }}
                    onClick={Next1step}
                  >
                    Next
                  </a>
                </div>
              </div>
            )}

            {/* Step Four */}
            {lstep === 4 && (
              <div className="form-step form-step-active">
                <div className="form-inner">
                  <h4>
                    File Uploads{" "}
                    <span>
                      ( Accepted Files are <b>.png, .jpg, .jpeg</b> & Max Upload
                      Size <b>3MB</b> )
                    </span>
                  </h4>

                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-8">
                        <label htmlFor="dob">
                          1. Add Photograph : <b className="text-danger">*</b>
                        </label>

                        <div className="mt-4">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept=".png,.jpg,.jpeg"
                            onChange={photofile}
                          />
                        </div>
                      </div>

                      {formphotoupload !== "" && (
                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={formphotoupload}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}

                      {formphotoupload === "" && (
                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={require("../../assets/images/placeholder.png")}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {formphotoerror !== "" && (
                      <span className="errortxt text-danger">
                        {formphotoerror}
                      </span>
                    )}
                  </div>

                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-8">
                        <label htmlFor="dob">
                          2. Add Identity Profile :{" "}
                          <b className="text-danger">*</b>
                        </label>
                        <div className="mt-4">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept=".png,.jpg,.jpeg"
                            onChange={idfile}
                          />
                        </div>
                      </div>
                      {formidupload !== "" && (
                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={formidupload}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}

                      {formidupload === "" && (
                        <div className="col-md-4">
                          <div className="imagecontainer">
                            <img
                              src={require("../../assets/images/placeholder.png")}
                              width="100%"
                              height="auto"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {formiderror !== "" && (
                      <span className="errortxt text-danger">
                        {formiderror}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-9">
                      <div className="checkbox checkbox-1">
                        <input
                          type="checkbox"
                          id="checkbox-1"
                          onChange={checkterm}
                          checked={check}
                        />
                        <label htmlFor="checkbox-1">
                          Agree to our{" "}
                          <a
                            target="_blank"
                            href="https://www.pythiangames.org/privacy"
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            target="_blank"
                            href="https://www.pythiangames.org/terms"
                          >
                            Terms & Conditions
                          </a>
                          <b className="text-danger">*</b>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="member">
                        <label>Membership Fee: {memfee} INR</label>
                      </div>
                    </div>
                  </div>
                </div>

                {errorMessage !== "" && check === false && (
                  <span className="errortxt text-danger">{errorMessage}</span>
                )}

                <div className="">
                  <a className="btn btn-prev btn-secondary" onClick={previous}>
                    Previous
                  </a>
                  <a
                    type="submit"
                    onClick={registersubmit}
                    className="btn btn-success"
                    style={{ float: "right" }}
                    disabled={false}
                  >
                    {!submitted ? (
                      "Submit"
                    ) : (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden"></span>
                      </div>
                    )}
                  </a>

                  {/* <input
                                        type="submit"
                                        value="Submit"
                                        className="btn btn-success"
                                        style={{ float: 'right' }}
                                        onClick={registersubmit}
                                    /> */}
                </div>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Body>
                    <Cropper
                      style={{ height: 400, width: "100%" }}
                      zoomTo={0.5}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={imagesrc}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                      guides={true}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    {errorMessage === "" && (
                      <a
                        className="btn btn-prev btn-primary"
                        style={{ float: "right" }}
                        onClick={getCropData}
                      >
                        Crop
                      </a>
                    )}
                    <a
                      className="btn btn-next btn-secondary"
                      style={{ float: "right" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </a>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
