import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { upcomingentryeventlist, userdatalist } from "../../services/admin";
// import "./users.css";

const Editeventrender = (props) => {
  return (
    <Link to={"/viewevent/" + props.value} className="text-info">
      <i className="ti ti-eye"></i>
    </Link>
  );
};

const Userentryeventlist = () => {
  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Event Name",
      width: 250,
      field: "event_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event Venue",
      width: 170,
      field: "event_venue",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "Category Name",
    //   width: 170,
    //   field: "category",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },

    {
      headerName: "Start Date",
      width: 150,
      field: "event_start",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "event_end",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "Result Declare Date",
      width: 150,
      field: "event_entry_close",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    // {
    //   headerName: "Type",
    //   width: 150,
    //   field: "event_file_type",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Actions",
      width: 130,
      field: "event_uid",
      filter: false,

      cellRenderer: function (params) {
        return (
          <Link
            className="btn-entry"
            to={"/user/entryeventreg/" + params.value}
          >
            Register
          </Link>
        );
      },
    },
  ];

  let arr = [];
  useEffect(() => {
    upcomingentryeventlist().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            var atype = "";
            var type = "";
            // atype = response.data[i].event_file_type;
            // var name = atype.split(",");
            // if (name.length !== 0) {
            //   for (let j = 0; j < name.length; j++) {
            //     if (type === "") {
            //       if (name[j] === "1") {
            //         type = "Video";
            //       } else if (name[j] === "2") {
            //         type = "Audio";
            //       } else if (name[j] === "3") {
            //         type = "Image";
            //       }
            //     } else {
            //       if (name[j] === "1") {
            //         type = type + ", Video";
            //       } else if (name[j] === "2") {
            //         type = type + ", Audio";
            //       } else if (name[j] === "3") {
            //         type = type + ", Image";
            //       }
            //     }
            //   }
            // }
            arr.push({
              event_name: response.data[i].event_name,
              event_venue: response.data[i].event_venue,
              event_start: response.data[i].event_start,
              event_end: response.data[i].event_end,
              event_entry_close: response.data[i].event_entry_close,
              // category: response.data[i].category,
              // event_file_type: type,
              event_uid: response.data[i].event_uid,
            });
          }
        }
        setFilterProduct(arr);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Upcoming Entry Event List
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="App">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600, width: 1000 }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      suppressDragLeaveHidesColumns={true}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Userentryeventlist;
