export const MENUITEMS = [
  {
    menutitle: "Navigation",
    Items: [
      {
        path: "/jury/dashboard",
        icon: "ti-home",
        type: "link",
        active: false,
        selected: false,
        title: "Dashboard",
      },
      {
        path: "/jury/profile",
        icon: "ti-user",
        type: "link",
        active: false,
        selected: false,
        title: "Profile",
      },
      {
        path: "/jury/eventlist",
        title: "Event",
        icon: "ti-announcement",
        type: "link",
        active: false,
        selected: false,
        // children: [
        //   {
        //     path: "/jury/eventlist",
        //     type: "link",
        //     active: false,
        //     selected: false,
        //     title: "Event Lists",
        //   },
        // ],
      },
    ],
  },
];
