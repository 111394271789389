import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, FormFloating, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { eventcategorylist } from "../../../services/admin";
import {
  citylist,
  countrylist,
  genderlist,
  statelist,
  juryslanguage,
} from "../../../services/register";
import imageUrl from "../../../ImageUrl";
import {
  editjuryupdate,
  juryupdate,
  juryviewdetail,
} from "../../../services/jury";

const JuryEdit = () => {
  const [submit, setSubmit] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [dob, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [glist, setGenderlist] = useState([]);

  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);

  const [primob, setPrimobile] = useState("");
  const [mobilecode, setPriCode] = useState("");
  const [mobonly, setPriOnly] = useState("");

  const [category, setCategory] = useState("");
  const [catlist, setCatList] = useState([]);

  const [interestlang, setLanguage] = useState("");
  const [langlist, setLanglist] = useState([]);

  const handleprimary = (value, data, event, formattedValue) => {
    setPriCode(data.dialCode);
    setPrimobile(value);
    setPriOnly(value.slice(data.dialCode.length));
  };

  const hangleInterest = (e) => {
    setCategory(e);
  };

  const hangleLanguage = (e) => {
    setLanguage(e);
  };

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }
  function masterlist() {
    genderlist().then((response) => {
      setGenderlist(response.genders);
    });

    countrylist().then((response) => {
      setCountrylist(response.country);
    });
  }

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  const [formiderror, setIdErrors] = useState("");
  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setErrorMessage("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));
        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Photograph size should be less than 3MB");
      }
    }
  }

  function idfile(event) {
    setIdErrors("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(event.target.files[0]));
        setIDFile(event.target.files[0]);
      } else {
        setIdErrors("Identity Profile should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setIdErrors("Identity Profile size should be less than 3MB");
      }
    }
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../assets/images/user.png");
    ev.target.onerror = null;
  }

  function addsubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    setSubmit(true);

    setEmailError("");
    setMobError("");
    var check = "";
    if (email !== "") {
      let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regEmail.test(email)) {
        check = "check";
        setEmailError("Please Enter a valid Email Address");
      }
    }
    if (primob !== "") {
      if (primob.length < 10) {
        check = "check";
        setMobError("Mobile Number must be 10 characters");
      }
    }

    if (
      fname !== "" &&
      email !== "" &&
      primob !== "" &&
      gender !== "" &&
      interestlang !== "" &&
      category !== "" &&
      check === ""
    ) {
      let cid = "";
      if (category.length !== 0) {
        for (let j = 0; j < category.length; j++) {
          if (cid === "") {
            cid = category[j].value;
          } else {
            cid = cid + "," + category[j].value;
          }
        }
      }

      let lanid = "";

      if (interestlang.length !== 0) {
        for (let j = 0; j < interestlang.length; j++) {
          if (lanid === "") {
            lanid = interestlang[j].value;
          } else {
            lanid = lanid + "," + interestlang[j].value;
          }
        }
      }
      const tempData = new FormData();
      tempData.append("user_uid", id);
      tempData.append("first_name", fname);
      tempData.append("last_name", lname);
      tempData.append("gender", gender);
      if (dob !== "") {
        tempData.append("date_of_birth", dob);
      } else {
        tempData.append("date_of_birth", "");
      }

      tempData.append("primary_mobile", mobonly);
      tempData.append("primary_mob_country_code", mobilecode);
      tempData.append("email", email);

      tempData.append("street_no", add1);
      tempData.append("address_2", add2);

      tempData.append("pincode", pincode);
      tempData.append("country", country);
      tempData.append("state", state);
      tempData.append("city", city);
      tempData.append("photo", formphoto);
      tempData.append("identity", formid);
      tempData.append("interested_category_uid", cid);
      tempData.append("language_uid", lanid);

      juryupdate(tempData).then((response) => {
        if (response.status === true) {
          setSubmit(false);
          navigate("/viewjury/" + id);
        } else {
          setErrorMessage(response.data);
        }
      });
    }
  }

  const { id } = useParams();
  const navigate = useNavigate();
  let arr1 = [];
  let arr2 = [];

  let carr = [];
  let larr = [];

  useEffect(() => {
    masterlist();

    eventcategorylist().then((response) => {
      if (response.data.length !== 0) {
        for (let i = 0; i < response.data.length; i++) {
          arr1.push({
            value: response.data[i].category_uid,
            label: response.data[i].category,
          });
        }
        setCatList(arr1);
      }
    });

    juryslanguage().then((response) => {
      if (response.language.length !== 0) {
        for (let i = 0; i < response.language.length; i++) {
          arr2.push({
            value: response.language[i].language_uid,
            label: response.language[i].language,
          });
        }
        setLanglist(arr2);
      }
    });

    juryviewdetail(id).then((response) => {
      if (response.status === true) {
        setFName(response.data.first_name);
        if (response.data.last_name !== null) {
          setLName(response.data.last_name);
        }
        setEmail(response.data.email);
        setPriOnly(response.data.primary_mobile);

        if (response.data.primary_mob_country_code !== null) {
          setPriCode(response.data.primary_mob_country_code);
          setPrimobile(
            "(+" +
              response.data.primary_mob_country_code +
              ") " +
              response.data.primary_mobile
          );
        } else {
          setPrimobile(response.data.primary_mobile);
        }

        setGender(response.data.gender_uid);
        if (response.data.date_of_birth !== null) {
          if (response.data.date_of_birth !== "--") {
            setDOB(moment(response.data.date_of_birth).format("YYYY-MM-DD"));
          }
        }

        setAdd1(response.data.street_no);
        if (response.data.address_2 !== null) {
          setAdd2(response.data.address_2);
        }

        setCountry(response.data.country);
        setState(response.data.state);
        setCity(response.data.city);
        setPincode(response.data.pincode);
        if (response.data.photo !== null) {
          setPhotoUpload(imageUrl + "jury/photo/" + response.data.photo);
        }
        if (response.data.identity !== null) {
          setIDUpload(imageUrl + "jury/id_proof/" + response.data.identity);
        }

        if (response.data.country !== "") {
          statelist(response.data.country).then((response) => {
            setStatelist(response.states);
          });
        }

        if (response.data.state !== "") {
          citylist(response.data.state).then((response) => {
            setCitylist(response.cities);
          });
        }

        var catname = "";
        setCategory("");

        if (response.data.category.length !== 0) {
          eventcategorylist().then((res) => {
            if (res.status === true) {
              if (res.data.length !== 0) {
                for (let j = 0; j < response.data.category.length; j++) {
                  for (let i = 0; i < res.data.length; i++) {
                    if (
                      response.data.category[j] ===
                      res.data[i].category_uid + ""
                    ) {
                      if (catname === "") {
                        catname = res.data[i].category;
                        carr.push({
                          value: res.data[i].category_uid,
                          label: res.data[i].category,
                        });
                      } else {
                        catname = catname + " , " + res.data[i].category;
                        carr.push({
                          value: res.data[i].category_uid,
                          label: res.data[i].category,
                        });
                      }
                    }
                  }
                }
              }
              setCategory(carr);
            }
          });
        }

        setLanguage("");

        if (response.data.language.length !== 0) {
          var langname = "";
          juryslanguage().then((res) => {
            if (res.language.length !== 0) {
              for (let j = 0; j < response.data.language.length; j++) {
                for (let i = 0; i < res.language.length; i++) {
                  if (
                    response.data.language[j] ===
                    res.language[i].language_uid + ""
                  ) {
                    if (langname === "") {
                      langname = res.language[i].language;
                      larr.push({
                        value: res.language[i].language_uid,
                        label: res.language[i].language,
                      });
                    } else {
                      langname = langname + " , " + res.language[i].language;
                      larr.push({
                        value: res.language[i].language_uid,
                        label: res.language[i].language,
                      });
                    }
                  }
                }
              }
            }
            setLanguage(larr);
          });
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">EDIT JURY</h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <h5>Personal Information</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            First Name :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your First Name"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            maxLength={25}
                            value={fname}
                            onChange={(e) => {
                              setFName(e.target.value);
                            }}
                          />
                          {submit && !fname && (
                            <span className="errortxt text-danger">
                              First Name is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">Last Name :</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Last Name"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            maxLength={25}
                            value={lname}
                            onChange={(e) => {
                              setLName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Email Address :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Email Address"
                            value={email}
                            maxLength={50}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          {submit && !email && (
                            <span className="errortxt text-danger">
                              Email Address is required
                            </span>
                          )}
                          {emailerror !== "" && email && (
                            <span className="errortxt text-danger">
                              {emailerror}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Mobile Number:<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <PhoneInput
                            specialLabel={""}
                            country={"in"}
                            placeholder="Enter Mobile Number"
                            value={primob}
                            onChange={handleprimary}
                            countryCodeEditable={false}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />

                          {submit && !primob && (
                            <span className="errortxt text-danger">
                              Mobile Number is required
                            </span>
                          )}
                          {moberror !== "" && primob && (
                            <span className="errortxt text-danger">
                              {moberror}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Gender :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Gender
                            </option>
                            {glist.map((data, i) => {
                              return (
                                <option value={data.gender_uid} key={i}>
                                  {data.gender}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !gender && (
                            <span className="errortxt text-danger">
                              Gender is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">Date of Birth :</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            value={dob}
                            onChange={(e) => {
                              setDOB(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <h5>Primary Information</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">Address 1 :</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 1"
                            value={add1}
                            onChange={(e) => {
                              setAdd1(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">Address 2 :</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Address 2"
                            value={add2}
                            onChange={(e) => {
                              setAdd2(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">Country :</label>
                        </div>
                        <div className="col-md-8">
                          <select value={country} onChange={Selectcountry}>
                            <option value="" disabled>
                              Select Your Country
                            </option>
                            {colist.map((data, i) => {
                              return (
                                <option value={data.country_uid} key={i}>
                                  {data.country_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">State :</label>
                        </div>
                        <div className="col-md-8">
                          <select value={state} onChange={Selectstate}>
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.state_uid} key={i}>
                                  {data.state_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">City :</label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your City
                            </option>
                            {clist.map((data, i) => {
                              return (
                                <option value={data.city_uid} key={i}>
                                  {data.city_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">Pincode:</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Pincode"
                            maxLength={6}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={pincode}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <h5>Professional Information</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Category : <b className="text-danger">*</b>
                      </label>
                      <Select
                        placeholder="Select Your Category"
                        value={category}
                        options={catlist}
                        onChange={hangleInterest}
                        isMulti
                        isClearable
                      />
                      {submit && !category && (
                        <span className="errortxt text-danger">
                          Category is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <label htmlFor="username">
                        Language :<b className="text-danger">*</b>{" "}
                      </label>

                      <Select
                        placeholder="Select Your Language"
                        value={interestlang}
                        options={langlist}
                        onChange={hangleLanguage}
                        isMulti
                        isClearable
                      />
                      {submit && !interestlang && (
                        <span className="errortxt text-danger">
                          Language is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <hr />

                <h5>File Uploads </h5>
                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="dob">
                        Photograph : <span>(Maximum size 900KB)</span>
                      </label>

                      <div className="mt-4">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={photofile}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>

                    {formphotoupload !== "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={formphotoupload}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}

                    {formphotoupload === "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formphotoerror !== "" && (
                    <span className="errortxt text-danger">
                      {formphotoerror}
                    </span>
                  )}
                </div>

                <div className="input-group form-upload">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="dob">
                        Identity Proof : <span>(Maximum size 900KB)</span>
                      </label>

                      <div className="mt-4">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={idfile}
                          onError={(e) => errorhandle(e)}
                        />
                      </div>
                    </div>

                    {formidupload !== "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img src={formidupload} width="100%" height="auto" />
                        </div>
                      </div>
                    )}

                    {formidupload === "" && (
                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={require("../../../assets/images/placeholder.png")}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {formiderror !== "" && (
                    <span className="errortxt text-danger">{formiderror}</span>
                  )}
                </div>
                <hr />
                <a
                  type="submit"
                  onClick={addsubmit}
                  className="btn btn-success"
                  style={{ float: "right" }}
                >
                  Update
                </a>

                <a
                  className="btn btn-prev btn-secondary"
                  style={{ float: "right" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default JuryEdit;
