import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function GuestOTPLogin() {
  const [otp, setOTP] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  function Submitlogin(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage("");

    if (otp !== "") {
      if (otp.length < 6) {
        setErrorMessage("OTP must be 6 characters");
      } else {
        if (otp === localStorage.getItem("Otp")) {
          localStorage.removeItem("Otp");
          navigate("/guest/details");
        } else {
          setErrorMessage("Please Enter a valid OTP");
        }
      }
    }
  }
  return (
    <div>
      <main id="loginpage">
        <div className="container">
          <div className="loginpage_section">
            <div className="loginpage_secionone">
              <div className="loginpage_sectionone_left">
                <div className="loginpage_logo">
                  <a href="https://pythiangames.org/" target="_blank">
                    <img
                      src={require("../../assets/images/logo/logonew.png")}
                      height="160"
                      className="logo-image"
                    />
                  </a>
                </div>

                <img
                  src={require("../../assets/images/imageleft.png")}
                  className="loginleftimg"
                />
              </div>
            </div>

            <div className="loginpage_seciontwo">
              <div className="loginpage_sectiontwo_form">
                <form className="form">
                  <h5 className="text-center"> Welcome to</h5>
                  <h5 className="text-center"> Modern Pythian Games</h5>
                  <h2 className="text-center">Guest Login</h2>
                  <span className="errortext">OTP send to your Email</span>
                  <fieldset className="otpvalue">
                    <label>OTP</label>
                    <input
                      type="text"
                      placeholder="Enter your OTP"
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                    />
                    {submitted && !otp && (
                      <span className="errortext">OTP is required</span>
                    )}
                  </fieldset>

                  {errorMessage && (
                    <span className="errortext"> {errorMessage} </span>
                  )}

                  <button type="submit" className="btn" onClick={Submitlogin}>
                    {" "}
                    Login
                  </button>
                  {/* <div className="text-center mt-4">
                    <h6 className="mb-0">Don&apos;t have an account?</h6>
                    <Link to={"/register"} className="text-primary">
                      Register Here!
                    </Link>
                  </div> */}
                </form>
              </div>
            </div>
            <div className="loginpage_footer">
              <div className="copyrights">
                <span>
                  © {new Date().getFullYear()} Pythian Games | All Rights
                  Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default GuestOTPLogin;
