import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import baseUrl from "../Api";

export async function juryslanguage(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-languages`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function genderlist() {
  try {
    return await axios
      .get(`${baseUrl}/get-genders`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function memberlist() {
  try {
    return await axios
      .get(`${baseUrl}/get-membershiptypes`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function educationlist() {
  try {
    return await axios
      .get(`${baseUrl}/get-educations`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function occupationlist() {
  try {
    return await axios
      .get(`${baseUrl}/get-occupations`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function countrylist() {
  try {
    return await axios
      .get(`${baseUrl}/get-countries`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function currencylist(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-countries-currency/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function statelist(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-states/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function citylist(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-cities/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function interestcategory(data) {
  try {
    return await axios
      .get(`${baseUrl}/interested_event_category`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function registerdata(data) {
  try {
    return await axios
      .post(`${baseUrl}/user-registration`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//guest registration
export async function guestregistration(data) {
  try {
    return await axios
      .post(`${baseUrl}/guest-registration`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//guest registration with file uploads
export async function guestnewregistration(data) {
  try {
    return await axios
      .post(`${baseUrl}/new-guest-registration`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//guest registration with mail send
export async function guestmailsent(data) {
  try {
    return await axios
      .get(`${baseUrl}/guest-registration-mail/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function getorderpay(data, data1, amt) {
  try {
    return await axios
      .get(`${baseUrl}/payment_order/${data}/${data1}/${amt}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function paymentgateway(data) {
  try {
    return await axios
      .post(`${baseUrl}/user-payment`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
// for guest payment
export async function getguesteventpay(data, data1) {
  try {
    return await axios
      .get(`${baseUrl}/guest-event-order/${data}/${data1}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//guest payment response
export async function guestpaymentresponse(data) {
  try {
    return await axios
      .post(`${baseUrl}/guest-event-payment`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function getmobile(data) {
  try {
    return await axios
      .get(`${baseUrl}/user-mobile-excist/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//restrict mobile first name last name already exist
export async function restrictdetails(data, data1, data2) {
  try {
    return await axios
      .get(`${baseUrl}/mobile-excist/${data}/${data1}/${data2}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
export async function getemail(data) {
  try {
    return await axios
      .get(`${baseUrl}/user-email-excist/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Donar registration

export async function donarregistration(data) {
  try {
    return await axios
      .post(`${baseUrl}/create-doners`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Donar Order Creation

export async function donarordercreation(uid, cry, amt) {
  try {
    return await axios
      .get(`${baseUrl}/doner-payment-order/${uid}/${cry}/${amt}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//Donar After Payment Done
export async function donarpaymentdone(data) {
  try {
    return await axios
      .post(`${baseUrl}/update-doner-payment`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function fastatelist(id) {
  try {
    return await axios
      .get(`${baseUrl}/get-states/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function facategorylist(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-festival-category`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function faparcatelist(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-festival-category-type/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function fasumbit(data) {
  try {
    return await axios
      .post(`${baseUrl}/fest-event-submit`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

export async function Schoolfasumbit(data) {
  try {
    return await axios
      .post(`${baseUrl}/fest-event-school-submit`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

// fapaymentdone
export async function fapaymentdone(data) {
  try {
    return await axios
      .post(`${baseUrl}/fest-event-payment`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

// festival  main category
export async function famaincategory(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-festival-maincategory`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}
//fest Sub Category list
export async function fasubcategory(data) {
  try {
    return await axios
      .get(`${baseUrl}/get-festival-category/${data}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

//festival coupon code verify
export async function festcouponcode(data) {
  try {
    return await axios
      .post(`${baseUrl}/fest-event-dispayment`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        Catcherror(error);
      });
  } catch (error) {
    Catcherror(error);
  }
}

function Catcherror(error) {
  if (error.response.status === 401 || error.response.status === 422) {
    // window.location.replace("/Pythian/#/login");
    localStorage.clear();
    window.location.reload();
    <Link to={"/login"}></Link>;
  } else if (error.response.status === 0) {
    toast.error(error.message);
  } else if (error.response.status === 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status !== 200 &&
    error.response.data.status === false &&
    error.response.data.code !== 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    console.log("Error", error.message);
  }
}
