import React from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/login/login";
import Register from "./components/registration/register";
import Dashboard from "./components/dashboard/dashboard";
import Dashboardapp from "./components/dashboardapp";
import Network from "./components/network";
import Forgotpassword from "./components/forgot/password";
import Userlist from "./components/dashboard/users/userlist";
import Userview from "./components/dashboard/users/usersview";
import Approvedlist from "./components/dashboard/users/approvedlist";
import Removedlist from "./components/dashboard/users/removelist";
import Useradd from "./components/dashboard/users/useradd";
import Useredit from "./components/dashboard/users/useredit";
import Guestuseredit from "./components/dashboard/users/guestuseredit";
import Userdashboard from "./components/userdashboard/dashboard";
import Profile from "./components/userdashboard/profile";
import Blockedlist from "./components/dashboard/users/blockedlist";
import Guestregister from "./components/registration/guestregister";
import Category from "./components/dashboard/event/category";
import Editcategory from "./components/dashboard/event/editcategory";
import Addevent from "./components/dashboard/event/addevent";
import Entryeventlist from "./components/landing/entryeventlist";
import Eventlist from "./components/dashboard/event/eventlist";
import Usereventlist from "./components/userdashboard/eventlist";
import Userentryeventlist from "./components/userdashboard/entryeventlist";
import Entryregister from "./components/userdashboard/entryreg";
import LandingPage from "./components/landing/landingpage";
import EntryregisterTwo from "./components/registration/entryregtwo";
import Eventwiselist from "./components/dashboard/event/eventwiselist";
import Eventwiseview from "./components/dashboard/event/eventwiseview";
import Guestlogin from "./components/login/guestlogin";
import Scroll from "./components/scroll";
import Guestuserlist from "./components/dashboard/users/guestuserlist";
import Guestuserview from "./components/dashboard/users/guestusersview";
import GuestOTPLogin from "./components/login/guestotplogin";
import Guestdashview from "./components/guestdashboard/guestdashview";
import GuestDashboardapp from "./components/guestdashboardapp";
import Paymentlist from "./components/dashboard/users/paymentlist";
import Juryview from "./components/dashboard/jury/juryview";
import Juryregister from "./components/dashboard/jury/juryregistration";
import Jurydashboard from "./components/jurydashboard/dashboard";
import EventJurylist from "./components/jurydashboard/eventjurylist";
import JuryProfile from "./components/jurydashboard/profile";
import Eventview from "./components/jurydashboard/eventview";
import JuryEventwiseview from "./components/jurydashboard/eventwiseview";
import JuryEventwiselist from "./components/jurydashboard/eventwiselist";
import MasterRole from "./components/dashboard/master/role";
import MasterPgadmin from "./components/dashboard/master/pgadmin";
import Editadmin from "./components/dashboard/master/editadmin";
import Paymentlistview from "./components/dashboard/users/paymentlistview";
import Eventresult from "./components/dashboard/results/eventresult";
import Eventmark from "./components/dashboard/results/eventmark";
import Eventregistration from "./components/registration/eventregistration";
import PaymentSuccess from "./components/registration/success";
import { Fileupload } from "./components/registration/fileupload";
import SingleGuestregister from "./components/registration/singleguestregister";
import Juryregisteration from "./components/registration/juryregister";
import PendingJurylist from "./components/dashboard/jury/pendinglist";
import BlockedJurylist from "./components/dashboard/jury/blockedlist";
import RemovedJurylist from "./components/dashboard/jury/removedlist";
import ApprovedJurylist from "./components/dashboard/jury/approvedlist";
import JuryEdit from "./components/dashboard/jury/juryedit";
import Guestmembereventview from "./components/dashboard/event/eventguestview";
import { EventEntrieslist } from "./components/dashboard/entries/evententries";
import EventwiseCategorylist from "./components/dashboard/entries/categoryviewlist";
import { AddQuizEvent } from "./components/dashboard/event/addquiz";
import NewEventRegister from "./components/registration/neweventregister";
import { QuizTest1 } from "./components/registration/quizevent";
import { QuizTest2 } from "./components/registration/quizqn2";
import { QuizTest3 } from "./components/registration/quizqn3";
import { QuizTest4 } from "./components/registration/quizqn4";
import { QuizTest5 } from "./components/registration/quizqn5";
import Quizevent from "./components/dashboard/event/quizregister";
import PaymentWay from "./components/dashboard/paymentway";
import { DonationPage } from "./components/registration/donation";
import Donarlist from "./components/dashboard/donarlist";
import { Faregister } from "./components/registration/faregister";
import FestivaleventList from "./components/dashboard/festival/festivaleventlist";
import { GovtFaregister } from "./components/registration/govtfestregister";
function App() {
  return (
    <HashRouter>
      <Network />
      <Scroll />
      <Routes>
        <Route path="/" exact element={<Navigate to="/login" />} />
        {/* <Route path="/home" exact={true} element={<LandingPage />} /> */}

        <Route path="/login" exact={true} element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/festivalregister" element={<Faregister />} />
        <Route path="/schoolfestivalregister" element={<GovtFaregister />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />

        <Route path="/guestlogin" element={<Guestlogin />} />
        <Route path="/guestotplogin" element={<GuestOTPLogin />} />
      </Routes>
      <Routes>
        <Route element={<Dashboardapp />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users/pendingusers" element={<Userlist />} />
          <Route path="/users/approvedusers" element={<Approvedlist />} />
          <Route path="/users/removedusers" element={<Removedlist />} />
          <Route path="/users/blockedusers" element={<Blockedlist />} />
          <Route path="/users/userview/:id" element={<Userview />} />
          <Route path="/users/paymentview/:id" element={<Paymentlistview />} />
          <Route path="/users/adduser" element={<Useradd />} />
          <Route path="/users/useredit/:id" element={<Useredit />} />
          <Route path="/users/guestedit/:id" element={<Guestuseredit />} />
          <Route path="/users/paymentlist" element={<Paymentlist />} />

          <Route path="/festival/festivaleventlist" element={<FestivaleventList/>} />

          <Route path="/master/role" element={<MasterRole />} />
          <Route path="/master/pgadmin" element={<MasterPgadmin />} />
          <Route path="/editadmin/:id" element={<Editadmin />} />

          <Route path="/event/categorylist" element={<Category />} />
          <Route path="/event/editcategory/:id" element={<Editcategory />} />
          <Route path="/eventlist" element={<Eventlist />} />
          <Route path="/addevent" element={<Addevent />} />
          <Route path="/quizregister" element={<Quizevent />} />
          <Route path="/addquizevent" element={<AddQuizEvent />} />
          <Route path="/viewevent/:id" element={<Addevent />} />
          <Route path="/editevent/:id" element={<Addevent />} />
          <Route path="/eventwiselist/:id" element={<Eventwiselist />} />
          <Route path="/eventwiseview/:id" element={<Eventwiseview />} />
          {/* <Route path="/eventuserview/:id" element={<Eventwiseview />} /> */}
          <Route path="/eventresult" element={<Eventresult />} />
          <Route path="/eventmark/:id" element={<Eventmark />} />
          <Route path="/guestlist" element={<Guestuserlist />} />
          <Route path="/guestuserview/:id" element={<Guestuserview />} />
          <Route
            path="/guestmembereventview/:id"
            element={<Guestmembereventview />}
          />

          <Route path="/juryregister" element={<Juryregister />} />
          <Route path="/viewjury/:id" element={<Juryview />} />
          <Route path="/jury/editjury/:id" element={<JuryEdit />} />

          <Route path="/jury/approvedjury" element={<ApprovedJurylist />} />
          <Route path="/jury/pendingjury" element={<PendingJurylist />} />
          <Route path="/jury/removedjury" element={<RemovedJurylist />} />
          <Route path="/jury/blockedjury" element={<BlockedJurylist />} />

          <Route path="/user/dashboard" element={<Userdashboard />} />
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/user/eventlist" element={<Usereventlist />} />
          <Route path="/user/entryeventlist" element={<Userentryeventlist />} />
          <Route path="/user/entryeventreg/:id" element={<Entryregister />} />

          <Route path="/jury/dashboard" element={<Jurydashboard />} />
          <Route path="/jury/eventlist" element={<EventJurylist />} />
          <Route path="/jury/profile" element={<JuryProfile />} />
          <Route path="/jury/eventview/:id" element={<Eventview />} />
          <Route path="/jury/userlist/:id" element={<JuryEventwiselist />} />
          <Route path="/jury/eventview/:id" element={<JuryEventwiseview />} />
          <Route
            path="/jury/eventuserview/:id"
            element={<JuryEventwiseview />}
          />
          <Route
            path="/jury/eventguestview/:id"
            element={<JuryEventwiseview />}
          />
          <Route path="/entries" element={<EventEntrieslist />} />
          <Route
            path="/eventwisecategorylist/:id"
            element={<EventwiseCategorylist />}
          />
          <Route path="/paymentway" element={<PaymentWay />} />
          <Route path="/donarlist" element={<Donarlist />} />
        </Route>
      </Routes>

      <Routes>
        <Route element={<GuestDashboardapp />}>
          <Route path="/guest/details" element={<Guestdashview />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/neweventregistration" element={<NewEventRegister />} />
        <Route path="/quiztest" element={<QuizTest1 />} />
        <Route path="/quizqn2" element={<QuizTest2 />} />
        <Route path="/quizqn3" element={<QuizTest3 />} />
        <Route path="/quizqn4" element={<QuizTest4 />} />
        <Route path="/quizqn5" element={<QuizTest5 />} />
        <Route path="/entryeventlist/:id" element={<Entryeventlist />} />
        <Route path="/guest/register/:id" element={<Guestregister />} />
        <Route
          path="/guest/registeration/:id"
          element={<SingleGuestregister />}
        />
        <Route path="/donation" element={<DonationPage />} />
        <Route path="/event/registration" element={<Eventregistration />} />
        <Route path="/jury/registeration" element={<Juryregisteration />} />

        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/fileupload" element={<Fileupload />} />

        <Route
          path="/guest-event-submission/:id"
          element={<EntryregisterTwo />}
        />
      </Routes>
    </HashRouter>
  );
}
export default App;
