export const MENUITEMS = [
  {
    menutitle: "Navigation",
    Items: [
      {
        path: "/dashboard",
        icon: "ti-home",
        type: "link",
        active: false,
        selected: false,
        title: "Dashboard",
      },
      {
        title: "Users",
        icon: "ti-user",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/users/approvedusers",
            type: "link",
            active: false,
            selected: false,
            title: "Approved Users",
          },

          {
            path: "/guestlist",
            type: "link",
            active: false,
            selected: false,
            title: "Guest User List",
          },
          {
            path: "/users/paymentlist",
            type: "link",
            active: false,
            selected: false,
            title: "Payment List",
          },
        ],
      },
      {
        title: "Tournament / Event",
        icon: "ti-announcement",
        type: "sub",
        active: false,
        selected: false,
        children: [
          {
            path: "/eventlist",
            type: "link",
            active: false,
            selected: false,
            title: "List Events",
          },
        ],
      },
    ],
  },
];
