import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { categorywiseevents } from "../../services/landing";
import "./landing.css";
const Entryeventlist = () => {
  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Event Name",
      width: 350,
      field: "event_name",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Event Venue",
      width: 200,
      field: "event_venue",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Category",
      width: 170,
      field: "category",
      sortable: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Start Date",
      width: 150,
      field: "event_start",
      sortable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "End Date",
      width: 150,
      field: "event_end",
      sortable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "Entry Close Date",
      width: 150,
      field: "event_entry_close",
      sortable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Actions",
      width: 350,
      field: "event_uid",
      cellRenderer: function (params) {
        // const data = (CryptoJS.AES.encrypt(
        //   JSON.stringify(params.value),
        //   "secret123"
        // ).toString());
        const data = params.value
        return (
          <div>
            <Link
              to={"/login"}
              style={{ textDecoration: "underline", color: "#0d6efd" }}
            >
              Register as Membership{" "}
            </Link>{" "}
            &nbsp; &nbsp;
            <Link
              to={"/guest/register/" + data}
              style={{ textDecoration: "underline", color: "#0d6efd" }}
            >
              Register as Guest
            </Link>
          </div>
        );
      },
    },
  ];

  const { id } = useParams();
  useEffect(() => {
    categorywiseevents(id).then((response) => {
      if (response.status === true) {
        setFilterProduct(response.data);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Categorywise Event List
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <div className="App">
                  <div
                    className="ag-theme-alpine ag-style"
                    style={{ height: 600 }}
                  >
                    <AgGridReact
                      columnDefs={columnDefs}
                      rowData={filterProduct}
                      pagination="true"
                      paginationPageSize="10"
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Entryeventlist;
