import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Row, Tab, Nav } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
  guestuserdatalist,
  guestlistwithfile,
  guestlistwithoutfile,
} from "../../../services/admin";
import { delguest } from "../../../services/admin";
const Guestuserlist = () => {
  const gridRef = useRef();

  const [filterProduct, setFilterProduct] = useState([]);
  const [filterProducttwo, setFilterProducttwo] = useState([]);

  const columnDefs = [
    {
      headerName: "Actions",
      width: 140,
      field: "guest_uid",
      filter: false,
      //   cellRenderer: GuestUsericon,
      cellRenderer: function (params) {
        return (
          <div className="row">
            <div className="col-md-5 col-right">
              <Link to={"/guestuserview/" + params.value} className="btn-view">
                View
              </Link>
            </div>
            {/* <div className="col-md-5">
              <Link to={"/guestlist/"} className="btn-delete">
                <span onClick={(e) => delGuest(e, params.value)}>Delete</span>
              </Link>
            </div> */}
          </div>
        );
      },
    },
    {
      headerName: "Full Name",
      width: 250,
      field: "guest_firstname",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Gender",
      width: 200,
      field: "gender",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Country",
      width: 200,
      field: "country_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    // {
    //   headerName: "State",
    //   width: 200,
    //   field: "state_name",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   cellStyle: { textAlign: "left" },
    // },

    {
      headerName: "Language",
      width: 200,
      field: "language_name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Registered Date",
      width: 150,
      field: "reg_date",
      sortable: true,
      filter: true,
      floatingFilter: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD-MM-YYYY");
      },
    },
  ];

  useEffect(() => {
    userreload();
  }, []);
  let arr = [];
  let arr1 = [];
  function userreload() {
    setFilterProduct([]);
    setFilterProducttwo([]);
    guestlistwithfile().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              guest_firstname:
                response.data[i].guest_firstname +
                " " +
                response.data[i].guest_lastname,
              gender: response.data[i].gender,
              country_name: response.data[i].country_name,
              state_name: response.data[i].state_name,
              reg_date: response.data[i].reg_date,
              guest_uid: response.data[i].guest_uid,
              language_name: response.data[i].language_name
            });
          }
          setFilterProduct(arr);
        }
      }
    });
    guestlistwithoutfile().then((response) => {
      if (response.status === true) {
        if (response.data.length !== 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr1.push({
              guest_firstname:
                response.data[i].guest_firstname +
                " " +
                response.data[i].guest_lastname,
              gender: response.data[i].gender,
              country_name: response.data[i].country_name,
              state_name: response.data[i].state_name,
              reg_date: response.data[i].reg_date,
              guest_uid: response.data[i].guest_uid,
              language_name: response.data[i].language_name
            });
          }
          setFilterProducttwo(arr1);
        }
      }
    });
  }
  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">GUEST MEMBERS LIST</h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="panel panel-primary tabs-style-2">
                <div className=" tab-menu-heading">
                  <div className="tabs-menu1">
                    <Tab.Container defaultActiveKey="notallot">
                      <Nav
                        variant="pills"
                        className="nav panel-tabs main-nav-line"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="notallot" className="mt-1">
                            Without Document
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="allot" className="mt-1">
                            With Document
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="notallot" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  columnDefs={columnDefs}
                                  rowData={filterProducttwo}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>

                      <Tab.Content>
                        <Tab.Pane eventKey="allot" className="border">
                          <div className="responsive">
                            <div className="App">
                              <div
                                className="ag-theme-alpine ag-style"
                                style={{ height: 600, width: "100%" }}
                              >
                                <AgGridReact
                                  columnDefs={columnDefs}
                                  rowData={filterProduct}
                                  pagination="true"
                                  paginationPageSize="10"
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Guestuserlist;
