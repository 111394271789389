import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, FormFloating, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";

import {
  editimageupdate,
  edituserupdate,
  userdataview,
} from "../../../services/admin";
import {
  citylist,
  countrylist,
  educationlist,
  genderlist,
  getemail,
  getmobile,
  memberlist,
  occupationlist,
  statelist,
} from "../../../services/register";
import imageUrl from "../../../ImageUrl";
import "../../registration/registration.css";
import "./users.css";

const Useredit = () => {
  const [submit, setSubmit] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [moberror, setMobError] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [memtype, setMember] = useState("");
  const [memlist, setMemberlist] = useState([]);

  const [dob, setDOB] = useState("");

  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [glist, setGenderlist] = useState([]);

  const [education, setEducation] = useState("");
  const [elist, setEdulist] = useState([]);

  const [occu, setOccu] = useState("");
  const [olist, setOcculist] = useState([]);

  const [house, setHouse] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [colist, setCountrylist] = useState([]);

  const [state, setState] = useState("");
  const [slist, setStatelist] = useState([]);

  const [city, setCity] = useState("");
  const [clist, setCitylist] = useState([]);

  const [portfolio, setPortfolio] = useState("");
  const [shortinfo, setShort] = useState("");
  const [brief, setBrief] = useState("");

  const [primob, setPrimobile] = useState("");
  const [primobcode, setPriCode] = useState("");
  const [primobonly, setPriOnly] = useState("");

  const [secmob, setSecMobile] = useState("");
  const [secmobcode, setSecCode] = useState("");
  const [secmobonly, setSecOnly] = useState("");

  const handleprimary = (value, data, event, formattedValue) => {
    setPriCode(data.dialCode);
    setPrimobile(value);
    setPriOnly(value.slice(data.dialCode.length));
  };

  const handlesecondary = (value, data, event, formattedValue) => {
    setSecCode(data.dialCode);
    setSecMobile(value);
    setSecOnly(value.slice(data.dialCode.length));
  };

  function LetterOnly(eve) {
    if (/^[a-zA-Z\s]*$/.test(eve.key)) {
      return true;
    } else {
      eve.preventDefault();
      return false;
    }
  }

  function onPasteLetter(e) {
    const str = e.clipboardData.getData("Text");
    const newStr = str.replace(/[+-.]/g, "");
    if (str !== newStr) {
      e.preventDefault();
    }
  }

  function Selectcountry(e) {
    setCountry(e.target.value);
    setState("");
    setStatelist([]);
    setCity("");
    setCitylist([]);
    statelist(e.target.value).then((response) => {
      setStatelist(response.states);
    });
  }

  function Selectstate(e) {
    setState(e.target.value);
    setCity("");
    setCitylist([]);
    citylist(e.target.value).then((response) => {
      setCitylist(response.cities);
    });
  }
  function masterlist() {
    genderlist().then((response) => {
      // if (response.status === true) {
      setGenderlist(response.genders);
      // }
    });

    memberlist().then((response) => {
      setMemberlist(response.memshiptypes);
    });

    occupationlist().then((response) => {
      setOcculist(response.occupation);
    });

    educationlist().then((response) => {
      setEdulist(response.education);
    });

    countrylist().then((response) => {
      setCountrylist(response.country);
    });
  }

  const [valmob, setValMob] = useState("");
  function checkmobile(e) {
    e.preventDefault();
    setValMob("");
    setMobError("");
    getmobile(e.target.value).then((response) => {
      if (response.status === false) {
        setValMob(response.message);
        Swal.fire({
          title:
            response.message +
            " - " +
            response.data.username +
            " has been already have this Mobile Number",
          allowOutsideClick: false,
        });
      }
    });
  }

  const [valemail, setValEmail] = useState("");
  function checkemail(e) {
    e.preventDefault();
    setValEmail("");
    setEmailError("");
    getemail(e.target.value).then((response) => {
      if (response.status === false) {
        setValEmail(response.message);
        Swal.fire({
          title:
            response.message +
            " - " +
            response.data.username +
            " has been already have this Email",
          allowOutsideClick: false,
        });
      }
    });
  }

  const [valsecmob, setValSecMob] = useState("");
  function checksecmobile(e) {
    e.preventDefault();
    setValSecMob("");
    if (secmob.length < 10 && secmob !== "") {
      setValSecMob("Secondary Mobile Number must be 10 characters");
    }
    if (primob !== "") {
      if (secmob !== "") {
        if (secmob.length >= 10) {
          if (primob === secmob) {
            setValSecMob("Primary and Secondary Mobile should not be same");
          }
        } else {
          setValSecMob("Secondary Mobile Number must be 10 characters");
        }
      }
    }
  }

  const [formphoto, setPhotoFile] = useState("");
  const [formphotoupload, setPhotoUpload] = useState("");
  const [formphotoerror, setPhotoErrors] = useState("");

  const [formiderror, setIdErrors] = useState("");
  const [formid, setIDFile] = useState("");
  const [formidupload, setIDUpload] = useState("");
  function photofile(e) {
    e.preventDefault();
    setPhotoErrors("");
    setErrorMessage("");
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPhotoUpload(URL.createObjectURL(e.target.files[0]));
        setPhotoFile(e.target.files[0]);
      } else {
        setPhotoErrors("Photograph should be jpg or png or jpeg format");
      }
      var size = e.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setPhotoErrors("Photograph size should be less than 3MB");
      }
    }
  }

  function idfile(event) {
    setIdErrors("");
    if (event.target.files.length !== 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setIDUpload(URL.createObjectURL(event.target.files[0]));
        setIDFile(event.target.files[0]);
      } else {
        setIdErrors("Identity Profile should be jpg or png or jpeg format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 3) {
        setIdErrors("Identity Profile size should be less than 3MB");
      }
    }
  }

  function addsubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    setSubmit(true);
    if (formphotoupload === "" && formidupload === "") {
      setPhotoErrors("Photograph is required");
      setIdErrors("DOB Proof is required");
    }
    if (formphotoupload === "") {
      setPhotoErrors("Photograph is required");
    } else if (formidupload === "") {
      setIdErrors("DOB Proof is required");
    }
    if (formphotoupload !== "" && formidupload !== "") {
      setSubmit(true);
    }

    setEmailError("");
    setMobError("");

    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regEmail.test(email)) {
      setEmailError("Please Enter a valid Email Address");
    }

    if (primob.length < 10) {
      setMobError("Primary Mobile Number must be 10 characters");
    }

    if (
      fname !== "" &&
      lname !== "" &&
      memtype !== "" &&
      dob !== "" &&
      email !== "" &&
      valemail === "" &&
      emailerror === "" &&
      valmob === "" &&
      valsecmob === "" &&
      moberror === "" &&
      primob !== "" &&
      gender !== "" &&
      education !== "" &&
      occu !== "" &&
      house !== "" &&
      pincode !== "" &&
      country !== "" &&
      state !== "" &&
      city !== "" &&
      formphotoerror === "" &&
      formiderror === ""
    ) {
      const tempData = new FormData();
      tempData.append("user_uid", id);
      tempData.append("admin_uid", localStorage.getItem("Userid"));
      tempData.append("first_name", fname);
      tempData.append("last_name", lname);
      tempData.append("membership_type", memtype);
      tempData.append("email_id", email);
      tempData.append("primary_mobile", primobonly);
      tempData.append("secondary_mobile", secmobonly);
      tempData.append("primary_mob_country_code", primobcode);
      tempData.append("sec_mob_country_code", secmobcode);

      tempData.append("gender", gender);
      tempData.append("date_of_birth", dob);
      tempData.append("education", education);
      tempData.append("occupation", occu);
      tempData.append("street_no", house);
      tempData.append("pincode", pincode);
      tempData.append("country", country);
      tempData.append("state", state);
      tempData.append("city", city);
      tempData.append("portfolio", portfolio);
      tempData.append("short_info", shortinfo);
      tempData.append("introductory_brief", brief);
      // tempData.append("photo", formphoto);
      // tempData.append("identity", formid);

      const tempData1 = new FormData();
      tempData1.append("user_uid", id);
      tempData1.append("admin_uid", localStorage.getItem("Userid"));
      tempData1.append("photo", formphoto);
      tempData1.append("identity", formid);

      edituserupdate(tempData).then((response) => {
        if (response.status === true) {
          setSubmit(false);
          // if (formphoto !== "" || formid !== "") {
          editimageupdate(tempData1).then((response) => {
            if (response.status === true) {
              navigate(-1);
            } else {
              setErrorMessage(response.data);
            }
          });
          // }else{
          //   navigate(-1);
          // }
        } else {
          setErrorMessage(response.data);
        }
      });
    }
  }

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    masterlist();

    userdataview(id).then((response) => {
      if (response.status === true) {
        setFName(response.data.first_name);
        setLName(response.data.last_name);
        setMember(response.data.membership_type);
        setEmail(response.data.email);
        setPriOnly(response.data.primary_mobile);

        if (response.data.primary_mob_country_code !== null) {
          setPriCode(response.data.primary_mob_country_code);
          setPrimobile(
            "(+" +
              response.data.primary_mob_country_code +
              ") " +
              response.data.primary_mobile
          );
        } else {
          setPrimobile(response.data.primary_mobile);
        }

        setSecOnly(response.data.secondary_mobile);

        if (response.data.sec_mob_country_code !== null) {
          setSecCode(response.data.sec_mob_country_code);

          setSecMobile(
            "(+" +
              response.data.sec_mob_country_code +
              ") " +
              response.data.secondary_mobile
          );
        } else {
          setSecMobile(response.data.secondary_mobile);
        }
        setGender(response.data.gender_uid);
        setDOB(moment(response.data.date_of_birth).format("YYYY-MM-DD"));
        //   setRole(response.data.role_uid);

        setEducation(response.data.education_uid);
        setOccu(response.data.occupation_uid);
        setHouse(response.data.street_no);
        setCountry(response.data.country);
        setState(response.data.state);
        setCity(response.data.city);
        setPincode(response.data.pincode);

        setPortfolio(response.data.portfolio);
        setShort(response.data.short_info);
        setBrief(response.data.introductory_brief);
        setPhotoUpload(imageUrl + "user/photo/" + response.data.photo);
        setIDUpload(imageUrl + "user/id_proof/" + response.data.identity);

        if (response.data.country !== "") {
          statelist(response.data.country).then((response) => {
            setStatelist(response.states);
          });
        }

        if (response.data.state !== "") {
          citylist(response.data.state).then((response) => {
            setCitylist(response.cities);
          });
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">EDIT USER</h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <h5>Personal Information</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            First Name :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your First Name"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            maxLength={25}
                            value={fname}
                            onChange={(e) => {
                              setFName(e.target.value);
                            }}
                          />
                          {submit && !fname && (
                            <span className="errortxt text-danger">
                              First Name is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Last Name :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Last Name"
                            onKeyPress={LetterOnly}
                            onPaste={onPasteLetter}
                            maxLength={25}
                            value={lname}
                            onChange={(e) => {
                              setLName(e.target.value);
                            }}
                          />
                          {submit && !lname && (
                            <span className="errortxt text-danger">
                              Last Name is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Membership Type :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={memtype}
                            onChange={(e) => {
                              setMember(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Membership Type
                            </option>
                            {memlist.map((data, i) => {
                              return (
                                <option
                                  value={data.membership_type_uid}
                                  key={i}
                                >
                                  {data.membership_type_name}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !memtype && (
                            <span className="errortxt text-danger">
                              Membership Type is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Email Address :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            // onBlur={checkemail}
                            placeholder="Enter Your Email Address"
                            value={email}
                            maxLength={50}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            readOnly
                            disabled
                          />
                          {submit && !email && (
                            <span className="errortxt text-danger">
                              Email Address is required
                            </span>
                          )}
                          {emailerror !== "" && email && (
                            <span className="errortxt text-danger">
                              {emailerror}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Primary Mobile :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <PhoneInput
                            specialLabel={""}
                            country={"in"}
                            placeholder="Enter Primary Mobile Number"
                            value={primob}
                            onChange={handleprimary}
                            countryCodeEditable={false}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />

                          {submit && !primob && (
                            <span className="errortxt text-danger">
                              Primary Mobile is required
                            </span>
                          )}
                          {moberror !== "" && primob && (
                            <span className="errortxt text-danger">
                              {moberror}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">Secondary Mobile :</label>
                        </div>
                        <div className="col-md-8">
                          <PhoneInput
                            specialLabel={""}
                            country={"in"}
                            placeholder="Enter Secondary Mobile Number"
                            countryCodeEditable={false}
                            value={secmob}
                            onChange={handlesecondary}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />

                          {valsecmob !== "" && secmob && (
                            <span className="errortxt text-danger">
                              {valsecmob}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Gender :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Gender
                            </option>
                            {glist.map((data, i) => {
                              return (
                                <option value={data.gender_uid} key={i}>
                                  {data.gender}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !gender && (
                            <span className="errortxt text-danger">
                              Gender is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Date of Birth :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            value={dob}
                            onChange={(e) => {
                              setDOB(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />
                          {submit && !dob && (
                            <span className="errortxt text-danger">
                              Date of birth is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <h5>Primary Information</h5>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Education :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={education}
                            onChange={(e) => {
                              setEducation(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Qualification
                            </option>
                            {elist.map((data, i) => {
                              return (
                                <option value={data.education_uid} key={i}>
                                  {data.education}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !education && (
                            <span className="errortxt text-danger">
                              Education is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Occupation :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={occu}
                            onChange={(e) => {
                              setOccu(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your Occupation
                            </option>
                            {olist.map((data, i) => {
                              return (
                                <option value={data.occupation_uid} key={i}>
                                  {data.occupation}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !occu && (
                            <span className="errortxt text-danger">
                              Occupation is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            House No / Street No :
                            <b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter House / Street Number"
                            value={house}
                            onChange={(e) => {
                              setHouse(e.target.value);
                            }}
                          />
                          {submit && !house && (
                            <span className="errortxt text-danger">
                              House no. is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Pincode:<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter Your Pincode"
                            maxLength={6}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={pincode}
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                          />
                          {submit && !pincode && (
                            <span className="errortxt text-danger">
                              Pincode is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            Country :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select value={country} onChange={Selectcountry}>
                            <option value="" disabled>
                              Select Your Country
                            </option>
                            {colist.map((data, i) => {
                              return (
                                <option value={data.country_uid} key={i}>
                                  {data.country_name}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !country && (
                            <span className="errortxt text-danger">
                              Country is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            State :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select value={state} onChange={Selectstate}>
                            <option value="" disabled>
                              Select Your State
                            </option>
                            {slist.map((data, i) => {
                              return (
                                <option value={data.state_uid} key={i}>
                                  {data.state_name}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !state && (
                            <span className="errortxt text-danger">
                              State is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="username">
                            City :<b className="text-danger">*</b>
                          </label>
                        </div>
                        <div className="col-md-8">
                          <select
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Select Your City
                            </option>
                            {clist.map((data, i) => {
                              return (
                                <option value={data.city_uid} key={i}>
                                  {data.city_name}
                                </option>
                              );
                            })}
                          </select>
                          {submit && !city && (
                            <span className="errortxt text-danger">
                              City is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <h5>Additional Information</h5>
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="username">
                            Portfolio :{/* <b className="text-danger">*</b> */}
                          </label>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            rows={3}
                            placeholder="Enter Your Portfolio"
                            value={portfolio}
                            onChange={(e) => {
                              setPortfolio(e.target.value);
                            }}
                          />
                          {/* {submit && !portfolio && (
                            <span className="errortxt text-danger">
                              Portfolio is required
                            </span>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="username">
                            Short Info :{/* <b className="text-danger">*</b> */}
                          </label>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            rows={3}
                            placeholder="Enter Your Short Info"
                            value={shortinfo}
                            onChange={(e) => {
                              setShort(e.target.value);
                            }}
                          />
                          {/* {submit && !shortinfo && (
                            <span className="errortxt text-danger">
                              Short Info is required
                            </span>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="input-group">
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="username">Introductory Brief :</label>
                        </div>
                        <div className="col-md-9">
                          <textarea
                            rows={3}
                            placeholder="Enter Your Introductory Brief"
                            value={brief}
                            onChange={(e) => {
                              setBrief(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <h5>
                  File Uploads{" "}
                  <span>
                    ( Accepted Files are <b>.png, .jpg, .jpeg</b> & Max Upload
                    Size <b>3MB</b> )
                  </span>
                </h5>
                <div className="row">
                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-8">
                        <label htmlFor="dob">
                          1. Add Photograph : <b className="text-danger">*</b>
                        </label>

                        <div className="mt-4">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept=".png,.jpg,.jpeg"
                            onChange={photofile}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img
                            src={formphotoupload}
                            width="100%"
                            height="auto"
                          />
                        </div>
                      </div>
                    </div>
                    {formphotoerror !== "" && (
                      <span className="errortxt text-danger">
                        {formphotoerror}
                      </span>
                    )}
                  </div>

                  <div className="input-group form-upload">
                    <div className="row">
                      <div className="col-md-8">
                        <label htmlFor="dob">
                          2. Add Identity Profile :{" "}
                          <b className="text-danger">*</b>
                        </label>
                        <div className="mt-4">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept=".png,.jpg,.jpeg"
                            onChange={idfile}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="imagecontainer">
                          <img src={formidupload} width="100%" height="auto" />
                        </div>
                      </div>
                    </div>
                    {formiderror !== "" && (
                      <span className="errortxt text-danger">
                        {formiderror}
                      </span>
                    )}
                    {errorMessage !== "" && (
                      <span className="errortxt text-danger">
                        {errorMessage}
                      </span>
                    )}
                  </div>
                </div>
                <hr />
                <a
                  type="submit"
                  onClick={addsubmit}
                  className="btn btn-success"
                  style={{ float: "right" }}
                >
                  Update
                </a>

                <a
                  className="btn btn-prev btn-secondary"
                  style={{ float: "right" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Useredit;
