import moment from "moment/moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { deluser } from "../../../services/admin";
import imageUrl from "../../../ImageUrl";
import { approveuser, reasonlist, userdataview } from "../../../services/admin";
import "./users.css";

const Userview = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  function approveduser(e) {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure want to Approve?",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#6259ca",
      cancelButtonColor: "#dc3545",
      confirmButtonText: "Approve",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const appr = {
          user_uid: id,
          admin_uid: localStorage.getItem("Userid"),
          role_uid: role,
          verified_status: 1,
          verified_reason: "Approved",
        };

        approveuser(appr).then((response) => {
          if (response.status === true) {
            toast.success(response.message);
            navigate("/users/approvedusers/");
          } else {
            toast.error(response.message);
          }
        });
      }
    });
  }

  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }

  const [removerea, setRemoveres] = useState("");
  const [relist, setReasonlist] = useState([]);
  const [removemessage, setRemovemess] = useState("");

  function submitremove(e) {
    e.preventDefault();
    setRemovemess("");
    const temp = {
      user_uid: id,
      admin_uid: localStorage.getItem("Userid"),
      role_uid: role,
      verified_status: 2,
      verified_reason: removerea,
    };
    if (removerea !== "") {
      approveuser(temp).then((response) => {
        if (response.status === true) {
          setShow(false);
          toast.success(response.message);
          navigate(-1);
        } else {
          toast.error(response.message);
        }
      });
    } else {
      setRemovemess("Please Select Your Reason");
    }
  }

  const [bshow, setBlock] = useState(false);
  function BlockShow() {
    setBlock(true);
  }
  function BlockClose() {
    setBlock(false);
  }

  const [ishow, setImage] = useState(false);
  const [file, setFile] = useState(false);

  function ImageShow(ifile) {
    setFile(ifile);
    setImage(true);
  }
  function ImageClose() {
    setImage(false);
  }

  const [blockrea, setBlockres] = useState("");
  const [blockmessage, setBlockmess] = useState("");

  function submitblock(e) {
    e.preventDefault();
    setBlockmess("");
    const temp = {
      user_uid: id,
      admin_uid: localStorage.getItem("Userid"),
      role_uid: role,
      verified_status: 3,
      verified_reason: blockrea,
    };
    if (blockrea !== "") {
      approveuser(temp).then((response) => {
        if (response.status === true) {
          setBlock(false);
          toast.success(response.message);
          navigate(-1);
        } else {
          toast.error(response.message);
        }
      });
    } else {
      setBlockmess("Please Select Your Reason");
    }
  }

  //unblock
  function submitunblock(e) {
    e.preventDefault();

    const temp = {
      user_uid: id,
      admin_uid: localStorage.getItem("Userid"),
      role_uid: role,
      verified_status: 4,
      verified_reason: "unblock",
    };

    approveuser(temp).then((response) => {
      if (response.status === true) {
        setBlock(false);
        toast.success(response.message);
        navigate(-1);
      } else {
        toast.error(response.message);
      }
    });
  }
  const [fname, setFirst] = useState("");
  const [lname, setLast] = useState("");
  const [member, setMember] = useState("");
  const [email, setEmail] = useState("");
  const [pmobile, setPrimary] = useState("");
  const [smobile, setSecondary] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [uname, setUser] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");
  const [education, setEdu] = useState("");
  const [occu, setOccu] = useState("");
  const [street, setStreet] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [port, setPort] = useState("");
  const [short, setShort] = useState("");
  const [brief, setBrief] = useState("");
  const [photo, setPhoto] = useState("");
  const [identity, setIdentity] = useState("");

  const [usertype, setUserType] = useState("");
  const [paystatus, setPayStatus] = useState("");

  const [payid, setPayId] = useState("");
  const [payorder, setPayOrder] = useState("");
  const [payamt, setPayAmt] = useState("");
  const [paydate, setPayDate] = useState("");

  function delUser(e) {
    e.preventDefault();

    Swal.fire({
      title: `Are you sure want to Delete this user?`,
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deluser(id).then((response) => {
          if (response.status === true) {
            if (status === "Pending") {
              navigate("/users/pendingusers/");
            }
            if (status === "Blocked") {
              navigate("/users/blockedusers/");
            }
            if (status === "Approved") {
              navigate("/users/approvedusers/");
            }
          }
        });
      }
    });
  }
  useEffect(() => {
    userdataview(id).then((response) => {
      if (response.status === true) {
        setFirst(response.data.first_name);
        setLast(response.data.last_name);
        setMember(response.data.membership_type_name);
        setEmail(response.data.email);
        if (response.data.primary_mob_country_code !== null) {
          setPrimary(
            "(+" +
              response.data.primary_mob_country_code +
              ") " +
              response.data.primary_mobile
          );
        } else {
          setPrimary(response.data.primary_mobile);
        }

        if (response.data.sec_mob_country_code !== null) {
          setSecondary(
            "(+" +
              response.data.sec_mob_country_code +
              ") " +
              response.data.secondary_mobile
          );
        } else {
          setSecondary(response.data.secondary_mobile);
        }
        setGender(response.data.gender);
        setDOB(moment(response.data.date_of_birth).format("DD-MM-YYYY"));
        setUser(response.data.username);
        if (response.data.verified_status === 1) {
          setStatus("Approved");
        } else if (response.data.verified_status === 2) {
          setStatus("Removed");
        } else if (response.data.verified_status === 3) {
          setStatus("Blocked");
        } else {
          setStatus("Pending");
        }
        setRole(response.data.role_uid);
        setEdu(response.data.education);
        setOccu(response.data.occupation);
        setStreet(response.data.street_no);
        setCountry(response.data.country_name);
        setState(response.data.state_name);
        setCity(response.data.city_name);
        setPincode(response.data.pincode);
        setUserType(response.data.user_types);
        setPort(response.data.portfolio);
        setShort(response.data.short_info);
        setBrief(response.data.introductory_brief);
        setPhoto(imageUrl + "user/photo/" + response.data.photo);
        setIdentity(imageUrl + "user/id_proof/" + response.data.identity);
        setPayStatus(response.data.payment_status);
        setPayId(response.data.payment_uid);
        setPayOrder(response.data.order_uid);
        setPayAmt(response.data.payment_amount);
        setPayDate(response.data.payment_date);
      }
    });

    reasonlist().then((response) => {
      if (response.status === true) {
        setReasonlist(response.data);
      }
    });
  }, []);
  // const downloadImage = () => {
  //   saveAS("image_uri", photo); // Put your image url here.
  // };

  return (
    <Fragment>
      <div className="page-header reported-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Users Information{" "}
          </h2>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <div className="responsive">
                <h5>Personal Information</h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">First Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{fname}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Last Name</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{lname}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Membership Type</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{member}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Email Address</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{email}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Primary Mobile</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pmobile}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Secondary Mobile</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{smobile}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Gender</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{gender}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Date of Birth</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{dob}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Username</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{uname}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Status</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{status}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>Primary Information</h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Education</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{education}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Occupcation</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{occu}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Street / House No</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{street}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Country</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{country}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">State</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{state}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">City</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{city}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">Pincode</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{pincode}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="profile-group">
                      <div className="row">
                        <div className="col-md-4">
                          <b className="text-inverse">User Type</b>
                        </div>
                        <div className="col-md-8">
                          <span className="text-inverse">{usertype}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>Additional Information</h5>
                <div className="row profile-group">
                  <div className="col-md-4">
                    <b className="text-inverse">Portfolio</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{port}</span>
                  </div>
                </div>
                <div className="row profile-group">
                  <div className="col-md-4">
                    <b className="text-inverse">Short Info</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{short}</span>
                  </div>
                </div>
                <div className="row profile-group">
                  <div className="col-md-4">
                    <b className="text-inverse">Introductory Brief</b>
                  </div>
                  <div className="col-md-8">
                    <span className="text-inverse">{brief}</span>
                  </div>
                </div>
                <hr />
                <h5>Payment Details</h5>
                {paystatus != 1 && (
                  <div className="row profile-group">
                    <div className="col-md-4">
                      <b className="text-inverse">Payment Status</b>
                    </div>
                    <div className="col-md-8">
                      <span
                        className="text-inverse"
                        style={{
                          color: "red",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Not Paid
                      </span>
                    </div>
                  </div>
                )}

                {paystatus == 1 && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Payment Id</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{payid}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Order Id</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">{payorder}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Payment Amount</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">₹ {payamt} /-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Payment Date</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">
                              {moment(paydate).format("DD-MM-YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="profile-group">
                        <div className="row">
                          <div className="col-md-4">
                            <b className="text-inverse">Payment Status</b>
                          </div>
                          <div className="col-md-8">
                            <span className="text-inverse">Paid</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="link-btn">
                          <b className="text-inverse">Photograph</b>
                          <a>
                            <img
                              className="profile_img"
                              src={photo}
                              height="160"
                              alt="photo"
                            />
                          </a>
                          <div>
                            <a
                              onClick={(e) => ImageShow(photo)}
                              className="btn-small btn-s"
                              target="_blank"
                            >
                              View
                            </a>
                            <a
                              href={photo}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 bot-gap">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="link-btn">
                          <b className="text-inverse">Identity Proof</b>
                          <a>
                            <img
                              className="profile_img"
                              src={identity}
                              height="160"
                              alt="photo"
                            />
                          </a>
                          <div>
                            <a
                              onClick={(e) => ImageShow(identity)}
                              className="btn-small btn-s"
                              target="_blank"
                            >
                              View
                            </a>
                            <a
                              href={identity}
                              className="btn-small"
                              target="_blank"
                              download
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {localStorage.getItem("Roleid") !== "3" && (
                  <div className="text-center">
                    <a
                      className="btn btn-prev btn-secondary"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Back
                    </a>
                    {status === "Pending" && (
                      <a
                        className="btn btn-prev btn-success"
                        onClick={(e) => approveduser(e)}
                      >
                        Approve
                      </a>
                    )}
                    <a className="btn btn-prev btn-danger" onClick={delUser}>
                      Delete
                    </a>
                    <a
                      className="btn btn-prev btn-info"
                      onClick={() => {
                        navigate("/users/useredit/" + id);
                      }}
                    >
                      Edit
                    </a>
                    {status !== "Removed" && (
                      <a
                        className="btn btn-prev btn-danger"
                        onClick={handleShow}
                      >
                        Remove
                      </a>
                    )}
                    {status === "Pending" && (
                      <a
                        className="btn btn-prev btn-primary"
                        onClick={BlockShow}
                      >
                        Block
                      </a>
                    )}

                    {status === "Approved" && (
                      <a
                        className="btn btn-prev btn-primary"
                        onClick={BlockShow}
                      >
                        Block
                      </a>
                    )}
                    {status === "Blocked" && (
                      <a
                        className="btn btn-prev btn-primary"
                        onClick={submitunblock}
                      >
                        Unblock
                      </a>
                    )}
                  </div>
                )}

                {localStorage.getItem("Roleid") === "3" && (
                  <div className="text-right">
                    <a
                      className="btn btn-prev btn-secondary"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Back
                    </a>
                  </div>
                )}
              </div>

              <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header>
                  <h4>Remove User</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">Remove Reason</label>
                      </div>
                      <div className="col-md-8">
                        <select
                          value={removerea}
                          onChange={(e) => {
                            setRemoveres(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Reason
                          </option>
                          {relist.map((data, i) => {
                            return (
                              <option value={data.reason} key={i}>
                                {data.reason}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">Removed By </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          value={localStorage.getItem("Name")}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  {removemessage !== "" && (
                    <span className="errortxt text-danger">
                      {removemessage}
                    </span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-prev btn-danger"
                    style={{ float: "right" }}
                    onClick={submitremove}
                  >
                    Remove
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={bshow} size="lg" onHide={BlockClose}>
                <Modal.Header>
                  <h4>Block User</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">Block Reason</label>
                      </div>
                      <div className="col-md-8">
                        <select
                          value={blockrea}
                          onChange={(e) => {
                            setBlockres(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            Select Reason
                          </option>
                          {relist.map((data, i) => {
                            return (
                              <option value={data.reason} key={i}>
                                {data.reason}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="input-group">
                      <div className="col-md-4">
                        <label htmlFor="username">Blocked By </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          value={localStorage.getItem("Name")}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  {blockmessage !== "" && (
                    <span className="errortxt text-danger">{blockmessage}</span>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-prev btn-danger"
                    style={{ float: "right" }}
                    onClick={submitblock}
                  >
                    Block
                  </a>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={BlockClose}
                  >
                    Cancel
                  </a>
                </Modal.Footer>
              </Modal>

              <Modal show={ishow} onHide={ImageClose}>
                <Modal.Body className="hhh">
                  <img
                    className="profile_img prof-img"
                    src={file}
                    height="160"
                    alt="photo"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <a
                    className="btn btn-next btn-secondary"
                    style={{ float: "right" }}
                    onClick={ImageClose}
                  >
                    Close
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Userview;
